import { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAPILogs, useAPILogsChart, useWorkflowDetails } from 'apis';
import { useAuthState, useEnvState } from 'stores/AuthStore';
import { Spinner } from 'components';
import { TopFilters } from 'pages/Logs/RequestLogs/Filters';
import RequestLogsTable from 'pages/Logs/RequestLogs/ListTable';
import { Header } from '../utils';

export default function WorkflowRequests() {
  const { slug } = useParams();
  const history = useHistory();
  let { search } = useLocation();
  const envState = useEnvState();
  const authState = useAuthState();

  const [autoRefresh, setAutoRefresh] = useState(true);
  const [openLogDetails, setOpenLogDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const queryParam = new URLSearchParams(search);

  const { data: workflow } = useWorkflowDetails(slug);

  const liveVersionData = workflow?.live_version;
  let mandatoryFilters;
  let apiTypeOptions;

  if (workflow?.is_broadcast) {
    apiTypeOptions = [{ name: 'Broadcast', value: 'broadcast' }];
    mandatoryFilters = { api_type: 'broadcast', api_name: workflow.name };
  } else if (workflow?.is_dynamic) {
    apiTypeOptions = [{ name: 'Workflow', value: 'workflow' }];
    mandatoryFilters = { api_type: 'workflow', api_name: workflow.name };
  } else if (workflow && !workflow?.is_dynamic) {
    apiTypeOptions = [
      { name: 'Event', value: 'event' },
      { name: 'Workflow', value: 'workflow' },
    ];
    mandatoryFilters = {
      api_type: ['workflow', 'event'],
      api_name: [workflow.slug],
    };
    const triggerEvents = liveVersionData?.trigger_events;
    if (Array.isArray(triggerEvents)) {
      mandatoryFilters.api_name = [
        ...mandatoryFilters.api_name,
        ...triggerEvents,
      ];
    }
  }

  const { data, isFetching, refetch } = useAPILogs({
    queryString: search,
    mandatoryFilters,
    automaticRefetchTime: autoRefresh ? 15000 : 0,
    enabled: !openLogDetails,
  });

  const {
    data: chartData,
    isLoading: chartLoading,
    isError: chartError,
    refetch: refetchChart,
  } = useAPILogsChart({
    queryString: search,
    mandatoryFilters,
    automaticRefetchTime: autoRefresh ? 15000 : 0,
    enabled: !openLogDetails,
  });

  useEffect(() => {
    if (!isFetching) {
      setShowLoader(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!queryParam.get('last_n_minutes') && !queryParam.get('start_ts')) {
      history.push('?last_n_minutes=1440');
    }
  }, []);

  const allEnv = authState.organization.get()?.environments;
  const currentEnv = allEnv?.find(
    obj => obj?.slug === envState.currentEnv.get()
  );

  const hasTenant = Number(currentEnv?.tenants_count) > 1;

  if (!workflow) {
    return <Spinner />;
  }

  if (!data?.results || showLoader) {
    return (
      <div>
        <Header workflow={workflow} selectedId={'requests'} />
        <div className="flex justify-end mb-6 mr-4 mt-3">
          <TopFilters
            queryString={queryParam}
            autoRefresh={autoRefresh}
            setAutoRefresh={setAutoRefresh}
            handleRefresh={() => {
              refetch();
              refetchChart();
            }}
            loader={isFetching && !showLoader}
          />
        </div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      <Header workflow={workflow} selectedId={'requests'} />
      <div className="flex justify-end mb-6 mr-4 mt-3">
        <TopFilters
          queryString={queryParam}
          autoRefresh={autoRefresh}
          setAutoRefresh={setAutoRefresh}
          handleRefresh={() => {
            refetch();
            refetchChart();
          }}
          loader={isFetching && !showLoader}
        />
      </div>
      <div className="p-4">
        <RequestLogsTable
          requestsData={data}
          queryParam={queryParam}
          chartData={chartData}
          hasTenant={hasTenant}
          setShowLoader={setShowLoader}
          openLogDetails={openLogDetails}
          setOpenLogDetails={setOpenLogDetails}
          apiTypeOptions={apiTypeOptions}
        />
      </div>
    </div>
  );
}

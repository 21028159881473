import { useState, useContext } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AceEditor from 'react-ace';
import { useReactFlow } from 'reactflow';
import {
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  Button,
  Textarea,
  Dialog,
  DialogContent,
} from 'new-components';
import { toast } from 'components';
import { useUpdateNodeProperties } from 'apis';
import { PlusIcon, XIcon } from 'lucide-react';
import { NodeFormHeader } from './Common';
import { FlowContext } from '../WorkflowDetails';
import { ReactComponent as DataTransformationIcon } from 'assets/svgs/dataTransformationIcon.svg';
import { ReactComponent as ExpandIcon } from 'assets/svgs/expandIcon.svg';
import { cn, MISSING_FIELD_ERROR } from 'utils';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

const defaultVariable = {
  key: '',
  value: '',
  value_lang: 'handlebars',
};

const validateBraces = input => {
  if (input) {
    let previousInput;
    let currentInput = input;

    // Regex patterns for double and triple braces
    const patternDouble = /{{[^{}]*}}/g;
    const patternTriple = /{{{[^{}]*}}}/g;

    // Iteratively remove all matched double and triple braces from the input
    do {
      previousInput = currentInput;
      currentInput = currentInput.replace(patternTriple, '');
      currentInput = currentInput.replace(patternDouble, '');
    } while (currentInput !== previousInput);

    // Check if any braces remain unpaired
    const unmatchedBraces = /[{}]/.test(currentInput);

    return !unmatchedBraces;
  }
  return false;
};

const dtValidationSchema = Yup.object().shape({
  variables: Yup.array().of(
    Yup.object().shape({
      value_lang: Yup.string(),
      key: Yup.string().required(MISSING_FIELD_ERROR),
      value: Yup.string().when('value_lang', {
        is: 'jsonnet',
        then: Yup.string().required(MISSING_FIELD_ERROR),
        otherwise: Yup.string()
          .required(MISSING_FIELD_ERROR)
          .test(
            'is-valid-handlebars-variables',
            'Invalid handlebars format',
            value => validateBraces(value)
          ),
      }),
    })
  ),
});

function VariableList({
  values,
  disabled,
  setFieldValue,
  editMode,
  setSelectedForm,
  setSelectedNode,
  nodeProperties,
  updateProperties,
  setExpandedView,
  expandedView,
  handleSubmit,
}) {
  return (
    <div>
      <div className="flex items-center mb-4">
        <p className="text-foreground font-semibold text-base">Variable List</p>
        {!expandedView && (
          <div
            onClick={() => {
              setExpandedView(true);
            }}
            className="cursor-pointer ml-auto"
          >
            <ExpandIcon />
          </div>
        )}
      </div>
      <div className={cn(expandedView && 'h-[572px] overflow-y-scroll')}>
        {values?.variables?.map((variable, index) => {
          const isJSONNET = variable?.value_lang === 'jsonnet';
          const currentVariable = nodeProperties?.variables?.find(
            item => item.key === variable.key
          );

          return (
            <div
              className="border border-dashed border-input rounded-lg w-full px-2.5 pt-2.5 mb-3 pb-1"
              key={index}
            >
              {editMode && values?.variables?.length > 1 && (
                <Button
                  variant="link"
                  className="hover:no-underline p-0 m-0 h-0 float-right mt-2"
                  onClick={() => {
                    const removeVariable = [...values?.variables];
                    removeVariable.splice(index, 1);
                    setFieldValue(`variables`, removeVariable);
                  }}
                >
                  <XIcon className="h-4 w-4 cursor-pointer text-muted-foreground" />
                </Button>
              )}
              <div className="mt-2">
                <Label>Key</Label>
                <Input
                  name={`variables[${index}].key`}
                  value={variable?.key}
                  onChange={e => {
                    setFieldValue(`variables[${index}].key`, e.target.value);
                  }}
                  className="mt-1"
                  placeholder="paid_plan"
                  disabled={disabled}
                />
                <ErrorMessage
                  name={`variables[${index}].key`}
                  component="div"
                  className="text-sm mt-1 text-destructive"
                />
              </div>
              <div className="mt-4">
                <div className="flex items-center">
                  <Label>Value</Label>
                  <Select
                    value={variable?.value_lang}
                    onValueChange={val => {
                      const modifiedValue =
                        currentVariable?.value_lang === val
                          ? currentVariable?.value
                          : '';

                      setFieldValue(`variables[${index}].value_lang`, val);
                      setFieldValue(`variables[${index}].value`, modifiedValue);
                    }}
                    disabled={disabled}
                  >
                    <SelectTrigger
                      className={cn(
                        'max-w-[100px] ml-auto focus:ring-0 outline-none focus:outline-none shadow-none border-0 p-0',
                        isJSONNET ? 'uppercase' : 'capitalize'
                      )}
                    >
                      {variable?.value_lang}
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="handlebars">Handlebars</SelectItem>
                      <SelectItem value="jsonnet">JSONNET</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {isJSONNET ? (
                  <>
                    <AceEditor
                      mode="json"
                      theme="github"
                      value={variable?.value}
                      onChange={value =>
                        setFieldValue(`variables[${index}].value`, value)
                      }
                      readOnly={disabled}
                      name="json-editor"
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{ useWorker: false }}
                      width="100%"
                      height="120px"
                      className="border border-border rounded"
                    />

                    <div className="flex">
                      <ErrorMessage
                        name={`variables[${index}].value`}
                        component="div"
                        className="text-sm mt-1 text-destructive"
                      />
                      <Button
                        type="button"
                        variant="link"
                        className="text-xs font-normal ml-auto px-0 py-0 h-7"
                        onClick={() => {
                          window.open(
                            'https://jsonnet.org/ref/language.html',
                            '_blank'
                          );
                        }}
                      >
                        Jsonnet reference
                      </Button>
                    </div>
                  </>
                ) : (
                  <div>
                    <Textarea
                      name={`variables[${index}].value`}
                      value={variable?.value}
                      onChange={e => {
                        setFieldValue(
                          `variables[${index}].value`,
                          e.target.value
                        );
                      }}
                      rows={4}
                      disabled={disabled}
                      placeholder="{{#compare pricing '==' “paid”}} true {{/compare}}"
                    />
                    <div className="flex">
                      <ErrorMessage
                        name={`variables[${index}].value`}
                        component="div"
                        className="text-sm mt-1 text-destructive"
                      />
                      <Button
                        type="button"
                        variant="link"
                        className="text-xs font-normal ml-auto px-0 py-0 h-7"
                        onClick={() => {
                          window.open(
                            'https://docs.suprsend.com/docs/handlebars-helpers',
                            '_blank'
                          );
                        }}
                      >
                        Handlebars helpers
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <Button
          type="button"
          variant="outline"
          className={cn('px-2 h-7', expandedView && 'mb-6', 'mt-4 w-[130px]')}
          onClick={() => {
            const addVariable = [...values?.variables, defaultVariable];
            setFieldValue('variables', addVariable);
          }}
          disabled={disabled}
        >
          <PlusIcon className="h-4 w-4 mr-2 text-accent-foreground" />
          <p className="text-accent-foreground"> Add Variable </p>
        </Button>
      </div>
      {editMode && (
        <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-background drop-shadow border-t z-50">
          <Button
            type="button"
            className="flex-grow justify-center"
            variant="outline"
            onClick={() => {
              if (expandedView) {
                setExpandedView(false);
              } else {
                setSelectedForm('nodes_list');
                setSelectedNode({});
              }
            }}
          >
            {expandedView ? 'Cancel' : 'Close'}
          </Button>
          <Button
            onClick={handleSubmit}
            className="flex-grow justify-center ml-2"
            disabled={updateProperties.isLoading}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

export default function DataTransformation() {
  const [expandedView, setExpandedView] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState();
  const {
    selectedNode,
    editMode,
    slug,
    version,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);
  const nodeProperties = nodeData?.data?.properties;

  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );

  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  return (
    <div className="overflow-scroll h-full">
      <div className="m-4 mt-6">
        <NodeFormHeader
          name="Data Transform"
          helptext="Generate or override variable using input"
          Icon={DataTransformationIcon}
          docLink="https://docs.suprsend.com/docs/data-transform"
        />
        <p className="text-accent-foreground text-sm px-4 py-2 bg-muted rounded mt-4">
          We use shallow merge strategy. Keys with the same name as input
          payload will be overridden.
        </p>
        <div className="mt-5">
          {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
            <div className="mb-6 text-xs -mt-2 text-destructive bg-destructive-muted p-3 rounded">
              <ul>
                {fieldErrors &&
                  Object.keys(fieldErrors)?.map((item, index) => {
                    return (
                      <li key={index}>
                        - {item}: {fieldErrors[item]}
                      </li>
                    );
                  })}
                {nonFieldErrors?.map((item, index) => {
                  return <li key={index}>- {item}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
        <Formik
          initialValues={{
            variables:
              nodeProperties?.variables?.length > 0
                ? nodeProperties?.variables
                : [defaultVariable],
          }}
          enableReinitialize={true}
          validationSchema={dtValidationSchema}
          onSubmit={async values => {
            try {
              setLoading(true);
              await updateProperties.mutateAsync({ properties: values });
              setShowFormErrors(true);
              setLoading(false);
              if (expandedView) {
                setExpandedView(false);
              }
              toast('Settings saved successfully', '', { autoClose: 1000 });
            } catch (e) {
              setLoading(false);
            }
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            const disabled = !editMode;
            return (
              <Form className="mb-24">
                <VariableList
                  values={values}
                  disabled={disabled}
                  setFieldValue={setFieldValue}
                  editMode={editMode}
                  setSelectedForm={setSelectedForm}
                  setSelectedNode={setSelectedNode}
                  updateProperties={updateProperties}
                  nodeProperties={nodeProperties}
                  setExpandedView={setExpandedView}
                  expandedView={expandedView}
                  handleSubmit={handleSubmit}
                />
                <Dialog open={expandedView} onOpenChange={setExpandedView}>
                  <DialogContent className="max-w-2xl h-[700px]">
                    <VariableList
                      values={values}
                      disabled={disabled}
                      setFieldValue={setFieldValue}
                      editMode={editMode}
                      setSelectedForm={setSelectedForm}
                      setSelectedNode={setSelectedNode}
                      updateProperties={updateProperties}
                      nodeProperties={nodeProperties}
                      setExpandedView={setExpandedView}
                      expandedView={expandedView}
                      handleSubmit={handleSubmit}
                    />
                  </DialogContent>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Info } from 'lucide-react';
import { Spinner, PageHeading } from 'components';
import { useWorkflowExecutionLogs, useExecutionFilterCount } from 'apis';
import { useAuthState, useEnvState } from 'stores/AuthStore';
import LogsTab from '../LogsTab';
import { TopFilters } from './Filters';
import { breadcrumbs } from '../utils';
import ListTable from 'pages/Workflow/Executions/ListTable';
import { MainFilters } from './Filters';

function Header({
  queryString,
  autoRefresh,
  setAutoRefresh,
  handleRefresh,
  loader,
}) {
  return (
    <div>
      <Helmet>
        <title>Workflow Executions</title>
      </Helmet>
      <div className="px-4 mt-1">
        <PageHeading breadcrumbs={breadcrumbs}>
          <TopFilters
            queryString={queryString}
            autoRefresh={autoRefresh}
            setAutoRefresh={setAutoRefresh}
            handleRefresh={handleRefresh}
            loader={loader}
          />
        </PageHeading>
      </div>
      <div className="mt-2 border-b">
        <LogsTab />
      </div>
    </div>
  );
}

export default function LogsExecutionsList() {
  const history = useHistory();
  let { search } = useLocation();
  const envState = useEnvState();
  const authState = useAuthState();

  const [autoRefresh, setAutoRefresh] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const queryParam = new URLSearchParams(search);

  const {
    data: workflowRuns,
    refetch: refetchWfRuns,
    isFetching: wfRunsFetching,
  } = useWorkflowExecutionLogs({
    queryString: search,
    automaticRefetchTime: autoRefresh ? 15000 : 0,
  });
  const { data: filtersCount } = useExecutionFilterCount({
    queryString: search,
  });

  useEffect(() => {
    if (!queryParam.get('last_n_minutes') && !queryParam.get('start_ts')) {
      history.push('?last_n_minutes=1440');
    }
  }, []);

  useEffect(() => {
    if (!wfRunsFetching) {
      setShowLoader(false);
    }
  }, [wfRunsFetching]);

  const allEnv = authState.organization.get()?.environments;
  const currentEnv = allEnv?.find(
    obj => obj?.slug === envState.currentEnv.get()
  );

  const hasTenant = Number(currentEnv?.tenants_count) > 1;

  if (!workflowRuns?.results || showLoader) {
    return (
      <div>
        <Header
          queryString={queryParam}
          autoRefresh={autoRefresh}
          setAutoRefresh={setAutoRefresh}
          loader={wfRunsFetching && !showLoader}
          handleRefresh={() => {
            refetchWfRuns();
          }}
        />
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Header
        queryString={queryParam}
        autoRefresh={autoRefresh}
        setAutoRefresh={setAutoRefresh}
        loader={wfRunsFetching && !showLoader}
        handleRefresh={() => {
          refetchWfRuns();
        }}
      />

      <div className="grid grid-cols-10 p-4 gap-4">
        <div className="col-span-2">
          <div className="border rounded">
            <MainFilters
              queryParam={queryParam}
              hasTenant={hasTenant}
              filtersCount={filtersCount}
            />
          </div>
        </div>
        <div className="col-span-8">
          <ListTable
            executionsData={workflowRuns}
            queryParam={queryParam}
            setShowLoader={setShowLoader}
          />
        </div>
      </div>
    </div>
  );
}

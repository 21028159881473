import { useContext } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { TimezonePreferenceContext } from 'custom-components';
import { toDate, formatInTimeZone } from 'date-fns-tz';

export default function TimeAgo({ dateInput, ...props }) {
  const { timezone, showRelative } = useContext(TimezonePreferenceContext);
  let date = dateInput;
  const [, setTime] = useState(Date.now());

  useEffect(() => {
    if (showRelative) {
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  if (!dateInput) return null;

  let formattedDate;
  if (typeof dateInput === 'string') formattedDate = new Date(dateInput);
  if (typeof dateInput === 'number') formattedDate = new Date(dateInput * 1000);
  date = toDate(formattedDate);

  return (
    <p {...props}>
      {showRelative
        ? formatDistanceToNowStrict(date, {
            addSuffix: true,
            locale: enGB,
          })
        : formatInTimeZone(date, timezone?.value, 'dd LLL yyyy HH:mm:ss')}
    </p>
  );
}

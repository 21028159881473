import { useState, useEffect, Fragment } from 'react';
import { DurationDiff, Tooltip, TimeAgo } from 'components';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid';
import { ClockIcon } from '@heroicons/react/outline';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';
import { differenceInMilliseconds, formatDistanceStrict } from 'date-fns';
import { copyToClipboard } from 'utils';
import { useEnvState } from 'stores/AuthStore';
import urlUtils from 'utils/urlUtils';
import { useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

const maskContent = (text, currentEnv) => {
  if (!text) return '';
  return currentEnv === 'sandbox' || currentEnv === 'staging'
    ? text
    : text.replaceAll(/(?<=\w{2,})\w/g, '*');
};

const workflowDetail = log => {
  return [
    {
      id: 'wf_exec_temporal_wf_id',
      label: 'Execution ID',
      value: log?.wf_exec_temporal_wf_id,
      tooltip: 'share this ID with the support for debugging issues',
      position: 'right',
    },
    {
      id: 'distinct_id',
      label: 'Distinct ID',
      value: log?.distinct_id,
      tooltip: '',
    },
    {
      id: 'tenant_id',
      label: 'Brand ID',
      value: log?.tenant_id,
      tooltip: 'brand mentioned in the workflow / event payload',
      position: 'right',
    },
    {
      id: 'idempotency_key',
      label: 'Idempotency_key',
      value: log?.idempotency_key,
      tooltip:
        'unique-id passed in API request to avoid duplicate calls / retries',
      position: 'bottom',
    },
    {
      id: 'triggered_at',
      label: 'triggered_at',
      value: log?.wf_exec_timestamps?.triggered_at,
      tooltip: '',
    },
    {
      id: 'wf_exec_status',
      label: 'Status',
      value: log?.wf_exec_status,
      tooltip: '',
    },
    {
      id: 'success',
      label: 'Success Metric',
      value: log?.ngs?.[0]?.ng_delivery?.success,
      tooltip: '',
    },
    {
      id: 'success_achieved_at',
      label: 'Succes Metric Achieved at',
      value: log?.ngs?.[0]?.ng_delivery?.success_achieved_at,
      tooltip: '',
    },
  ];
};

const status = {
  success: [
    'triggered',
    'sent_by_vendor',
    'delivered',
    'dismissed',
    'seen',
    'interacted',
  ],
  delivered: ['delivered', 'dismissed', 'seen', 'interacted'],
  seen: ['seen', 'interacted'],
  error: ['trigger_failed', 'failure_by_vendor', 'not_delivered'],
  progress: ['to_be_triggered'],
  not_to_be_triggered: ['not_to_be_triggered'],
};

function deSlugify(slug) {
  var words = slug.split('_');

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
}

const VendorFallbackTimeDiff = ({
  ng_notification_statuses,
  index,
  log,
  chstatus,
}) => {
  if (chstatus?.vfallback_applicable && chstatus?.vfallback_level !== 0) {
    return (
      <div className="-mt-9 ml-2">
        <TimeDiff
          fromDate={ng_notification_statuses[index - 1]?.triggered_at}
          toDate={chstatus.triggered_at}
        />
      </div>
    );
  }
  return (
    <TimeDiff
      fromDate={log.wf_exec_timestamps.triggered_at}
      toDate={chstatus.triggered_at}
    />
  );
};

const LogStatus = ({ log, successChannels }) => {
  if (log?.wf_exec_status === 'in_progress') {
    return (
      <p>
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
          In Progress
        </span>
      </p>
    );
  } else if (
    (!log?.ngs &&
      !log?.wf_exec_failure_reason &&
      log?.execution_errors?.length === 0) ||
    successChannels > 0
  ) {
    return (
      <p>
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          Completed
        </span>
      </p>
    );
  }
  return (
    <p>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        Failed
      </span>
    </p>
  );
};

export default function LogsTable({
  logs,
  meta,
  currentPage,
  setCurrentPage,
  setShowLoader,
  ...props
}) {
  if (logs && logs.length > 0) {
    return (
      <>
        <div className="flex bg-gray-50 border-t">
          <div className="w-6 m-3"></div>
          <div className="grid grid-cols-12 text-sm font-medium flex-grow">
            <p className="p-2 col-span-2">Timestamp</p>
            <p className="p-2 col-span-2">Workflow</p>
            <p className="p-2 col-span-2">Distinct ID</p>
            <p className="p-2 col-span-2">Notification Category</p>
            <p className="p-2 col-span-2">User Channel</p>
            <p className="p-2 col-span-2">Status</p>
          </div>
        </div>
        <ul className="divide-y divide-gray-200 border-t">
          {logs.map(function (item, idx) {
            if (item.record_type === 'validation') {
              return (
                <LogItemFailed
                  key={item.ve_event_id}
                  log={item}
                  index={idx}
                ></LogItemFailed>
              );
            } else {
              if (item.wf_exec_status === 'failed') {
                return (
                  <LogItemFailed
                    key={item.wf_exec_id}
                    log={item}
                    index={idx}
                  ></LogItemFailed>
                );
              } else if (
                item.wf_exec_status === 'completed' ||
                item.wf_exec_status === 'in_progress'
              ) {
                return (
                  <LogItemSuccess
                    key={item.wf_exec_id}
                    log={item}
                    index={idx}
                  ></LogItemSuccess>
                );
              } else if (item.wf_exec_status === 'to_be_triggered') {
                return (
                  <LogItemProgress
                    key={item.wf_exec_id}
                    log={item}
                    index={idx}
                  ></LogItemProgress>
                );
              }
            }
          })}
        </ul>
        <nav
          className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">{currentPage * 20 + 1}</span> to{' '}
              <span className="font-medium">
                {Math.min(currentPage * 20 + 20, meta.count)}
              </span>{' '}
              of <span className="font-medium">{meta.count}</span> results
            </p>
          </div>
          <div className="flex-1 flex justify-between sm:justify-end">
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
                setShowLoader && setShowLoader(true);
              }}
              disabled={!meta.previous}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-40"
            >
              Previous
            </button>
            <button
              onClick={() => {
                setCurrentPage(currentPage + 1);
                setShowLoader && setShowLoader(true);
              }}
              disabled={!meta.next}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-40"
            >
              Next
            </button>
          </div>
        </nav>
      </>
    );
  } else {
    return (
      <div className="h-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 10h16M4 14h16M4 18h16"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No Logs Available
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Please check{' '}
          <a
            className="text-indigo-500 underline"
            href="https://docs.suprsend.com/docs/workflows"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>{' '}
          to know more.
        </p>
      </div>
    );
  }
}

const LogItemSuccessWithMultipleNgs = ({
  log,
  index,
  currentEnv,
  ...props
}) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [copiedKey, setCopiedKey] = useState('Click to Copy!');
  const updateXarrow = useXarrow();
  let [successChannels, setSuccessChannels] = useState(0);
  let [errorChannels, setErrorChannels] = useState(0);
  let [progressChannels, setProgressChannels] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (detailOpen) {
      updateXarrow();
    }
  }, [detailOpen, index, log]);

  useEffect(() => {
    let status_length = log?.ngs?.map(ngs =>
      ngs?.ng_notification_statuses ? ngs?.ng_notification_statuses : []
    );

    status_length = status_length?.reduce((acc, arr) => (acc += arr.length), 0);

    let error_length = log?.execution_errors?.length || 0;
    let success_length = 0;

    let inprogress_length = 0;
    let not_to_be_triggered_length = 0;
    if (log?.ngs?.length > 0) {
      log?.ngs?.map(ngs => {
        return ngs?.ng_notification_statuses?.map(notif_status => {
          success_length = status.success.includes(notif_status.status)
            ? success_length + 1
            : success_length + 0;
        });
      });
      setSuccessChannels(success_length);

      log?.ngs?.map(ngs => {
        return ngs?.ng_notification_statuses?.map(notif_status => {
          inprogress_length = status.progress.includes(notif_status.status)
            ? inprogress_length + 1
            : inprogress_length + 0;
        });
      });
      setProgressChannels(inprogress_length);

      log?.ngs?.map(ngs => {
        return ngs?.ng_notification_statuses?.filter(notif_status => {
          not_to_be_triggered_length = status.not_to_be_triggered.includes(
            notif_status.status
          )
            ? not_to_be_triggered_length + 1
            : not_to_be_triggered_length + 0;
        });
      });
    }

    setErrorChannels(
      error_length +
        status_length -
        success_length -
        inprogress_length -
        not_to_be_triggered_length
    );
  }, [log]);

  const detailArr = workflowDetail(log);

  const ng_notification_statuses = [];

  log?.ngs?.map(ngs =>
    ngs?.ng_notification_statuses?.map(notif_status => {
      ng_notification_statuses.push(notif_status);
    })
  );

  const execution_errors = log?.execution_errors || [];

  const wfSlugStart = `${log.workspace_id}-`;
  let wfSlug = log?.workflow_slug;
  if (log?.workflow_slug && log?.workflow_slug?.startsWith(wfSlugStart)) {
    wfSlug = wfSlug.replace(wfSlugStart, '');
  }

  return (
    <li className="block hover:bg-gray-50" key={index}>
      <div
        onClick={event => {
          if (event.target.nodeName !== 'A') {
            setDetailOpen(!detailOpen);
            event.preventDefault();
          } else {
            const url = new URL(event.target.href);
            history.push(url.pathname);
            event.preventDefault();
          }
        }}
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex">
          <div className="w-6 m-3">
            {detailOpen ? (
              <ChevronDownIcon className="w-5" />
            ) : (
              <ChevronRightIcon className="w-5" />
            )}
          </div>
          <div className="grid grid-cols-12 flex-grow p-1 text-sm">
            <p className="p-2 col-span-2">
              {log?.wf_exec_timestamps?.triggered_at ? (
                <TimeAgo dateInput={log?.wf_exec_timestamps?.triggered_at} />
              ) : (
                ''
              )}
            </p>
            <p className="p-2 truncate col-span-2">
              {wfSlug ? (
                <a
                  href={urlUtils.makeURL(`/workflows/${wfSlug}`)}
                  className="hover:underline"
                >
                  {log?.workflow_name}
                </a>
              ) : (
                <p>{log?.workflow_name}</p>
              )}
            </p>
            {log?.subscriber_is_transient ? (
              <p className="p-2 truncate col-span-2" />
            ) : (
              <p className="p-2 truncate col-span-2">
                <a
                  href={urlUtils.makeURL(`/subscribers/${log?.distinct_id}`)}
                  className="hover:underline"
                >
                  {log?.distinct_id}
                </a>
              </p>
            )}
            <p className="p-2 col-span-2">{log?.category}</p>
            <p className="p-2 flex gap-2 col-span-2">
              {successChannels > 0 && (
                <span className="flex items-center text-sm text-gray-500">
                  <CheckCircleIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-green-600"
                    aria-hidden="true"
                  />
                  {successChannels} Sent
                </span>
              )}
              {successChannels > 0 && errorChannels > 0 && (
                <span className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <ExclamationCircleIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {errorChannels} Failed
                </span>
              )}
              {progressChannels > 0 && (
                <span className="flex items-center text-sm text-gray-500">
                  <CheckCircleIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {progressChannels} In progress
                </span>
              )}
            </p>
            <div className="flex p-2 col-span-2">
              <LogStatus log={log} successChannels={successChannels} />
              {log?.record_type === 'workflow' && (
                <div className="ml-auto mr-6 pt-1">
                  <Tooltip content="view details" direction="top">
                    <button
                      className=""
                      onClick={e => {
                        e.stopPropagation();
                        setShowDetailsModal(true);
                      }}
                    >
                      <InformationCircleIcon className="flex-shrink-0 h-4 w-4 text-gray-500 ml-6 self-center" />
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>

            <Transition.Root show={showDetailsModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={setShowDetailsModal}
              >
                <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <div
                      className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full max-w-3xl"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="pt-5 pb-4 sm:p-6 sm:pb-4 bg-[#F1F6FE]">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Log details
                        </Dialog.Title>
                      </div>
                      <div className="flex flex-wrap">
                        {detailArr.map((val, index) => {
                          return (
                            <div className="p-6 border-b w-1/2" key={index}>
                              <div className="flex items-center">
                                <p className="text-sm font-semibold mb-1">
                                  {val?.label}
                                </p>
                                {val?.tooltip && (
                                  <Tooltip
                                    content={val?.tooltip}
                                    direction={val?.position}
                                  >
                                    <QuestionMarkCircleIcon className="h-4 w-4 text-[#605F5F] ml-1 mb-1" />
                                  </Tooltip>
                                )}
                              </div>
                              {!val?.value ||
                              (val?.id === 'distinct_id' &&
                                log.subscriber_is_transient) ? (
                                <p className="text-sm font-normal text-gray-600 text-left">
                                  -
                                </p>
                              ) : (
                                <button
                                  onClick={e => {
                                    setCopiedKey('Copied');
                                    setTimeout(() => {
                                      setCopiedKey('Click to Copy!');
                                    }, 2000);
                                    copyToClipboard(val?.value);
                                  }}
                                >
                                  <Tooltip
                                    content={copiedKey}
                                    direction="top"
                                    delay={100}
                                  >
                                    <p className="text-sm font-normal text-gray-600 text-left">
                                      {val?.value}
                                    </p>
                                  </Tooltip>
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        </div>
      </div>
      {!detailOpen || (
        <div className="shadow-inner bg-gray-100 py-4">
          <div className="px-6 mb-4">
            {execution_errors?.map(error => (
              <div className="flex items-center">
                <ExclamationCircleIcon className="h-3.5 w-3.5 text-red-500" />
                <p className="text-sm text-red-500 m-1">{error?.message} </p>
              </div>
            ))}
          </div>
          <div
            id="canvas"
            className="relative flex justify-evenly items-center"
          >
            <Xwrapper>
              <div>
                <Box
                  type="success_box"
                  id={`${index}-requested`}
                  key={`${index}-requested`}
                >
                  Requested
                </Box>
              </div>
              <div>
                <Box
                  type="success_box"
                  id={`${index}-processed`}
                  key={`${index}-processed`}
                >
                  Processed
                </Box>
              </div>
              <div
                className="flex flex-col text-left gap-2"
                id={`${index}-trigger`}
                key={`${index}-trigger`}
              >
                {ng_notification_statuses?.map((chstatus, i) => {
                  const vendorName = chstatus?.tenant_vendor?.vendor_name;
                  const vendorNameString = vendorName
                    ? `(via ${vendorName})`
                    : '';
                  return (
                    <Fragment key={i}>
                      {status.success.includes(chstatus.status) && (
                        <Box
                          type="success_box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <>
                            {['email', 'sms', 'whatsapp'].includes(
                              chstatus.channel_type
                            ) ? (
                              <Tooltip
                                content={maskContent(
                                  chstatus.channel_value,
                                  currentEnv
                                )}
                                direction="top"
                              >
                                <div className="flex">
                                  <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                                  {chstatus.channel} triggered{' '}
                                  {vendorNameString}
                                </div>
                              </Tooltip>
                            ) : (
                              <>
                                {chstatus.channel} triggered {vendorNameString}
                              </>
                            )}
                          </>
                        </Box>
                      )}
                      {status.progress.includes(chstatus.status) && (
                        <Box
                          type="progress_box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <Tooltip
                            content={maskContent(
                              chstatus.channel_value,
                              currentEnv
                            )}
                            direction="top"
                          >
                            <div className="flex">
                              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                              {chstatus.channel} : {deSlugify(chstatus.status)}{' '}
                              {vendorNameString}
                            </div>
                          </Tooltip>
                        </Box>
                      )}
                      {status.not_to_be_triggered.includes(chstatus.status) && (
                        <Box
                          type="box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <Tooltip
                            content={`${maskContent(
                              chstatus.channel_value,
                              currentEnv
                            )} - ${deSlugify(chstatus.note)}`}
                            direction="top"
                          >
                            <div className="flex">
                              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                              {chstatus.channel} : {deSlugify(chstatus.status)}{' '}
                              {vendorNameString}
                            </div>
                          </Tooltip>
                        </Box>
                      )}
                      {status.error.includes(chstatus.status) && (
                        <Box
                          type="error_box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <div className="relative flex flex-col group">
                            <div className="flex items-center">
                              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                              {chstatus.channel} Failed :{' '}
                              {deSlugify(chstatus.status)} {vendorNameString}
                            </div>
                            <div className="absolute flex-col items-center hidden mb-6 group-hover:flex -bottom-2">
                              <div className="relative z-50 p-1 text-xs leading-tight text-white whitespace-pre-wrap bg-black shadow-lg rounded-md w-full max-h-40 overflow-y-scroll">
                                <p className="break-all">
                                  {`${
                                    chstatus.channel_value
                                      ? `${maskContent(
                                          chstatus.channel_value,
                                          currentEnv
                                        )} :`
                                      : ''
                                  } ${chstatus.err_log.message}`}
                                </p>
                              </div>
                              <div className="w-3 h-3 rotate-45 bg-black -mt-2"></div>
                            </div>
                          </div>
                        </Box>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div
                className="flex flex-col text-left gap-2"
                id={`${index}-delivered`}
                key={`${index}-delivered`}
              >
                {ng_notification_statuses?.map((chstatus, i) => (
                  <Fragment key={i}>
                    {status.success.includes(chstatus.status) ? (
                      <>
                        {status.delivered.includes(chstatus.status) ? (
                          <Box
                            type="success_box"
                            id={`${index}-delivered-${chstatus.nid}`}
                            key={`${index}-delivered-${chstatus.nid}`}
                          >
                            Delivered
                          </Box>
                        ) : (
                          <Box
                            type="box"
                            id={`${index}-delivered-${chstatus.nid}`}
                            key={`${index}-delivered-${chstatus.nid}`}
                          >
                            Delivered
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box
                        type="empty_box"
                        id={`${index}-delivered-${chstatus.nid}`}
                        key={`${index}-delivered-${chstatus.nid}`}
                      ></Box>
                    )}
                  </Fragment>
                ))}
              </div>
              <div
                className="flex flex-col text-left gap-2"
                id={`${index}-seen`}
                key={`${index}-seen`}
              >
                {ng_notification_statuses?.map((chstatus, i) => (
                  <Fragment key={i}>
                    {status.success.includes(chstatus.status) ? (
                      <>
                        {status.seen.includes(chstatus.status) ? (
                          <Box
                            type="success_box"
                            id={`${index}-seen-${chstatus.nid}`}
                            key={`${index}-seen-${chstatus.nid}`}
                          >
                            Seen
                          </Box>
                        ) : (
                          <Box
                            type="box"
                            id={`${index}-seen-${chstatus.nid}`}
                            key={`${index}-seen-${chstatus.nid}`}
                          >
                            Seen
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box
                        type="empty_box"
                        id={`${index}-seen-${chstatus.nid}`}
                        key={`${index}-seen-${chstatus.nid}`}
                      ></Box>
                    )}
                  </Fragment>
                ))}
              </div>
              <Xarrow
                key={`${index}-requested-processed`}
                start={`${index}-requested`}
                end={`${index}-processed`}
                strokeWidth={2}
                color="gray"
                labels={
                  <TimeDiff
                    fromDate={log?.wf_exec_timestamps?.created_at}
                    toDate={log?.wf_exec_timestamps?.triggered_at}
                  ></TimeDiff>
                }
              />
              {ng_notification_statuses?.map((chstatus, i) => {
                const start =
                  chstatus?.vfallback_applicable &&
                  chstatus?.vfallback_level !== 0
                    ? `${index}-trigger-${ng_notification_statuses[i - 1]?.nid}`
                    : `${index}-processed`;
                return (
                  <Xarrow
                    key={`${index}-processed-trigger-${chstatus.nid}-${i}`}
                    start={start}
                    end={`${index}-trigger-${chstatus.nid}`}
                    strokeWidth={2}
                    path={'grid'}
                    color="gray"
                    labels={{
                      end: chstatus.wait_time_in_seconds ? (
                        chstatus.status === 'not_to_be_triggered' ? null : (
                          <div
                            style={{ fontSize: '11px' }}
                            className="flex mt-1 animate-flicker"
                          >
                            <ClockIcon className="h-4 w-4 text-gray-700 mt-1 mr-1" />
                            <p className="mt-1">
                              <DurationDiff
                                duration={chstatus.wait_time_in_seconds}
                              ></DurationDiff>
                            </p>
                          </div>
                        )
                      ) : (
                        <VendorFallbackTimeDiff
                          index={i}
                          chstatus={chstatus}
                          ng_notification_statuses={ng_notification_statuses}
                          log={log}
                        />
                      ),
                    }}
                  />
                );
              })}

              {ng_notification_statuses?.map((chstatus, i) => (
                <Fragment key={i}>
                  {status.success.includes(chstatus.status) && (
                    <Xarrow
                      key={`${index}-trigger-delivered-${chstatus.nid}-${i}`}
                      start={`${index}-trigger-${chstatus.nid}`}
                      end={`${index}-delivered-${chstatus.nid}`}
                      strokeWidth={2}
                      path={'grid'}
                      color="gray"
                      dashness={
                        status.delivered.includes(chstatus.status)
                          ? false
                          : { animation: 2 }
                      }
                      labels={{
                        middle: status.delivered.includes(chstatus.status) ? (
                          <div className="ml-12">
                            <TimeDiff
                              fromDate={chstatus.triggered_at}
                              toDate={chstatus.delivered_at}
                            />
                          </div>
                        ) : (
                          <></>
                        ),
                      }}
                    />
                  )}
                </Fragment>
              ))}
              {ng_notification_statuses?.map((chstatus, i) => (
                <Fragment key={i}>
                  {status.success.includes(chstatus.status) && (
                    <Xarrow
                      key={`${index}-delivered-${chstatus.nid}-seen-${i}`}
                      start={`${index}-delivered-${chstatus.nid}`}
                      end={`${index}-seen-${chstatus.nid}`}
                      strokeWidth={2}
                      path={'grid'}
                      color="gray"
                      dashness={
                        status.seen.includes(chstatus.status)
                          ? false
                          : { animation: 2 }
                      }
                      labels={{
                        middle: status.seen.includes(chstatus.status) ? (
                          <TimeDiff
                            fromDate={chstatus.delivered_at}
                            toDate={chstatus.seen_at}
                          ></TimeDiff>
                        ) : (
                          <></>
                        ),
                      }}
                    />
                  )}
                </Fragment>
              ))}
            </Xwrapper>
          </div>
        </div>
      )}
    </li>
  );
};

const LogItemSuccessWithOneNgs = ({ log, index, currentEnv, ...props }) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [copiedKey, setCopiedKey] = useState('Click to Copy!');
  const updateXarrow = useXarrow();
  let [successChannels, setSuccessChannels] = useState(0);
  let [errorChannels, setErrorChannels] = useState(0);
  let [progressChannels, setProgressChannels] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (detailOpen) {
      updateXarrow();
    }
  }, [detailOpen, index, log]);

  const ngs = log?.ngs;
  const ngNotificationStatus = ngs?.[0]?.ng_notification_statuses;
  const executionErrors = log?.execution_errors || [];

  useEffect(() => {
    let status_length = ngNotificationStatus?.length || 0;
    let error_length = log.execution_errors?.length || 0;
    let success_length = 0;
    let inprogress_length = 0;
    let not_to_be_triggered_length = 0;

    if (ngs?.length > 0) {
      success_length = ngNotificationStatus?.filter(function (item) {
        return status.success.includes(item?.status);
      }).length;
      setSuccessChannels(success_length);

      inprogress_length = ngNotificationStatus?.filter(function (item) {
        return status.progress.includes(item?.status);
      }).length;
      setSuccessChannels(success_length);
      setProgressChannels(inprogress_length);
      not_to_be_triggered_length = ngNotificationStatus?.filter(function (
        item
      ) {
        return status.not_to_be_triggered.includes(item.status);
      }).length;
    }
    setErrorChannels(
      error_length +
        status_length -
        success_length -
        inprogress_length -
        not_to_be_triggered_length
    );
  }, [log]);
  const detailArr = workflowDetail(log);

  const wfSlugStart = `${log.workspace_id}-`;
  let wfSlug = log?.workflow_slug;
  if (log?.workflow_slug && log?.workflow_slug?.startsWith(wfSlugStart)) {
    wfSlug = wfSlug.replace(wfSlugStart, '');
  }
  return (
    <li className="block hover:bg-gray-50" key={index}>
      <div
        onClick={event => {
          if (event.target.nodeName !== 'A') {
            setDetailOpen(!detailOpen);
            event.preventDefault();
          } else {
            const url = new URL(event.target.href);
            history.push(url.pathname);
            event.preventDefault();
          }
        }}
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex">
          <div className="w-6 m-3">
            {detailOpen ? (
              <ChevronDownIcon className="w-5" />
            ) : (
              <ChevronRightIcon className="w-5" />
            )}
          </div>
          <div className="grid grid-cols-12 flex-grow p-1 text-sm">
            <p className="p-2 col-span-2">
              {log?.wf_exec_timestamps?.triggered_at ? (
                <TimeAgo dateInput={log?.wf_exec_timestamps?.triggered_at} />
              ) : (
                ''
              )}
            </p>
            <p className="p-2 truncate col-span-2">
              {wfSlug ? (
                <a
                  href={urlUtils.makeURL(`/workflows/${wfSlug}`)}
                  className="hover:underline"
                >
                  {log?.workflow_name}
                </a>
              ) : (
                <p>{log?.workflow_name}</p>
              )}
            </p>
            {log?.subscriber_is_transient ? (
              <p className="p-2 truncate col-span-2" />
            ) : (
              <p className="p-2 truncate col-span-2">
                <a
                  href={urlUtils.makeURL(`/subscribers/${log?.distinct_id}`)}
                  className="hover:underline"
                >
                  {log?.distinct_id}
                </a>
              </p>
            )}
            <p className="p-2 col-span-2">{log?.category}</p>
            <p className="p-2 flex gap-2 col-span-2">
              {successChannels > 0 && (
                <span className="flex items-center text-sm text-gray-500">
                  <CheckCircleIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-green-600"
                    aria-hidden="true"
                  />
                  {successChannels} Sent
                </span>
              )}
              {successChannels > 0 && errorChannels > 0 && (
                <span className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <ExclamationCircleIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {errorChannels} Failed
                </span>
              )}
              {progressChannels > 0 && (
                <span className="flex items-center text-sm text-gray-500">
                  <CheckCircleIcon
                    className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {progressChannels} In progress
                </span>
              )}
            </p>
            <div className="flex p-2 col-span-2">
              <LogStatus log={log} successChannels={successChannels} />
              {log?.record_type === 'workflow' && (
                <div className="ml-auto mr-6 pt-1">
                  <Tooltip content="view details" direction="top">
                    <button
                      className=""
                      onClick={e => {
                        e.stopPropagation();
                        setShowDetailsModal(true);
                      }}
                    >
                      <InformationCircleIcon className="flex-shrink-0 h-4 w-4 text-gray-500 ml-6 self-center" />
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>

            <Transition.Root show={showDetailsModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={setShowDetailsModal}
              >
                <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <div
                      className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full max-w-3xl"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="pt-5 pb-4 sm:p-6 sm:pb-4 bg-[#F1F6FE]">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Log details
                        </Dialog.Title>
                      </div>
                      <div className="flex flex-wrap">
                        {detailArr.map((val, index) => {
                          return (
                            <div className="p-6 border-b w-1/2" key={index}>
                              <div className="flex items-center">
                                <p className="text-sm font-semibold mb-1">
                                  {val?.label}
                                </p>
                                {val?.tooltip && (
                                  <Tooltip
                                    content={val?.tooltip}
                                    direction={val?.position}
                                  >
                                    <QuestionMarkCircleIcon className="h-4 w-4 text-[#605F5F] ml-1 mb-1" />
                                  </Tooltip>
                                )}
                              </div>
                              {!val?.value ||
                              (val?.id === 'distinct_id' &&
                                log.subscriber_is_transient) ? (
                                <p className="text-sm font-normal text-gray-600 text-left">
                                  -
                                </p>
                              ) : (
                                <button
                                  onClick={e => {
                                    setCopiedKey('Copied');
                                    setTimeout(() => {
                                      setCopiedKey('Click to Copy!');
                                    }, 2000);
                                    copyToClipboard(val?.value);
                                  }}
                                >
                                  <Tooltip
                                    content={copiedKey}
                                    direction="top"
                                    delay={100}
                                  >
                                    <p className="text-sm font-normal text-gray-600 text-left">
                                      {val?.value}
                                    </p>
                                  </Tooltip>
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        </div>
      </div>
      {!detailOpen || (
        <div className="shadow-inner bg-gray-100 py-4">
          <div
            id="canvas"
            className="relative flex justify-evenly items-center"
          >
            <Xwrapper>
              <div>
                <Box
                  type="success_box"
                  id={`${index}-requested`}
                  key={`${index}-requested`}
                >
                  Requested
                </Box>
              </div>
              <div>
                <Box
                  type="success_box"
                  id={`${index}-processed`}
                  key={`${index}-processed`}
                >
                  Processed
                </Box>
              </div>
              <div
                className="flex flex-col text-left gap-2"
                id={`${index}-trigger`}
                key={`${index}-trigger`}
              >
                {executionErrors?.map((cherror, i) => (
                  <Box
                    type="error_box"
                    id={`${index}-trigger-${i}`}
                    key={`${index}-trigger-${i}`}
                  >
                    {cherror?.message}
                  </Box>
                ))}
                {ngNotificationStatus?.map((chstatus, i) => {
                  const vendorName = chstatus?.tenant_vendor?.vendor_name;
                  const vendorNameString = vendorName
                    ? `(via ${vendorName})`
                    : '';
                  return (
                    <Fragment key={i}>
                      {status.success.includes(chstatus.status) && (
                        <Box
                          type="success_box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <>
                            {['email', 'sms', 'whatsapp'].includes(
                              chstatus.channel_type
                            ) ? (
                              <Tooltip
                                content={maskContent(
                                  chstatus.channel_value,
                                  currentEnv
                                )}
                                direction="top"
                              >
                                <div className="flex">
                                  <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                                  {chstatus.channel} triggered{' '}
                                  {vendorNameString}
                                </div>
                              </Tooltip>
                            ) : (
                              <>
                                {chstatus.channel} triggered {vendorNameString}
                              </>
                            )}
                          </>
                        </Box>
                      )}
                      {status.progress.includes(chstatus.status) && (
                        <Box
                          type="progress_box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <Tooltip
                            content={maskContent(
                              chstatus.channel_value,
                              currentEnv
                            )}
                            direction="top"
                          >
                            <div className="flex">
                              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                              {chstatus.channel} : {deSlugify(chstatus.status)}{' '}
                              {vendorNameString}
                            </div>
                          </Tooltip>
                        </Box>
                      )}
                      {status.not_to_be_triggered.includes(chstatus.status) && (
                        <Box
                          type="box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <Tooltip
                            content={`${maskContent(
                              chstatus.channel_value,
                              currentEnv
                            )} - ${deSlugify(chstatus.note)}`}
                            direction="top"
                          >
                            <div className="flex">
                              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                              {chstatus.channel} : {deSlugify(chstatus.status)}{' '}
                              {vendorNameString}
                            </div>
                          </Tooltip>
                        </Box>
                      )}
                      {status.error.includes(chstatus.status) && (
                        <Box
                          type="error_box"
                          key={`${index}-trigger-${chstatus.nid}`}
                          id={`${index}-trigger-${chstatus.nid}`}
                        >
                          <div className="relative flex flex-col group">
                            <div className="flex items-center">
                              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-3 w-4" />
                              {chstatus.channel} Failed :{' '}
                              {deSlugify(chstatus.status)} {vendorNameString}
                            </div>
                            <div className="absolute flex-col items-center hidden mb-6 group-hover:flex -bottom-2">
                              <div className="relative z-50 p-1 text-xs leading-tight text-white whitespace-pre-wrap bg-black shadow-lg rounded-md w-full max-h-40 overflow-y-scroll">
                                <p className="break-all">
                                  {`${
                                    chstatus.channel_value
                                      ? `${maskContent(
                                          chstatus.channel_value,
                                          currentEnv
                                        )} :`
                                      : ''
                                  } ${chstatus.err_log.message}`}
                                </p>
                              </div>
                              <div className="w-3 h-3 rotate-45 bg-black -mt-2"></div>
                            </div>
                          </div>
                        </Box>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div
                className="flex flex-col text-left gap-2"
                id={`${index}-delivered`}
                key={`${index}-delivered`}
              >
                {executionErrors?.map((cherror, i) => (
                  <Box
                    type="empty_box"
                    id={`${index}-delivered-${i}`}
                    key={`${index}-delivered-${i}`}
                  ></Box>
                ))}
                {ngNotificationStatus?.map((chstatus, i) => (
                  <Fragment key={i}>
                    {status.success.includes(chstatus.status) ? (
                      <>
                        {status.delivered.includes(chstatus.status) ? (
                          <Box
                            type="success_box"
                            id={`${index}-delivered-${chstatus.nid}`}
                            key={`${index}-delivered-${chstatus.nid}`}
                          >
                            Delivered
                          </Box>
                        ) : (
                          <Box
                            type="box"
                            id={`${index}-delivered-${chstatus.nid}`}
                            key={`${index}-delivered-${chstatus.nid}`}
                          >
                            Delivered
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box
                        type="empty_box"
                        id={`${index}-delivered-${chstatus.nid}`}
                        key={`${index}-delivered-${chstatus.nid}`}
                      ></Box>
                    )}
                  </Fragment>
                ))}
              </div>
              <div
                className="flex flex-col text-left gap-2"
                id={`${index}-seen`}
                key={`${index}-seen`}
              >
                {executionErrors.map((cherror, i) => (
                  <p
                    id={`${index}-seen-${i}`}
                    key={`${index}-seen-${i}`}
                    className="border-dashed border border-transparent p-2 rounded-md m-2 capitalize text-xs"
                  >
                    &nbsp;
                  </p>
                ))}
                {ngNotificationStatus?.map((chstatus, i) => (
                  <Fragment key={i}>
                    {status.success.includes(chstatus.status) ? (
                      <>
                        {status.seen.includes(chstatus.status) ? (
                          <Box
                            type="success_box"
                            id={`${index}-seen-${chstatus.nid}`}
                            key={`${index}-seen-${chstatus.nid}`}
                          >
                            Seen
                          </Box>
                        ) : (
                          <Box
                            type="box"
                            id={`${index}-seen-${chstatus.nid}`}
                            key={`${index}-seen-${chstatus.nid}`}
                          >
                            Seen
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box
                        type="empty_box"
                        id={`${index}-seen-${chstatus.nid}`}
                        key={`${index}-seen-${chstatus.nid}`}
                      ></Box>
                    )}
                  </Fragment>
                ))}
              </div>
              <Xarrow
                key={`${index}-requested-processed`}
                start={`${index}-requested`}
                end={`${index}-processed`}
                strokeWidth={2}
                color="gray"
                labels={
                  <TimeDiff
                    fromDate={log?.wf_exec_timestamps.created_at}
                    toDate={log?.wf_exec_timestamps.triggered_at}
                  ></TimeDiff>
                }
              />
              {ngNotificationStatus?.map((chstatus, i) => {
                const start =
                  chstatus?.vfallback_applicable &&
                  chstatus?.vfallback_level !== 0
                    ? `${index}-trigger-${ngNotificationStatus[i - 1]?.nid}`
                    : `${index}-processed`;
                return (
                  <Xarrow
                    key={`${index}-processed-trigger-${chstatus.nid}-${i}`}
                    start={start}
                    end={`${index}-trigger-${chstatus.nid}`}
                    strokeWidth={2}
                    path={'grid'}
                    color="gray"
                    labels={{
                      end: chstatus.wait_time_in_seconds ? (
                        chstatus.status === 'not_to_be_triggered' ? null : (
                          <div
                            style={{ fontSize: '11px' }}
                            className="flex mt-1 animate-flicker"
                          >
                            <ClockIcon className="h-4 w-4 text-gray-700 mt-1 mr-1" />
                            <p className="mt-1">
                              <DurationDiff
                                duration={chstatus.wait_time_in_seconds}
                              ></DurationDiff>
                            </p>
                          </div>
                        )
                      ) : (
                        <VendorFallbackTimeDiff
                          index={i}
                          chstatus={chstatus}
                          ng_notification_statuses={ngNotificationStatus}
                          log={log}
                        />
                      ),
                    }}
                  />
                );
              })}
              {executionErrors.map((cherror, i) => (
                <Xarrow
                  key={`${index}-processed-trigger-${i}`}
                  start={`${index}-processed`}
                  end={`${index}-trigger-${i}`}
                  strokeWidth={2}
                  path={'grid'}
                  color="gray"
                  labels={{
                    end: (
                      <div
                        style={{ marginTop: '5px', fontSize: '11px' }}
                      >{`<1 ms`}</div>
                    ),
                  }}
                />
              ))}
              {ngNotificationStatus?.map((chstatus, i) => (
                <Fragment key={i}>
                  {status.success.includes(chstatus.status) && (
                    <Xarrow
                      key={`${index}-trigger-delivered-${chstatus.nid}-${i}`}
                      start={`${index}-trigger-${chstatus.nid}`}
                      end={`${index}-delivered-${chstatus.nid}`}
                      strokeWidth={2}
                      path={'grid'}
                      color="gray"
                      dashness={
                        status.delivered.includes(chstatus.status)
                          ? false
                          : { animation: 2 }
                      }
                      labels={{
                        middle: status.delivered.includes(chstatus.status) ? (
                          <div className="ml-12">
                            <TimeDiff
                              fromDate={chstatus.triggered_at}
                              toDate={chstatus.delivered_at}
                            />
                          </div>
                        ) : (
                          <></>
                        ),
                      }}
                    />
                  )}
                </Fragment>
              ))}
              {ngNotificationStatus?.map((chstatus, i) => (
                <Fragment key={i}>
                  {status.success.includes(chstatus.status) && (
                    <Xarrow
                      key={`${index}-delivered-${chstatus.nid}-seen-${i}`}
                      start={`${index}-delivered-${chstatus.nid}`}
                      end={`${index}-seen-${chstatus.nid}`}
                      strokeWidth={2}
                      path={'grid'}
                      color="gray"
                      dashness={
                        status.seen.includes(chstatus.status)
                          ? false
                          : { animation: 2 }
                      }
                      labels={{
                        middle: status.seen.includes(chstatus.status) ? (
                          <TimeDiff
                            fromDate={chstatus.delivered_at}
                            toDate={chstatus.seen_at}
                          ></TimeDiff>
                        ) : (
                          <></>
                        ),
                      }}
                    />
                  )}
                </Fragment>
              ))}
            </Xwrapper>
          </div>
        </div>
      )}
    </li>
  );
};

const LogItemSuccess = ({ log, index, ...props }) => {
  const envState = useEnvState();
  const currentEnv = envState.currentEnv.get();

  if (log?.ngs?.length > 1) {
    return (
      <LogItemSuccessWithMultipleNgs
        log={log}
        index={index}
        currentEnv={currentEnv}
        props
      />
    );
  }

  return (
    <LogItemSuccessWithOneNgs
      log={log}
      index={index}
      currentEnv={currentEnv}
      props
    />
  );
};

const LogItemFailed = ({ log, index, ...props }) => {
  const history = useHistory();
  const [detailOpen, setDetailOpen] = useState(false);
  const timestamp = log.ve_event_timestamp
    ? log.ve_event_timestamp
    : log.wf_exec_timestamps.triggered_at;

  const wfSlugStart = `${log.workspace_id}-`;
  let wfSlug = log?.workflow_slug;
  if (log?.workflow_slug && log?.workflow_slug?.startsWith(wfSlugStart)) {
    wfSlug = wfSlug.replace(wfSlugStart, '');
  }
  return (
    <li key={index}>
      <div
        onClick={event => {
          if (event.target.nodeName !== 'A') {
            setDetailOpen(!detailOpen);
            event.preventDefault();
          } else {
            const url = new URL(event.target.href);
            history.push(url.pathname);
            event.preventDefault();
          }
        }}
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex">
          <div className="m-3">
            {detailOpen ? (
              <ChevronDownIcon className="w-5" />
            ) : (
              <ChevronRightIcon className="w-5" />
            )}
          </div>
          <div className="grid grid-cols-6 flex-grow p-1 text-sm">
            <p className="p-2">
              {timestamp ? <TimeAgo dateInput={timestamp} /> : ''}
            </p>
            <p className="p-2 truncate">
              {wfSlug ? (
                <a
                  href={urlUtils.makeURL(`workflows/${wfSlug}`)}
                  className="hover:underline"
                >
                  {log.workflow_name}
                </a>
              ) : (
                <p>{log.workflow_name}</p>
              )}
            </p>
            {log.subscriber_is_transient ? (
              <p className="p-2 truncate" />
            ) : (
              <p className="p-2 truncate">
                <a
                  href={urlUtils.makeURL(`/subscribers/${log.distinct_id}`)}
                  className="hover:underline"
                >
                  {log.distinct_id}
                </a>
              </p>
            )}
            <p className="p-2">{log?.category}</p>
            <p className="p-2 flex gap-2"></p>
            <p className="p-2">
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                Failed
              </span>
            </p>
          </div>
        </div>
      </div>
      {!detailOpen || (
        <div className="shadow-inner bg-gray-100 py-4">
          <p className="flex items-center text-sm text-red-600 ml-10">
            <ExclamationCircleIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            {log?.execution_errors?.length > 0 ? (
              <ul>
                {log.execution_errors.map(function (item, idx) {
                  return <li key={idx}>{item.message}</li>;
                })}
              </ul>
            ) : (
              log?.wf_exec_failure_reason
            )}
          </p>
        </div>
      )}
    </li>
  );
};

const LogItemProgress = ({ log, index, ...props }) => {
  if (log?.ng_delivery?.smart) {
    return <LogItemSuccess log={log} index={index} props></LogItemSuccess>;
  }
  const timestamp = log.ve_event_timestamp
    ? log.ve_event_timestamp
    : log.wf_exec_timestamps.triggered_at;

  const wfSlugStart = `${log.workspace_id}-`;
  let wfSlug = log?.workflow_slug;
  if (log?.workflow_slug && log?.workflow_slug?.startsWith(wfSlugStart)) {
    wfSlug = wfSlug.replace(wfSlugStart, '');
  }

  return (
    <li key={index}>
      <div className="block">
        <div className="flex">
          <div className="w-6 m-3"></div>
          <div className="grid grid-cols-6 flex-grow p-1">
            <p className="p-2">
              {timestamp ? <TimeAgo dateInput={timestamp} /> : ''}
            </p>
            <p className="p-2 truncate">
              {wfSlug ? (
                <a
                  href={urlUtils.makeURL(`workflows/${wfSlug}`)}
                  className="hover:underline"
                >
                  {log.workflow_name}
                </a>
              ) : (
                <p> {log.workflow_name}</p>
              )}
            </p>
            {log.subscriber_is_transient ? (
              <p className="p-2 truncate" />
            ) : (
              <p className="p-2 truncate">
                <a
                  href={urlUtils.makeURL(`/subscribers/${log.distinct_id}`)}
                  className="hover:underline"
                >
                  {log.distinct_id}
                </a>
              </p>
            )}
            <p className="p-2">{log?.category}</p>
            <p className="p-2 flex gap-2"></p>
            <p className="p-2">
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                In Progress
              </span>
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

const TimeDiff = ({ fromDate, toDate, ...props }) => {
  const fDate = Date.parse(fromDate);
  const tDate = Date.parse(toDate);
  const diffMs = differenceInMilliseconds(tDate, fDate);
  let diff = 0;
  if (diffMs > 1000) {
    let str = formatDistanceStrict(tDate, fDate);
    str = str.replace('seconds', 's').replace('second', 's');
    str = str.replace('minutes', 'm').replace('minute', 'm');
    return <div style={{ marginTop: '20px', fontSize: '11px' }}>{str}</div>;
  } else {
    return (
      <div style={{ marginTop: '20px', fontSize: '11px' }}>
        {diffMs && Math.abs(diffMs)} ms
      </div>
    );
  }
};

const Box = ({ type, children, ...props }) => {
  let className = 'border-dashed border p-2 m-2 rounded-md max-w-md text-xs ';
  if (type === 'success_box') {
    className += 'border-green-800 bg-green-300 capitalize text-black';
  } else if (type === 'error_box') {
    className +=
      'border-dashed border border-red-800 bg-red-200 p-2 m-2 rounded-md text-black';
  } else if (type === 'progress_box') {
    className +=
      'border-dashed border border-indigo-800 bg-indigo-200 p-2 m-2 rounded-md capitalize text-black';
  } else if (type === 'box') {
    className +=
      'border-dashed border border-gray-400 p-2 m-2 rounded-md capitalize';
  } else if (type === 'empty_box') {
    className +=
      'border-dashed border border-transparent p-2 m-2 rounded-md capitalize text-black';
  }
  if (type === 'empty_box') {
    return (
      <p className={className} {...props}>
        &nbsp;
      </p>
    );
  }
  return (
    <p className={className} {...props}>
      {children}
    </p>
  );
};

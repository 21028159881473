import { useState } from 'react';
import { TableCell, TableRow } from 'new-components';
import { TimeAgo } from 'components';
import { JSONDisplay } from 'custom-components';
import CopyButton from './CopyButton';
import { useEditAccess } from 'utils';

function deepTransformKeys(obj) {
  if (obj && typeof obj === 'object') {
    var allKeys = Object.keys(obj);
    for (var i = 0; i < allKeys.length; i++) {
      var k = allKeys[i];
      var value = obj[k];

      if (k[0] === '$') {
        delete obj[k];
      }
      if (typeof value === 'object') {
        deepTransformKeys(value);
      }
    }
  }
  return obj;
}

export default function WorkflowStartedRow({ oblog, barColor }) {
  const editAccess = useEditAccess();

  const [open, setOpen] = useState(false);

  const hasData = oblog.data.length > 0;
  const nodeData = deepTransformKeys(JSON.parse(oblog?.data));
  const nodeDataStr = JSON.stringify(nodeData, 4, '\t');
  return (
    <>
      <TableRow
        className={hasData && editAccess ? 'cursor-pointer' : ''}
        onClick={ev => {
          if (editAccess) {
            const el = ev.nativeEvent.target;
            if (el.dataset?.id !== 'copybutton') setOpen(!open);
            ev.preventDefault();
          }
        }}
      >
        <TableCell>
          <div
            className="h-6 w-1 rounded-lg"
            style={{
              backgroundColor: `hsl(var(${barColor}))`,
            }}
          />
        </TableCell>
        <TableCell>
          <TimeAgo dateInput={oblog.time} className="py-3" />
        </TableCell>
        <TableCell>{`${oblog['other_info_map.msg']}`}</TableCell>
        <TableCell className="w-[200px] overflow-auto text-wrap">
          {editAccess && hasData && !open && (
            <div className="group relative">
              <CopyButton
                className="hidden group-hover:block absolute top-1 right-1 h-7 w-7 p-1"
                textToCopy={nodeDataStr}
                data-id="copybutton"
              />
              <div className="flex min-w-[200px] max-w-[200px] border overflow-clip truncate rounded dark:bg-[#1D2635] bg-[#F8FAFC]">
                <JSONDisplay jsonObj={nodeDataStr} />
              </div>
            </div>
          )}
        </TableCell>
      </TableRow>
      {open && hasData && (
        <tr className="shadow-inner border-b w-full">
          <td colSpan="4">
            <div className="group relative">
              <CopyButton
                className="hidden group-hover:block absolute top-1 right-1 h-7 w-7 p-1"
                textToCopy={nodeDataStr}
                data-id="copybutton"
              />
              <pre className="max-h-60 font-mono overflow-auto text-wrap">
                <JSONDisplay jsonObj={nodeDataStr} />
              </pre>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

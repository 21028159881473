import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { useDeepCompareMemo } from 'use-deep-compare';
import { enGB } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { classNames } from 'utils';

const COLORS_SERIES = [
  '#5b8ff9',
  '#5ad8a6',
  '#fe99c3',
  '#5e7092',
  '#f6bd18',
  '#6ec8ec',
  '#945fb9',
  '#ff9845',
  '#299796',
];

const chartOneOptions = title => ({
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        boxHeight: 1,
        boxWidth: 20,
      },
    },
    title: {
      display: true,
      text: title,
      color: 'black',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
      },
      adapters: {
        date: {
          locale: enGB,
        },
      },
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        padding: 12,
        minRotation: 0,
        major: true,
      },
    },
    y: {
      grid: { borderColor: 'white', drawTicks: true, tickColor: 'white' },
    },
  },
});

export const ChartOne = ({ resultSet, totalPercentage }) => {
  const labels = ['Requested', 'Triggered'];
  const custom_color_series = [COLORS_SERIES[0], COLORS_SERIES[2]];
  const title = `Triggered: ${totalPercentage}%`;
  const datasets = useDeepCompareMemo(
    () =>
      resultSet?.series().map((s, index) => ({
        label: labels[index],
        data: s.series.map(r => r.value),
        borderColor: custom_color_series[index],
        backgroundColor: custom_color_series[index],
        pointRadius: 2 * (index + 1),
        pointHoverRadius: 2 * (index + 1),
        pointStyle: index === 0 ? 'circle' : 'rectRot',
      })),
    [resultSet]
  );

  const data = {
    labels: resultSet.categories().map(c => c.x),
    datasets,
  };
  return (
    <Line
      data={data}
      options={chartOneOptions(title)}
      height={265}
      width={600}
    />
  );
};

export const SmartWorkflowChart = ({ resultSet }) => {
  const labels = ['with smart workflow', 'w/o smart workflow'];
  const custom_color_series = [COLORS_SERIES[0], COLORS_SERIES[2]];
  const datasets = useDeepCompareMemo(
    () =>
      resultSet?.series().map((s, index) => ({
        label: labels[index],
        data: s.series.map(r => r.value),
        borderDash: index === 1 ? [5, 5] : [],
        borderColor: custom_color_series[index],
        backgroundColor: custom_color_series[index],
        pointRadius: 2 * (index + 1),
        pointHoverRadius: 2 * (index + 1),
        pointStyle: index === 0 ? 'circle' : 'rectRot',
      })),
    [resultSet]
  );

  const data = {
    labels: resultSet.categories().map(c => c.x),
    datasets,
  };
  return (
    <Line data={data} options={chartOneOptions()} height={260} width={600} />
  );
};

const chartTwoOptions = title => ({
  responsive: true,
  fill: true,
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      max: 100,
      ticks: {
        callback: function (value, index) {
          if (index % 2 === 0) {
            return value + '%';
          }
          return null;
        },
      },
      grid: {
        borderColor: 'white',
        drawTicks: true,
        tickColor: 'white',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      text: title,
      display: true,
      color: 'black',
      padding: { bottom: 30 },
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context) {
          const labels = [
            'Triggered',
            'Delivered/Triggered',
            'Seen/Triggered',
            'Interacted/Triggered',
          ];
          return `${labels[context.dataIndex]} : ${context.parsed.y}%`;
        },
      },
    },
    datalabels: {
      align: 'top',
      anchor: 'end',
      offset: -20,
      color: 'black',
      borderWidth: 1,
      borderColor: '#d6d6d6',
      textAlign: 'center',
      borderRadius: 6,
      padding: {
        top: 2,
        bottom: 2,
        left: 15,
        right: 15,
      },
      font: {
        lineHeight: 1.5,
      },
      backgroundColor: 'white',
      formatter: function (value, context) {
        if (context.dataIndex > 3 || value === 0) {
          return null;
        }
        const count = context.dataset.data[context.dataIndex + 5] || 0;
        return `${value}%\n(${count})`;
      },
    },
  },
});

export const ChartTwo = ({ resultSet }) => {
  let labels = ['Triggered', 'Delivered', 'Seen', 'Interacted'];
  let title;
  let datasets = {
    data: [],
    backgroundColor: COLORS_SERIES[0],
    barThickness: 100,
  };
  resultSet.series().forEach((item, index) => {
    const values = item.series.map(i => i.value);
    datasets.data.push(...values);
    if (index === 4) {
      title = `Overall: ${item.series[0].value}%`;
    }
  });

  const data = {
    labels,
    datasets: [datasets],
  };

  return (
    <Bar
      data={data}
      plugins={[ChartDataLabels]}
      options={chartTwoOptions(title)}
      height={247}
      width={600}
    />
  );
};

export const ChartThreeOne = ({ resultSet }) => {
  const result_data = resultSet.series();
  const wf_triggered = result_data[0].series[0].value;
  const count = result_data[1].series[0].value;
  let avg = result_data[2].series[0].value;
  avg = avg > 0 ? avg.toFixed(1) : 0;
  return (
    <div className="shadow rounded p-5 py-10 h-[320px]">
      <div className="flex flex-col justify-center">
        <div className="flex justify-center border-b pb-8">
          <div className="pr-8 flex-wrap">
            <p className="font-light text-center">Workflows Triggered</p>
            <p className="text-3xl text-center mt-2 text-blue-500">
              {wf_triggered}
            </p>
          </div>
          <div>
            <p className="font-light flex-wrap text-center">
              Notifications on all channels
            </p>
            <p className="text-3xl text-center mt-2 text-blue-500">{count}</p>
          </div>
        </div>
        <div>
          <p className="text-center pt-8 flex-wrap font-semibold">
            Avg Notification / User
          </p>
          <p className="text-3xl text-green-600 text-center mt-2">{avg}</p>
        </div>
      </div>
    </div>
  );
};

export const ChartThreeTwo = ({ resultSet }) => {
  const chartData = resultSet.series()[0];
  const data = [];
  const labels = [];
  let isEmpty = true;
  chartData?.series?.forEach(item => {
    isEmpty = false;
    data.push(item.value);
    labels.push(item.x);
  });
  if (isEmpty) {
    return (
      <div className="shadow rounded p-5 py-10 h-[320px]">
        <p className="text-gray-500 italic text-center pt-12">
          No data available, send notifications to see data
        </p>
      </div>
    );
  }
  const datasets = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: COLORS_SERIES,
        radius: 120,
      },
    ],
  };

  return (
    <div className="shadow rounded py-1 px-6 h-[320px]">
      <Doughnut
        data={datasets}
        width={313}
        options={{
          aspectRatio: 2,
          responsive: true,
          plugins: {
            legend: { position: 'left' },
          },
          parsing: {
            key: 'value',
          },
        }}
      />
    </div>
  );
};

export const ChartFour = ({ resultSet }) => {
  const labels = ['webpush', 'whatsapp', 'androidpush', 'email'];
  const datasets = resultSet.series().map((item, index) => ({
    label: item.title.substr(18),
    data: item.series.map(r => ({ x: r.x, y: r.value })),
    backgroundColor: COLORS_SERIES[index],
  }));
  const data = {
    labels,
    datasets,
  };

  return (
    <div>
      <Bar
        data={data}
        height={240}
        width={600}
        options={{
          scales: {
            y: {
              max: 100,
              ticks: {
                callback: function (value, index) {
                  if (index % 2 === 0) {
                    return value + '%';
                  }
                  return null;
                },
              },
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.dataset.label} : ${context.parsed.y}%`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

const pivotConfigError = {
  x: ['WorkflowErrors.failureReason'],
  y: ['measures'],
};

export const ChartFive = ({ resultSet }) => {
  const headers = ['Failure reason', 'Total workflows'];
  const dataSource = useDeepCompareMemo(() => {
    return resultSet.tablePivot(pivotConfigError);
  }, [resultSet, pivotConfigError]);

  return (
    <div className="border rounded">
      <div className="grid grid-cols-2">
        {headers.map((header, index) => {
          return (
            <p
              className={classNames(
                `text-sm font-medium border-b p-2 py-3 grid-cols-1`,
                index === 1 && 'text-center'
              )}
              key={index}
            >
              {header}
            </p>
          );
        })}
      </div>

      <div className="max-h-[200px] overflow-scroll">
        {dataSource.map((row, index) => {
          if (row['WorkflowErrors.errorCount'] !== '0') {
            return (
              <div className="grid grid-cols-2 border-b gap-2" key={index}>
                <p className="text-xs p-2 break-all">
                  {row['WorkflowErrors.failureReason']}
                </p>
                <p className="text-xs p-2 text-center">
                  {row['WorkflowErrors.errorCount']}
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

const pivotConfig = {
  x: ['WorkflowChannels.channelSlug', 'WorkflowChannels.failureReason'],
  y: ['measures'],
};

export const ChartSix = ({ resultSet }) => {
  const headers = ['Channel', 'Failure reason', 'Total workflows'];
  const dataSource = useDeepCompareMemo(() => {
    return resultSet.tablePivot(pivotConfig);
  }, [resultSet, pivotConfig]);
  return (
    <div className="border rounded">
      <div className="grid grid-cols-3">
        {headers.map((header, index) => {
          return (
            <p
              className={classNames(
                `text-sm font-medium border-b p-2 py-3 grid-col-1`,
                index === 2 && 'text-center'
              )}
              key={index}
            >
              {header}
            </p>
          );
        })}
      </div>

      <div className="max-h-[200px] overflow-scroll">
        {dataSource.map((row, index) => {
          if (row['WorkflowChannels.countFailed'] !== '0') {
            return (
              <div className="grid grid-cols-3 border-b" key={index}>
                <p className="text-xs grid-cols-1 p-2">
                  {row['WorkflowChannels.channelSlug']}
                </p>
                <p className="text-xs break-all grid-cols-1 p-2">
                  {row['WorkflowChannels.failureReason']}
                </p>
                <p className="text-xs  grid-cols-1 text-center p-2">
                  {row['WorkflowChannels.countFailed']}
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

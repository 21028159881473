import { useState } from 'react';
import { TableCell, TableRow } from 'new-components';
import { TimeAgo } from 'components';
import { useEditAccess } from 'utils';
import { JSONDisplay } from 'custom-components';
import CopyButton from './CopyButton';

export default function BasicLogRow({ oblog, barColor }) {
  const editAccess = useEditAccess();
  const [open, setOpen] = useState(false);

  const hasData = oblog.data.length > 0;

  return (
    <>
      <TableRow
        className={editAccess && hasData ? 'cursor-pointer' : ''}
        onClick={ev => {
          if (editAccess) {
            const el = ev.nativeEvent.target;
            if (el.dataset?.id !== 'copybutton') setOpen(!open);
            ev.preventDefault();
          }
        }}
      >
        <TableCell>
          <div
            className="h-6 w-1 rounded-lg"
            style={{
              backgroundColor: `hsl(var(${barColor}))`,
            }}
          />
        </TableCell>
        <TableCell className="min-w-48">
          <TimeAgo dateInput={oblog.time} className="py-3" />
        </TableCell>
        <TableCell colSpan={hasData ? 1 : 2}>
          <p className="w-auto inline-block whitespace-nowrap text-pretty overflow-clip">{`${oblog['other_info_map.msg']}`}</p>
        </TableCell>

        {editAccess && hasData && !open && (
          <TableCell className="w-[200px] overflow-auto text-wrap">
            <div className="group relative">
              <CopyButton
                className="hidden group-hover:block absolute top-1 right-1 h-7 w-7 p-1"
                textToCopy={oblog.data}
                data-id="copybutton"
              />
              <div className="flex min-w-[200px] max-w-[200px] truncate rounded border dark:bg-[#1D2635] bg-[#F8FAFC]">
                <JSONDisplay jsonObj={oblog.data} wrap={false} />
              </div>
            </div>
          </TableCell>
        )}
      </TableRow>
      {open && hasData && (
        <tr className="shadow-inner border-b w-full">
          <td colSpan="4">
            <div className="group relative">
              <CopyButton
                className="hidden group-hover:block absolute top-1 right-1 h-7 w-7 p-1"
                textToCopy={oblog.data}
                data-id="copybutton"
              />
              <pre className="max-h-60 font-mono overflow-auto text-wrap">
                <JSONDisplay jsonObj={oblog.data} />
              </pre>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

import urlUtils from 'utils/urlUtils';
import { useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Button,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from 'new-components';
import Gravatar from './Gravatar';
import { useAuthState, logout } from 'stores/AuthStore';
import { PersonIcon, GearIcon, ExitIcon } from '@radix-ui/react-icons';

function Profile({ theme, setTheme }) {
  const authState = useAuthState();
  const history = useHistory();

  const userDetails = authState.user.get();

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild className="data-[state=open]:bg-accent">
        <Button
          variant="ghost"
          className="focus-visible:ring-0 border-0 text-foreground rounded-full p-1.5"
        >
          <Gravatar email={userDetails?.email} name={userDetails?.name} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-44 mr-4">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              history.push(urlUtils.makeCommonURL('user-settings/general'));
            }}
          >
            <PersonIcon className="h-4 w-4 mr-2 text-muted-foreground" />
            <span>User Settings</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              history.push(urlUtils.makeCommonURL('account-settings/general'));
            }}
          >
            <GearIcon className="h-4 w-4 mr-2 text-muted-foreground" />
            <span>Account Settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>

        {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>Theme</DropdownMenuLabel>
            <DropdownMenuRadioGroup value={theme} onValueChange={setTheme}>
              <DropdownMenuRadioItem value="light">Light</DropdownMenuRadioItem>
              <DropdownMenuRadioItem value="dark">Dark</DropdownMenuRadioItem>
              <DropdownMenuRadioItem value="system">
                System
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onSelect={async () => {
            await logout();
          }}
        >
          <ExitIcon className="h-4 w-4 mr-2 text-muted-foreground" />
          <span> Logout </span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
export default Profile;

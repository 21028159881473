import { Children, isValidElement, cloneElement } from 'react';
import { isFragment } from 'react-is';
import { TimeAgo } from 'components';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'new-components';
import { JSONDisplay } from 'custom-components';
import NodeStatus from './NodeStatus';
import urlUtils from 'utils/urlUtils';
import { cn } from 'utils';
var _ = require('lodash');

function humanizeNodeName(str) {
  //cleanup few words to make thing presentable
  str = str.replace('httpapi', '');
  if (str.startsWith('send')) str = str.replace('send', '');
  //remove underscore and capitalize string
  var i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  str = frags.join(' ').trim();

  if (str.toLowerCase() === 'sms') {
    return 'SMS';
  }
  return str;
}

const PropertyTable = function ({ properties, children, ...props }) {
  const flattenChildren = function (children, depth = 0, keys = []) {
    return Children.toArray(children).reduce((acc, node, nodeIndex) => {
      if (isFragment(node)) {
        acc.push.apply(
          acc,
          flattenChildren(
            node.props.children,
            depth + 1,
            keys.concat(node.key || nodeIndex)
          )
        );
      } else {
        if (isValidElement(node)) {
          acc.push(
            cloneElement(node, {
              key: keys.concat(String(node.key)).join('.'),
            })
          );
        } else if (typeof node === 'string' || typeof node === 'number') {
          acc.push(node);
        }
      }
      return acc;
    }, []);
  };
  let allChilds = flattenChildren(_.flatten(children));
  const rows = _.range(window.Math.ceil(allChilds.length / 2));
  return (
    <div className="flex">
      <table className={cn('border overflow-hidden w-full', props.className)}>
        <tbody>
          {rows.map((i, index) => {
            return (
              <tr key={index}>
                <td className="p-2 w-72 bg-muted text-accent-foreground text-sm border-r border-b">
                  {allChilds[i * 2]}
                </td>
                <td className="p-2 text-sm border-b">{allChilds[i * 2 + 1]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default function NodeDetail({ selectedNode, logs, ...props }) {
  if (!selectedNode?.node_type) {
    return null;
  }
  let CustomNodeDetailView = null;
  let propertyObject = {};
  let allProperties = selectedNode?.properties;

  switch (selectedNode.node_type) {
    case 'httpapi_fetch':
    case 'httpapi_webhook':
      if (allProperties['output_key'] !== '') {
        propertyObject['Response Key'] = allProperties['output_key'];
      }
      let defaultTab = '';
      let hasBodyTab = !_.isNull(allProperties?.['body']);
      if (hasBodyTab) defaultTab = 'body';
      let headersTab = allProperties['headers']?.length > 0;
      if (defaultTab === '' && headersTab) defaultTab = 'headers';
      let queryParamsTab = allProperties['query_params']?.length > 0;
      if (defaultTab === '' && queryParamsTab) defaultTab = 'query';
      CustomNodeDetailView = (
        <>
          <div className="flex mx-4 text-sm my-2 border rounded">
            <p className="p-2 bg-primary-muted dark:bg-slate-700 rounded-l font-medium">
              {allProperties['http_method']}
            </p>
            <p className="p-2 bg-muted dark:bg-slate-500 rounded-r min-w-36 flex-grow">
              {allProperties['url'].replaceAll('"', '')}
            </p>
          </div>
          <Tabs
            defaultValue={defaultTab}
            className="mx-4"
            key={selectedNode.id}
          >
            {(headersTab || queryParamsTab) && (
              <TabsList>
                {hasBodyTab && (
                  <TabsTrigger value="body" key="body">
                    Body
                  </TabsTrigger>
                )}
                {headersTab && (
                  <TabsTrigger value="headers" key="headers">
                    Headers
                  </TabsTrigger>
                )}
                {queryParamsTab && (
                  <TabsTrigger value="query" key="query">
                    Query Params
                  </TabsTrigger>
                )}
              </TabsList>
            )}

            {hasBodyTab && (
              <TabsContent
                value="body"
                className="flex flex-col divide-y border"
                key="body"
              >
                <div className="rounded-b">
                  <pre>
                    <JSONDisplay jsonObj={decodeURI(allProperties?.['body'])} />
                  </pre>
                </div>
              </TabsContent>
            )}
            {headersTab && (
              <TabsContent value="headers" key="headers">
                <PropertyTable className="border-t-0">
                  {allProperties['headers'].map(item => {
                    return (
                      <>
                        <p>{item.key}</p>
                        <p>{item.value}</p>
                      </>
                    );
                  })}
                </PropertyTable>
              </TabsContent>
            )}
            {queryParamsTab && (
              <TabsContent value="query" key="query">
                <PropertyTable className="border-t-0">
                  {allProperties['query_params'].map(item => {
                    return (
                      <>
                        <p>{item.key}</p>
                        <p>{item.value}</p>
                      </>
                    );
                  })}
                </PropertyTable>
              </TabsContent>
            )}
          </Tabs>
        </>
      );
      break;
    case 'delay':
      propertyObject['Delay Type'] = allProperties['delay_type'];
      propertyObject['Value'] = allProperties['value'];
      break;
    case 'batch':
      if (allProperties.window_type === 'fixed') {
        propertyObject['Batch Window'] = allProperties['_fixed_window_parsed'];
      }
      if (allProperties.window_type === 'dynamic') {
        propertyObject['Batch Window'] = allProperties['dynamic_window_expr'];
      }
      propertyObject['Batch Key'] = allProperties['batch_key'];
      propertyObject[
        'Retain Batch Events'
      ] = `${allProperties['retain_order']} ${allProperties['retain_count']} `;
      break;
    case 'send_smart_channel_routing':
      propertyObject['Template'] = {
        displayText: allProperties['template'],
        href: urlUtils.makeURL(
          `/templates/${allProperties['_template_json']['slug']}/${
            allProperties['channels']?.[0] || ''
          }`
        ),
      };
      propertyObject['Time To Live'] = allProperties['_ttl_value_parsed'];
      propertyObject['Smart Delivery'] = allProperties['smart'];
      propertyObject['Mandatory Channels'] =
        allProperties['mandatory_channels'];
      propertyObject['Success Metric'] = allProperties['success'];
      break;
    case 'send_sms':
    case 'send_email':
    case 'send_whatsapp':
    case 'send_slack':
    case 'send_msteams':
    case 'send_androidpush':
    case 'send_mobilepush':
    case 'send_iospush':
    case 'send_webpush':
    case 'send_inbox':
    case 'send_multi_channel':
      if (allProperties['_template_json']) {
        propertyObject['Template'] = {
          displayText: allProperties['template'],
          href: urlUtils.makeURL(
            `/templates/${allProperties['_template_json']?.['slug']}/${
              allProperties['channels']?.[0] || ''
            }`
          ),
        };
      } else {
        propertyObject['Template'] = {
          displayText: allProperties['template'],
          href: urlUtils.makeURL(`/templates/${allProperties['template']}/`),
        };
      }
      break;
    default:
      break;
  }

  let started_at = logs.findOne({
    node_id: selectedNode.id,
    logtype: 'node_start',
  })?.['time'];

  return (
    <>
      {selectedNode?.node_type && (
        <>
          <div className="flex items-center pt-4 pb-2">
            <h1 className="px-4 font-semibold text-xl">
              {humanizeNodeName(selectedNode?.name || selectedNode?.node_type)}
            </h1>
            <NodeStatus
              logs={logs}
              node_type={selectedNode?.node_type}
              node_id={selectedNode?.id}
            />
          </div>
          {started_at && (
            <div className="px-4 text-sm flex space-x-2 mb-2">
              <p className="font-medium">Started at:</p>
              <TimeAgo
                dateInput={
                  logs.findOne({
                    node_id: selectedNode.id,
                    logtype: 'node_start',
                  })?.['time']
                }
              />
            </div>
          )}
        </>
      )}
      {propertyObject && Object.entries(propertyObject).length > 0 && (
        <>
          <p className="mx-4 font-medium text-sm mb-1 pt-2">Properties</p>
          <div className="mx-4 mb-6">
            <PropertyTable>
              {Object.entries(propertyObject).map(([key, value]) => {
                if (value === '' || _.isNull(value) || _.isUndefined(value)) {
                  return null;
                } else {
                  if (!!value?.displayText && !!value?.href) {
                    return (
                      <>
                        <p>{key}</p>
                        <a
                          href={value.href}
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary-text hover:underline"
                        >
                          {value.displayText}
                        </a>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <p>{key}</p>
                        <p>{value + ''}</p>
                      </>
                    );
                  }
                }
              })}
            </PropertyTable>
          </div>
        </>
      )}
      {!!CustomNodeDetailView && (
        <div className="py-2">{CustomNodeDetailView}</div>
      )}
    </>
  );
}

import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronLeftIcon, ExternalLinkIcon } from 'lucide-react';
import { TabBar } from 'custom-components';
import urlUtils from 'utils/urlUtils';
import { DEFAULT_NODE_WIDTH, DEFAULT_NODE_HEIGHT } from './WorkflowDetails';
import ELK from 'elkjs/lib/elk.bundled.js';
import { cn } from 'utils';
import { COverflowTooltip } from 'new-components';

const BRANCH_NODES = ['multibranch_wait_until', 'branch', 'branch_waituntil'];

export function handleBranchingMap(data, mainObj) {
  if (!BRANCH_NODES.includes(data.node_type)) return;

  const nodeData = {
    id: data.id,
    type: data.node_type,
    data: data,
  };

  mainObj[nodeData.id] = nodeData;

  // loop through branch conditions
  for (let branch of data.branches) {
    const nodeData = {
      ...branch,
      parent_node_id: data.id,
      node_type: 'condition_node',
    };
    mainObj[nodeData.id] = {
      id: nodeData.id,
      type: nodeData.node_type,
      data: {
        ...branch,
        parent_node_id: nodeData.parent_node_id,
        branch_id: branch.id,
      },
    };

    let counter = 0;
    for (let branchStep of branch.nodes) {
      if (BRANCH_NODES.includes(branchStep.node_type)) {
        handleBranchingMap(branchStep, mainObj);
      } else {
        if (counter === 0) {
          const nodeData = {
            ...branchStep,
            parent_node_id: branch.id,
            branch_id: branch.id,
          };
          mainObj[nodeData.id] = {
            id: nodeData.id,
            type: branchStep.node_type,
            data: nodeData,
          };
        } else {
          mainObj[branchStep.id] = {
            id: branchStep.id,
            type: branchStep.node_type,
            data: { ...branchStep, branch_id: branch.id },
          };
        }
      }
      counter++;
    }

    mainObj[`join_node_${data.id}`] = {
      id: `join_node_${data.id}`,
      type: 'join_node',
      data: { id: `join_node_${data.id}`, type: 'join_node', wf_id: data.id },
    };
  }
}

export function convertTreeToObj(data) {
  const mainObj = {};
  data.forEach((node, index) => {
    if (BRANCH_NODES.includes(node.node_type)) {
      if (index === 1) {
        node.parent_node_id = data[0].id;
      }
      handleBranchingMap(node, mainObj);
    } else {
      const parentNodeId = index === 1 ? data[0].id : node.parent_node_id;
      const nodeData = {
        id: node.id,
        type: node.node_type,
        data: {
          ...node,
          parent_node_id: parentNodeId,
        },
      };
      mainObj[nodeData.id] = nodeData;
    }
  });

  return mainObj;
}

export function handleBranching(data, nodesObj) {
  let nodes = [];
  let edges = [];

  if (!BRANCH_NODES.includes(data.node_type)) return;

  // push multi-branch node itself
  const nodeData = {
    id: data.id,
    type: data.node_type,
    data: data,
  };

  // if parent node is multibranch make parent node as join node
  const parentNodeData = nodeData?.data?.parent_node_id
    ? nodesObj?.[nodeData.data.parent_node_id]
    : null;
  if (BRANCH_NODES.includes(parentNodeData?.type)) {
    nodeData.data.parent_node_id = `join_node_${parentNodeData.id}`;
  }

  nodes.push(nodeData);
  edges.push({
    id: `${nodeData.data.parent_node_id}-${nodeData.id}`,
    source: nodeData.data.parent_node_id,
    target: nodeData.id,
  });

  // loop through branch conditions
  for (let branch of data.branches) {
    const nodeData = {
      ...branch,
      parent_node_id: data.id,
      node_type: 'condition_node',
    };
    nodes.push({
      id: nodeData.id,
      type: nodeData.node_type,
      data: {
        ...branch,
        parent_node_id: nodeData.parent_node_id,
        branch_id: branch.id,
        node_type: nodeData.node_type,
      },
    });
    edges.push({
      id: `${nodeData.parent_node_id}-${nodeData.id}`,
      source: nodeData.parent_node_id,
      target: nodeData.id,
      type: 'branching-edge',
    });

    const totalBranchSteps = branch?.nodes?.length || 0;

    if (totalBranchSteps <= 0) {
      edges.push({
        id: `${branch.id}-join_node_${data.id}`,
        source: `${branch.id}`,
        target: `join_node_${data.id}`,
        type: 'joining-edge',
      });
    } else {
      let counter = 0;
      for (let branchStep of branch.nodes) {
        if (BRANCH_NODES.includes(branchStep.node_type)) {
          if (counter === 0) {
            branchStep.parent_node_id = branch.id;
          }
          const [newNodes, newEdges] = handleBranching(branchStep, nodesObj);
          if (counter === 0) {
            newEdges[0].source = branch.id;
            newEdges[0].id = `${newEdges[0].source}-${newEdges[0].target}`;
          }
          if (counter === totalBranchSteps - 1) {
            edges.push({
              id: `join_node_${branchStep.id}-join_node_${data.id}`,
              source: `join_node_${branchStep.id}`,
              target: `join_node_${data.id}`,
              type: 'joining-edge',
            });
          }
          nodes = [...nodes, ...newNodes];
          edges = [...edges, ...newEdges];
        } else {
          if (counter === 0) {
            const nodeData = {
              ...branchStep,
              parent_node_id: branch.id,
              branch_id: branch.id,
            };
            nodes.push({
              id: nodeData.id,
              type: branchStep.node_type,
              data: nodeData,
            });
            edges.push({
              id: `${nodeData.parent_node_id}-${nodeData.id}`,
              source: nodeData.parent_node_id,
              target: nodeData.id,
            });
            if (counter === totalBranchSteps - 1) {
              edges.push({
                id: `${nodeData.id}-join_node_${data.id}`,
                source: nodeData.id,
                target: `join_node_${data.id}`,
                type: 'joining-edge',
              });
            }
          } else {
            // if parent node is multibranch make parent node as join node
            const parentNodeData = branchStep?.parent_node_id
              ? nodesObj?.[branchStep.parent_node_id]
              : null;
            if (BRANCH_NODES.includes(parentNodeData?.type)) {
              branchStep.parent_node_id = `join_node_${parentNodeData.id}`;
            }

            nodes.push({
              id: branchStep.id,
              type: branchStep.node_type,
              data: { ...branchStep, branch_id: branch.id },
            });
            edges.push({
              id: `${branchStep.parent_node_id}-${branchStep.id}`,
              source: branchStep.parent_node_id,
              target: branchStep.id,
            });
            if (counter === totalBranchSteps - 1) {
              edges.push({
                id: `${branchStep.id}-join_node_${data.id}`,
                source: branchStep.id,
                target: `join_node_${data.id}`,
                type: 'joining-edge',
              });
            }
          }
        }
        counter++;
      }
    }
  }

  // add join node at end
  nodes.push({
    id: `join_node_${data.id}`,
    type: 'join_node',
    data: { id: `join_node_${data.id}`, type: 'join_node', wf_id: data.id },
  });

  return [nodes, edges];
}

export function convertTreeToGraph(data, nodesObj) {
  let nodes = [];
  let edges = [];

  data.forEach((node, index) => {
    if (BRANCH_NODES.includes(node.node_type)) {
      if (index === 1) {
        node.parent_node_id = data[0].id;
      }
      const [branchedNodes, branchedEdges] = handleBranching(node, nodesObj);
      nodes = [...nodes, ...branchedNodes];
      edges = [...edges, ...branchedEdges];
    } else {
      const parentNodeId = index === 1 ? data[0].id : node.parent_node_id;
      const nodeData = {
        id: node.id,
        type: node.node_type,
        data: {
          ...node,
          parent_node_id: parentNodeId,
        },
      };

      // if parent node is multibranch make parent node as join node
      const parentNodeData = parentNodeId ? nodesObj?.[parentNodeId] : null;
      if (BRANCH_NODES.includes(parentNodeData?.type)) {
        nodeData.data.parent_node_id = `join_node_${parentNodeData.id}`;
      }

      nodes.push(nodeData);
      if (node.node_type !== 'trigger') {
        edges.push({
          id: `${nodeData.data.parent_node_id}-${nodeData.id}`,
          source: nodeData.data.parent_node_id,
          target: nodeData.id,
        });
      }
    }
  });

  return [nodes, edges];
}

export async function layoutELK(nodes, edges) {
  const elk = new ELK();

  const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.direction': 'DOWN',
    'elk.layered.spacing.edgeEdgeBetweenLayers': '0',
    'elk.layered.spacing.nodeNodeBetweenLayers': '40',
    'elk.layered.considerModelOrder.strategy': 'NODES_AND_EDGES',
    'elk.layered.layering.strategy': 'INTERACTIVE',
    'elk.layered.nodePlacement.favorStraightEdges': 'false',
    'elk.aspectRatio': '2',
    'elk.spacing.nodeNode': '10',
    'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
  };

  const graph = {
    id: 'root',
    layoutOptions: elkOptions,
    children: nodes.map(node => {
      const nodesData = {
        condition_node: 25,
        join_node: 20,
      };
      const nodesHeight = nodesData[node.type] || DEFAULT_NODE_HEIGHT;
      return {
        ...node,
        width: DEFAULT_NODE_WIDTH,
        height: nodesHeight,
      };
    }),
    edges: edges,
  };

  const data = await elk
    .layout(graph)
    .then(layoutedGraph => ({
      nodes: layoutedGraph.children.map(node => ({
        ...node,
        position: { x: node.x, y: node.y },
      })),
      edges: layoutedGraph.edges,
    }))
    .catch(console.error);
  return [data.nodes, data.edges];
}

export function Header({ workflow, selectedId, CTAComponent, editMode }) {
  const history = useHistory();
  const { slug } = useParams();

  const [tabOptions, setTabOptions] = useState([
    {
      name: 'Overview',
      id: 'overview',
      onClick: () => {
        history.push(urlUtils.makeURL(`/workflows/${slug}`));
      },
    },
    {
      name: 'Requests',
      id: 'requests',
      onClick: () => {
        history.push(
          urlUtils.makeURL(`/workflows/${slug}/requests?last_n_minutes=1440`)
        );
      },
    },
    {
      name: 'Analytics',
      id: 'analytics',
      onClick: () => {
        history.push(urlUtils.makeURL(`/workflows/${slug}/analytics`));
      },
    },
  ]);

  const hasMessagesTab = tabOptions?.some(obj => obj.id === 'messages');
  const hasBroadcastExecutionsTab = tabOptions?.some(
    obj => obj.id === 'broadcast_executions'
  );
  const hasExecutions = tabOptions?.some(obj => obj.id === 'executions');
  const addTabs = [...tabOptions];

  if (workflow?.is_broadcast && !hasMessagesTab && !hasBroadcastExecutionsTab) {
    addTabs.splice(2, 0, {
      name: (
        <div className="flex items-center gap-x-1">
          Messages <ExternalLinkIcon className="h-4 w-4" />
        </div>
      ),
      id: 'messages',
      onClick: () => {
        history.push(
          urlUtils.makeURL(
            `/logs/workflow/?last_n_minutes=2880&filter_type=workflow_slug&filter_value=${workflow?.slug}&filter_name=${workflow?.name}`
          )
        );
      },
    });

    addTabs.splice(3, 0, {
      name: (
        <div className="flex items-center gap-x-1">
          Broadcast Executions <ExternalLinkIcon className="h-4 w-4" />
        </div>
      ),
      id: 'broadcast_executions',
      onClick: () => {
        history.push(
          urlUtils.makeURL(
            `/logs/broadcast/?last_n_minutes=2880&filter_type=workflow_slug&filter_value=${workflow?.slug}&filter_name=${workflow?.name}`
          )
        );
      },
    });

    setTabOptions(addTabs);
  } else if (!workflow?.is_broadcast && !hasExecutions) {
    addTabs.splice(2, 0, {
      name: 'Executions',
      id: 'executions',
      onClick: () => {
        history.push(
          urlUtils.makeURL(`/workflows/${slug}/executions?last_n_minutes=1440`)
        );
      },
    });
    setTabOptions(addTabs);
  }

  return (
    <div className="flex border-b border-gray-100 pr-4 items-center mt-2 justify-between">
      <div className="flex items-start w-[30%] break-all">
        <div
          onClick={() => {
            history.goBack();
          }}
          className="pl-4 pr-1.5 cursor-pointer"
        >
          <ChevronLeftIcon className="h-6 w-6 text-muted-foreground mt-0.5" />
        </div>
        <COverflowTooltip
          trigger={
            <p className="text-lg font-semibold truncate">{workflow?.name}</p>
          }
          side="bottom"
        >
          {workflow?.name}
        </COverflowTooltip>
        <div>
          {editMode ? (
            <p
              className={cn(
                'py-0.5 px-2  text-xs rounded bg-warning-muted text-warning ml-2 w-max mt-1.5',
                selectedId !== 'overview' && 'invisible'
              )}
            >
              Draft
            </p>
          ) : (
            <p
              className={cn(
                'py-0.5 px-2  text-xs rounded bg-success-muted text-success ml-2 w-max mt-1.5',
                selectedId !== 'overview' && 'invisible'
              )}
            >
              Live
            </p>
          )}
        </div>
      </div>
      <div className="self-end">
        <TabBar
          options={tabOptions}
          selectedId={selectedId}
          containerClassName="border-none"
        />
      </div>
      <div
        className={cn(
          'flex justify-end',
          workflow?.is_broadcast ? 'w-[200px]' : 'w-[400px]'
        )}
      >
        {CTAComponent ? (
          <CTAComponent />
        ) : (
          <div
            className={cn(
              workflow?.is_broadcast ? 'w-[200px]' : 'w-[400px]',
              'h-[40px]'
            )}
          />
        )}
      </div>
    </div>
  );
}

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from 'stores/AuthStore';
import { toast } from 'components';

const getVendors = async (channel_slug = '') => {
  const { data } = await loggedInAPI.get(
    `/vendor/?channel_slug=${channel_slug}`
  );
  return data;
};

export const useVendors = channel_slug => {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/vendor/?channel_slug=${channel_slug}`,
    () => getVendors(channel_slug)
  );
};

const getChannelVendors = async (channel_slug = '', tenant_id = '') => {
  const url = buildAPIURL(
    `/tenant/${tenant_id}/vendor/?channel=${channel_slug}&limit=50`
  );
  const { data } = await loggedInAPI.get(url);
  return data;
};

export const useChannelVendors = (channel_slug, tenant_id) => {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor?channel=${channel_slug}`,
    () => getChannelVendors(channel_slug, tenant_id),
    { enabled: !!tenant_id }
  );
};

const getChannelVendor = async (id, tenant_id) => {
  const url = buildAPIURL(`/tenant/${tenant_id}/vendor/${id}/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

export const useChannelVendor = (id, tenant_id, call_vendor) => {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor/${id}`,
    () => getChannelVendor(id, tenant_id),
    { enabled: call_vendor }
  );
};

export const useEditVendorSettings = (id, tenant_id, channel_slug) => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/tenant/${tenant_id}/vendor/${id}/`);
  return useMutation(data => loggedInAPI.put(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor?channel=${channel_slug}`
      );
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor/${id}`
      );
    },
    onError: error => {
      toast(
        `Error saving vendor settings: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

export const useAddVendorSettings = (channel_slug, tenant_id) => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/tenant/${tenant_id}/vendor/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor?channel=${channel_slug}`
      );
    },
    onError: error => {
      toast(
        `Error saving vendor settings: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

export const useSubscribeToTopic = (tenant_id, vendor_id) => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(
    `/tenant/${tenant_id}/vendor/${vendor_id}/subscribe_to_topic/`
  );
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor/${vendor_id}`
      );
    },
    onError: error => {
      toast(
        `Error subscribing to topic : ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

export const useEditVendorWithUploadSettings = (
  id,
  tenant_id,
  channel_slug
) => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/tenant/${tenant_id}/vendor_with_upload/${id}/`);
  return useMutation(data => loggedInAPI.put(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor?channel=${channel_slug}`
      );
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor/${id}`
      );
    },
    onError: error => {
      toast(
        `Error saving vendor settings: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

export const useAddVendorWithUploadSettings = (channel_slug, tenant_id) => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/tenant/${tenant_id}/vendor_with_upload/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor?channel=${channel_slug}`
      );
    },
    onError: error => {
      toast(
        `Error saving vendor settings: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

const getClientVendorRouting = async (
  tenant_id,
  channel_slug = '',
  id_provider,
  notif_category
) => {
  const url = buildAPIURL(
    `/tenant/${tenant_id}/vendor_routing/${channel_slug}/${id_provider}/${notif_category}/`
  );
  const data = await loggedInAPI.get(url);
  return data;
};

export const useClientVendorRouting = ({
  tenant_id,
  channel_slug,
  id_provider = '_',
  notif_category,
  enabled = true,
}) => {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor_routing/${channel_slug}/${id_provider}/${notif_category}`,
    () =>
      getClientVendorRouting(
        tenant_id,
        channel_slug,
        id_provider,
        notif_category
      ),
    { enabled: enabled }
  );
};

export const useAddClientVendorRouting = ({
  tenant_id,
  channel_slug = '',
  id_provider = '_',
  notif_category,
}) => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(
    `/tenant/${tenant_id}/vendor_routing/${channel_slug}/${id_provider}/${notif_category}/`
  );
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor_routing/${channel_slug}/${id_provider}/${notif_category}`
      );
    },
    onError: error => {
      toast(
        `Error changing vendor config: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

export const useOverrideTenantVendor = ({
  tenant_id,
  channel_slug = '',
  id_provider = '_',
  notif_category,
}) => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(
    `/tenant/${tenant_id}/vendor_routing/${channel_slug}/${id_provider}/${notif_category}/override/`
  );
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/tenant/${tenant_id}/vendor_routing/${channel_slug}/${id_provider}/${notif_category}`
      );
    },
    onError: error => {
      toast(
        `Error overiding vendor config: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Switch,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  Label,
} from 'new-components';
import { useAuthState } from 'stores/AuthStore';
import urlUtils from 'utils/urlUtils';
import {
  ClockIcon,
  Pencil1Icon,
  CaretSortIcon,
  CheckIcon,
} from '@radix-ui/react-icons';
import { TimezonePreferenceContext } from 'custom-components';

function TimeZoneSwitcher() {
  const authState = useAuthState();
  const history = useHistory();

  const { timezone, showRelative, setTimezone, setShowRelative } = useContext(
    TimezonePreferenceContext
  );

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userInfo = authState.user.get();

  let timezoneTypes = [
    {
      id: 'utc',
      label: 'UTC',
      value: 'UTC',
    },
    {
      id: 'local',
      label: `Local (${localTimezone})`,
      value: localTimezone,
    },
  ];

  if (userInfo?.timezone) {
    timezoneTypes.push({
      id: 'preferred',
      label: `Preferred (${userInfo?.timezone})`,
      value: userInfo?.timezone,
      isEditable: true,
    });
  }

  return (
    <div className="max-w-lg lg:max-w-xs">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="border-0 focus-visible:ring-0 h-7 my-1.5 p-0 mx-2 px-3 data-[state=open]:bg-accent"
          >
            <p className="inline-flex items-center justify-center font-normal">
              <ClockIcon className="mr-2 h-4 w-4 text-muted-foreground" />
              <span className="text-foreground">
                {showRelative ? 'Relative' : timezone.value}
              </span>
              <CaretSortIcon className="h-4 w-4 opacity-50 ml-1" />
            </p>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="mt-1 px-0 py-1 relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <div>
            {timezoneTypes?.map(type => (
              <PopoverClose asChild key={type?.id}>
                <div
                  onClick={() => {
                    const modifiedTimezone = {
                      id: type.id,
                      value: type.value,
                    };
                    setTimeout(() => {
                      setTimezone(modifiedTimezone);
                      localStorage.setItem(
                        'suprsend-user-time-preference',
                        JSON.stringify({
                          timezone: modifiedTimezone,
                          showRelative: showRelative,
                        })
                      );
                    }, 100);
                  }}
                  className="max-w-80 cursor-pointer flex items-center m-1 py-1.5 rounded pl-2 pr-4 text-sm outline-none focus:bg-accent focus:text-accent-foreground hover:bg-accent"
                >
                  {type.id === timezone?.id ? (
                    <CheckIcon className="h-4 w-4 mr-6" />
                  ) : (
                    <p className="mr-10" />
                  )}
                  <p>{type.label}</p>
                  {type.isEditable && (
                    <div
                      className="ml-auto cursor-pointer flex justify-center"
                      onClick={e => {
                        e.stopPropagation();
                        history.push(
                          urlUtils.makeCommonURL('/user-settings/general')
                        );
                      }}
                    >
                      <PopoverClose>
                        <Pencil1Icon className="h-4 w-4" />
                      </PopoverClose>
                    </div>
                  )}
                </div>
              </PopoverClose>
            ))}
            <div className="border-t border-border">
              <div className="flex items-center m-1 py-1.5 rounded-md pl-2 text-sm outline-none">
                <Switch
                  id="relative_timestamp"
                  checked={showRelative}
                  onCheckedChange={e => {
                    setShowRelative(!showRelative);
                    localStorage.setItem(
                      'suprsend-user-time-preference',
                      JSON.stringify({
                        timezone: timezone,
                        showRelative: !showRelative,
                      })
                    );
                  }}
                  className="h-4 w-8"
                  thumbStyle="h-3 w-3"
                />
                <Label
                  htmlFor="relative_timestamp"
                  className="ml-2 cursor-pointer font-normal"
                >
                  Show relative timestamp
                </Label>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default TimeZoneSwitcher;

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

function MobileField({ mobile, setFieldValue, name, formChanged, ...props }) {
  return (
    <PhoneInput
      placeholder="Mobile Number"
      value={mobile}
      international
      onChange={value => {
        setFieldValue(name, value || '');
        formChanged?.();
      }}
      {...props}
    />
  );
}

export default MobileField;

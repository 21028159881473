import { NavLink, useRouteMatch, useParams } from 'react-router-dom';
import { classNames } from 'utils';
import urlUtils from 'utils/urlUtils';

const TabOptions = [
  {
    name: 'Overview',
    id: 'overview',
    path: '',
  },
  {
    name: 'Requests',
    id: 'requests',
    path: 'requests',
  },
  {
    name: 'Executions',
    id: 'executions',
    path: 'executions',
  },
  {
    name: 'Analytics',
    id: 'analytics',
    path: 'analytics',
  },
];

export function TabBar() {
  let { url } = useRouteMatch();
  const { slug } = useParams();

  return (
    <div className="flex">
      {TabOptions.map(tab => {
        const isSelected =
          tab.id === 'overview'
            ? url.endsWith(slug) ||
              url.endsWith(`${slug}/`) ||
              url.endsWith(`${slug}/edit`) ||
              url.endsWith(`${slug}/edit/`)
            : url.endsWith(tab.path) || url.endsWith(`${tab.path}/`);
        return (
          <NavLink
            to={urlUtils.makeURL(`workflows/${slug}/${tab.path}`)}
            key={tab.name}
            className={classNames(
              'px-4 font-medium',
              isSelected
                ? 'text-primary border-b-2 border-indigo-600 dark:border-gray-100 mb-[-16px]'
                : ''
            )}
          >
            <p>{tab.name}</p>
          </NavLink>
        );
      })}
    </div>
  );
}

const InnerTabOptions = [
  {
    name: 'Node List',
    id: 'nodes_list',
  },
  {
    name: 'Workflow Settings',
    id: 'workflow_settings',
  },
];

export function InnerTabBar({ selectedForm, setSelectedForm }) {
  return (
    <div className="border-b">
      <div className="flex flex-grow mx-4">
        {InnerTabOptions.map(tab => {
          const isSelected = selectedForm === tab.id;
          return (
            <div
              key={tab.id}
              onClick={() => {
                setSelectedForm(tab.id);
              }}
              className={classNames(
                'pt-5 px-6 pb-1 font-medium cursor-pointer',
                isSelected
                  ? 'text-indigo-600  border-b-2 border-indigo-600'
                  : 'text-gray-500'
              )}
            >
              <p>{tab.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function CommonTabBar({ options, selectedId, tabClassName }) {
  return (
    <div className="flex flex-grow border-b">
      {options.map(tab => {
        const isSelected = selectedId === tab.id;
        return (
          <div
            key={tab.id}
            onClick={() => {
              tab.onClick();
            }}
            className={classNames(
              'pt-5 px-6 pb-1 font-medium cursor-pointer',
              isSelected
                ? 'text-indigo-600  border-b-2 border-indigo-600'
                : 'text-gray-500',
              tabClassName
            )}
          >
            <p>{tab.name}</p>
          </div>
        );
      })}
    </div>
  );
}

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from 'stores/AuthStore';
import toast from 'react-hot-toast';

const getWebhook = async () => {
  const url = buildAPIURL(`/webhook/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useWebhook() {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/webhook/`, () => getWebhook());
}

function useEnableWebhook() {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/webhook/`);
  return useMutation(() => loggedInAPI.patch(url, { is_enabled: true }), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/webhook/`);
    },
    onError: error => {
      toast.error(`Error enabling webhook : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}
export { useWebhook, useEnableWebhook };

import { forwardRef } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { Input, Label } from 'new-components';
import Handlebars from 'handlebars';

function TextField(props, ref) {
  const {
    label,
    name,
    mandatory = true,
    as = Input,
    className = '',
    inputContainerClassName = '',
    inputClassName = '',
    rightComponent,
    leftComponent,
    channelType,
    warning,
    formChanged,
    ...others
  } = props;

  const { handleChange } = useFormikContext();

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={name}>
          <p className="my-2">
            {label}
            {mandatory && <span className="text-destructive">*</span>}
          </p>
        </Label>
      )}
      <div className={inputContainerClassName}>
        {leftComponent && leftComponent()}
        <Field
          as={as}
          name={name}
          id={name}
          innerRef={input => {
            if (ref) ref.current = input;
          }}
          onChange={e => {
            handleChange(e);
            formChanged?.();
          }}
          validate={value => {
            if (channelType === 'whatsapp') {
              try {
                const compiledTemplate = Handlebars.compile(value, {
                  strict: true,
                  knownHelpersOnly: true,
                  knownHelpers: {
                    helperMissing: false,
                    blockHelperMissing: false,
                    each: false,
                    if: false,
                    unless: false,
                    with: false,
                    log: false,
                    lookup: false,
                  },
                });
                compiledTemplate({ name: 1 });
              } catch (err) {
                if (err.message.includes('You specified knownHelpersOnly')) {
                  return 'Handlebars helpers are not allowed';
                }
              }
            }
          }}
          autoComplete="off"
          className={inputClassName}
          {...others}
        />
        {rightComponent && rightComponent()}
      </div>
      <ErrorMessage name={name}>
        {msg => <p className="text-destructive text-sm pt-2">{msg}</p>}
      </ErrorMessage>
      {warning && <p className="text-destructive text-sm pt-2">{warning}</p>}
    </div>
  );
}

export default forwardRef(TextField);

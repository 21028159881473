import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import { usePublishDraftTemplate, useChannelListAPI } from 'apis';
import { classNames } from 'utils';
import Spinner from './Spinner';

export default function PublishDraftModal({
  open,
  setOpen,
  template,
  channel,
  lang,
  successMessage,
  languageSchema,
  nonDltLanguageSchema,
  liveOnly,
}) {
  const [versionTag, setVersionTag] = useState('');
  const [chooseLanguageModal, setChooseLanguageModal] = useState(false);
  const [languageToPublish, setLanguageToPublish] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const inputRef = useRef(null);
  const publishDraft = usePublishDraftTemplate(
    template,
    channel,
    successMessage
  );

  const { data: channelVersions, isFetching: isChannelVLFetching } =
    useChannelListAPI(template, channel, liveOnly);

  const version = channelVersions?.results?.find(
    item => item.status === 'draft'
  );

  let errorMessage = 'Errors in template form';

  const channelSpecificError = {
    email: 'Subject in Other Email Settings or content is missing in template',
    sms: 'Error in template form (or) variables used in template are missing in mock data',
    whatsapp:
      'Error in template form (or) variables used in template are missing / have empty value in mock data',
  };

  if (['sms', 'whatsapp', 'email'].includes(channel)) {
    errorMessage = channelSpecificError[channel];
  }

  const validations = () => {
    let defaultCheckedLanguages = [];
    try {
      version?.templates?.map(async langObj => {
        let isError = true;
        if (channel === 'sms' && !langObj.is_approval_needed) {
          isError = await nonDltLanguageSchema.isValid(langObj?.content);
        } else if (channel === 'email') {
          isError =
            (await languageSchema.isValid(langObj?.content)) &&
            langObj &&
            langObj.content &&
            Object.keys(langObj.content).length > 0;
        } else {
          isError = await languageSchema.isValid(langObj?.content);
        }
        if (langObj?.content) {
          setErrors(current => [
            ...current,
            { slug: langObj?.language?.slug, mandatoryCheck: isError },
          ]);
        }
        if (isError) {
          defaultCheckedLanguages.push(langObj?.language?.slug);
        }
        setLanguageToPublish(defaultCheckedLanguages);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (version && !isChannelVLFetching) {
      validations();
    }
  }, [channelVersions, isChannelVLFetching]);

  const showLoading = !version || isChannelVLFetching;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={inputRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {!chooseLanguageModal ? (
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <InformationCircleIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Please provide a version name
                      </Dialog.Title>
                      <div className="mt-2">
                        <input
                          className="flex-grow p-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Version Name"
                          ref={inputRef}
                          value={versionTag}
                          onChange={ev => setVersionTag(ev.target.value)}
                        ></input>
                        <p className="text-sm text-gray-500 mt-2">
                          It will help you and your team members to understand
                          what changed in your templates.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
                    disabled={versionTag.length === 0 || showLoading}
                    onClick={() => {
                      setChooseLanguageModal(true);
                    }}
                  >
                    {`Next ->`}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-[#F0F6FF] px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-[#276AC5]"
                      >
                        Confirm which languages to publish
                      </Dialog.Title>
                      <p className="text-sm text-gray-500 mt-2">
                        You can't publish languages where the message content is
                        incomplete or contain errors.
                        <span className="text-sm text-gray-900 font-semibold ml-1">
                          English content is mandatory to publish
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {version?.templates?.map((languageObj, index) => {
                  const language = languageObj?.language;
                  const filterErrors = errors.filter(
                    val => val.slug === language?.slug
                  );
                  const disableCheckBox = !filterErrors[0]?.mandatoryCheck;
                  return (
                    <div
                      className="py-3 border-b border-b-gray-100"
                      key={index}
                    >
                      <div className="flex items-center">
                        <p className="ml-10">{language?.name}</p>
                        {language?.slug === lang && (
                          <p className="text-xs self-center bg-[#9FF9CB] py-[2px] px-[10px] rounded-xl text-[#047857] ml-6">
                            default
                          </p>
                        )}
                        {language?.slug !== lang && (
                          <input
                            type="checkbox"
                            className={classNames(
                              'ml-auto mr-8 flex justify-center active:ring-0 focus:ring-0 rounded-sm',
                              disableCheckBox
                                ? 'bg-gray-200 border border-gray-300'
                                : ''
                            )}
                            style={{
                              color: '#4D88D9',
                            }}
                            value={languageObj}
                            disabled={disableCheckBox}
                            defaultChecked={!disableCheckBox}
                            onChange={e => {
                              if (e.target.checked) {
                                setLanguageToPublish([
                                  ...languageToPublish,
                                  language?.slug,
                                ]);
                              } else {
                                const valueToRemove = languageToPublish.filter(
                                  element => element !== language?.slug
                                );
                                setLanguageToPublish(valueToRemove);
                              }
                            }}
                          />
                        )}
                      </div>
                      {disableCheckBox && (
                        <div className="flex ml-10">
                          <XCircleIcon className="h-3 w-3 text-red-500 mt-[6px]" />
                          <p className="text-xs text-gray-500 ml-1 mt-1">
                            {errorMessage}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    disabled={
                      (languageToPublish &&
                        !languageToPublish.includes(lang)) ||
                      showLoader
                    }
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
                    onClick={async () => {
                      setShowLoader(true);
                      try {
                        window.publishDraftActionDone = true;
                        await publishDraft.mutateAsync({
                          versionTag: versionTag,
                          languageToPublish: languageToPublish,
                        });
                        setShowLoader(false);
                        setOpen(false);
                      } catch (error) {
                        console.log(error);
                        setShowLoader(false);
                      }
                    }}
                  >
                    Publish Draft
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  {showLoader && (
                    <div className="-mt-3">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

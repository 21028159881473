export function updateBeamerUserProps(authState) {
  const userDetails = authState.user.get();
  const orgDetails = authState.organization.get();

  window?.Beamer?.update({
    user_firstname: userDetails?.name,
    user_email: userDetails?.email,
    user_org: orgDetails?.name,
  });
}

import { useQuery } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from '../stores/AuthStore';

const getNotificationCategories = async () => {
  const url = buildAPIURL(`/notification_category/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useNotificationCategoriesListAPI() {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/notification_category/`, () =>
    getNotificationCategories()
  );
}

export { useNotificationCategoriesListAPI };

import { Badge } from 'new-components';

function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}

function StatusBadge({ status, children, ...props }) {
  let bgcolor = '';
  let textcolor = 'text-gray-100';
  switch (status) {
    case 'completed':
      bgcolor = 'bg-[#ECFEE6]';
      textcolor = 'text-[#007A52]';
      break;
    case 'processing':
      bgcolor = 'bg-[#F1F8FF]';
      textcolor = 'text-[#1052BC]';
      break;
    case 'failed':
      bgcolor = 'bg-[#FEF4F3]';
      textcolor = 'text-[#BB2D2A]';
      break;
    case 'partial_failure':
      bgcolor = 'bg-[#FFF9EB]';
      textcolor = 'text-[#BF6028]';
      break;
    case 'not_executed':
      bgcolor = 'bg-[#E2E8F0]';
      textcolor = 'text-accent-foreground';
      break;
    case 'cancelled':
      bgcolor = 'bg-[#FFF9EB]';
      textcolor = 'text-[#BF6028]';
      break;
    default:
      break;
  }
  return (
    <Badge variant="outline" className={bgcolor + ` border-none ` + textcolor}>
      {children || humanize(status)}
    </Badge>
  );
}

export default StatusBadge;

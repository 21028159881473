import { useQuery, useMutation, useQueryClient } from 'react-query';
import { loggedInAPI, useEnvState } from '../stores/AuthStore';
import { toast } from 'components';

const getOnboardingStep = async url => {
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useOnboardingStep({ enabled }) {
  return useQuery(
    '/v1/onboarding_flow/',
    () => getOnboardingStep('/v1/onboarding_flow/'),
    {
      enabled,
      retry: 0,
    }
  );
}

function useChangeOnboardingStep() {
  const queryClient = useQueryClient();
  return useMutation(data => loggedInAPI.patch('/v1/onboarding_flow/', data), {
    onSuccess: () => {
      queryClient.invalidateQueries('/v1/onboarding_flow/');
    },
    onError: error => {
      toast('Somthing went wrong', 'error');
      throw new Error(error.response.data.message);
    },
  });
}

function useSetUserChannels() {
  const envState = useEnvState();
  const queryClient = useQueryClient();

  return useMutation(
    data => {
      const { distId, ...channelData } = data;
      return loggedInAPI.post(
        `v1/${envState.currentEnv.get()}/subscriber/${distId}/identity_events/`,
        channelData
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `${envState.currentEnv.get()}/subscriber_list`
        );
      },
    },
    {
      onError: error => {
        toast(error.response.data.message, 'error');
        throw new Error(error.response.data.message);
      },
    }
  );
}

export { useOnboardingStep, useChangeOnboardingStep, useSetUserChannels };

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n.on('languageChanged', function (lng) {
  const url = window.location.pathname;
  const currentLangAsPerURL = url.match(/\/\w*/)[0];
  const oldLang = currentLangAsPerURL.substring(1);
  if (oldLang !== lng) {
    window.location.pathname = url.replace(/\/\w*/, '/' + lng);
    return false;
  }
});

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    preload: ['en'],
    load: 'languageOnly',
    lowerCaseLng: true,
    fallbackLng: 'en',
    whitelist: ['en', 'hi'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS: 'common',
    ns: ['common'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['path', 'navigator'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
  });

export default i18n;

import { useEffect } from 'react';
import { useAuthState } from 'stores/AuthStore';

const ChatWootWrapper = () => {
  const authState = useAuthState();

  useEffect(() => {
    if (!authState.isLoggedIn.get() || process.env.NODE_ENV !== 'production') {
      return null;
    }
    window.chatwootSettings = {
      hideMessageBubble: true,
      type: 'expanded_bubble',
      position: 'right',
    };
    const userDetails = authState.user.get();
    window.addEventListener('chatwoot:ready', function () {
      if (userDetails?.email) {
        window.$chatwoot.setUser(userDetails?.email, {
          email: userDetails?.email,
          name: userDetails?.name,
        });
      }
    });
    (function (d, t) {
      var BASE_URL = 'https://app.chatwoot.com';
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + '/packs/js/sdk.js';
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');
  });

  return null;
};

export default ChatWootWrapper;

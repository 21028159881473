import { useState, useMemo, useEffect, createContext } from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import lodash from 'lodash';
import { Helmet } from 'react-helmet-async';
import { PencilIcon } from '@heroicons/react/solid';
import { XIcon, PlayIcon, EllipsisVerticalIcon, CopyIcon } from 'lucide-react';
import {
  useNewWorkflowVersionDetails,
  useUpdateWorkflow,
  useTemplateDetailAPI,
  useWorkflowDetails,
} from 'apis';
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  MarkerType,
  useReactFlow,
  ReactFlowProvider,
  useStoreApi,
} from 'reactflow';
import { Spinner, toast as reactToast } from 'components';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  Switch,
  Label,
} from 'new-components';
import urlUtils from 'utils/urlUtils';
import {
  convertTreeToObj,
  convertTreeToGraph,
  layoutELK,
  Header,
} from './utils';
import {
  WorkflowNodeList,
  WorkflowSettingsForm,
  DelayNodeForm,
  TriggerNodeForm,
  BatchNodeForm,
  MultiChannelNodeForm,
  SmartChannelNodeForm,
  ChannelSendNodeForm,
  WaitUntilNodeForm,
  WebhookNodeForm,
  BranchNodeForm,
  DataTransformationForm,
  TimeWindowForm,
  DigestNodeForm,
} from './Forms';
import {
  TriggerNode,
  CustomCommonNode,
  ConditionNode,
  JoinNode,
  ExitNode,
  DynamicWorkflowNode,
} from './CustomNodes';
import {
  CustomEdge,
  CustomBranchingEdge,
  CustomJoiningEdge,
} from './CustomEdges';
import CloneWorkflowModal from './CloneWorkflowModal';
import CommitWorkflowModal from './CommitWorkflowModal';
import TestWorkflowModal from './TestWorkflowModal';

import { ReactComponent as CommitIcon } from 'assets/svgs/commitIcon.svg';
import { ReactComponent as FitToScreenIcon } from 'assets/svgs/fitToScreenIcon.svg';
import { ReactComponent as ZoomInFlowIcon } from 'assets/svgs/zoomInFlowIcon.svg';
import { ReactComponent as ZoomOutFlowIcon } from 'assets/svgs/zoomOutFlowIcon.svg';
import { ReactComponent as LoadingCircleIcon } from 'assets/svgs/loadingCircle.svg';
import { ReactComponent as GreenCircleSuccessIcon } from 'assets/svgs/greenCircleSuccess.svg';
import 'reactflow/dist/style.css';

export const FlowContext = createContext({});
export const DEFAULT_NODE_WIDTH = 250;
export const DEFAULT_NODE_HEIGHT = 75;

export default function WorkflowDetails() {
  const params = useParams();
  const { url } = useRouteMatch();
  const editMode = url.endsWith('/edit') || url.endsWith('/edit/');

  const { data: wfDetails, refetch: refetchWfDetails } = useWorkflowDetails(
    params?.slug
  );

  if (!wfDetails) {
    return <Spinner />;
  }

  if (wfDetails.is_dynamic) {
    return (
      <div>
        <Helmet>
          <title>{wfDetails?.name}</title>
        </Helmet>
        <DynamicWorkflow wfDetails={wfDetails} />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{wfDetails?.name}</title>
      </Helmet>
      <ReactFlowProvider>
        <DetailsWithFlow
          wfDetails={wfDetails}
          refetchWfDetails={refetchWfDetails}
          editMode={editMode}
        />
      </ReactFlowProvider>
    </div>
  );
}

function DetailsWithFlow({ wfDetails, refetchWfDetails, editMode }) {
  const { slug } = useParams();
  const history = useHistory();
  const { getViewport, setViewport, getNode } = useReactFlow();

  const [nodes, setNodes] = useState();
  const [edges, setEdges] = useState();
  const [nodesObj, setNodesObj] = useState();
  const [loading, setLoading] = useState();
  const [isInitialLayout, setIsInitialLayout] = useState(true);
  const [selectedForm, setSelectedForm] = useState(
    editMode ? 'nodes_list' : 'workflow_settings'
  );
  const [selectedNode, setSelectedNode] = useState();
  const [dragStart, setDragStart] = useState(false);
  const [draggingNodeId, setDraggingNodeId] = useState();
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);

  const version = useMemo(() => {
    if (!wfDetails) return;
    if (editMode) {
      return wfDetails?.draft_version?.id;
    } else if (wfDetails) {
      if (!wfDetails.live_version?.id) {
        setSelectedForm('nodes_list');
        history.replace(urlUtils.makeURL(`workflows/${slug}/edit`));
      }
      return wfDetails.live_version?.id
        ? wfDetails.live_version.id
        : wfDetails.draft_version.id;
    }
  }, [wfDetails, editMode]);

  const { data: wfVersionDetails } = useNewWorkflowVersionDetails(
    slug,
    version,
    { enabled: !!version }
  );

  const updateWFDetails = useUpdateWorkflow(slug, version);

  async function getLayout() {
    if (!wfVersionDetails) return [];

    const data = wfVersionDetails;
    let finalData = [{ ...data, node_type: 'trigger' }, ...data.tree.nodes];

    let nodesObject = convertTreeToObj(finalData); // convert tree to object for get operations

    const [nodesData, edgesData] = convertTreeToGraph(finalData, nodesObject); // convert tree to nodes and edges

    const leafNode = nodesData[nodesData.length - 1]; // adding leaf node exit at end
    const exitNodeObj = {
      id: 'exit',
      type: 'exit',
      parent_node_id: leafNode.id,
    };

    // if parent node is multibranch make parent node as join node
    const parentNodeData = nodesObj?.[leafNode.id];
    if (
      parentNodeData?.type === 'multibranch_wait_until' ||
      parentNodeData?.type === 'branch_waituntil'
    ) {
      exitNodeObj.parent_node_id = `join_node_${parentNodeData.id}`;
    }
    nodesObject[exitNodeObj.id] = exitNodeObj;
    nodesData.push(exitNodeObj);
    edgesData.push({
      id: `${leafNode.id}-exit`,
      source: leafNode.id,
      target: 'exit',
    });

    let flowData = getViewport();
    setIsInitialLayout(false);

    const [fnodes, fedges] = await layoutELK(nodesData, edgesData); // layout of graph

    // needed to set graph in previous position after adding multi-branch node
    if (!isInitialLayout) {
      setViewport({
        x: flowData.x - (fnodes[0].x - nodes[0].x) * flowData.zoom,
        zoom: flowData.zoom,
      });
    }

    // set final data in state
    setNodes(fnodes);
    setEdges(fedges);
    setNodesObj(nodesObject);
  }

  useEffect(() => {
    getLayout();
  }, [wfVersionDetails]);

  const FormComponent = useMemo(() => {
    switch (selectedForm) {
      case 'nodes_list':
        return WorkflowNodeList;
      case 'workflow_settings':
        return WorkflowSettingsForm;
      case 'trigger':
        return TriggerNodeForm;
      case 'delay':
        return DelayNodeForm;
      case 'batch':
        return BatchNodeForm;
      case 'send_multi_channel':
        return MultiChannelNodeForm;
      case 'send_smart_channel_routing':
        return SmartChannelNodeForm;
      case 'send_email':
      case 'send_sms':
      case 'send_whatsapp':
      case 'send_inbox':
      case 'send_mobile_push':
      case 'send_webpush':
      case 'send_slack':
      case 'send_ms_teams':
        return ChannelSendNodeForm;
      case 'multibranch_wait_until':
      case 'branch_waituntil':
        return WaitUntilNodeForm;
      case 'branch':
        return BranchNodeForm;
      case 'transform':
        return DataTransformationForm;
      case 'timewindow':
        return TimeWindowForm;
      case 'digest':
        return DigestNodeForm;
      case 'httpapi_webhook':
        return WebhookNodeForm;
      case 'httpapi_fetch':
        return WebhookNodeForm;
      default:
        return null;
    }
  }, [selectedForm]);

  if (!edges || !edges || !nodesObj) {
    return <Spinner />;
  }

  return (
    <FlowContext.Provider
      value={{
        version, // wf version
        slug, // wf slug
        editMode,
        dragStart,
        setDragStart,
        selectedNode, // selected node data
        setSelectedNode,
        selectedForm,
        setSelectedForm,
        setLoading,
        draggingNodeId,
        setDraggingNodeId,
        nodesObj,
      }}
    >
      <div>
        <Header
          selectedId="overview"
          workflow={wfDetails}
          editMode={editMode}
          CTAComponent={() => {
            if (editMode) {
              return (
                <div className="flex">
                  <div className="self-center mr-2 text-sm text-gray-500 font-medium">
                    {loading === true ? (
                      <div className="flex justify-center items-center gap-1">
                        <LoadingCircleIcon />
                        <p>Saving</p>
                      </div>
                    ) : loading === false ? (
                      <div className="flex justify-center items-center gap-1">
                        <GreenCircleSuccessIcon />
                        <p>Saved</p>
                      </div>
                    ) : null}
                  </div>
                  {wfDetails.live_version?.id && editMode && (
                    <Button
                      variant="outline"
                      onClick={() => {
                        setSelectedForm('workflow_settings');
                        history.push(urlUtils.makeURL(`workflows/${slug}`));
                      }}
                    >
                      <XIcon className="h-5 w-5 mr-2 text-muted-foreground" />
                      Edit
                    </Button>
                  )}
                  <Button
                    variant="outline"
                    onClick={() => {
                      setShowTestModal(true);
                    }}
                    className="ml-2"
                  >
                    <PlayIcon className="h-4 w-4 mr-2 text-muted-foreground" />
                    Test
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => {
                      setShowPublishModal(true);
                    }}
                  >
                    <CommitIcon className="h-5 w-5 text-indigo-600" />
                    <p className="ml-2">Commit</p>
                  </Button>
                </div>
              );
            } else {
              return (
                <div className="flex">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setShowTestModal(true);
                    }}
                  >
                    <PlayIcon className="h-4 w-4 mr-2 text-muted-foreground" />
                    Test
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => {
                      setSelectedForm('nodes_list');
                      history.replace(
                        urlUtils.makeURL(`workflows/${slug}/edit`)
                      );
                    }}
                  >
                    <PencilIcon className="h-4 w-4 mr-2" />
                    <p> Edit</p>
                  </Button>
                  <Popover>
                    <PopoverTrigger asChild className="self-center ml-2">
                      <EllipsisVerticalIcon className="h-5 w-5 cursor-pointer" />
                    </PopoverTrigger>
                    <PopoverContent
                      align="end"
                      className="mt-1 px-0 py-1 relative z-50 max-h-96 w-52 overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
                    >
                      <PopoverClose asChild>
                        <div
                          onClick={() => {
                            setShowCloneModal(true);
                          }}
                          className="cursor-pointer flex items-center m-1 py-1.5 rounded pl-2 pr-4 text-sm outline-none focus:bg-accent focus:text-accent-foreground hover:bg-accent"
                        >
                          <CopyIcon className="h-4 w-4 mr-6" />
                          <p>Clone Workflow</p>
                        </div>
                      </PopoverClose>
                      <div className="flex items-center m-1 py-1.5 rounded-md pl-2 text-sm outline-none">
                        <Switch
                          id="is_enabled"
                          checked={wfDetails?.is_enabled}
                          onCheckedChange={async checked => {
                            await updateWFDetails.mutateAsync({
                              is_enabled: checked,
                            });
                            reactToast(
                              `Workflow ${
                                checked ? 'enabled' : 'disabled'
                              } successfully`
                            );
                          }}
                          className="h-4 w-8 focus-visible:ring-0"
                          thumbStyle="h-3 w-3"
                        />
                        <Label
                          htmlFor="is_enabled"
                          className="ml-2 cursor-pointer font-normal"
                        >
                          Enable
                        </Label>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              );
            }
          }}
        />
      </div>
      <div className="grid grid-cols-10 overflow-hidden h-[calc(100vh-95px)]">
        <div className="col-span-7">
          <Flow
            nodes={nodes}
            edges={edges}
            onPaneClick={e => {
              e.stopPropagation();
              setSelectedNode({});
              editMode
                ? setSelectedForm('nodes_list')
                : setSelectedForm('workflow_settings');
            }}
            onNodeClick={(e, nodeData) => {
              e.stopPropagation();
              if (nodeData.type === 'exit') {
                setSelectedNode({});
                editMode
                  ? setSelectedForm('nodes_list')
                  : setSelectedForm('workflow_settings');
              } else {
                setSelectedForm(); // needed to reset form
                let nodeType = nodeData.type;
                if (nodeData.type === 'condition_node') {
                  const parentData = getNode(nodeData.data.parent_node_id);
                  nodeType = parentData.type;
                  setSelectedNode(parentData);
                } else {
                  setSelectedNode(nodeData.data);
                }
                setTimeout(() => {
                  setSelectedForm(nodeType);
                }, 0);
              }
            }}
          />
        </div>
        <div className="col-span-3 border-l relative h-full overflow-hidden">
          {FormComponent && (
            <FormComponent
              wfDetails={wfDetails}
              wfVersionDetails={wfVersionDetails}
            />
          )}
        </div>
      </div>
      <Tooltip
        id="node-description"
        style={{ width: '220px', borderRadius: 12 }}
        place="left"
      />
      <CommitWorkflowModal
        showPublishModal={showPublishModal}
        setShowPublishModal={setShowPublishModal}
        refetchWfDetails={refetchWfDetails}
      />
      <CloneWorkflowModal
        showCloneModal={showCloneModal}
        setShowCloneModal={setShowCloneModal}
        wfDetails={wfDetails}
      />
      <TestWorkflowModal
        open={showTestModal}
        setOpen={setShowTestModal}
        wfDetails={wfDetails}
        wfVersionDetails={wfVersionDetails}
        editMode={editMode}
      />
    </FlowContext.Provider>
  );
}

function DynamicWorkflow({ wfDetails }) {
  const { slug } = useParams();

  const template = wfDetails?.live_version?.template_groups?.[0];

  const version = useMemo(() => {
    return wfDetails.live_version?.id
      ? wfDetails.live_version.id
      : wfDetails.draft_version.id;
  }, [wfDetails]);

  const { data: wfVersionDetails } = useNewWorkflowVersionDetails(
    slug,
    version,
    { enabled: !!version }
  );
  const { data: templateDetails } = useTemplateDetailAPI(template);

  const channelSlug = templateDetails?.channels?.[0]?.channel?.slug;

  return (
    <ReactFlowProvider>
      <Header selectedId="overview" workflow={wfDetails} />
      <div className="grid grid-cols-10 overflow-hidden h-[calc(100vh-95px)]">
        <div className="col-span-7">
          <Flow
            nodes={[
              {
                id: 'dynamic_wf',
                type: 'dynamic_wf',
                position: { x: 50, y: 50 },
                data: {
                  template: wfDetails?.live_version?.template_groups?.[0] || '',
                  listId: wfDetails?.subscriber_list_id || '',
                  isBroadcast: wfDetails?.is_broadcast,
                  templateActiveChannel: channelSlug,
                },
              },
            ]}
          />
        </div>
        <div className="col-span-3 border-l relative h-full overflow-hidden">
          <WorkflowSettingsForm
            wfDetails={wfDetails}
            isDynamic
            wfVersionDetails={wfVersionDetails}
          />
        </div>
      </div>
    </ReactFlowProvider>
  );
}

export function Flow({
  nodes,
  edges,
  onPaneClick,
  onNodeClick,
  ...otherProps
}) {
  const { zoomTo, getZoom, viewportInitialized, setViewport } = useReactFlow();
  const [currentZoom, setCurrentZoom] = useState();
  const [viewPortSet, setLocalViewPortValue] = useState(false);
  const flowStoreApi = useStoreApi();

  const setViewportCenter = () => {
    const flowStore = flowStoreApi.getState();

    setViewport({
      x:
        flowStore.width / 2 - // 1st, 2nd lines for centering chart and 3rd, 4th for multi branch cases bcz chart itself takes dynamic width
        DEFAULT_NODE_WIDTH -
        nodes[0].position.x + // root node point
        DEFAULT_NODE_WIDTH / 2,
      y: 0,
      zoom: 1,
    });
  };

  useEffect(() => {
    if (viewportInitialized) {
      setViewportCenter();
      setLocalViewPortValue(true);
    }
  }, [viewportInitialized]);

  useEffect(() => {
    const newZoomValue = getZoom();
    setCurrentZoom(newZoomValue);
  }, []);

  const nodeTypes = useMemo(
    () => ({
      trigger: TriggerNode,
      delay: CustomCommonNode,
      batch: CustomCommonNode,
      send_multi_channel: CustomCommonNode,
      send_smart_channel_routing: CustomCommonNode,
      send_email: CustomCommonNode,
      send_sms: CustomCommonNode,
      send_whatsapp: CustomCommonNode,
      send_inbox: CustomCommonNode,
      send_mobile_push: CustomCommonNode,
      send_webpush: CustomCommonNode,
      send_slack: CustomCommonNode,
      send_ms_teams: CustomCommonNode,
      httpapi_webhook: CustomCommonNode,
      httpapi_fetch: CustomCommonNode,
      multibranch_wait_until: CustomCommonNode,
      branch_waituntil: CustomCommonNode,
      branch: CustomCommonNode,
      transform: CustomCommonNode,
      timewindow: CustomCommonNode,
      digest: CustomCommonNode,
      condition_node: ConditionNode,
      join_node: JoinNode,
      exit: ExitNode,
      dynamic_wf: DynamicWorkflowNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      'custom-edge': CustomEdge,
      'branching-edge': CustomBranchingEdge,
      'joining-edge': CustomJoiningEdge,
    }),
    []
  );

  const zoomInDisabled = currentZoom >= 1;
  const zoomOutDisabled = currentZoom <= 0.7;

  return (
    <ReactFlow
      nodes={viewPortSet ? nodes : []}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      panOnDrag={false}
      panOnScroll={true}
      minZoom={0.7}
      maxZoom={1}
      onMove={() => {
        const newZoomValue = getZoom();
        setCurrentZoom(lodash.round(newZoomValue, 1));
      }}
      proOptions={{ hideAttribution: true }}
      snapToGrid={true}
      onPaneClick={onPaneClick}
      onNodeClick={onNodeClick}
      defaultEdgeOptions={{
        type: 'custom-edge',
        focusable: false,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      }}
      {...otherProps}
    >
      <Background
        size={4}
        style={{ backgroundColor: '#FAFBFC' }}
        color="#E9EDF2"
      />
      <Controls
        showInteractive={false}
        showFitView={false}
        showZoom={false}
        style={{ padding: 0 }}
        position="top-left"
      >
        <ControlButton
          style={{
            height: 24,
            width: 24,
            backgroundColor: zoomInDisabled && '#f3f4f6',
          }}
          onClick={() => {
            const oldZoomValue = getZoom();
            if (oldZoomValue >= 1) return;
            const newZoomValue = oldZoomValue >= 1 ? 1 : oldZoomValue + 0.1;
            setCurrentZoom(lodash.round(newZoomValue, 1));
            zoomTo(newZoomValue);
          }}
        >
          <ZoomInFlowIcon style={{ maxWidth: 20, maxHeight: 20 }} />
        </ControlButton>
        <ControlButton
          style={{
            height: 24,
            width: 24,
            backgroundColor: zoomOutDisabled && '#f3f4f6',
          }}
          onClick={() => {
            const oldZoomValue = getZoom();
            if (oldZoomValue <= 0.7) return;
            const newZoomValue = oldZoomValue <= 0.7 ? 0.7 : oldZoomValue - 0.1;
            setCurrentZoom(lodash.round(newZoomValue, 1));
            zoomTo(newZoomValue);
          }}
        >
          <ZoomOutFlowIcon style={{ maxWidth: 20, maxHeight: 20 }} />
        </ControlButton>
        <ControlButton
          style={{ height: 24, width: 24 }}
          onClick={() => {
            setViewportCenter();
          }}
        >
          <FitToScreenIcon style={{ maxWidth: 20, maxHeight: 20 }} />
        </ControlButton>
      </Controls>
    </ReactFlow>
  );
}

import { envStore } from 'stores/AuthStore';

function getCurrentBase(type) {
  const currentLang = localStorage.getItem('i18nextLng') || 'en';
  if (type === 'common') {
    return `/${currentLang}`;
  } else {
    const currentEnv = envStore.get();
    return `/${currentLang}/${currentEnv.currentEnv}`;
  }
}

function makeURL(path) {
  if (path.charAt(0) === '/') {
    return getCurrentBase() + path;
  } else {
    return getCurrentBase() + '/' + path;
  }
}

function makeCommonURL(path) {
  if (path.charAt(0) === '/') {
    return getCurrentBase('common') + path;
  } else {
    return getCurrentBase('common') + '/' + path;
  }
}

function makePublicURL(path) {
  const currentLang = localStorage.getItem('i18nextLng') || 'en';
  if (path.charAt(0) === '/') {
    return '/' + currentLang + path;
  } else {
    return '/' + currentLang + '/' + path;
  }
}

const urlUtils = {
  makeURL,
  makePublicURL,
  makeCommonURL,
};

export default urlUtils;

import { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Info } from 'lucide-react';
import { Spinner } from 'components';
import { useEnvState, useAuthState } from 'stores/AuthStore';
import {
  useWorkflowDetails,
  useWorkflowExecutionLogs,
  useExecutionFilterCount,
} from 'apis';
import { Header } from '../utils';
import { TopFilters } from 'pages/Logs/Executions/Filters';
import { MainFilters } from 'pages/Logs/Executions/Filters';
import ListTable from './ListTable';

function NavHeader({
  workflow,
  queryParam,
  autoRefresh,
  setAutoRefresh,
  handleRefresh,
  loader,
}) {
  return (
    <div>
      <Helmet>
        <title>{workflow?.name + ` - Workflow Runs`}</title>
      </Helmet>
      <Header workflow={workflow} selectedId="executions" />
      <div className="flex justify-end px-4 mt-3 mb-6">
        <TopFilters
          queryString={queryParam}
          autoRefresh={autoRefresh}
          setAutoRefresh={setAutoRefresh}
          loader={loader}
          handleRefresh={handleRefresh}
        />
      </div>
    </div>
  );
}

export default function WorkflowRunList() {
  const envState = useEnvState();
  const authState = useAuthState();
  const history = useHistory();
  let { search } = useLocation();
  const { slug } = useParams();

  const [autoRefresh, setAutoRefresh] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const queryParam = new URLSearchParams(search);

  const { data: workflow } = useWorkflowDetails(slug);
  const {
    data: workflowRuns,
    refetch: refetchWfRuns,
    isFetching: wfRunsFetching,
  } = useWorkflowExecutionLogs({
    queryString: search,
    mandatoryFilters: { workflow_id: workflow?.id },
    enabled: !!workflow?.id,
    automaticRefetchTime: autoRefresh ? 15000 : 0,
  });
  const { data: filtersCount } = useExecutionFilterCount({
    queryString: search,
    mandatoryFilters: { workflow_id: workflow?.id },
    enabled: !!workflow?.id,
  });

  useEffect(() => {
    if (!queryParam.get('last_n_minutes') && !queryParam.get('start_ts')) {
      history.push('?last_n_minutes=1440');
    }
  }, []);

  useEffect(() => {
    if (!wfRunsFetching) {
      setShowLoader(false);
    }
  }, [wfRunsFetching]);

  const allEnv = authState.organization.get()?.environments;
  const currentEnv = allEnv?.find(
    obj => obj?.slug === envState.currentEnv.get()
  );

  const hasTenant = Number(currentEnv?.tenants_count) > 1;

  if (!workflowRuns?.results || showLoader) {
    return (
      <div>
        <NavHeader
          workflow={workflow}
          queryParam={queryParam}
          autoRefresh={autoRefresh}
          setAutoRefresh={setAutoRefresh}
          loader={wfRunsFetching && !showLoader}
          handleRefresh={() => {
            refetchWfRuns();
          }}
        />
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <NavHeader
        workflow={workflow}
        queryParam={queryParam}
        autoRefresh={autoRefresh}
        setAutoRefresh={setAutoRefresh}
        loader={wfRunsFetching && !showLoader}
        handleRefresh={() => {
          refetchWfRuns();
        }}
      />

      <div className="grid grid-cols-10 p-4 gap-4">
        <div className="col-span-2">
          <div className="border rounded">
            <MainFilters
              queryParam={queryParam}
              hasTenant={hasTenant}
              filtersCount={filtersCount}
              showWorkflowSlug={false}
            />
          </div>
        </div>
        <div className="col-span-8">
          <ListTable
            executionsData={workflowRuns}
            queryParam={queryParam}
            setShowLoader={setShowLoader}
          />
        </div>
      </div>
    </div>
  );
}

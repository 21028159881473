import { useQuery, useMutation } from 'react-query';
import { useDebounce } from 'ahooks';
import { convertObjToString } from 'utils';
import {
  loggedInAPI,
  buildAPIURL,
  useEnvState,
  envStore,
} from 'stores/AuthStore';
import { toast } from 'components';

const getEnvKeys = async is_default => {
  let url = buildAPIURL(`/ws_key/`);
  url = is_default ? `${url}?is_default=true` : url;
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useEnvKeys(is_default = false) {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/ws_key/${is_default}`, () =>
    getEnvKeys(is_default)
  );
}

export const getAnyEnvKey = async (env, is_default = false) => {
  let url = buildAPIURL(`/ws_key/`, env);
  url = is_default ? `${url}?is_default=false` : url;
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useAnyEnvKey(env, is_default = false) {
  return useQuery(`${env}/ws_key/${is_default}`, () =>
    getAnyEnvKey(is_default)
  );
}

const getInboxInfo = async () => {
  let url = 'v1/user/inbox_info/';
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useInboxInfo(isSandbox) {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/inbox_info/`,
    () => getInboxInfo(),
    {
      enabled: !isSandbox,
    }
  );
}
const getAthenaSyncTime = async () => {
  const url = `/v1/record_task_timestamp/athena_pipeline_sync/`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useAthenaSyncTime() {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/athena_pipeline_sync/`, () =>
    getAthenaSyncTime()
  );
}

const getSandboxInboxInfo = async distinctId => {
  let url = buildAPIURL(`/subscriber/${distinctId}/inbox_info/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useSandboxInboxInfo(distinctId, isSandbox) {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/sandbox/inbox_info/`,
    () => getSandboxInboxInfo(distinctId),
    {
      enabled: isSandbox,
    }
  );
}

const getSearchedTemplates = async searchedText => {
  let url = buildAPIURL(`/search/template/?q=${searchedText}&limit=20`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useSearchedTemplates(searchedText) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/search/template/`, searchedText],
    () => getSearchedTemplates(searchedText),
    {
      enabled: searchedText?.length > 2,
    }
  );
}

const getSearchedWorkflows = async ({
  searchedText,
  workspaceSlug,
  isDynamic,
}) => {
  const params = {
    limit: 20,
    q: searchedText,
    is_dynamic: isDynamic === false ? false : '',
  };

  let url = buildAPIURL(
    `/search/workflow/${convertObjToString(params)}`,
    workspaceSlug
  );

  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useSearchedWorkflows({
  searchedText,
  workspaceSlug,
  isDynamic,
}) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/search/workflow/`,
      searchedText,
      workspaceSlug,
      isDynamic,
    ],
    () => getSearchedWorkflows({ searchedText, workspaceSlug, isDynamic }),
    {
      enabled: searchedText?.length > 2,
    }
  );
}

const getLanguages = async () => {
  const url = `/language/all/`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useAllLanguages() {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/language/all`, () =>
    getLanguages()
  );
}

export function useFeedback() {
  return useMutation(data => loggedInAPI.post('/v1/post_feedback/', data), {
    onError: error => {
      toast(
        `Error in sending feedback: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

export function useModifyUserDetails() {
  return useMutation(data => loggedInAPI.patch('/v2/user/', data), {
    onError: error => {
      toast(
        `Error in updating profile: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

export async function getVercelWorkerAuth() {
  const url = 'v1/vercel/proxy_token/';
  const { data } = await loggedInAPI.get(url);
  return data;
}

const getSubscriberInfoSearch = async ({ distinctId, email, phoneNumber }) => {
  const params = {
    distinct_id: distinctId.toLowerCase(),
    email: email,
    phone_number: phoneNumber,
  };

  let url = `/v2/${envStore.currentEnv.get()}/search/subscriber/${convertObjToString(
    params
  )}`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

export function useSubscriberInfoSearch({ distinctId, email, phoneNumber }) {
  const envState = useEnvState();

  return useQuery(
    [
      `${envState.currentEnv.get()}/search/subscriber/`,
      distinctId,
      email,
      phoneNumber,
    ],
    () =>
      getSubscriberInfoSearch({
        distinctId,
        email,
        phoneNumber,
      }),
    {
      enabled: !!distinctId || !!email || !!phoneNumber,
    }
  );
}

import { useContext, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { Formik, Form } from 'formik';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  Label,
  Checkbox,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  Input,
  Textarea,
  CTooltip,
} from 'new-components';
import { toast } from 'components';
import { cn } from 'utils';
import {
  useAddBranch,
  useUpdateBranch,
  useDeleteBranch,
  useMoveBranch,
} from 'apis';
import {
  GripVertical,
  EllipsisVertical,
  Plus,
  Trash2,
  Pencil,
  Loader2,
  CircleAlert,
} from 'lucide-react';
import { NodeFormHeader } from './Common';
import { FlowContext } from '../WorkflowDetails';
import Conditions from '../Conditions';
import { ReactComponent as BranchNodeIcon } from 'assets/svgs/branchNodeIcon.svg';

function move(input, from, to) {
  let numberOfDeletedElm = 1;
  const elm = input.splice(from, numberOfDeletedElm)[0];
  numberOfDeletedElm = 0;
  input.splice(to, numberOfDeletedElm, elm);
}

function BranchForm({ branch, dragProps = {}, branchesList }) {
  const { editMode, slug, version, setLoading } = useContext(FlowContext);

  const [showMetaDataModal, setMetaDataModal] = useState(false);

  const updateBranch = useUpdateBranch(slug, version, branch.id);
  const deleteBranch = useDeleteBranch(slug, version, branch.id);

  const branchesCount = branchesList?.length;
  const isDefault = branch.is_default;
  const hasErrors = branch?.errors
    ? Object.keys(branch?.errors)?.length > 0
    : false;

  return (
    <Dialog open={showMetaDataModal} onOpenChange={setMetaDataModal}>
      <div className="mt-6 flex items-start gap-1 bg-background">
        {editMode && !isDefault && (
          <span {...dragProps}>
            <GripVertical className="h-4 w-4 text-muted-foreground mt-1" />
          </span>
        )}
        <div
          className={cn(
            'flex flex-col grow pb-4',
            !isDefault && 'border-b',
            editMode && isDefault && 'ml-5'
          )}
        >
          <div className="flex items-center justify-between">
            <p className="font-medium">{branch.name}</p>
            <div className="flex items-center gap-2">
              {hasErrors && (
                <CTooltip
                  trigger={<CircleAlert className="h-4 w-4 text-destructive" />}
                >
                  <p>{JSON.stringify(branch.errors)}</p>
                </CTooltip>
              )}

              <DropdownMenu>
                <DropdownMenuTrigger>
                  {editMode && (
                    <EllipsisVertical className="h-4 w-4 text-muted-foreground cursor-pointer ring-0" />
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DialogTrigger asChild>
                    <DropdownMenuItem>
                      <Pencil className="h-4 w-4 mr-2" />
                      Edit metadata
                    </DropdownMenuItem>
                  </DialogTrigger>
                  {!isDefault && branchesCount > 2 && (
                    <DropdownMenuItem
                      className="text-destructive"
                      onClick={async () => {
                        setLoading(true);
                        await deleteBranch.mutateAsync();
                        setLoading(false);
                      }}
                    >
                      <Trash2 className="h-4 w-4 mr-2" /> Delete
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>

          {isDefault ? (
            <p className="border text-accent-foreground text-xs px-2 py-1 bg-muted rounded mt-4">
              This branch will be executed if no previous branch conditions
              match.
            </p>
          ) : (
            <Conditions
              conditions={branch?.conditions}
              isBranchCondition
              id={branch.id}
            />
          )}

          {/* <div className="flex items-center space-x-2 mt-4">
            <Checkbox
              id={`exit-${branch.id}`}
              checked={branch.exit_at_end}
              disabled={!editMode}
              onCheckedChange={async value => {
                setLoading(true);
                await updateBranch.mutateAsync({ exit_at_end: value });
                setLoading(false);
              }}
            />
            <Label
              htmlFor={`exit-${branch.id}`}
              className="text-sm text-foreground font-medium"
            >
              <p>Exit at this branch</p>
            </Label>
          </div> */}
        </div>

        {/* edit meta data modal */}
        <DialogContent
          onOpenAutoFocus={e => {
            e.preventDefault();
          }}
        >
          <DialogTitle>Branch metadata</DialogTitle>
          <Formik
            initialValues={{
              name: branch.name || '',
              // description: branch.description || '',
            }}
            onSubmit={async values => {
              setLoading(true);
              await updateBranch.mutateAsync(values);
              setLoading(false);
              setMetaDataModal(false);
              toast('Saved successfully', '', { autoClose: 1000 });
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="mt-2">
                    <div className="mb-1">
                      <Label className="font-medium text-sm text-foreground">
                        Name
                      </Label>
                    </div>
                    <Input
                      value={values.name}
                      onChange={e => {
                        setFieldValue('name', e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="mt-4">
                    <div className="mb-1">
                      <Label className="font-medium text-sm text-foreground">
                        Description
                      </Label>
                    </div>
                    <Textarea
                      value={values.description}
                      onChange={e => {
                        setFieldValue('description', e.target.value);
                      }}
                    />
                  </div> */}
                  <DialogFooter className="mt-8">
                    <Button type="submit" disabled={updateBranch.isLoading}>
                      {updateBranch.isLoading && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Save changes
                    </Button>
                  </DialogFooter>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default function BranchNodeForm() {
  const {
    selectedNode,
    editMode,
    slug,
    version,
    setLoading,
    setSelectedForm,
    setSelectedNode,
  } = useContext(FlowContext);
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);
  const branches = nodeData?.data?.branches;

  const addBranch = useAddBranch(slug, version);
  const moveBranch = useMoveBranch(slug, version);

  const handleDragEnd = async e => {
    const from = e?.source?.index;
    const to = e?.destination?.index;

    if (!e.source || !e.destination) return;
    if (from === to) return;
    move(branches, from, to);
    setLoading(true);
    await moveBranch.mutateAsync({
      branchID: e.draggableId,
      seq_no: to + 1, // BE index starts from 1,
    });
    setLoading(false);
  };

  const branchesCount = branches?.length;
  const defaultBranch = branches.find(branch => branch.is_default);
  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  return (
    <div className="overflow-scroll h-full">
      <div className="m-4 mt-6">
        <NodeFormHeader
          name="Branch"
          helptext="Follow the first branch that satisfies the condition."
          Icon={BranchNodeIcon}
          docLink="https://docs.suprsend.com/docs/branch"
        />
        {(nonFieldErrors || hasFieldErrors) && (
          <div className="mb-6 mt-4 text-xs text-red-500 bg-red-50 p-3 rounded">
            <ul>
              {Object.keys(fieldErrors)?.map((item, index) => {
                return (
                  <li key={index}>
                    - {item}: {fieldErrors[item]}
                  </li>
                );
              })}
              {nonFieldErrors?.map((item, index) => {
                return <li key={index}>- {item}</li>;
              })}
            </ul>
          </div>
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {branches.map((branch, index) => {
                  if (branch.is_default) return null;

                  return (
                    <Draggable
                      key={branch.id}
                      draggableId={branch.id}
                      index={index}
                      isDragDisabled={branch.is_default}
                    >
                      {provided => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={provided.draggableProps.style}
                        >
                          <BranchForm
                            branch={branch}
                            branchesList={branches}
                            dragProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="mb-24">
            {editMode && (
              <div className="py-4 border-b ml-5">
                {branchesCount < 10 ? (
                  <Button
                    className="h-8"
                    disabled={addBranch.isLoading}
                    onClick={async () => {
                      setLoading(true);
                      await addBranch.mutateAsync({
                        source_node_id: nodeData.data.id,
                      });
                      setLoading(false);
                    }}
                  >
                    <Plus className="h-5 w-5 mr-2 " />
                    Add Branch
                  </Button>
                ) : (
                  <CTooltip
                    trigger={
                      <div>
                        <Button className="h-8" disabled>
                          <Plus className="h-5 w-5 mr-2 " />
                          Add Branch
                        </Button>
                      </div>
                    }
                  >
                    Max 10 branches can be added
                  </CTooltip>
                )}
              </div>
            )}
            <BranchForm branch={defaultBranch} branchesList={branches} />
          </div>
        </DragDropContext>
        {editMode && (
          <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t z-50">
            <Button
              className="flex-grow justify-center"
              as="button"
              variant="outline"
              onClick={() => {
                setSelectedForm('nodes_list');
                setSelectedNode({});
              }}
            >
              Close
            </Button>
            <Button
              as="button"
              className="flex-grow justify-center ml-2"
              disabled={true}
            >
              Auto Saved
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

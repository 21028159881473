import { cn } from 'utils';

export function TabBar({
  options,
  selectedId,
  tabClassName,
  containerClassName,
}) {
  return (
    <div
      className={cn(
        'flex flex-grow border-b border-border',
        containerClassName
      )}
    >
      {options?.map(tab => {
        const isSelected = selectedId === tab.id;
        return (
          <div
            key={tab.id}
            onClick={() => {
              tab.onClick();
            }}
            className={cn(
              'pt-5 px-1 mr-4 pb-1 text-sm font-medium cursor-pointer',
              isSelected
                ? 'text-primary  border-b-2 border-primary'
                : 'text-muted-foreground',
              tabClassName
            )}
          >
            <p>{tab.name}</p>
          </div>
        );
      })}
    </div>
  );
}

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { loggedInAPI } from '../stores/AuthStore';
import toast from 'react-hot-toast';

const getOrganisation = async () => {
  const url = '/v1/organization/';
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useOrgAPI(config = {}) {
  return useQuery('organization', getOrganisation, {
    enabled: config?.enabled ?? true,
    retry: 0,
  });
}

const getOrganisationUser = async () => {
  const url = '/v1/organization/people/self/';
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useOrgUserAPI(config = {}) {
  return useQuery('/v1/organization/people/self/', getOrganisationUser, {
    enabled: config?.enabled ?? true,
    retry: 0,
  });
}

function useModifyOrg() {
  const queryClient = useQueryClient();
  const url = `/v1/organization/`;
  return useMutation(orgdata => loggedInAPI.patch(url, orgdata), {
    onSettled: () => {
      queryClient.invalidateQueries('organization');
    },
    onError: error => {
      toast.error(
        `Error modifying Organisation : ${error.response.data.message}`
      );
      throw new Error(error.response.data.message);
    },
  });
}

export { useOrgAPI, useModifyOrg, useOrgUserAPI };

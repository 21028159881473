import { useEffect, useState } from 'react';
import cubejs from '@cubejs-client/core';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { QueryBuilder } from '@cubejs-client/react';
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';
import { DownloadIcon } from '@heroicons/react/outline';
import { cubeJWTSign } from 'utils';
import { useWorkflowDetails } from 'apis';
import { Spinner, Tooltip } from 'components';
import { useEnvState, useAuthState } from 'stores/AuthStore';
import CSVDownloadModal from 'components/CSVDownloadModal';
import { ReactComponent as SmartDeliveryIcon } from 'assets/svgs/smart_delivery.svg';
import DateFilter, { DAFAULT_DATE_RANGE } from './DateFilter';
import {
  ChartOne,
  ChartTwo,
  ChartThreeOne,
  ChartThreeTwo,
  ChartFour,
  ChartFive,
  ChartSix,
  SmartWorkflowChart,
} from './WorkflowCharts';
import 'chartjs-adapter-date-fns';
import { Header } from './utils';

function getPastDate(noOfDays) {
  const today = format(new Date(), 'yyyy-MM-dd');
  let date = new Date();
  date.setDate(date.getDate() - noOfDays);
  const formattedPastDate = format(date, 'yyyy-MM-dd');
  return [formattedPastDate, today];
}

const WorkflowChart = ({
  resultSet,
  error,
  ChartComponent,
  totalPercentage,
}) => {
  if (error) {
    return (
      <div className="border rounded-md flex p-4 bg-gray-50">
        <div>
          <InformationCircleIcon className="h-5 w-5 text-[#5C5F62] mr-3" />
        </div>
        <div>
          <p className="text-sm font-medium">
            We are currently encountering issues in the Analytics pipeline. The
            team is actively resolving the issue.
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Rest assured, your notification delivery will not be impacted by
            this temporary hiccup.
          </p>
        </div>
      </div>
    );
  }
  if (!resultSet) {
    return <Spinner />;
  }
  return (
    <ChartComponent resultSet={resultSet} totalPercentage={totalPercentage} />
  );
};

export default function WorkflowDetailsAnalytics() {
  const { slug } = useParams();
  const envState = useEnvState();
  const authState = useAuthState();
  const currentEnv = envState.currentEnv.get();
  const envs = authState.organization.get()?.environments;
  const CUBE_API_SECRET = process.env.REACT_APP_ATHENA_CUBE_API_SECRET;

  const [currentEnvID, setCurrentEnvID] = useState(null);
  const [cubejsAPI, setCubejsAPI] = useState({});
  const [cubeInstanceLoading, setCubeInstanceLoading] = useState(true);
  const [dateRange, setDateRange] = useState(DAFAULT_DATE_RANGE);
  const [csvModalOpen, setCsvModalOpen] = useState(false);

  const { data: workflow } = useWorkflowDetails(slug);

  useEffect(() => {
    setCubeInstanceLoading(true);
    const measures = [
      'WorkflowNotificationGroups.environmentID',
      'WorkflowEngagement.environmentID',
      'WorkflowChannels.environmentID',
      'WorkflowErrors.environmentID',
    ];
    envs.forEach(env => {
      if (env.slug === currentEnv) {
        setCurrentEnvID(env.id);
        const cubeInstances = {};
        measures.map(async item => {
          const cubejsToken = await cubeJWTSign(
            {
              env_id: env.id,
              member: item,
            },
            CUBE_API_SECRET
          );
          const cubeApi = cubejs(cubejsToken, {
            apiUrl: process.env.REACT_APP_ATHENA_CUBE_URL,
          });
          cubeInstances[item] = cubeApi;
        });
        setCubejsAPI(cubeInstances);
        setTimeout(() => {
          setCubeInstanceLoading(false);
        }, 500);
      }
    });
  }, [currentEnv]);

  if (currentEnvID && !cubeInstanceLoading) {
    const selectedDateValue = dateRange.value;
    const isCustom = dateRange.label === 'Custom';
    const dateText = isCustom
      ? `${format(new Date(selectedDateValue[0]), 'dd MMM')} to ${format(
          new Date(selectedDateValue[1]),
          'dd MMM'
        )}`
      : dateRange?.display_name;
    const queryDateRange = isCustom
      ? selectedDateValue
      : getPastDate(selectedDateValue);

    if (!workflow) {
      return <Spinner />;
    }
    return (
      <div>
        <Helmet>
          <title>{workflow?.name}</title>
        </Helmet>
        <Header workflow={workflow} selectedId="analytics" />
        <div className="px-6 py-4">
          <div className="flex">
            <div
              className="flex items-center ml-auto border border-gray-300  rounded-md mr-2 h-[30px] px-4 cursor-pointer mt-4"
              onClick={() => {
                setCsvModalOpen(true);
              }}
            >
              <DownloadIcon className="h-4 w-4 text-gray-400" />
              <p className="text-sm text-gray-500 font-normal ml-2">{`Download (.csv)`}</p>
            </div>
            <div className="items-bottom mt-4">
              <DateFilter
                type="bottom"
                selectedDate={selectedDateValue}
                setDate={setDateRange}
              />
            </div>
            <CSVDownloadModal
              csvModalOpen={csvModalOpen}
              setCsvModalOpen={setCsvModalOpen}
              workflowSlug={workflow?.slug}
            />
          </div>
          <div className="pb-5 border-b mt-10 mb-8 ">
            <QueryBuilder
              cubejsApi={cubejsAPI['WorkflowEngagement.environmentID']}
              query={{
                measures: [
                  'WorkflowEngagement.TriggeredCount',
                  'WorkflowEngagement.DeliveredRatio',
                  'WorkflowEngagement.SeenRatio',
                  'WorkflowEngagement.SuccessRatio',
                ],
                timeDimensions: [
                  {
                    dimension: 'WorkflowEngagement.createdAt',
                    dateRange: queryDateRange,
                  },
                ],
                order: { 'WorkflowEngagement.createdAt': 'asc' },
                filters: [
                  {
                    member: 'WorkflowEngagement.workflowID',
                    operator: 'equals',
                    values: [String(workflow.id)],
                  },
                ],
              }}
              render={props => {
                const resultSet = props?.resultSet?.series();
                const wf_trigg = resultSet?.[0]?.series?.[0]?.value;
                const delivered = resultSet?.[1]?.series?.[0]?.value;
                const seen = resultSet?.[2]?.series?.[0]?.value;
                const sucessRate = resultSet?.[3]?.series?.[0]?.value;
                return (
                  <div className="grid grid-cols-4 gap-x-3 m-auto text-center divide-x">
                    <div>
                      <p className="pr-2 text-2xl text-blue-800 font-medium">
                        {wf_trigg || '--'}
                      </p>
                      <p className="text-sm text-gray-500 px-1">
                        workflows triggered
                      </p>
                    </div>

                    <div>
                      <p className="pr-2 text-2xl text-blue-800 font-medium">
                        {delivered || '--'}%
                      </p>
                      <p className="text-sm text-gray-500  px-1">
                        delivery rate
                      </p>
                    </div>
                    <div>
                      <p className="pr-2 text-2xl text-blue-800 font-medium">
                        {seen || '--'}%
                      </p>
                      <p className="text-sm text-gray-500  px-1">seen rate</p>
                    </div>
                    <div>
                      <p className="pr-2 text-2xl text-blue-800 font-medium">
                        {sucessRate || '--'}%
                      </p>
                      <p className="text-sm text-gray-500  px-1">
                        success rate
                      </p>
                    </div>
                  </div>
                );
              }}
            />
          </div>

          <div className="flex w-full">
            <div className="pr-3 mb-8 w-[50%]">
              <div className="flex pb-2 items-center">
                <p className="font-medium">
                  How many workflow requests were triggered?
                </p>
                <a className="flex items-center ml-2" href="#workflowError">
                  <Tooltip content="Click to View Workflow Errors" delay={1}>
                    <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
                  </Tooltip>
                </a>
              </div>
              <div className="flex mb-4">
                <p className="text-sm text-gray-700">{dateText}</p>
                <p className="font-black px-2 -mt-1">.</p>
                <p className="text-sm text-gray-700">
                  Request received vs triggered by SuprSend on atleast one
                  channel
                </p>
              </div>

              <QueryBuilder
                cubejsApi={
                  cubejsAPI['WorkflowNotificationGroups.environmentID']
                }
                query={{
                  measures: [
                    'WorkflowNotificationGroups.countRequest',
                    'WorkflowNotificationGroups.countTriggered',
                  ],
                  timeDimensions: [
                    {
                      dimension: 'WorkflowNotificationGroups.createdAt',
                      granularity: 'day',
                      dateRange: queryDateRange,
                    },
                  ],
                  order: { 'WorkflowNotificationGroups.createdAt': 'asc' },
                  filters: [
                    {
                      member: 'WorkflowNotificationGroups.workflowID',
                      operator: 'equals',
                      values: [String(workflow.id)],
                    },
                  ],
                }}
                render={props => {
                  return (
                    <QueryBuilder
                      cubejsApi={
                        cubejsAPI['WorkflowNotificationGroups.environmentID']
                      }
                      query={{
                        measures: ['WorkflowNotificationGroups.PerTriggered'],
                        timeDimensions: [
                          {
                            dimension: 'WorkflowNotificationGroups.createdAt',
                            dateRange: queryDateRange,
                          },
                        ],
                        order: {
                          'WorkflowNotificationGroups.createdAt': 'asc',
                        },
                        filters: [
                          {
                            member: 'WorkflowNotificationGroups.workflowID',
                            operator: 'equals',
                            values: [String(workflow.id)],
                          },
                        ],
                      }}
                      render={other_props => {
                        const seriesData = other_props?.resultSet?.series();
                        const percentage =
                          seriesData?.[0].series?.[0]?.value?.toFixed(1);
                        return (
                          <WorkflowChart
                            {...props}
                            ChartComponent={ChartOne}
                            totalPercentage={percentage}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="pl-3 mb-4 w-[50%]">
              <p className="text-l mb-2 font-medium">
                How engaged are the users?
              </p>
              <div className="flex mb-4">
                <p className="text-sm text-gray-700">{dateText}</p>
                <p className="font-black px-2 -mt-1">.</p>
                <p className="text-sm text-gray-700">
                  Communication triggered and user interacting on atleast one
                  channel
                </p>
              </div>
              <QueryBuilder
                cubejsApi={cubejsAPI['WorkflowEngagement.environmentID']}
                query={{
                  measures: [
                    'WorkflowEngagement.TriggeredRatio',
                    'WorkflowEngagement.DeliveredRatio',
                    'WorkflowEngagement.SeenRatio',
                    'WorkflowEngagement.InteractedRatio',
                    'WorkflowEngagement.OverallRatio',
                    'WorkflowEngagement.TriggeredCount',
                    'WorkflowEngagement.DeliveredCount',
                    'WorkflowEngagement.SeenCount',
                    'WorkflowEngagement.InteractedCount',
                  ],
                  timeDimensions: [
                    {
                      dimension: 'WorkflowEngagement.createdAt',
                      dateRange: queryDateRange,
                    },
                  ],
                  order: { 'WorkflowEngagement.createdAt': 'asc' },
                  filters: [
                    {
                      member: 'WorkflowEngagement.workflowID',
                      operator: 'equals',
                      values: [String(workflow.id)],
                    },
                  ],
                }}
                render={props => {
                  return (
                    <div className="h-max">
                      <WorkflowChart {...props} ChartComponent={ChartTwo} />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="mb-8">
            <div className="flex w-full">
              <div className="w-[50%] mr-3">
                <p className="text-l mb-2 font-medium">
                  How many notifications were sent across all channels?
                </p>

                <div>
                  <div className="flex mb-4">
                    <p className="text-sm text-gray-700 mr-1">{dateText}</p>
                    <p className="font-black px-2 -mt-1">.</p>
                    <p className="text-sm text-gray-700">
                      Total Notifications on all channels
                    </p>
                  </div>
                  <QueryBuilder
                    cubejsApi={cubejsAPI['WorkflowEngagement.environmentID']}
                    query={{
                      measures: [
                        'WorkflowEngagement.TriggeredCount',
                        'WorkflowEngagement.TriggeredSum',
                        'WorkflowEngagement.AvgNotif',
                      ],
                      timeDimensions: [
                        {
                          dimension: 'WorkflowEngagement.createdAt',
                          dateRange: queryDateRange,
                        },
                      ],
                      filters: [
                        {
                          member: 'WorkflowEngagement.workflowID',
                          operator: 'equals',
                          values: [String(workflow.id)],
                        },
                      ],
                    }}
                    render={props => {
                      return (
                        <div className="mr-1">
                          <WorkflowChart
                            {...props}
                            ChartComponent={ChartThreeOne}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="w-[50%] ml-3">
                <p className="text-l mb-2 font-medium">
                  How many notifications were sent across all channels?
                </p>
                <div>
                  <div className="flex mb-4">
                    <p className="text-sm text-gray-700">{dateText}</p>
                    <p className="font-black px-2 -mt-1">.</p>
                    <p className="text-sm text-gray-700">
                      Share of each channel
                    </p>
                  </div>
                  <QueryBuilder
                    cubejsApi={cubejsAPI['WorkflowChannels.environmentID']}
                    query={{
                      measures: ['WorkflowChannels.countTriggered'],
                      dimensions: ['WorkflowChannels.channelSlug'],
                      timeDimensions: [
                        {
                          dimension: 'WorkflowChannels.createdAt',
                          dateRange: queryDateRange,
                        },
                      ],
                      order: { 'WorkflowChannels.countTriggered': 'desc' },
                      filters: [
                        {
                          member: 'WorkflowChannels.workflowID',
                          operator: 'equals',
                          values: [String(workflow.id)],
                        },
                      ],
                    }}
                    render={props => {
                      return (
                        <div className="ml-1">
                          <WorkflowChart
                            {...props}
                            ChartComponent={ChartThreeTwo}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <QueryBuilder
              cubejsApi={cubejsAPI['WorkflowChannels.environmentID']}
              query={{
                measures: ['WorkflowChannels.PerNotificationSaved'],
                timeDimensions: [
                  {
                    dimension: 'WorkflowChannels.createdAt',
                    dateRange: queryDateRange,
                  },
                ],
                filters: [
                  {
                    member: 'WorkflowChannels.workflowID',
                    operator: 'equals',
                    values: [String(workflow.id)],
                  },
                ],
              }}
              render={savedPercentage => {
                const notificationSavedPercentage =
                  savedPercentage?.resultSet?.series()?.[0]?.series?.[0]?.value;
                return (
                  <QueryBuilder
                    cubejsApi={cubejsAPI['WorkflowChannels.environmentID']}
                    query={{
                      measures: ['WorkflowChannels.countTriggered'],
                      dimensions: ['WorkflowChannels.channelSlug'],
                      timeDimensions: [
                        {
                          dimension: 'WorkflowChannels.createdAt',
                          dateRange: queryDateRange,
                        },
                      ],
                      order: { 'WorkflowChannels.countTriggered': 'desc' },
                      filters: [
                        {
                          member: 'WorkflowChannels.workflowID',
                          operator: 'equals',
                          values: [String(workflow.id)],
                        },
                      ],
                    }}
                    render={sharedChannels => {
                      const shareOfEachChannel =
                        sharedChannels?.resultSet?.series()?.[0]?.series
                          ?.length;

                      if (shareOfEachChannel === 1) {
                        return (
                          <div className="bg-[#FFFDEE] flex items-start border rounded-lg py-2 px-3 mb-8">
                            <SmartDeliveryIcon className="h-8 w-8 mr-3 -mt-[4px]" />
                            <p className="font-medium text-md">
                              Multi-channel strategy
                              <span className="ml-1 font-normal">
                                is used by a lot of users to improve engagement,
                                adding a single channel can improve your seen
                                rate by
                              </span>
                              <span className="ml-1 text-green-600">10%</span>
                            </p>
                          </div>
                        );
                      } else if (
                        notificationSavedPercentage === 0 &&
                        shareOfEachChannel > 1
                      ) {
                        return (
                          <div className="bg-[#FFFDEE] flex items-center border rounded-lg py-2 px-3 mb-8">
                            <SmartDeliveryIcon className="h-6 w-6 mr-3" />
                            <p className="font-medium text-md">
                              Try Smart Delivery
                              <span className="font-normal">
                                , it can save your notification cost by
                              </span>
                              <span className="ml-1 text-green-600">20%</span>
                            </p>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="flex mb-8 w-full">
            <div className="pr-3 w-[50%]">
              <p className="py-2 font-medium">
                What is the performance of each channel?
              </p>
              <div className="flex mb-4">
                <p className="text-sm text-gray-700 mb-2">{dateText}</p>
                <p className="font-black px-2 -mt-1">.</p>
                <p className="text-sm text-gray-700">
                  Delivery rate, seen rate and interaction rate for all channels
                </p>
              </div>
              <QueryBuilder
                cubejsApi={cubejsAPI['WorkflowChannels.environmentID']}
                query={{
                  measures: [
                    'WorkflowChannels.DeliveredRatio',
                    'WorkflowChannels.SeenRatio',
                    'WorkflowChannels.InteractedRatio',
                  ],
                  dimensions: ['WorkflowChannels.channelSlug'],
                  timeDimensions: [
                    {
                      dimension: 'WorkflowChannels.createdAt',
                      dateRange: queryDateRange,
                    },
                  ],
                  order: { 'WorkflowChannels.DeliveredRatio': 'asc' },
                  filters: [
                    {
                      member: 'WorkflowChannels.workflowID',
                      operator: 'equals',
                      values: [String(workflow.id)],
                    },
                  ],
                }}
                render={props => {
                  return (
                    <div className="">
                      <WorkflowChart {...props} ChartComponent={ChartFour} />
                    </div>
                  );
                }}
              />
            </div>

            <div className="pl-3 w-[50%]">
              <div>
                <QueryBuilder
                  cubejsApi={cubejsAPI['WorkflowChannels.environmentID']}
                  query={{
                    measures: ['WorkflowChannels.PerNotificationSaved'],
                    timeDimensions: [
                      {
                        dimension: 'WorkflowChannels.createdAt',
                        dateRange: queryDateRange,
                      },
                    ],
                    filters: [
                      {
                        member: 'WorkflowChannels.workflowID',
                        operator: 'equals',
                        values: [String(workflow.id)],
                      },
                    ],
                  }}
                  render={savedPercentage => {
                    const notificationSavedPercentage =
                      savedPercentage?.resultSet?.series()?.[0]?.series?.[0]
                        ?.value;
                    return (
                      <QueryBuilder
                        cubejsApi={cubejsAPI['WorkflowChannels.environmentID']}
                        query={{
                          measures: ['WorkflowChannels.countTriggered'],
                          dimensions: ['WorkflowChannels.channelSlug'],
                          timeDimensions: [
                            {
                              dimension: 'WorkflowChannels.createdAt',
                              dateRange: queryDateRange,
                            },
                          ],
                          order: { 'WorkflowChannels.countTriggered': 'desc' },
                          filters: [
                            {
                              member: 'WorkflowChannels.workflowID',
                              operator: 'equals',
                              values: [String(workflow.id)],
                            },
                          ],
                        }}
                        render={sharedChannels => {
                          if (notificationSavedPercentage > 0) {
                            return (
                              <div>
                                <div className="flex items-center">
                                  <p className="py-2 font-medium">
                                    How smart workflows helped in sending fewer
                                    notifications?
                                  </p>
                                </div>
                                <div className="flex mb-4">
                                  <p className="text-sm text-gray-700">
                                    {dateText}
                                  </p>
                                  <p className="font-black px-2 -mt-1">.</p>
                                  <p className="text-sm text-gray-700">
                                    Total Notifications sent across channel with
                                    or without smart workflows
                                  </p>
                                </div>

                                <QueryBuilder
                                  cubejsApi={
                                    cubejsAPI['WorkflowChannels.environmentID']
                                  }
                                  query={{
                                    measures: [
                                      'WorkflowChannels.countTriggered',
                                      'WorkflowChannels.countTriggerWoSmartDelivery',
                                    ],
                                    timeDimensions: [
                                      {
                                        dimension: 'WorkflowChannels.createdAt',
                                        granularity: 'day',
                                        dateRange: queryDateRange,
                                      },
                                    ],
                                    filters: [
                                      {
                                        member: 'WorkflowChannels.workflowID',
                                        operator: 'equals',
                                        values: [String(workflow.id)],
                                      },
                                    ],
                                  }}
                                  render={props => {
                                    return (
                                      <WorkflowChart
                                        {...props}
                                        ChartComponent={SmartWorkflowChart}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <p className="text-lg font-semibold text-gray-500 capitalize mt-5 mb-4">
              ACTIONABLES
            </p>

            <div id="workflowError">
              <QueryBuilder
                cubejsApi={
                  cubejsAPI['WorkflowNotificationGroups.environmentID']
                }
                query={{
                  measures: ['WorkflowNotificationGroups.PerTriggered'],
                  timeDimensions: [
                    {
                      dimension: 'WorkflowNotificationGroups.createdAt',
                      dateRange: queryDateRange,
                    },
                  ],
                  order: { 'WorkflowNotificationGroups.createdAt': 'asc' },
                  filters: [
                    {
                      member: 'WorkflowNotificationGroups.workflowID',
                      operator: 'equals',
                      values: [String(workflow.id)],
                    },
                  ],
                }}
                render={other_props => {
                  const seriesData = other_props?.resultSet?.series();
                  const percentage =
                    seriesData?.[0].series?.[0]?.value?.toFixed(1);
                  const floatingPercent = parseFloat(percentage);
                  const errorPercentage = floatingPercent
                    ? 100 - floatingPercent
                    : 0;

                  return (
                    <div>
                      <div className="flex items-center mb-2">
                        <div>
                          <p className="text-base font-medium">
                            Workflow failure reasons
                          </p>
                        </div>
                        <div>
                          <p className="text-sm px-2 py-1 font-semibold">
                            (failure rate -{' '}
                            <span className="text-red-600">
                              {errorPercentage
                                ? `${errorPercentage?.toFixed(1)}%`
                                : '--'}
                            </span>
                            )
                          </p>
                        </div>
                        <a
                          href="https://docs.suprsend.com/docs/error-guides"
                          target="_blank"
                          rel="noreferrer"
                          className="ml-auto text-sm text-[#2E70E8] hover:underline cursor-pointer"
                        >
                          Refer error guide
                        </a>
                      </div>

                      {!!errorPercentage && (
                        <div>
                          <div className="flex mb-4">
                            <p className="text-sm text-gray-700">{dateText}</p>
                            <p className="font-black px-2 -mt-1">.</p>
                            <p className="text-sm text-gray-700">
                              Workflows failed vs failure reasons
                            </p>
                          </div>
                          <QueryBuilder
                            cubejsApi={
                              cubejsAPI['WorkflowErrors.environmentID']
                            }
                            query={{
                              measures: ['WorkflowErrors.errorCount'],
                              timeDimensions: [
                                {
                                  dimension: 'WorkflowErrors.createdAt',
                                  dateRange: queryDateRange,
                                },
                              ],
                              order: {
                                'WorkflowErrors.errorCount': 'desc',
                              },
                              dimensions: ['WorkflowErrors.failureReason'],
                              filters: [
                                {
                                  member: 'WorkflowErrors.workflowID',
                                  operator: 'equals',
                                  values: [String(workflow.id)],
                                },
                              ],
                            }}
                            render={props => {
                              return (
                                <WorkflowChart
                                  {...props}
                                  ChartComponent={ChartFive}
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div id="deliveryError">
              <QueryBuilder
                cubejsApi={cubejsAPI['WorkflowChannels.environmentID']}
                query={{
                  measures: ['WorkflowChannels.FailedRatio'],
                  timeDimensions: [
                    {
                      dimension: 'WorkflowChannels.createdAt',
                      dateRange: queryDateRange,
                    },
                  ],
                  order: {
                    'WorkflowChannels.createdAt': 'asc',
                  },
                  filters: [
                    {
                      member: 'WorkflowChannels.workflowID',
                      operator: 'equals',
                      values: [String(workflow.id)],
                    },
                  ],
                }}
                render={props => {
                  const seriesData = props?.resultSet?.series();
                  const percentage =
                    seriesData?.[0].series?.[0]?.value?.toFixed(1);
                  const errorPercentage = parseFloat(percentage);
                  return (
                    <div className="mt-8">
                      <div className="flex items-center mb-2">
                        <div>
                          <p className="text-base font-medium">
                            Delivery failure reasons
                          </p>
                        </div>
                        <div>
                          <p className="text-sm px-2 py-1 font-semibold">
                            (failure rate -{' '}
                            <span className="text-red-600">
                              {errorPercentage
                                ? `${errorPercentage.toFixed(1)}%`
                                : '--'}
                            </span>
                            )  
                          </p>
                        </div>
                      </div>
                      {!!errorPercentage && (
                        <div>
                          <div className="flex mb-4">
                            <p className="text-sm text-gray-700">{dateText}</p>
                            <p className="font-black px-2 -mt-1">.</p>
                            <p className="text-sm text-gray-700">
                              Notifications not delivered vs delivery failure
                              reasons
                            </p>
                          </div>
                          <QueryBuilder
                            cubejsApi={
                              cubejsAPI['WorkflowChannels.environmentID']
                            }
                            query={{
                              measures: ['WorkflowChannels.countFailed'],
                              timeDimensions: [
                                {
                                  dimension: 'WorkflowChannels.createdAt',
                                  dateRange: queryDateRange,
                                },
                              ],
                              order: {
                                'WorkflowChannels.countFailed': 'desc',
                              },
                              filters: [
                                {
                                  member: 'WorkflowChannels.workflowID',
                                  operator: 'equals',
                                  values: [String(workflow.id)],
                                },
                              ],
                              dimensions: [
                                'WorkflowChannels.channelSlug',
                                'WorkflowChannels.failureReason',
                              ],
                            }}
                            render={props => {
                              return (
                                <WorkflowChart
                                  {...props}
                                  ChartComponent={ChartSix}
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
}

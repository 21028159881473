import { useQuery, useQueryClient, useMutation } from 'react-query';
import { loggedInAPI, BASE_URL } from 'stores/AuthStore';
import { toast } from 'components';
import { convertObjToString } from 'utils';

const getServiceTokensList = async page => {
  const params = {
    limit: 20,
    offset: page * 20 || 0,
  };

  const url = `${BASE_URL}/v1/service_token/${convertObjToString(params)}`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

const useServiceTokensList = page => {
  return useQuery(['service_token', page], () => getServiceTokensList(page));
};

const useAddServiceToken = () => {
  const queryClient = useQueryClient();
  const url = `${BASE_URL}/v1/service_token/`;

  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries('service_token');
    },
    onError: error => {
      toast(
        `Error while adding service token: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const useDeleteServiceToken = id => {
  const queryClient = useQueryClient();
  const url = `${BASE_URL}/v1/service_token/${id}/disable/`;

  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries('service_token');
    },
    onError: error => {
      toast(
        `Error while deleting service token: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

export { useServiceTokensList, useAddServiceToken, useDeleteServiceToken };

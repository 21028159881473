import { differenceInMilliseconds, formatDistanceStrict } from 'date-fns';
var _ = require('lodash');

const TimeDiff = ({ fromDate, toDate, ...props }) => {
  const fDate = Date.parse(fromDate);
  const tDate = Date.parse(toDate);
  const diffMs = differenceInMilliseconds(tDate, fDate);

  if (_.isNaN(diffMs)) {
    return null;
  }

  if (diffMs > 1000) {
    let str = formatDistanceStrict(tDate, fDate);
    str = str.replace('seconds', 's').replace('second', 's');
    str = str.replace('minutes', 'm').replace('minute', 'm');
    str = str.replace(' ', '');
    return <div {...props}>{str}</div>;
  } else {
    return <div {...props}>{diffMs && Math.abs(diffMs)}ms</div>;
  }
};

export default TimeDiff;

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { loggedInAPI, useEnvState } from 'stores/AuthStore';
import { useDebounce } from 'ahooks';
import { toast } from 'react-toastify';

const getTeamList = async ({ debouncedQuery, role, status }) => {
  let url = '/v1/organization/people/?limit=200';
  if (debouncedQuery) {
    url = `${url}&search=${debouncedQuery}`;
  }
  if (role) {
    url = `${url}&role=${role}`;
  }
  if (status) {
    url = `${url}&status=${status}`;
  }
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTeamList({ searchText, role, status }) {
  const envState = useEnvState();
  const debouncedQuery = useDebounce(searchText, 500);
  return useQuery(
    [`${envState.currentEnv.get()}/people/`, debouncedQuery, role, status],
    () => getTeamList({ debouncedQuery, role, status }),
    { keepPreviousData: true }
  );
}

function useAddUser() {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = '/v1/organization/people/invitation/';
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/people/`);
    },
  });
}

function useResendInvite(inviteID) {
  const url = `/v1/organization/people/invitation/${inviteID}/remind/`;
  return useMutation(data => loggedInAPI.patch(url, data), {
    onError: error => {
      toast(`Error in resending invitation: ${error.response.data.message}`, {
        type: 'error',
        position: 'bottom-right',
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useDeleteInvite(inviteID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = `/v1/organization/people/invitation/${inviteID}/cancel/`;
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/people/`);
    },
    onError: error => {
      toast(`Error in deleting invite: ${error.response.data.message}`, {
        type: 'error',
        position: 'bottom-right',
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useChangeRole(userID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = `/v1/organization/people/member/${userID}/`;
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/people/`);
    },
    onError: error => {
      toast(`Error in changing role: ${error.response.data.message}`, {
        type: 'error',
        position: 'bottom-right',
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useDeleteUser(userID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = `/v1/organization/people/member/${userID}/deactivate/`;
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/people/`);
    },
    onError: error => {
      toast(`Error in deleting user: ${error.response.data.message}`, {
        type: 'error',
        position: 'bottom-right',
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useActivateUser(userID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = `/v1/organization/people/member/${userID}/reactivate/`;
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/people/`);
    },
    onError: error => {
      toast(`Error in activating user: ${error.response.data.message}`, {
        type: 'error',
        position: 'bottom-right',
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useRequestMFA() {
  const queryClient = useQueryClient();
  const url = `/v1/organization/request_mfa/`;
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries('organization');
    },
  });
}

export {
  useAddUser,
  useTeamList,
  useDeleteUser,
  useDeleteInvite,
  useResendInvite,
  useActivateUser,
  useChangeRole,
  getTeamList,
  useRequestMFA,
};

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { loggedInAPI, useEnvState } from '../stores/AuthStore';

const uploadFile = async (body, headers, setPercentage, env) => {
  try {
    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setPercentage(percent);
        }
      },
      headers,
    };
    const { data } = await loggedInAPI.put(
      `/v1/${env}/upload_file/`,
      body,
      options
    );
    return data;
  } catch (error) {
    console.log('Netwrok Error ', error.message);
    return {
      error: 'Network Error',
    };
  }
};

function ImageUploadWidget({ onComplete, isOpen = true, onClose, ...props }) {
  const [percentage, setPercentage] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const envState = useEnvState();
  const handleFile = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const headers = {
        'Content-Disposition': `attachment; filename=${e.target.files[0].name}`,
      };
      setUploading(true);
      const data = await uploadFile(
        formData,
        headers,
        setPercentage,
        envState.currentEnv.get()
      );
      if (data) {
        if (typeof data === 'string') {
          onComplete(data);
          onClose();
        } else {
          setError(data.error);
        }
      }
    } catch (err) {
      setError(err + '');
      console.log('Setting Error : ', err);
    } finally {
      setPercentage(0);
      setUploading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => onClose()}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Upload Image
              </Dialog.Title>
              <div className="flex w-full items-center justify-center bg-grey-lighter p-16">
                {!uploading && (
                  <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-indigo rounded-lg shadow-lg tracking-wide uppercase border border-indigo cursor-pointer hover:bg-indigo hover:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="mt-2 text-base leading-normal">
                      Select a file
                    </span>
                    <input
                      id="upload"
                      type="file"
                      accept="image/*"
                      multiple={false}
                      className="hidden"
                      onChange={event => {
                        handleFile(event);
                      }}
                      onClick={event => {
                        event.target.value = null;
                      }}
                    />
                  </label>
                )}
                {uploading && (
                  <div className="shadow w-full bg-grey-light">
                    <div
                      className="bg-indigo text-xs leading-none py-1 text-center text-white"
                      style={{ width: `${percentage}%` }}
                    >
                      {percentage}%
                    </div>
                  </div>
                )}
              </div>
              {error && error !== '' && (
                <p className="w-full text-center text-red-600">{error}</p>
              )}
              <div className="mt-4">
                {/* <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                  onClick={() => onClose()}
                >
                  Got it, thanks!
                </button> */}
                <button className="opacity-0 absolute bottom-0"></button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ImageUploadWidget;

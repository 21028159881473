import { useQuery, useMutation, useQueryClient } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from 'stores/AuthStore';
import { toast } from 'components';

const getApiKeys = async (page = 0) => {
  let offset = page * 10;
  let url = buildAPIURL(`/api_key/?limit=10&offset=${offset}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useApiKeys({ page = 0 }) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/api_key`, page],
    () => getApiKeys(page),
    { keepPreviousData: true }
  );
}

const useAddApiKey = () => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/api_key/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/api_key`);
    },
    onError: error => {
      toast(
        `Error while adding api key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const useDeleteApiKey = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/api_key/${id}/`);
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/api_key`);
    },
    onError: error => {
      toast(
        `Error while deleting api key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const getPublicKeys = async (page = 0) => {
  let offset = page * 10;
  let url = buildAPIURL(`/ws_public_key/?limit=10&offset=${offset}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function usePublicKeys({ page = 0 }) {
  const envState = useEnvState();
  return useQuery([`${envState.currentEnv.get()}/public_key`, page], () =>
    getPublicKeys(page)
  );
}

const useAddPublicKey = () => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/ws_public_key/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/public_key`);
    },
    onError: error => {
      toast(
        `Error while adding public key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const useRotatePublicKey = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/ws_public_key/${id}/rotate/`);
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/public_key`);
    },
    onError: error => {
      toast(
        `Error while rotating public key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const useSetSecureMode = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/ws_public_key/${id}/`);
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/public_key`);
    },
    onError: error => {
      toast(
        `Error while setting secure mode: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const useDeletePublicKey = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/ws_public_key/${id}/`);
  return useMutation(data => loggedInAPI.delete(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/public_key`);
    },
    onError: error => {
      toast(
        `Error while deleting public key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const getSigningKeys = async () => {
  let url = buildAPIURL(`/ws_signing_key/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useSigningKeys() {
  const envState = useEnvState();
  return useQuery([`${envState.currentEnv.get()}/signing_key`], () =>
    getSigningKeys()
  );
}

const useAddSigningKey = () => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/ws_signing_key/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/signing_key`);
    },
    onError: error => {
      toast(
        `Error while adding signing key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const useRollSigningKey = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/ws_signing_key/${id}/roll/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/signing_key`);
    },
    onError: error => {
      toast(
        `Error while rolling signing key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

const useDeleteSigningKey = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/ws_signing_key/${id}/`);
  return useMutation(data => loggedInAPI.delete(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/signing_key`);
    },
    onError: error => {
      toast(
        `Error while deleting signing key: ${error.response.data.message}`,
        'error'
      );
    },
  });
};

export {
  useApiKeys,
  useAddApiKey,
  useDeleteApiKey,
  usePublicKeys,
  useAddPublicKey,
  useRotatePublicKey,
  useSetSecureMode,
  useDeletePublicKey,
  useSigningKeys,
  useAddSigningKey,
  useRollSigningKey,
  useDeleteSigningKey,
};

import { toast as reactToast } from 'react-toastify';

export default function toast(message, type, config) {
  const toastConfig = {
    type: type || 'success',
    position: 'bottom-right',
    autoClose: 3000,
    ...config,
  };

  reactToast(message, toastConfig);
}

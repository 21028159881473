import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { RefreshCcw, Filter, X } from 'lucide-react';
import CreatableSelect from 'react-select/creatable';
import {
  Button,
  Label,
  Checkbox,
  Input,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
  DateTimeRangePicker,
  Switch,
} from 'new-components';
import { Spinner } from 'components';
import { useTenantsData, useSubscriberInfoSearch } from 'apis';
import { cn } from 'utils';

const validMainFiltersList = [
  'status',
  'tenant',
  'distinct_id',
  'idempotency_key',
  'workflow_slug',
  'execution_id',
];

const getFilterCount = qs => {
  return validMainFiltersList.reduce((acc, current) => {
    return qs.has(current) ? ++acc : acc;
  }, 0);
};

export function TopFilters({
  queryString,
  autoRefresh,
  setAutoRefresh,
  handleRefresh,
  loader,
  filterModal,
}) {
  const history = useHistory();

  let date = 1440;
  if (queryString.get('start_ts') && queryString.get('end_ts')) {
    date = {
      from: queryString.get('start_ts'),
      to: queryString.get('end_ts'),
    };
  } else if (queryString.get('last_n_minutes')) {
    date = Number(queryString.get('last_n_minutes'));
  }

  const filterCount = getFilterCount(queryString);

  return (
    <div className="flex space-x-4 -mb-8">
      {loader && (
        <div className="-m-2 mr-2">
          <Spinner />
        </div>
      )}
      <DateTimeRangePicker
        rangeValue={date}
        onChangeData={({ type, values }) => {
          if (type === 'absolute') {
            queryString.delete('last_n_minutes');
            queryString.set('start_ts', values.from);
            queryString.set('end_ts', values.to);
          } else {
            queryString.set('last_n_minutes', values);
            queryString.delete('start_ts');
            queryString.delete('end_ts');
          }
          queryString.set('page', 0);
          history.push({ search: queryString.toString() });
        }}
      />
      {filterModal && (
        <SheetTrigger asChild>
          <Button variant="outline">
            <Filter className="h-4 w-4 mr-2" />
            Filters
            {filterCount !== 0 && (
              <div className="flex items-center">
                <p className="ml-1 bg-primary rounded-full inline-flex items-center px-[6px] py-[1px] text-xs font-medium text-white">
                  {filterCount}
                </p>
                <p className="pl-2.5 pr-2 ">|</p>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    validMainFiltersList.forEach(item => {
                      queryString.delete(item);
                    });
                    queryString.set('page', 0);
                    history.push({ search: queryString.toString() });
                  }}
                >
                  <X className="h-4 w-4" />
                </div>
              </div>
            )}
          </Button>
        </SheetTrigger>
      )}
      <Button
        variant="outline"
        size="icon"
        onClick={() => {
          handleRefresh();
        }}
      >
        <RefreshCcw className="h-5 w-5" />
      </Button>
      <div className="flex items-center space-x-2">
        <Label htmlFor="auto-refresh" className=" text-accent-foreground">
          Auto-refresh
        </Label>
        <Switch
          id="auto-refresh"
          onCheckedChange={() => {
            setAutoRefresh(!autoRefresh);
          }}
          checked={autoRefresh}
        />
      </div>
    </div>
  );
}

const STATUS_OPTIONS = [
  {
    name: 'Processing',
    value: 'processing',
    bgColor: 'bg-primary-subtle',
    totalCountKey: 'total_processing',
  },
  {
    name: 'Completed',
    value: 'completed',
    bgColor: 'bg-success-subtle',
    totalCountKey: 'total_completed',
  },
  {
    name: 'Partial Failure',
    value: 'partial_failure',
    bgColor: 'bg-warning-subtle',
    totalCountKey: 'total_partial_failure',
  },
  {
    name: 'Failed',
    value: 'failed',
    bgColor: 'bg-destructive-subtle',
    totalCountKey: 'total_failed',
  },
];

export function MainFilters({
  queryParam,
  hasTenant,
  filtersCount,
  filters,
  setFilters,
  isModal,
  showWorkflowSlug = true,
}) {
  const history = useHistory();

  const filtersData = isModal ? new URLSearchParams(filters) : queryParam;

  const tenant = filtersData.get('tenant');
  const distinctID = filtersData.get('distinct_id');
  const idempotencyKey = filtersData.get('idempotency_key');
  const executionID = filtersData.get('execution_id');
  const workflowSlug = filtersData.get('workflow_slug');

  const idempotencyRef = useRef();
  const executionRef = useRef();
  const workflowSlugRef = useRef();

  const [tenantInfo, setTenant] = useState();
  const [distinctIDInfo, setDistinctIDInfo] = useState();
  const [idempotencyFocus, setIdempotencyFocus] = useState(false);
  const [executionFocus, setExecutionFocus] = useState(false);
  const [workflowSlugFocus, setWorkflowSlugFocus] = useState(false);

  const { data: searchedDistinctIDs, isLoading: DistinctIdLoading } =
    useSubscriberInfoSearch({
      distinctId: distinctIDInfo,
    });
  const { data: tenantData, isLoading: tenantLoading } = useTenantsData(
    tenantInfo,
    !!tenantInfo
  );

  return (
    <div>
      {!isModal && (
        <div className="flex items-center justify-between border-b px-4 py-1">
          <p className="text-foreground font-semibold text-base">Filters</p>
          <Button
            variant="link"
            className="hover:no-underline p-0"
            onClick={() => {
              validMainFiltersList.forEach(item => {
                filtersData.delete(item);
              });
              filtersData.set('page', 0);
              idempotencyRef.current.value = '';
              executionRef.current.value = '';
              if (workflowSlugRef.current) {
                workflowSlugRef.current.value = '';
              }
              history.push({ search: filtersData.toString() });
            }}
          >
            Clear All
          </Button>
        </div>
      )}
      <div className={cn('pb-8', isModal ? 'p-0 pt-4' : 'p-4')}>
        <div>
          <div>
            <p className="font-medium text-sm text-foreground">Status</p>
          </div>
          <div className="py-2">
            {STATUS_OPTIONS.map((status, index) => {
              let checked = false;
              const entries = filtersData.entries();
              for (let filter of entries) {
                if (filter[0] === 'status' && filter[1] === status.value) {
                  checked = true;
                }
              }
              return (
                <div
                  className="flex items-center justify-between py-1"
                  key={index}
                >
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={status.value}
                      checked={checked}
                      onCheckedChange={value => {
                        if (value) {
                          filtersData.append('status', status.value);
                          filtersData.set('page', 0);
                          if (isModal) {
                            setFilters(filtersData.toString());
                          } else {
                            history.push({ search: filtersData.toString() });
                          }
                        } else {
                          const entries = filtersData.entries();
                          const newQueryParam = new URLSearchParams();
                          for (let filter of entries) {
                            if (
                              !(
                                filter[0] === 'status' &&
                                filter[1] === status.value
                              )
                            ) {
                              newQueryParam.append(filter[0], filter[1]);
                            }
                          }
                          newQueryParam.set('page', 0);
                          if (isModal) {
                            setFilters(newQueryParam.toString());
                          } else {
                            history.push({ search: newQueryParam.toString() });
                          }
                        }
                      }}
                    />
                    <Label
                      htmlFor={status.value}
                      className="text-sm text-foreground font-normal"
                    >
                      <div className="flex gap-2 items-center">
                        <div
                          className={cn(
                            'h-3 w-3 rounded-[2px]',
                            status.bgColor
                          )}
                        />
                        <p>{status.name}</p>
                      </div>
                    </Label>
                  </div>
                  <p className="text-xs text-muted-foreground font-normal">
                    {filtersCount?.[status.totalCountKey]}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {showWorkflowSlug && (
          <div className="mt-3">
            <div className="mb-1">
              <Label className="font-medium text-sm text-foreground">
                Workflow Slug
              </Label>
            </div>
            <div
              className={cn(
                'flex items-center border border-input bg-transparent rounded',
                workflowSlugFocus && 'outline-none ring-1 ring-ring'
              )}
            >
              <Input
                defaultValue={workflowSlug}
                className="border-none focus-visible:ring-0"
                onFocus={() => {
                  setWorkflowSlugFocus(true);
                }}
                id="subscriber_workflow_slug_filter"
                ref={workflowSlugRef}
                onBlur={() => {
                  setWorkflowSlugFocus(false);
                }}
                onChange={e => {
                  if (!e.target.value) {
                    filtersData.delete('workflow_slug');
                    filtersData.set('page', 0);
                    if (isModal) {
                      setFilters(filtersData.toString());
                    } else {
                      history.push({ search: filtersData.toString() });
                    }
                  } else {
                    if (isModal) {
                      filtersData.set('workflow_slug', e.target.value);
                      filtersData.set('page', 0);
                      setFilters(filtersData.toString());
                    }
                  }
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && e.target.value) {
                    filtersData.set('workflow_slug', e.target.value);
                    filtersData.set('page', 0);
                    if (isModal) {
                      setFilters(filtersData.toString());
                    } else {
                      history.push({ search: filtersData.toString() });
                    }
                  }
                }}
              />
              {!isModal && (
                <div
                  className="bg-border/50 rounded-r h-9 px-2 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    const value = workflowSlugRef.current.value;
                    if (value) {
                      filtersData.set('workflow_slug', value);
                      filtersData.set('page', 0);
                      if (isModal) {
                        setFilters(filtersData.toString());
                      } else {
                        history.push({ search: filtersData.toString() });
                      }
                    }
                  }}
                >
                  <MagnifyingGlassIcon className={'h-5 w-5'} />
                </div>
              )}
            </div>
          </div>
        )}
        <div className={cn(showWorkflowSlug ? 'mt-4' : 'mt-3')}>
          <div className="mb-1">
            <Label className="font-medium text-sm text-foreground">
              Idempotency Key
            </Label>
          </div>
          <div
            className={cn(
              'flex items-center border border-input bg-transparent rounded',
              idempotencyFocus && 'outline-none ring-1 ring-ring'
            )}
          >
            <Input
              defaultValue={idempotencyKey}
              className="border-none focus-visible:ring-0"
              onFocus={() => {
                setIdempotencyFocus(true);
              }}
              id="subscriber_idempotency_filter"
              ref={idempotencyRef}
              onBlur={() => {
                setIdempotencyFocus(false);
              }}
              onChange={e => {
                if (!e.target.value) {
                  filtersData.delete('idempotency_key');
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                } else {
                  if (isModal) {
                    filtersData.set('idempotency_key', e.target.value);
                    filtersData.set('page', 0);
                    setFilters(filtersData.toString());
                  }
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' && e.target.value) {
                  filtersData.set('idempotency_key', e.target.value);
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                }
              }}
            />
            {!isModal && (
              <div
                className="bg-border/50 rounded-r h-9 px-2 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  const value = idempotencyRef.current.value;
                  if (value) {
                    filtersData.set('idempotency_key', value);
                    filtersData.set('page', 0);
                    if (isModal) {
                      setFilters(filtersData.toString());
                    } else {
                      history.push({ search: filtersData.toString() });
                    }
                  }
                }}
              >
                <MagnifyingGlassIcon className={'h-5 w-5'} />
              </div>
            )}
          </div>
        </div>

        <div className="mt-4">
          <div className="mb-1">
            <Label className="font-medium text-sm text-foreground">
              Execution ID
            </Label>
          </div>
          <div
            className={cn(
              'flex items-center border border-input bg-transparent rounded',
              executionFocus && 'outline-none ring-1 ring-ring'
            )}
          >
            <Input
              id="subscriber_execution_filter"
              defaultValue={executionID}
              className="border-none focus-visible:ring-0"
              onFocus={() => {
                setExecutionFocus(true);
              }}
              ref={executionRef}
              onBlur={() => {
                setExecutionFocus(false);
              }}
              onChange={e => {
                if (!e.target.value) {
                  filtersData.delete('execution_id');
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                } else {
                  if (isModal) {
                    filtersData.set('execution_id', e.target.value);
                    filtersData.set('page', 0);
                    setFilters(filtersData.toString());
                  }
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' && e.target.value) {
                  filtersData.set('execution_id', e.target.value);
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                }
              }}
            />
            {!isModal && (
              <div
                className="bg-border/50 rounded-r h-9 px-2 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  const value = executionRef.current.value;
                  if (value) {
                    filtersData.set('execution_id', value);
                    filtersData.set('page', 0);
                    if (isModal) {
                      setFilters(filtersData.toString());
                    } else {
                      history.push({ search: filtersData.toString() });
                    }
                  }
                }}
              >
                <MagnifyingGlassIcon className={'h-5 w-5'} />
              </div>
            )}
          </div>
        </div>
        {!isModal && (
          <div className="mt-4">
            <div className="mb-1">
              <Label className="font-medium text-sm text-foreground">
                Distinct ID
              </Label>
            </div>
            <CreatableSelect
              classNamePrefix="react-select"
              placeholder="Type to search"
              defaultValue={distinctID ? { distinct_id: distinctID } : null}
              value={distinctID ? { distinct_id: distinctID } : null}
              isLoading={DistinctIdLoading}
              isClearable
              openMenuOnClick={false}
              options={searchedDistinctIDs ? searchedDistinctIDs?.results : []}
              getOptionLabel={i => i?.distinct_id}
              getOptionValue={i => i?.distinct_id}
              onInputChange={data => {
                setDistinctIDInfo(data);
              }}
              createOptionPosition="first"
              onChange={(data, event) => {
                if (
                  event.action === 'select-option' ||
                  event.action === 'create-option'
                ) {
                  filtersData.set('distinct_id', data.distinct_id);
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                } else if (event.action === 'clear') {
                  filtersData.delete('distinct_id');
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                }
              }}
              getNewOptionData={newOption => {
                if (newOption) {
                  return { distinct_id: newOption };
                }
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
        {hasTenant && (
          <div className="mt-4">
            <div className="mb-1">
              <Label className="font-medium text-sm text-foreground">
                Tenant ID
              </Label>
            </div>
            <CreatableSelect
              classNamePrefix="react-select"
              placeholder="Type to search"
              defaultValue={tenant ? { tenant_id: tenant } : null}
              value={tenant ? { tenant_id: tenant } : null}
              isLoading={tenantLoading}
              isClearable
              options={tenantInfo ? tenantData : []}
              createOptionPosition="first"
              getOptionLabel={i => i?.tenant_id}
              getOptionValue={i => i?.tenant_id}
              onInputChange={data => {
                setTenant(data);
              }}
              onChange={(data, event) => {
                if (
                  event.action === 'select-option' ||
                  event.action === 'create-option'
                ) {
                  filtersData.set('tenant', data.tenant_id);
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                } else if (event.action === 'clear') {
                  filtersData.delete('tenant');
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                }
              }}
              getNewOptionData={newOption => {
                if (newOption) {
                  return { tenant_id: newOption };
                }
              }}
              noOptionsMessage={() => null}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function MainModalFilter({
  queryParam,
  hasTenant,
  filters,
  setFilters,
  filtersCount,
}) {
  const history = useHistory();

  return (
    <>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Filters</SheetTitle>
        </SheetHeader>
        <MainFilters
          queryParam={queryParam}
          hasTenant={hasTenant}
          filters={filters}
          setFilters={setFilters}
          filtersCount={filtersCount}
          isModal
        />
        <div className="flex items-center justify-between absolute bottom-0 left-0 right-0 border-t p-4">
          <Button
            variant="link"
            onClick={() => {
              const filtersData = new URLSearchParams(filters);
              validMainFiltersList.forEach(item => {
                filtersData.delete(item);
              });
              const idempotenctInput = document.getElementById(
                'subscriber_idempotency_filter'
              );
              const executionInput = document.getElementById(
                'subscriber_execution_filter'
              );
              const workflowSlugInput = document.getElementById(
                'subscriber_workflow_slug_filter'
              );
              idempotenctInput.value = '';
              executionInput.value = '';
              workflowSlugInput.value = '';
              setFilters(filtersData.toString());
            }}
          >
            Clear Filters
          </Button>
          <SheetClose>
            <Button
              onClick={() => {
                history.push({ search: filters });
              }}
            >
              {`View ${filtersCount?.total_count || 0} results`}
            </Button>
          </SheetClose>
        </div>
      </SheetContent>
    </>
  );
}

import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import { createState, useState } from '@hookstate/core';
import { useTimeout } from 'ahooks';

const ToastList = createState([]);

export function useToastManager() {
  const myToastList = useState(ToastList);
  const addToast = toastParams => {
    toastParams.id = Math.random().toString(16).slice(2);
    //setToastList(toastList => [...toastList, toastParams]);
    myToastList.merge([toastParams]);
  };

  return { addToast };
}

export default function ToastManager() {
  const myToastList = useState(ToastList);
  const close = id => {
    let index = null;
    myToastList.get().forEach((toast, idx) => {
      if (toast.id === id) {
        index = idx;
      }
    });
    //setToastList([...toastList.slice(0, index), ...toastList.slice(index + 1)]);
    myToastList.set(l => {
      l.splice(index, 1);
      return l;
    });
  };
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}

      <div
        aria-live="assertive"
        className="fixed inset-x-2 bottom-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

          {myToastList.get().map((toast, index) => (
            <Toast
              key={toast.id}
              id={toast.id}
              close={close}
              type={toast.type}
              title={toast.title}
              desc={toast.desc}
              autoClose={toast.autoClose}
            ></Toast>
          ))}
        </div>
      </div>
    </>
  );
}

const ToastIcon = ({ type }) => {
  switch (type) {
    case 'success':
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-500"
          aria-hidden="true"
        />
      );
    case 'error':
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 text-red-400"
          aria-hidden="true"
        />
      );
    case 'info':
    default:
      return (
        <InformationCircleIcon
          className="h-6 w-6 text-primary-400"
          aria-hidden="true"
        />
      );
    case 'warning':
      return (
        <ExclamationIcon
          className="h-6 w-6 text-yellow-500"
          aria-hidden="true"
        />
      );
  }
};

export function Toast({
  id,
  close,
  title = '',
  desc = '',
  type = 'info',
  actions = [],
  autoClose = false,
  ...props
}) {
  const closeMe = () => {
    close(id);
  };
  useTimeout(() => {
    if (autoClose === true) {
      close(id);
    }
  }, 3000);
  return (
    <Transition
      show={true}
      as={Fragment}
      enter="transform ease-out duration-500 transition"
      enterFrom="transform scale-50 translate-y-6 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="transform scale-100 translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-500"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-50 opacity-0"
    >
      <div className="max-w-sm w-full bg-white border border-gray-100 shadow-2xl drop-shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <ToastIcon type={type} />
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{desc}</p>
              {actions.length > 0 && (
                <div className="mt-4 flex">
                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    className="ml-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Decline
                  </button>
                </div>
              )}
            </div>
            {!autoClose && (
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  onClick={() => {
                    closeMe();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
}

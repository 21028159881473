import { useContext, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { FlowContext } from '../WorkflowDetails';
import { SEND_NODES } from '../CustomNodes';
import { ListBox, NodeFormHeader, TextField } from './Common';
import {
  useUpdateNodeProperties,
  useSearchTemplatesAPI,
  useUniqueEventsAPI,
  useGlobalChannelAPI,
  useTemplateDetailAPI,
} from 'apis';
import CreatableSelect from 'react-select/creatable';
import { MISSING_FIELD_ERROR, ValidateEventName, classNames } from 'utils';
import { Button, toast } from 'components';
import { ReactComponent as RadioChecked } from 'assets/svgs/radioChecked.svg';
import { ReactComponent as RadioUnchecked } from 'assets/svgs/radioUnchecked.svg';
import urlUtils from 'utils/urlUtils';
import * as Yup from 'yup';
import { useReactFlow } from 'reactflow';
import { SquareArrowOutUpRightIcon } from 'lucide-react';

const SUCCESS_IS_EVENT = [
  { name: 'Notification Status', value: false },
  { name: 'Custom Event', value: true },
];
const NOTIFICATION_STATUS_EVENTS = [
  { label: 'Delivered', value: 'delivered' },
  { label: 'Seen', value: 'seen' },
  { label: 'Interaction', value: 'interaction' },
];

const multiSelectStyles = disabled => {
  return {
    placeholder: styles => ({ ...styles, color: '#d4d4d4', fontSize: 14 }),
    control: base => ({
      ...base,
      border: '1px solid #d4d4d4',
      '&:hover': {
        border: '1px solid #d4d4d4',
      },
      boxShadow: 'none',
      backgroundColor: disabled ? 'rgb(249 250 251);' : '#FFFFFF',
    }),
  };
};

const MultiChannelNodeValidationSchema = Yup.object({
  template: Yup.string().required(MISSING_FIELD_ERROR),
  success_is_event: Yup.boolean().required(MISSING_FIELD_ERROR),
  success: Yup.string().required(MISSING_FIELD_ERROR),
  channels_expr: Yup.string().when('channels_expr_enabled', {
    is: true,
    then: Yup.string().required(MISSING_FIELD_ERROR),
  }),
  channels: Yup.array().when('send_to_all_channels', {
    is: false,
    then: Yup.array().min(1, 'Minimum one channel should be selected'),
  }),
});

export default function MultiChannelNodeForm() {
  const [showFormErrors, setShowFormErrors] = useState();
  const {
    selectedNode,
    slug,
    version,
    editMode,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const nodeMetaData = SEND_NODES[selectedNode.node_type];
  const nodeProperties = selectedNode?.properties || {};
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);

  const [templateSearchText, setTemplateSearchText] = useState('');
  const [selectedtemplate, setSelectedTemplate] = useState(
    nodeProperties?.template || ''
  ); // needed to make api call to get active channels for redirection on view template button as formik ref is not causing rerendering

  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );
  const { data: channels } = useGlobalChannelAPI();
  const { status: searchTemplateStatus, data: templateSearchResults } =
    useSearchTemplatesAPI(templateSearchText, true);
  const { status: uniqueEventStatus, data: uniqueEvents } =
    useUniqueEventsAPI();

  const { data: templateDetails } = useTemplateDetailAPI(selectedtemplate);
  const channelSlug = templateDetails?.channels?.[0]?.channel?.slug;

  const formattedEventOptions = useMemo(() => {
    return (
      uniqueEvents?.map(event => ({
        label: event.event,
        value: event.event,
      })) || []
    );
  }, [uniqueEvents]);

  const channelsList = useMemo(() => {
    return channels?.results?.map(item => {
      return {
        label: item.slug,
        value: item.slug,
      };
    });
  }, [channels]);

  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;
  return (
    <Formik
      initialValues={{
        template: nodeProperties?.template || '',
        success_is_event: nodeProperties?.success_is_event || false,
        success: nodeProperties?.success || 'seen',
        channels_expr_enabled: nodeProperties?.channels_expr || false,
        channels_expr: nodeProperties?.channels_expr || '',
        channels: nodeProperties?.channels || [],
        send_to_all_channels: !(nodeProperties?.channels?.length > 0),
      }}
      enableReinitialize={true}
      validationSchema={MultiChannelNodeValidationSchema}
      onSubmit={async values => {
        try {
          setLoading(true);
          await updateProperties.mutateAsync({ properties: values });
          setLoading(false);
          setShowFormErrors(true);
          toast('Settings saved successfully', '', { autoClose: 1000 });
        } catch (e) {
          setLoading(false);
        }
      }}
    >
      {({ values, setFieldValue }) => {
        const disabled = !editMode;
        const successIsEvent = SUCCESS_IS_EVENT.find(
          item => item.value === values.success_is_event
        );

        const templateName = values?.template
          ? { name: values.template, slug: values.template }
          : '';
        const defaultCustomEvent = values.success
          ? { label: values.success, value: values.success }
          : null;
        const defaultNotifStatus = NOTIFICATION_STATUS_EVENTS.find(
          item => item.value === values.success
        );
        let defaultChannels = '';
        if (values.channels?.length > 0) {
          defaultChannels = values.channels?.map(item => ({
            value: item,
            label: item,
          }));
        }

        return (
          <Form className="overflow-scroll h-full">
            <div className="m-4 mt-6">
              <NodeFormHeader
                name={nodeMetaData.name}
                helptext={nodeMetaData.formHelptext}
                Icon={nodeMetaData.icon}
                docLink={nodeMetaData.docLink}
              />
              <div className="mt-6 mb-24">
                {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
                  <div className="mb-6 text-xs -mt-2 text-red-500 bg-red-50 p-3 rounded">
                    <ul>
                      {Object.keys(fieldErrors)?.map((item, index) => {
                        return (
                          <li key={index}>
                            - {item}: {fieldErrors[item]}
                          </li>
                        );
                      })}
                      {nonFieldErrors?.map((item, index) => {
                        return <li key={index}>- {item}</li>;
                      })}
                    </ul>
                  </div>
                )}
                <div className="pb-6">
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor="wf-template"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Template<span className="text-red-500">*</span>
                    </label>
                    {values?.template && (
                      <Link
                        className="text-xs text-[#2e70e8] cursor-pointer"
                        to={urlUtils.makeURL(
                          `templates/${values?.template}/${
                            channelSlug ? channelSlug : ''
                          }`
                        )}
                        target="_blank"
                      >
                        View Template
                      </Link>
                    )}
                  </div>
                  <Select
                    placeholder="Select active template"
                    value={templateName}
                    isDisabled={disabled}
                    styles={{
                      control: styles => {
                        return {
                          ...styles,
                          backgroundColor: disabled ? '#f9fafb' : '#fff',
                          border: '1px solid #d4d4d4',
                        };
                      },
                      placeholder: styles => ({
                        ...styles,
                        color: '#d4d4d4',
                        fontSize: 14,
                      }),
                      singleValue: styles => {
                        return { ...styles, color: '#000', fontSize: 14 };
                      },
                    }}
                    isLoading={searchTemplateStatus === 'loading'}
                    noOptionsMessage={() => 'No templates found'}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.slug}
                    onChange={(selectedItem, actionTypes) => {
                      if (actionTypes.action === 'clear') {
                        setFieldValue('template', '');
                        setSelectedTemplate('');
                      }
                      if (selectedItem) {
                        setFieldValue('template', selectedItem.slug);
                        setSelectedTemplate(selectedItem.slug);
                      }
                    }}
                    options={templateSearchResults}
                    onInputChange={inputValue => {
                      setTemplateSearchText(inputValue);
                    }}
                  />
                  <ErrorMessage
                    name="template"
                    render={msg => (
                      <p className="text-red-500 text-sm pt-2">{msg}</p>
                    )}
                  />
                  {editMode && (
                    <Link
                      className="flex items-center mt-1.5 text-primary"
                      to={urlUtils.makeURL(
                        `templates/?action=create_new_template`
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="text-sm">Create new template</p>
                      <SquareArrowOutUpRightIcon className="h-3 w-3 ml-2" />
                    </Link>
                  )}
                </div>
                <div>
                  <label className="block text-sm text-gray-700 my-2 font-medium">
                    Always send to<span className="text-red-500">*</span>
                  </label>

                  <div
                    className={classNames(
                      'border border-[#d4d4d4] rounded-md px-2 py-2 mb-4',
                      disabled && 'bg-[#f9fafb]'
                    )}
                  >
                    <div
                      className={'flex items-center gap-2 cursor-pointer'}
                      onClick={() => {
                        if (disabled) return;
                        setFieldValue('send_to_all_channels', true);
                        setFieldValue('channels', []);
                      }}
                    >
                      {values.send_to_all_channels ? (
                        <RadioChecked height={24} width={24} />
                      ) : (
                        <RadioUnchecked height={24} width={24} />
                      )}
                      <p className="text-sm">All active channels</p>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      'border border-[#d4d4d4] rounded-md px-2 py-3 mb-6',
                      disabled && 'bg-[#f9fafb]'
                    )}
                  >
                    <div
                      className="flex items-center gap-2 cursor-pointer"
                      onClick={() => {
                        if (disabled) return;
                        setFieldValue('send_to_all_channels', false);
                        setFieldValue('channels', []);
                      }}
                    >
                      {values.send_to_all_channels ? (
                        <RadioUnchecked height={24} width={24} />
                      ) : (
                        <RadioChecked height={24} width={24} />
                      )}
                      <p className="text-sm">Selected channels</p>
                    </div>
                    {!values.send_to_all_channels && (
                      <div className="mt-2 mb-2 px-2">
                        <Select
                          placeholder="Select Channels"
                          options={channelsList}
                          value={defaultChannels}
                          isDisabled={disabled}
                          isMulti
                          styles={multiSelectStyles(disabled)}
                          isSearchable={false}
                          onChange={(_, actionData) => {
                            if (
                              actionData.action === 'select-option' ||
                              actionData.action === 'create-option'
                            ) {
                              setFieldValue('channels', [
                                ...values.channels,
                                actionData.option.value,
                              ]);
                            } else if (
                              actionData.action === 'remove-value' ||
                              actionData.action === 'pop-value'
                            ) {
                              const removedChannel =
                                actionData?.removedValue?.value;
                              if (!removedChannel) return;
                              const existingChannels = [...values?.channels];

                              const newChannels = existingChannels.filter(
                                item => {
                                  return item !== removedChannel;
                                }
                              );

                              setFieldValue('channels', [...newChannels]);
                            } else if (actionData.action === 'clear') {
                              setFieldValue('channels', []);
                            }
                          }}
                        />
                        <ErrorMessage
                          name="channels"
                          render={msg => (
                            <p className="text-red-500 text-sm pt-2">{msg}</p>
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="border-t pb-6 border-b">
                  <p className="text-[#1E293B] font-semibold text-base mb-4 mt-6">
                    Success metric
                  </p>
                  <div className="mt-2 mb-6">
                    <ListBox
                      label="Type"
                      id="success_is_event"
                      disabled={disabled}
                      value={values.success_is_event}
                      displayValue={successIsEvent?.name}
                      handleOnchange={value => {
                        if (values.success_is_event !== value) {
                          setFieldValue('success_is_event', value);
                          setFieldValue('success', '');
                        }
                      }}
                      options={SUCCESS_IS_EVENT}
                      getLabel={e => e.name}
                      getValue={e => e.value}
                    />
                  </div>
                  {values.success_is_event ? (
                    <div>
                      <label
                        htmlFor="event"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Event<span className="text-red-500">*</span>
                      </label>
                      <CreatableSelect
                        placeholder="Search or Type Event"
                        isLoading={uniqueEventStatus === 'loading'}
                        noOptionsMessage={() => 'No Events found'}
                        inputId="workflow-select-input"
                        defaultValue={defaultCustomEvent}
                        options={formattedEventOptions}
                        isDisabled={disabled}
                        styles={{
                          menuList: styles => {
                            return { ...styles, maxHeight: 200 };
                          },
                          placeholder: styles => ({
                            ...styles,
                            color: '#d4d4d4',
                            fontSize: 14,
                          }),
                          control: styles => {
                            return {
                              ...styles,
                              backgroundColor: disabled ? '#f9fafb' : '#fff',
                              border: '1px solid #d4d4d4',
                            };
                          },
                          singleValue: styles => {
                            return { ...styles, color: '#000', fontSize: 14 };
                          },
                        }}
                        getNewOptionData={v => {
                          if (v) {
                            const formattedEvent = ValidateEventName(v);
                            const upperCaseValue =
                              formattedEvent?.toUpperCase();
                            return {
                              value: upperCaseValue,
                              label: upperCaseValue,
                            };
                          }
                        }}
                        onChange={selectedItem => {
                          if (selectedItem) {
                            setFieldValue('success', selectedItem.value);
                          }
                        }}
                      />
                      <ErrorMessage
                        name="success"
                        render={msg => (
                          <p className="text-red-500 text-sm pt-2">{msg}</p>
                        )}
                      />
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="event"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Event<span className="text-red-500">*</span>
                      </label>
                      <Select
                        placeholder="Select Event"
                        inputId="workflow-select-input"
                        value={defaultNotifStatus}
                        options={NOTIFICATION_STATUS_EVENTS}
                        isDisabled={disabled}
                        getNewOptionData={value => {
                          if (value) {
                            return {
                              value: value,
                              label: value,
                            };
                          }
                        }}
                        styles={{
                          control: styles => {
                            return {
                              ...styles,
                              backgroundColor: disabled ? '#f9fafb' : '#fff',
                              border: '1px solid #d4d4d4',
                            };
                          },
                          placeholder: styles => ({
                            ...styles,
                            color: '#d4d4d4',
                            fontSize: 14,
                          }),
                          singleValue: styles => {
                            return { ...styles, color: '#000', fontSize: 14 };
                          },
                        }}
                        onChange={selectedItem => {
                          if (selectedItem) {
                            setFieldValue('success', selectedItem.value);
                          }
                        }}
                      />
                      <ErrorMessage
                        name="success"
                        render={msg => (
                          <p className="text-red-500 text-sm pt-2">{msg}</p>
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-4 mb-10">
                  <p className="text-[#1E293B] font-semibold text-base mb-4 mt-6">
                    Advanced Configuration
                  </p>
                  <div>
                    <div className="flex items-center mt-5 mb-2">
                      <Field
                        id="channels_expr_checkbox"
                        type="checkbox"
                        className="form-check-input rounded border-gray-300 w-[18px] h-[18px] cursor-pointer focus:ring-0 focus:ring-offset-0"
                        checked={values.channels_expr_enabled}
                        disabled={disabled}
                        onChange={e => {
                          setFieldValue(
                            'channels_expr_enabled',
                            e.target.checked
                          );
                          setFieldValue('channels_expr', '');
                        }}
                      />
                      <label
                        htmlFor="channels_expr_checkbox"
                        className="ml-4 text-sm text-gray-700 cursor-pointer"
                      >
                        Override Channels
                      </label>
                    </div>
                    {values.channels_expr_enabled && (
                      <div className="mt-3">
                        <TextField
                          id="channels_expr"
                          placeholder=".channel_array"
                          disabled={disabled}
                          helpText={
                            <p className="text-[#64748B] text-xs mt-1">
                              add the property key from your event call to send
                              on selected channels
                            </p>
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                {editMode && (
                  <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t">
                    <Button
                      className="flex-grow justify-center"
                      as="button"
                      onClick={() => {
                        setSelectedForm('nodes_list');
                        setSelectedNode({});
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="primary"
                      as="submit"
                      className="flex-grow justify-center ml-2"
                      disabled={updateProperties.isLoading}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

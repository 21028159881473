import { useContext, useEffect } from 'react';
import {
  BaseEdge,
  getSmoothStepPath,
  EdgeLabelRenderer,
  useNodes,
  useEdges,
  useReactFlow,
} from 'reactflow';
import { cloneDeep } from 'lodash';
import { classNames } from 'utils';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { useCreateWorkflowNode, useModifyWorkflowNode } from 'apis';
import { FlowContext } from './WorkflowDetails';
import { getNodeStatus } from './Executions/NodeStatus';

const executedStatus = ['completed', 'partial_failure', 'failed'];

const getDefaultPayload = nodeType => {
  let payload;
  switch (nodeType) {
    case 'delay':
      payload = {
        delay_type: 'fixed',
        value: '0d0h0m0s',
      };
      break;
    case 'batch':
      payload = {
        window_type: 'fixed',
        fixed_window: '0d0h0m0s',
        retain_order: 'first',
        retain_count: 10,
      };
      break;
    case 'send_multi_channel':
      payload = {
        success_is_event: false,
        success: 'seen',
        channels: [],
      };
      break;
    case 'send_smart_channel_routing':
      payload = {
        success_is_event: false,
        success: 'seen',
        routing_basis: 'cost_low_to_high',
        ttl_value: '0d0h0m0s',
      };
      break;
    case 'send_email':
    case 'send_sms':
    case 'send_whatsapp':
    case 'send_inbox':
    case 'send_mobile_push':
    case 'send_webpush':
    case 'send_slack':
    case 'send_ms_teams':
      payload = {
        success_is_event: false,
        success: 'seen',
      };
      break;
    case 'httpapi_webhook':
      payload = { http_method: 'POST' };
      break;
    case 'httpapi_fetch':
      payload = { http_method: 'GET' };
      break;
    default:
      break;
  }
  return payload;
};

function getCustomEdgeHighlight({ target, source, nodesObj, wfExecutionData }) {
  let highlight = false;
  const targetData = nodesObj[target];
  const sourceData = nodesObj[source];

  if (targetData?.type === 'exit') {
    const wfEndData = wfExecutionData?.find({ logtype: 'workflow_end' });
    highlight = wfEndData.count() > 0;
  } else {
    if (sourceData.type === 'condition_node') {
      const branchNodeData = wfExecutionData?.find({
        node_id: sourceData?.data?.parent_node_id,
        'other_info_map.logsubtype': 'branch_finalized',
      });
      const branchDataString = branchNodeData?.first()?.data?.[0]?.data;

      if (branchDataString) {
        const data = JSON.parse(branchDataString);
        highlight = data?.branch_id === source;
      }
    } else {
      let targetStatus = getNodeStatus({
        logs: wfExecutionData,
        node_id: target,
      });

      highlight = executedStatus.includes(targetStatus?.nodeStatus);
    }
  }
  return highlight;
}

function getBranchingEdgeHighlight({ wfExecutionData, source, target }) {
  let highlight = false;

  const branchNodeData = wfExecutionData?.find({
    node_id: source,
    'other_info_map.logsubtype': 'branch_finalized',
  });

  const branchDataString = branchNodeData?.first()?.data?.[0]?.data;

  if (branchDataString) {
    const data = JSON.parse(branchDataString);
    highlight = data?.branch_id === target;
  }

  return highlight;
}

function getJoiningEdgeBranchingHighlight({
  joinNodeId,
  wfExecutionData,
  nodes,
}) {
  const branchNodeData = wfExecutionData?.find({
    node_id: joinNodeId,
    'other_info_map.logsubtype': 'branch_finalized',
  });
  const branchDataString = branchNodeData?.first()?.data?.[0]?.data;

  if (!branchDataString) return;
  const data = JSON.parse(branchDataString);
  const nodeData = nodes.find(node => node.id === joinNodeId);
  const finalisedBranchData = nodeData?.data?.branches?.find(
    item => item.id === data?.branch_id
  );
  const finalizedBranchNodes = finalisedBranchData?.nodes;
  if (!finalizedBranchNodes || finalizedBranchNodes?.length < 1) {
    return true; // if no internal branch nodes are present and node is finalised then we assume its succesfully executed
  }

  const lastNode = finalizedBranchNodes[finalizedBranchNodes.length - 1];
  if (['branch', 'multibranch'].includes(lastNode.node_base_type)) {
    return getJoiningEdgeBranchingHighlight({
      joinNodeId: lastNode.id,
      wfExecutionData,
      nodes,
      loop: true,
    });
  } else {
    let targetStatus = getNodeStatus({
      logs: wfExecutionData,
      node_id: lastNode.id,
    });

    return executedStatus.includes(targetStatus?.nodeStatus);
  }
}

function getJoiningEdgeHighlight({ nodesObj, source, wfExecutionData, nodes }) {
  let highlight = false;
  const sourceData = nodesObj[source];

  if (sourceData.type === 'condition_node') {
    const branchNodeData = wfExecutionData?.find({
      node_id: sourceData?.data?.parent_node_id,
      'other_info_map.logsubtype': 'branch_finalized',
    });
    const branchDataString = branchNodeData?.first()?.data?.[0]?.data;

    if (branchDataString) {
      const data = JSON.parse(branchDataString);
      highlight = data?.branch_id === source;
    }
  } else if (sourceData.type === 'join_node') {
    const joinNodeId = sourceData.data.wf_id; // rename wf_id to node_id
    highlight = getJoiningEdgeBranchingHighlight({
      joinNodeId,
      wfExecutionData,
      nodes,
    });
  } else {
    let sourceStatus = getNodeStatus({
      logs: wfExecutionData,
      node_id: source,
    });

    highlight = executedStatus.includes(sourceStatus?.nodeStatus);
  }
  return highlight;
}

export function CustomEdge({
  id,
  sourcePosition,
  targetPosition,
  sourceX,
  sourceY,
  targetX,
  targetY,
  markerEnd,
  source,
  target,
}) {
  const {
    slug,
    version,
    dragStart,
    editMode,
    setLoading,
    draggingNodeId,
    nodesObj,
    isObservability,
    wfExecutionData,
  } = useContext(FlowContext);
  const nodes = useNodes();
  const edges = useEdges();
  const { getNode } = useReactFlow();

  const createNode = useCreateWorkflowNode(slug, version);
  const modifyNode = useModifyWorkflowNode(slug, version);

  const isTargetJoinNode = nodesObj[target]?.type === 'join_node';
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    nodes,
    offset: isTargetJoinNode ? -10 : 20,
  });

  const hideDropView = draggingNodeId === source || draggingNodeId === target;

  let highlight = false;
  if (isObservability) {
    highlight = getCustomEdgeHighlight({
      target,
      source,
      nodesObj,
      edges,
      wfExecutionData,
      getNode,
    });
  }

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={!isTargetJoinNode && markerEnd}
        style={{
          stroke: highlight ? '#12B76A' : null,
          strokeWidth: highlight ? 1.3 : 1,
        }}
      />
      <EdgeLabelRenderer>
        {editMode && dragStart && !hideDropView && (
          <div
            onDrop={async e => {
              const sourceData = nodesObj[source];
              const hasTriggerNode = sourceData.type === 'trigger';
              const dragAction = e.dataTransfer.getData('dragAction');
              const parentNodeId = hasTriggerNode ? null : source;
              const isFirstNodeInBranch =
                sourceData?.data?.branch_id === sourceData.id;

              try {
                setLoading(true);
                if (dragAction === 'create') {
                  const nodeType = e.dataTransfer.getData('nodeType');
                  const defaultPayload = getDefaultPayload(nodeType);
                  const payload = {
                    node_type: nodeType,
                    properties: defaultPayload,
                  };
                  if (isFirstNodeInBranch) {
                    payload.branch_id = sourceData.data.branch_id;
                    payload.parent_node_id = null;
                  } else {
                    payload.parent_node_id = parentNodeId;
                  }
                  if (sourceData.type === 'join_node') {
                    payload.parent_node_id = sourceData.data.wf_id;
                  }
                  await createNode.mutateAsync(payload);
                } else if (dragAction === 'update') {
                  const nodeId = e.dataTransfer.getData('nodeId');
                  const payload = { nodeId: nodeId };

                  if (isFirstNodeInBranch) {
                    payload.branch_id = sourceData.data.branch_id;
                    payload.parent_node_id = null;
                  } else {
                    payload.parent_node_id = parentNodeId;
                  }
                  if (sourceData.type === 'join_node') {
                    payload.parent_node_id = sourceData.data.wf_id;
                  }
                  await modifyNode.mutateAsync(payload);
                }
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            }}
            onDragOver={e => {
              e.preventDefault();
            }}
            className={classNames(
              'absolute pointer-events-auto bg-[#EBEFF5] border border-dashed border-[#AAB3BD] rounded-md w-[250px] h-[30px] nodrag nopan flex justify-center items-center'
            )}
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
          >
            <PlusCircleIcon className="h-[12px] w-[12px] text-[#AAB3BD] mr-1" />
            <div>
              <p className="text-[8px] text-[#64748B]">Drop here</p>
            </div>
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
}

export function CustomBranchingEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  source,
  target,
}) {
  const { getEdges } = useReactFlow();
  const nodes = useNodes();
  const { isObservability, wfExecutionData, setEdges } =
    useContext(FlowContext);

  const isLeft = sourceX > targetX;
  const horizontalText = isLeft
    ? `-${sourceX - targetX}`
    : `${targetX - sourceX}`;

  const path = `M ${sourceX},${sourceY} v 20 h ${horizontalText} v 20`;

  let highlight = false;
  if (isObservability) {
    highlight = getBranchingEdgeHighlight({
      wfExecutionData,
      source,
      target,
    });
  }

  // move highlighted edge to last so that other edges wont override common path
  useEffect(() => {
    if (highlight) {
      setTimeout(() => {
        const edges = getEdges();
        const currentEdgeIndex = edges.findIndex(item => item.id === id);
        const clonedValues = cloneDeep(edges);
        clonedValues.push(clonedValues.splice(currentEdgeIndex, 1)[0]); // move specific index item to last
        setEdges(clonedValues);
      }, 0);
    }
  }, [highlight, nodes]);

  return (
    <>
      <g id={id}>
        <path
          fill="none"
          d={path}
          stroke={highlight ? '#12B76A' : '#C7C7D4'}
          strokeWidth={highlight ? 1.3 : 1}
        ></path>
      </g>
    </>
  );
}

export function CustomJoiningEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
}) {
  const {
    slug,
    version,
    dragStart,
    editMode,
    setLoading,
    draggingNodeId,
    nodesObj,
    isObservability,
    wfExecutionData,
    setEdges,
  } = useContext(FlowContext);
  const { getEdges } = useReactFlow();
  const nodes = useNodes();

  const createNode = useCreateWorkflowNode(slug, version);
  const modifyNode = useModifyWorkflowNode(slug, version);

  const hideDropView = draggingNodeId === source || draggingNodeId === target;
  const isLeft = sourceX > targetX;
  let path;
  if (isLeft) {
    path = `M ${sourceX},${sourceY} v ${targetY - sourceY + 10} h ${
      targetX - sourceX
    }`;
  } else {
    path = `M ${sourceX},${sourceY} v ${targetY - sourceY + 10} h ${
      targetX - sourceX
    }`;
  }

  let highlight = false;
  if (isObservability) {
    highlight = getJoiningEdgeHighlight({
      nodesObj,
      source,
      wfExecutionData,
      nodes,
    });
  }

  // move highlighted edge to last so that other edges wont override common path
  useEffect(() => {
    if (highlight) {
      setTimeout(() => {
        const edges = getEdges();
        const currentEdgeIndex = edges.findIndex(item => item.id === id);
        const clonedValues = cloneDeep(edges);
        clonedValues.push(clonedValues.splice(currentEdgeIndex, 1)[0]); // move specific index item to last
        setEdges(clonedValues);
      }, 0);
    }
  }, [highlight, nodes]);

  return (
    <>
      <g id={id}>
        <path
          fill="none"
          d={path}
          stroke={highlight ? '#12B76A' : '#C7C7D4'}
          strokeWidth={highlight ? 1.3 : 1}
        ></path>
      </g>
      <EdgeLabelRenderer>
        {editMode && dragStart && !hideDropView && (
          <div
            onDrop={async e => {
              const sourceData = nodesObj[source];
              const hasTriggerNode = sourceData.type === 'trigger';
              const dragAction = e.dataTransfer.getData('dragAction');
              const parentNodeId = hasTriggerNode ? null : source;
              const isFirstNodeInBranch =
                sourceData?.data?.branch_id === sourceData.id;

              try {
                setLoading(true);
                if (dragAction === 'create') {
                  const nodeType = e.dataTransfer.getData('nodeType');
                  const defaultPayload = getDefaultPayload(nodeType);
                  const payload = {
                    node_type: nodeType,
                    properties: defaultPayload,
                  };
                  if (isFirstNodeInBranch) {
                    payload.branch_id = sourceData.data.branch_id;
                    payload.parent_node_id = null;
                  } else {
                    payload.parent_node_id = parentNodeId;
                  }
                  if (sourceData.type === 'join_node') {
                    payload.parent_node_id = sourceData.data.wf_id;
                  }
                  await createNode.mutateAsync(payload);
                } else if (dragAction === 'update') {
                  const nodeId = e.dataTransfer.getData('nodeId');
                  const payload = { nodeId: nodeId };
                  if (isFirstNodeInBranch) {
                    payload.branch_id = sourceData.data.branch_id;
                    payload.parent_node_id = null;
                  } else {
                    payload.parent_node_id = parentNodeId;
                  }
                  if (sourceData.type === 'join_node') {
                    payload.parent_node_id = sourceData.data.wf_id;
                  }
                  await modifyNode.mutateAsync(payload);
                }
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            }}
            onDragOver={e => {
              e.preventDefault();
            }}
            className={classNames(
              'absolute pointer-events-auto bg-[#EBEFF5] border border-dashed border-[#AAB3BD] rounded-md w-[250px] h-[30px] nodrag nopan flex justify-center items-center'
            )}
            style={{
              transform: `translate(-50%, -50%) translate(${sourceX}px,${
                sourceY + 25
              }px)`,
            }}
          >
            <PlusCircleIcon className="h-[12px] w-[12px] text-[#AAB3BD] mr-1" />
            <div>
              <p className="text-[8px] text-[#64748B]">Drop here</p>
            </div>
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
}

import { useQueryClient, useQuery, useMutation } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from '../stores/AuthStore';
import { useToastManager } from 'components/ToastManager';
import { useDebounce } from 'ahooks';
import toast from 'react-hot-toast';

const getTemplates = async ({
  page = 0,
  isActive,
  channels,
  tags,
  status,
  editedByMe,
  limit,
}) => {
  const limitCount = limit || 20;
  let offset = page * 20;
  let url = buildAPIURL(`/template/?limit=${limitCount}&offset=${offset}`);
  if (!isActive) {
    url = `${url}&is_archived=true`;
  }
  if (channels) {
    url = `${url}&has_channels_any=${channels}`;
  }
  if (tags) {
    url = `${url}&has_tag_ids_any=${tags}`;
  }
  if (status && status !== 'all') {
    url = `${url}&is_active=${status === 'published' ? true : false}`;
  }
  if (editedByMe) {
    url = `${url}&only_mine=${editedByMe}`;
  }
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTemplatesAPI({
  page = 0,
  isActive,
  channels,
  tags,
  status,
  editedByMe,
}) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/template`,
      page,
      isActive,
      channels,
      tags,
      status,
      editedByMe,
    ],
    () => getTemplates({ page, isActive, channels, tags, status, editedByMe }),
    { keepPreviousData: true }
  );
}

const getSmsHeaders = async notifCategory => {
  const url = buildAPIURL(
    `/tenant/default/vendor/sms_headers/?root_category=${notifCategory}`
  );
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useSMSHeadersAPI(notifCategory, skip = false) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/tenant/default/vendor/sms_headers`,
      notifCategory,
    ],
    () => getSmsHeaders(notifCategory),
    { keepPreviousData: true, enabled: !skip }
  );
}

function useCreateTemplate(template) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL('/template/');
  return useMutation(template => loggedInAPI.post(url, { ...template }), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/template`);
    },
    onError: error => {
      toast.error(`Error creating template : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

const getTemplateDetails = async templateslug => {
  const url = buildAPIURL(`/template/${templateslug}/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTemplateDetailAPI(templateslug, enable = true, retry = true) {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/template/${templateslug}`,
    () => getTemplateDetails(templateslug),
    {
      enabled: !!templateslug && enable,
      retry: retry,
    }
  );
}

function useSaveDraftTemplate(template, channel, lang) {
  const url = buildAPIURL(
    `/template/${template}/channel/${channel}/version/_/lang/${lang}/`
  );
  return useMutation(data => loggedInAPI.patch(url, { content: data }), {
    onError: error => {
      toast.error(`Error saving draft : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

function useSaveLanguage(templateslug, liveOnly, channelSlug) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/template/${templateslug}/`);
  return useMutation(
    data =>
      loggedInAPI.patch(url, {
        enabled_languages: data.languagesCode,
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          `${envState.currentEnv.get()}/template/${templateslug}/${channelSlug}`,
          liveOnly,
        ]);
        queryClient.invalidateQueries([
          `${envState.currentEnv.get()}/template/${templateslug}`,
        ]);
      },
      onError: error => {
        toast.error(
          `Error Enabling languages : ${error.response.data.message}`
        );
        throw new Error(error.response.data.message);
      },
    }
  );
}
function usePublishDraftTemplate(template, channel, successMessage = '') {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(
    `/template/${template}/channel/${channel}/version/_/`
  );
  return useMutation(
    data =>
      loggedInAPI.patch(url, {
        status: 'active',
        version_tag_user: data.versionTag,
        published_languages: data.languageToPublish,
      }),
    {
      onSuccess: async () => {
        //sessionStorage.removeItem('selectedVersion');
        toast.success(successMessage || `Successfully published draft`);
        await queryClient.invalidateQueries(
          `${envState.currentEnv.get()}/template/${template}/${channel}`
        );
        await queryClient.invalidateQueries(
          `${envState.currentEnv.get()}/template/${template}`
        );
      },
      onError: error => {
        toast.error(`Error publishing draft : ${error.response.data.message}`);
        throw new Error(error.response.data.message);
      },
    }
  );
}

const searchTemplates = async (query = '', isActive) => {
  let url = buildAPIURL(`/template/?search=${query}`);
  if (isActive) {
    url = `${url}&is_active=true`;
  }
  const { data } = await loggedInAPI.get(url);
  return data.results;
};

function useSearchTemplatesAPI(query = '', isActive) {
  const envState = useEnvState();
  const debouncedQuery = useDebounce(query, 500);
  return useQuery(
    `${envState.currentEnv.get()}/template/?search=${debouncedQuery}`,
    () => searchTemplates(debouncedQuery, isActive)
  );
}

const searchTemplatesWithEnv = async (query = '', env) => {
  const url = buildAPIURL(`/template/?search=${query}`, env);
  const { data } = await loggedInAPI.get(url);
  return data.results;
};

function useSearchTemplatesAPIWithEnv(query = '', env = '') {
  const debouncedQuery = useDebounce(query, 500);
  return useQuery(`${env}/template/?search=${debouncedQuery}`, () =>
    searchTemplatesWithEnv(debouncedQuery, env)
  );
}

const getTemplateVariables = async tplgroup => {
  const url = buildAPIURL(`/template/${tplgroup}/sample_data/`);
  const { data } = await loggedInAPI.get(url);
  return data || {};
};

function useTemplatesVariablesAPI(tplgroup, config = {}) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/template/${tplgroup}/sample_data/`],
    () => getTemplateVariables(tplgroup),
    { keepPreviousData: true, ...config }
  );
}

function useSaveSampleData(tplgroup) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/template/${tplgroup}/sample_data/`);
  return useMutation(data => loggedInAPI.put(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/template/${tplgroup}/sample_data/`
      );
    },
    onError: error => {
      if (error?.response?.data?.message) {
        toast.error(
          `Error saving variables: ${error?.response?.data?.message}`
        );
      } else {
        toast.error(`Error saving variables`);
      }
      throw new Error(error);
    },
  });
}

function useCloneTemplate(tplgroup) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/template/${tplgroup}/clone/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/template`);
    },
    onError: error => {
      toast.error(`Error cloning template : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

const getChannelVendorSuggestions = async (tplgroup, selectedChannel) => {
  const url = buildAPIURL(
    `/template/${tplgroup}/channel/${selectedChannel}/vendor_suggestion/`
  );
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useChannelVendorSuggestions(tplgroup, selectedChannel) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/template/${tplgroup}/channel/${selectedChannel}/vendor_suggestion/`,
    ],
    () => getChannelVendorSuggestions(tplgroup, selectedChannel)
  );
}

function useDisableTemplate(groupSlug, channelSlug, liveOnly) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/template/${groupSlug}/channel/${channelSlug}/`);
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/template/${groupSlug}`
      );
      queryClient.invalidateQueries([
        `${envState.currentEnv.get()}/template/${groupSlug}/${channelSlug}`,
        liveOnly,
      ]);
    },
    onError: error => {
      toast.error(`Error disabling template : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

function useJsonnetRender() {
  return useMutation(
    data =>
      loggedInAPI.post(
        process.env.REACT_APP_JSONNET_API_BASE_URL + 'render/',
        data
      ),
    {
      onError: error => {
        toast.error(
          `Error rendering JSONNET : ${error?.response?.data?.message}`
        );
        throw new Error(error?.response?.data?.message);
      },
    }
  );
}

function useEditTemplateDetails(templateslug) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/template/${templateslug}/`);
  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries([
        `${envState.currentEnv.get()}/template/${templateslug}`,
      ]);
    },
    onError: error => {
      toast.error(
        `Error editing template details : ${error.response.data.message}`
      );
      throw new Error(error.response.data.message);
    },
  });
}

function useArchiveTemplate(templateSlug) {
  const url = buildAPIURL(`/template/${templateSlug}/`);
  const { addToast } = useToastManager();

  return useMutation(() => loggedInAPI.delete(url), {
    onError: error => {
      addToast({
        title: `Error in archiving template`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

const getTags = async (searchText, tags) => {
  let url = buildAPIURL(`/tag/?search=${searchText}&limit=50`);

  if (tags) {
    url = `${url}&ids=${tags}`;
  }

  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTags(searchText, tags) {
  const envState = useEnvState();
  return useQuery([`${envState.currentEnv.get()}/tag`, searchText, tags], () =>
    getTags(searchText, tags)
  );
}

const getTagsDetails = async tags => {
  let url = buildAPIURL(`/tag/?ids=${tags}&limit=50`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTagsDetails(tags) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/tag`, tags],
    () => getTagsDetails(tags),
    {
      enabled: !!tags,
    }
  );
}

function useCreateTag() {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/tag/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/tag`);
    },
    onError: error => {
      toast.error(`Error creating tag : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

const getInboxTags = async searchText => {
  let url = buildAPIURL(`/inbox_tag/?search=${searchText}&limit=50`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useInboxTags(searchText) {
  const envState = useEnvState();
  return useQuery([`${envState.currentEnv.get()}/inbox_tag`, searchText], () =>
    getInboxTags(searchText)
  );
}

export {
  useTemplatesAPI,
  getTemplates,
  useCreateTemplate,
  useTemplateDetailAPI,
  useSaveDraftTemplate,
  usePublishDraftTemplate,
  useSearchTemplatesAPI,
  useTemplatesVariablesAPI,
  useSaveSampleData,
  useSearchTemplatesAPIWithEnv,
  useCloneTemplate,
  useSMSHeadersAPI,
  useChannelVendorSuggestions,
  useDisableTemplate,
  useJsonnetRender,
  useSaveLanguage,
  useEditTemplateDetails,
  useArchiveTemplate,
  useTags,
  useCreateTag,
  useTagsDetails,
  useInboxTags,
};

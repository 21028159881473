import { useState, useContext, useMemo } from 'react';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import { useReactFlow } from 'reactflow';
import { useUpdateNodeProperties } from 'apis';
import { Button, toast, Modal, TextField as CTextField } from 'components';
import { FlowContext } from '../WorkflowDetails';
import { Label, Switch } from 'new-components';
import { NodeFormHeader, ListBox, TextField } from './Common';
import { CommonTabBar } from '../TabBar';
import { ReactComponent as WebhookNodeIcon } from 'assets/svgs/webhookNodeIcon.svg';
import { ReactComponent as FetchNodeIcon } from 'assets/svgs/fetchNodeIcon.svg';
import { ReactComponent as ExpandIcon } from 'assets/svgs/expandIcon.svg';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-json';
import { XIcon, PlusIcon } from '@heroicons/react/solid';
import * as Yup from 'yup';
import { MISSING_FIELD_ERROR } from 'utils';

const validationSchema = Yup.object({
  http_method: Yup.string().required(MISSING_FIELD_ERROR),
  url: Yup.string().required(MISSING_FIELD_ERROR),
  query_params: Yup.array().of(
    Yup.object({
      key: Yup.string().test(
        'validateQueryParams',
        'key/value is missing',
        (value, fieldData) => {
          if (value && !fieldData?.parent?.value) {
            return false;
          } else if (fieldData?.parent?.value && !value) {
            return false;
          } else {
            return true;
          }
        }
      ),
    })
  ),
  headers: Yup.array().of(
    Yup.object({
      key: Yup.string().test(
        'validateQueryParams',
        'key/value is missing',
        (value, fieldData) => {
          if (value && !fieldData?.parent?.value) {
            return false;
          } else if (fieldData?.parent?.value && !value) {
            return false;
          } else {
            return true;
          }
        }
      ),
    })
  ),
  output_key: Yup.string().test(
    'validateOutput',
    'Key cannot start with $ or ss_',
    value => {
      if (
        value &&
        (value.startsWith('$') || value.toLowerCase().startsWith('ss_'))
      ) {
        return false;
      } else {
        return true;
      }
    }
  ),
});

const WEBHOOK_METHOD_OPTIONS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
const FETCH_METHOD_OPTIONS = ['GET'];

export default function WebhookNodeForm() {
  const [showFormErrors, setShowFormErrors] = useState();
  const [openRequestModal, setRequestModal] = useState(false);
  const [URLFocus, setURLFocus] = useState(false);
  const {
    selectedNode,
    editMode,
    slug,
    version,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);
  const nodeProperties = nodeData?.data?.properties;
  const isWebhookNode = selectedNode.node_type === 'httpapi_webhook';
  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );
  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  const isParamsEmpty =
    !nodeProperties?.query_params || nodeProperties?.query_params?.length <= 0;
  const isHeadersEmpty =
    !nodeProperties?.headers || nodeProperties?.headers?.length <= 0;
  return (
    <Formik
      initialValues={{
        http_method: nodeProperties?.http_method || null,
        url: nodeProperties?.url || '',
        query_params: isParamsEmpty
          ? [{ key: '', value: '' }]
          : nodeProperties?.query_params,
        headers: isHeadersEmpty
          ? [{ key: '', value: '' }]
          : nodeProperties?.headers,
        body: nodeProperties?.body || null,
        body_content_type: 'application/json',
        output_key: nodeProperties?.output_key || '',
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { setFieldValue, resetForm }) => {
        const validParams = values.query_params.filter(
          param => param.key && param.value
        );
        const validHeaders = values.headers.filter(
          param => param.key && param.value
        );

        setFieldValue(
          'query_params',
          validParams?.length <= 0 ? [{ key: '', value: '' }] : validParams
        );
        setFieldValue(
          'headers',
          validHeaders?.length <= 0 ? [{ key: '', value: '' }] : validHeaders
        );
        const payload = {
          ...values,
          query_params: validParams,
          headers: validHeaders,
        };

        try {
          setLoading(true);
          await updateProperties.mutateAsync({ properties: payload });
          toast('Settings saved successfully', '', { autoClose: 1000 });
          setLoading(false);
          setShowFormErrors(true);
          setRequestModal(false);
          resetForm();
        } catch (e) {
          setLoading(false);
        }
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        const disabled = !editMode;
        const inputPayload = { k1: 'v1', k2: 'v2' };
        const webhookResponse = { [values?.output_key || 'k2']: 'v3' };
        const finalPayload = { ...inputPayload, ...webhookResponse };

        const examplePayload = `// Input Payload\n${JSON.stringify(
          inputPayload
        )}\n\n// Webhook Response\n${JSON.stringify(
          webhookResponse
        )}\n\n// Final Payload\n${JSON.stringify(finalPayload)}`;

        return (
          <Form className="overflow-scroll h-full">
            <div className="m-4 mt-6">
              {isWebhookNode ? (
                <NodeFormHeader
                  name="Webhook"
                  helptext="Make HTTP request to an endpoint."
                  Icon={WebhookNodeIcon}
                  docLink="https://docs.suprsend.com/docs/webhook"
                />
              ) : (
                <NodeFormHeader
                  name="Fetch"
                  helptext="Fetch data from an API endpoint."
                  Icon={FetchNodeIcon}
                  docLink="https://docs.suprsend.com/docs/fetch"
                />
              )}
              <p className="text-[#64748B] text-sm px-4 py-2 bg-[#F8FAFC] rounded mt-4">
                All fields below accept variables. Add it in JSONNET format as{' '}
                <span className="font-medium">{`data.<var>`}</span>
              </p>
              <div className="mt-5 mb-24">
                {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
                  <div className="mb-6 text-xs -mt-2 text-red-500 bg-red-50 p-3 rounded">
                    <ul>
                      {Object.keys(fieldErrors)?.map((item, index) => {
                        return (
                          <li key={index}>
                            - {item}: {fieldErrors[item]}
                          </li>
                        );
                      })}
                      {nonFieldErrors?.map((item, index) => {
                        return <li key={index}>- {item}</li>;
                      })}
                    </ul>
                  </div>
                )}
                <div className="flex justify-between items-center mb-4">
                  <p className="text-[#1E293B] font-semibold text-base">Path</p>
                  <div
                    onClick={() => {
                      setRequestModal(true);
                    }}
                    className="cursor-pointer"
                  >
                    <ExpandIcon />
                  </div>
                </div>
                <ListBox
                  label="Method"
                  id="http_method"
                  value={values.http_method}
                  displayValue={values.http_method}
                  disabled={disabled}
                  options={
                    isWebhookNode
                      ? WEBHOOK_METHOD_OPTIONS
                      : FETCH_METHOD_OPTIONS
                  }
                  getLabel={e => e}
                  getValue={e => e}
                  handleOnchange={value => {
                    setFieldValue('http_method', value);
                  }}
                />
                <div className="mt-4">
                  <TextField
                    label="Endpoint"
                    id="url"
                    disabled={disabled}
                    onFocus={() => {
                      setURLFocus(true);
                    }}
                    onBlur={() => {
                      setURLFocus(false);
                    }}
                  />
                  {URLFocus && (
                    <p className="text-[#64748B] text-xs mt-1">
                      Enclose fixed values in "" , else they will be treated as
                      variables
                    </p>
                  )}
                </div>
                <div className="mt-8">
                  <p className="text-[#1E293B] font-semibold text-base">
                    Params
                  </p>
                  <p className="text-[#64748B] text-xs mt-1">
                    Enclose fixed values in "" , else they will be treated as
                    variables
                  </p>
                  <AddItems
                    value={values?.query_params}
                    fieldNameKey="query_params"
                    buttonName="Add Param"
                    disabled={disabled}
                  />
                </div>
                <div className="mt-8">
                  <p className="text-[#1E293B] font-semibold text-base">
                    Headers
                  </p>
                  <p className="text-[#64748B] text-xs mt-1">
                    Enclose fixed values in "" , else they will be treated as
                    variables
                  </p>
                  <AddItems
                    value={values?.headers}
                    fieldNameKey="headers"
                    buttonName="Add Header"
                    disabled={disabled}
                  />
                </div>

                {isWebhookNode && values?.http_method !== 'GET' && (
                  <div className="mt-8 border-b pb-8">
                    <p className="text-[#1E293B] font-semibold text-base">
                      Body
                    </p>
                    <AceEditor
                      name="body_code"
                      mode="json"
                      setOptions={{ useWorker: false }}
                      theme="github"
                      width="100%"
                      height="175px"
                      editorProps={{ $blockScrolling: true }}
                      fontSize={12}
                      tabSize={2}
                      className="border rounded mt-2"
                      value={values?.body}
                      onChange={value => {
                        setFieldValue('body', value);
                      }}
                      highlightActiveLine={false}
                      readOnly={disabled}
                    />
                  </div>
                )}
                <p className="text-[#1E293B] font-semibold text-base mb-4 mt-8">
                  Webhook Response
                </p>
                <div className="flex items-center gap-5">
                  <Label
                    className="text-gray-700"
                    htmlFor="webhook-response-switch"
                  >
                    Merge with input payload
                  </Label>
                  <Switch
                    checked={true}
                    disabled
                    id="webhook-response-switch"
                  />
                </div>
                <div className="mt-2 mb-4">
                  <TextField
                    label="Response key"
                    id="output_key"
                    disabled={disabled}
                    mandatory={false}
                  />
                  <p className="text-[#64748B] text-xs mt-1">
                    If empty, response will be merged at parent level else it
                    will be attached to this key and merged.
                  </p>
                </div>
                <Label className="text-gray-700 text-xs">Example Payload</Label>
                <AceEditor
                  name="response_key_example_code"
                  mode="javascript"
                  setOptions={{ useWorker: false }}
                  theme="github"
                  width="100%"
                  height="150px"
                  editorProps={{ $blockScrolling: true }}
                  fontSize={12}
                  className="border rounded"
                  value={examplePayload}
                  readOnly
                  highlightActiveLine={false}
                />
              </div>
              {editMode && (
                <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t z-50">
                  <Button
                    className="flex-grow justify-center"
                    as="button"
                    onClick={() => {
                      setSelectedForm('nodes_list');
                      setSelectedNode({});
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    as="submit"
                    type="primary"
                    className="flex-grow justify-center ml-2"
                    disabled={updateProperties.isLoading}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
            <RequestModal
              setOpen={setRequestModal}
              open={openRequestModal}
              values={values}
              setFieldValue={setFieldValue}
              disabled={disabled}
              editMode={editMode}
              updateProperties={updateProperties}
              submitForm={submitForm}
              isWebhookNode={isWebhookNode}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

function RequestModal({
  setOpen,
  open,
  values,
  setFieldValue,
  disabled,
  editMode,
  updateProperties,
  submitForm,
  isWebhookNode,
}) {
  const [selectedTab, setSelectedTab] = useState('params');

  const tabOptions = useMemo(() => {
    const data = [
      {
        id: 'params',
        name: 'Params',
        onClick: () => {
          setSelectedTab('params');
        },
      },
      {
        id: 'header',
        name: 'Headers',
        onClick: () => {
          setSelectedTab('header');
        },
      },
      {
        id: 'body',
        name: 'Body',
        onClick: () => {
          setSelectedTab('body');
        },
      },
    ];
    if (isWebhookNode) {
      if (values.http_method === 'GET') {
        data.pop();
        return data;
      }
      return data;
    } else {
      data.pop();
      return data;
    }
  }, [isWebhookNode, values?.http_method]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="max-w-[50rem] inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
        <div className="mt-3 text-center sm:mt-0 sm:text-left p-6">
          <p className="text-lg leading-6 font-medium text-gray-900">
            {isWebhookNode ? 'Webhook Request' : 'Fetch Request'}
          </p>
          <p className="text-[#64748B] text-xs mt-1 mb-8">
            Enclose fixed values in "" , else they will be treated as variables
          </p>

          <div className="mb-4">
            <CTextField
              name="url"
              inputClassName="h-9 p-[4px] mt-1 focus:outline-none rounded-l-none"
              inputContainerClassName="flex items-center"
              disabled={disabled}
              leftComponent={() => {
                return (
                  <ListBox
                    id="http_method"
                    mandatory={false}
                    value={values.http_method}
                    displayValue={values.http_method}
                    disabled={disabled}
                    options={
                      isWebhookNode
                        ? WEBHOOK_METHOD_OPTIONS
                        : FETCH_METHOD_OPTIONS
                    }
                    getLabel={e => e}
                    getValue={e => e}
                    handleOnchange={value => {
                      setFieldValue('http_method', value);
                      if (value === 'GET' && selectedTab === 'body') {
                        setSelectedTab('params');
                      }
                    }}
                    menuButtonClassName="rounded-r-none w-24 border border-r-0"
                  />
                );
              }}
            />
          </div>
          <CommonTabBar
            selectedId={selectedTab}
            tabClassName="px-1 pb-1 mr-4"
            options={tabOptions}
          />
          <div className="mt-4">
            {selectedTab === 'params' && (
              <div className="mt-8">
                <AddItems
                  value={values?.query_params}
                  fieldNameKey="query_params"
                  buttonName="Add Param"
                  disabled={disabled}
                />
              </div>
            )}
            {selectedTab === 'header' && (
              <div className="mt-8">
                <AddItems
                  value={values?.headers}
                  fieldNameKey="headers"
                  buttonName="Add Header"
                  disabled={disabled}
                />
              </div>
            )}
            {isWebhookNode && selectedTab === 'body' && (
              <>
                <AceEditor
                  name="body_code_modal"
                  mode="json"
                  setOptions={{ useWorker: false }}
                  theme="github"
                  width="100%"
                  height="200px"
                  editorProps={{ $blockScrolling: true }}
                  fontSize={12}
                  tabSize={2}
                  className="border rounded mt-2"
                  value={values?.body}
                  onChange={value => {
                    setFieldValue('body', value);
                  }}
                  highlightActiveLine={false}
                  showPrintMargin={false}
                  readOnly={disabled}
                />
                <p className="text-muted-foreground text-sm mt-1">
                  Add content in{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                    href="https://jsonnet.org/ref/language.html"
                  >
                    JSONNET
                  </a>{' '}
                  format
                </p>
              </>
            )}
          </div>
          {editMode && (
            <div className="flex mt-4 justify-end">
              <Button
                as="button"
                className="w-24 justify-center"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                onClick={async () => {
                  submitForm();
                }}
                as="button"
                type="primary"
                className="ml-2 w-24 justify-center"
                disabled={updateProperties.isLoading}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

function AddItems({ value, fieldNameKey, buttonName, disabled }) {
  return (
    <div>
      <FieldArray
        name={fieldNameKey}
        render={arrayHelpers => {
          return (
            <div>
              {value?.map((_, index) => {
                return (
                  <div key={index}>
                    <div className="flex gap-3 items-center flex-row mb-2 mt-4">
                      <TextField
                        id={`${fieldNameKey}.${index}.key`}
                        containerClassName="grow"
                        placeholder="key"
                        showError={false}
                        disabled={disabled}
                      />
                      <TextField
                        id={`${fieldNameKey}.${index}.value`}
                        containerClassName="grow"
                        placeholder="value"
                        showError={false}
                        disabled={disabled}
                      />
                      {!disabled && (
                        <p
                          onClick={() => {
                            if (value?.length === 1 && index === 0) {
                              arrayHelpers.remove(index);
                              arrayHelpers.push({
                                key: '',
                                value: '',
                              });
                            } else {
                              arrayHelpers.remove(index);
                            }
                          }}
                        >
                          <XIcon className="h-4 w-4 text-gray-700 cursor-pointer" />
                        </p>
                      )}
                    </div>
                    <ErrorMessage
                      name={`${fieldNameKey}.${index}.key`}
                      render={msg => (
                        <p className="text-red-500 text-sm">{msg}</p>
                      )}
                    />
                  </div>
                );
              })}
              {!disabled && (
                <Button
                  className="flex-grow justify-center px-6 pt-1 pb-1 mt-3"
                  as="button"
                  onClick={() => {
                    arrayHelpers.push({ key: '', value: '' });
                  }}
                >
                  <PlusIcon className="h-4 w-4 text-gray-700 mr-1" />{' '}
                  {buttonName}
                </Button>
              )}
            </div>
          );
        }}
      />
    </div>
  );
}

import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Transition, Disclosure } from '@headlessui/react';
import { EnvNavLink } from 'components';
import SuprSendLogo from 'assets/pngs/suprsendLogo.png';
import { classNames } from 'utils';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { ReactComponent as WorkflowIcon } from 'assets/svgs/sidebarWorkflowIcon.svg';
import {
  FilePen,
  AlignJustify,
  Users,
  BookUser,
  Building2,
  Database,
  SquareTerminal,
  Puzzle,
  Home,
} from 'lucide-react';

const navigation = [
  {
    name: 'Dashboard',
    href: '/',
    icon: Home,
  },
  {
    name: 'Workflows',
    href: '/workflows',
    icon: WorkflowIcon,
  },
  {
    name: 'Templates',
    href: '/templates',
    icon: FilePen,
  },
  {
    name: 'Logs',
    href: '/logs/api',
    icon: AlignJustify,
  },

  {
    name: 'Subscribers',
    href: '/subscribers',
    icon: Users,
  },
  {
    name: 'Lists',
    href: '/lists',
    icon: BookUser,
  },
  {
    name: 'Tenants',
    href: '/brands',
    icon: Building2,
  },
  {
    name: 'Vendors',
    href: '/vendors',
    icon: Puzzle,
  },
  {
    name: 'Connectors',
    href: '/connectors',
    icon: Database,
  },
  {
    name: 'Developers',
    href: '/developers',
    icon: SquareTerminal,
    subNavigation: [
      {
        name: 'API Keys',
        href: '/developers/api-keys',
      },
      {
        name: 'Webhooks',
        href: '/developers/webhooks',
      },
      {
        name: 'Preference Categories',
        href: '/developers/preference-categories',
      },
    ],
  },
];

const SideBar = ({ currentEnv }) => {
  let { url } = useRouteMatch();
  const splitURL = url.split('/');
  const pageSlug = splitURL[3];
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={classNames(
        'flex flex-col fixed h-screen z-20 bg-[#1D2635] transition-all duration-200',
        expanded ? 'w-[205px]' : 'w-[60px]'
      )}
      onMouseEnter={() => {
        setExpanded(true);
      }}
      onMouseLeave={() => {
        setExpanded(false);
      }}
    >
      <div className="flex items-center mt-3 ml-3 mb-5 mr-0">
        <img className="h-10" src={SuprSendLogo} alt="SuprSend" />
        <p
          className={classNames(
            'text-xl text-white font-medium',
            !expanded && 'hidden'
          )}
        >
          SuprSend
        </p>
      </div>
      <div className="flex flex-col overflow-y-auto no-scrollbar space-y-1 overflow-x-hidden">
        {navigation.map(item => {
          if (!item?.subNavigation) {
            return (
              <EnvNavLink
                key={item.name}
                currentEnv={currentEnv?.[0]}
                isRenderProp={true}
                exact
                to={item.href}
                activeClassName="bg-[#63ABF729] border-l-4 border-[#63ABF7] mx-2"
                className={classNames(
                  'flex items-center py-2.5 hover:bg-[#63ABF729] pr-3 rounded mx-2'
                )}
              >
                {isActive => {
                  return (
                    <div
                      className={classNames(
                        isActive && '-ml-1',
                        'flex items-center'
                      )}
                    >
                      <item.icon
                        className={classNames('h-5 w-5 text-white ml-3')}
                      />
                      <p
                        className={classNames(
                          'text-xs font-medium text-white mt-0.5 mx-3',
                          !expanded && 'hidden'
                        )}
                      >
                        {item.name}
                      </p>
                    </div>
                  );
                }}
              </EnvNavLink>
            );
          }

          const isActive = pageSlug && item?.href?.includes(pageSlug);
          return (
            <Disclosure
              as="div"
              defaultOpen={isActive}
              className={classNames('mx-2', expanded && 'w-[205px]')}
              key={item.name}
            >
              {({ open }) => {
                return (
                  <>
                    <Disclosure.Button
                      className={classNames('w-full', expanded && 'pr-4')}
                    >
                      <div
                        className={classNames(
                          'py-2.5 w-full flex items-center hover:bg-[#63ABF729] rounded',
                          isActive &&
                            'border-l-4 border-[#63ABF7] bg-[#63ABF729] ml-[-1px]'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            'h-5 w-5 text-[#E8E8E8]',
                            isActive ? 'ml-[9px]' : 'ml-3'
                          )}
                        />
                        <div
                          className={classNames(
                            'flex items-center justify-between grow pr-2',
                            !expanded && 'hidden'
                          )}
                        >
                          <p className="text-xs font-medium text-[#E8E8E8] pt-0.5 mx-3">
                            {item.name}
                          </p>
                          <ChevronDownIcon
                            className={classNames(
                              open && 'rotate-180',
                              'h-5 w-5 shrink-0 text-[#E8E8E8] pt-[3px] duration-300'
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </Disclosure.Button>
                    <Transition
                      className="transition-all duration-300 overflow-hidden"
                      enterFrom="transform scale-95 max-h-0"
                      enterTo="transform scale-100 max-h-96"
                      leaveFrom="transform scale-100 max-h-96"
                      leaveTo="transform scale-95 max-h-0"
                    >
                      {expanded && (
                        <Disclosure.Panel
                          as="ul"
                          className="mt-1 pl-[9px] pr-4"
                        >
                          {item.subNavigation.map(subItem => (
                            <li key={subItem.name}>
                              <EnvNavLink
                                key={subItem.name}
                                currentEnv={currentEnv[0]}
                                exact
                                to={subItem.href}
                                activeClassName="bg-[#63ABF729] ml-6"
                                className={classNames(
                                  'flex items-center py-2.5 hover:bg-[#63ABF729] rounded ml-6 mt-0.5'
                                )}
                              >
                                <div
                                  className={classNames('flex items-center')}
                                >
                                  {expanded && (
                                    <p
                                      className={classNames(
                                        'text-xs font-medium transition-transform text-[#E8E8E8] mt-0.5 pl-3'
                                      )}
                                    >
                                      {subItem.name}
                                    </p>
                                  )}
                                </div>
                              </EnvNavLink>
                            </li>
                          ))}
                        </Disclosure.Panel>
                      )}
                    </Transition>
                  </>
                );
              }}
            </Disclosure>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;

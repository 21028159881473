import { useContext, useEffect, useState, useMemo } from 'react';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
  Label,
  Input,
  Button,
} from 'new-components';
import { Formik, Form } from 'formik';
import { Spinner, toast } from 'components';
import { Plus, X } from 'lucide-react';
import { useUpdateBranch, useUpdateWFVersion } from 'apis';
import { cloneDeep } from 'lodash';
import { FlowContext } from './WorkflowDetails';
import { cn, MISSING_FIELD_ERROR } from 'utils';
import * as Yup from 'yup';

const DATA_TYPE_OPTIONS = [
  { name: 'Input Payload', value: 'data' },
  { name: 'Brand', value: '$brand' },
  { name: 'Recipient', value: '$recipient' },
  { name: 'Actor', value: '$actor' },
];

const OPERATOR_OPTIONS = [
  { name: '==', value: '==' },
  { name: '!=', value: '!=' },
  { name: '>', value: '>' },
  { name: '>=', value: '>=' },
  { name: '<', value: '<' },
  { name: '<=', value: '<=' },
  { name: 'contains', value: 'CONTAINS' },
  { name: 'not contains', value: 'NOT_CONTAINS' },
  { name: 'is empty', value: 'EMPTY' },
  { name: 'is not empty', value: 'NON_EMPTY' },
];

const conditionValidationSchema = Yup.object({
  variable: Yup.string().required(MISSING_FIELD_ERROR),
  op: Yup.string().required(MISSING_FIELD_ERROR),
  value: Yup.string().test(
    'validateQueryParams',
    MISSING_FIELD_ERROR,
    (value, fieldData) => {
      const parentObj = fieldData?.parent;
      if (!['EMPTY', 'NON_EMPTY'].includes(parentObj.op) && !value) {
        return false;
      } else {
        return true;
      }
    }
  ),
});

function ConditionsForm({
  innerConditionData,
  handleCancel,
  handleSave,
  updateAPI,
}) {
  return (
    <Formik
      initialValues={{
        variable_ns: innerConditionData?.variable_ns || '',
        variable: innerConditionData?.variable || '',
        op: innerConditionData?.op || '',
        value: innerConditionData?.value || '',
      }}
      enableReinitialize
      validateOnMount
      validationSchema={conditionValidationSchema}
      onSubmit={values => {
        const clonedValues = { ...values };
        if (clonedValues.variable_ns === 'data') {
          clonedValues.variable_ns = '';
        }
        handleSave(clonedValues);
      }}
    >
      {({ values, setFieldValue, isValid }) => {
        return (
          <Form className="border border-dashed border-muted-foreground/50 rounded p-4">
            <div>
              <Label>Data Type</Label>
              <Select
                onValueChange={value => {
                  setFieldValue('variable_ns', value);
                }}
                value={values.variable_ns || 'data'}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {DATA_TYPE_OPTIONS.map(option => (
                      <SelectItem value={option.value} key={option.value}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="mt-4">
              <Label>Property</Label>
              <Input
                placeholder="key"
                value={values.variable}
                onChange={e => {
                  setFieldValue('variable', e.target.value);
                }}
              />
            </div>
            <div className="mt-4">
              <Label>Operator</Label>
              <Select
                onValueChange={value => {
                  setFieldValue('op', value);
                }}
                value={values.op}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {OPERATOR_OPTIONS.map(option => (
                      <SelectItem value={option.value} key={option.value}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            {!['EMPTY', 'NON_EMPTY'].includes(values.op) && (
              <div className="mt-4">
                <Label>Value</Label>
                <Input
                  value={values.value}
                  placeholder='"value"'
                  onChange={e => {
                    setFieldValue('value', e.target.value);
                  }}
                />
              </div>
            )}
            <div className="flex gap-4 mt-6">
              <Button
                className="py-0 h-7"
                variant="outline"
                onClick={handleCancel}
                type="button"
              >
                Cancel
              </Button>
              <Button
                className="py-0 h-7"
                type="submit"
                disabled={updateAPI.isLoading || !isValid}
              >
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default function Conditions({
  conditions,
  id, // this is dynamic (if isBranchCondition then id=branchID else if isWorkflowCondition then id=wfID else if isNodeCondition then id=nodeID )
  isBranchCondition,
  isWorkflowCondition,
}) {
  const { slug, version, setLoading, editMode } = useContext(FlowContext);

  const [conditionsData, setConditions] = useState();

  const updateBranch = useUpdateBranch(slug, version, id);
  const updateWFVersion = useUpdateWFVersion(slug, version);
  let updateAPI;
  if (isBranchCondition) {
    updateAPI = updateBranch;
  } else if (isWorkflowCondition) {
    updateAPI = updateWFVersion;
  }

  useEffect(() => {
    setConditions(conditions || []);
  }, [conditions]);

  const handleAddCondition = () => {
    setConditions([
      {
        type: 'expression_v1',
        expression_v1: {
          op: 'AND',
          args: [
            {
              is_form: true,
              is_form_new: true,
              variable_ns: '',
              variable: '',
              op: '',
              value: '',
            },
          ],
        },
      },
    ]);
  };

  const handleFormCancel = ({
    innerCondition,
    innerConditions,
    innerIndex,
    outerIndex,
  }) => {
    if (innerCondition.is_form_new) {
      innerConditions.splice(innerIndex, 1);
    } else {
      innerCondition.is_form = false;
      innerCondition.is_form_new = false;
    }
    // remove totally if inner conditions are empty
    if (!(innerConditions.length > 0)) {
      conditionsData.splice(outerIndex, 1);
    }
    setConditions([...conditionsData]);
  };

  const handleFormSave = async ({ outerIndex, innerIndex, newData }) => {
    const clonedConditions = cloneDeep(conditionsData);
    const clonedInnerConditionsData = clonedConditions[outerIndex];
    const clonedInnerConditions =
      clonedInnerConditionsData[clonedInnerConditionsData.type].args;
    clonedInnerConditions[innerIndex] = newData;

    setLoading(true);
    await updateAPI.mutateAsync({ conditions: clonedConditions });
    setLoading(false);
    toast('Condition saved successfully', '', { autoClose: 1000 });
  };

  const handleAddAnd = ({ innerConditions }) => {
    innerConditions.push({
      is_form: true,
      is_form_new: true,
      variable_ns: '',
      variable: '',
      op: '',
      value: '',
    });
    setConditions([...conditionsData]);
  };

  const handleAddOR = () => {
    conditionsData.push({
      type: 'expression_v1',
      expression_v1: {
        op: 'AND',
        args: [
          {
            is_form: true,
            is_form_new: true,
            variable_ns: '',
            variable: '',
            op: '',
            value: '',
          },
        ],
      },
    });
    setConditions([...conditionsData]);
  };

  const handleConditionRemove = async ({ outerIndex, innerIndex }) => {
    const clonedConditions = cloneDeep(conditionsData);
    const clonedInnerConditionsData = clonedConditions[outerIndex];
    const clonedInnerConditions =
      clonedInnerConditionsData[clonedInnerConditionsData.type].args;

    clonedInnerConditions.splice(innerIndex, 1);

    // remove totally if inner conditions are empty
    if (!(clonedInnerConditions.length > 0)) {
      clonedConditions.splice(outerIndex, 1);
    }

    setLoading(true);
    await updateAPI.mutateAsync({
      conditions: clonedConditions,
    });
    setLoading(false);
    toast('Condition removed successfully', '', { autoClose: 1000 });
  };

  const isFormActive = useMemo(() => {
    let formActive = false;
    conditionsData?.map(condition => {
      const innerConditions = condition[condition.type].args;
      innerConditions?.map(innerCondition => {
        if (innerCondition.is_form) {
          formActive = true;
        }
      });
    });
    return formActive;
  }, [conditionsData]);

  if (!conditionsData) {
    return <Spinner />;
  } else if (conditionsData?.length <= 0) {
    return (
      <div className="mt-1">
        <Button
          className="px-2 my-3 h-7"
          variant="outline"
          disabled={!editMode}
          onClick={handleAddCondition}
        >
          <Plus className="h-4 w-4 mr-2 text-accent-foreground" />
          <p className="text-accent-foreground">Add Condition</p>
        </Button>
      </div>
    );
  }

  const showCTA = editMode && !isFormActive;

  return (
    <div className="mt-4">
      {conditionsData.map((condition, outerIndex) => {
        const innerConditionData = condition[condition.type];
        const innerConditions = innerConditionData.args || [];
        const isLast = conditionsData.length === outerIndex + 1;

        return (
          <div className="my-2" key={outerIndex}>
            {innerConditions.map((innerCondition, innerIndex) => {
              const isLast = innerConditions.length === innerIndex + 1;
              const operationLabel = OPERATOR_OPTIONS.find(
                operation => operation.value === innerCondition.op
              );
              return (
                <div className="border-l pl-2" key={innerIndex}>
                  {innerCondition.is_form ? (
                    <ConditionsForm
                      innerConditionData={innerCondition}
                      updateAPI={updateAPI}
                      handleSave={async newData => {
                        handleFormSave({ outerIndex, innerIndex, newData });
                      }}
                      handleCancel={() => {
                        handleFormCancel({
                          innerCondition,
                          innerConditions,
                          innerIndex,
                          outerIndex,
                        });
                      }}
                    />
                  ) : (
                    <div className="flex items-center gap-2">
                      <code
                        className={cn(
                          'border border-dashed border-muted-foreground/50 rounded block bg-muted p-2 text-sm cursor-pointer grow text-accent-foreground',
                          !showCTA && 'cursor-default'
                        )}
                        onClick={() => {
                          if (!showCTA) return;

                          innerCondition.is_form = true;
                          innerCondition.is_form_new = false;
                          setConditions([...conditionsData]);
                        }}
                      >
                        {innerCondition.variable_ns &&
                          `${innerCondition.variable_ns}.`}
                        {innerCondition.variable} {operationLabel?.name}{' '}
                        {innerCondition.value}
                      </code>
                      {showCTA && (
                        <X
                          className={cn(
                            'h-4 w-4 cursor-pointer text-muted-foreground',
                            updateAPI.isLoading &&
                              'cursor-not-allowed text-border'
                          )}
                          onClick={async () => {
                            if (updateAPI.isLoading) return;
                            handleConditionRemove({ innerIndex, outerIndex });
                          }}
                        />
                      )}
                    </div>
                  )}
                  {isLast ? (
                    <>
                      {editMode && (
                        <Button
                          className="px-1.5 my-2 h-6 flex gap-1"
                          variant="outline"
                          disabled={!showCTA}
                          onClick={() => {
                            handleAddAnd({ innerConditions });
                          }}
                        >
                          <Plus className="h-4 w-4 text-accent-foreground" />{' '}
                          <p className="text-xs text-accent-foreground">AND</p>
                        </Button>
                      )}
                    </>
                  ) : (
                    <p className="text-sm py-2 pl-2 text-muted-foreground font-medium">
                      {innerConditionData.op}
                    </p>
                  )}
                </div>
              );
            })}
            {isLast ? (
              <>
                {editMode && (
                  <Button
                    className="px-1.5 my-2 h-6 flex gap-1"
                    variant="outline"
                    disabled={!showCTA}
                    onClick={handleAddOR}
                  >
                    <Plus className="h-4 w-4 text-accent-foreground" />{' '}
                    <p className="text-xs text-accent-foreground">OR</p>
                  </Button>
                )}
              </>
            ) : (
              <p className="text-sm my-2 text-muted-foreground font-medium">
                OR
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
}

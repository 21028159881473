import { useContext, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { FlowContext } from '../WorkflowDetails';
import { NodeFormHeader, DurationInput, TextField, ListBox } from './Common';
import { ReactComponent as BatchingNodeIcon } from 'assets/svgs/batchingNodeIcon.svg';
import { Button, toast } from 'components';
import { useUpdateNodeProperties } from 'apis';
import * as Yup from 'yup';
import { MISSING_FIELD_ERROR } from 'utils';
import { useReactFlow } from 'reactflow';

const WINDOW_TYPE = [
  {
    name: 'Fixed',
    value: 'fixed',
  },
  {
    name: 'Dynamic',
    value: 'dynamic',
  },
];

const RETAIN_BATCH_TYPE = [
  {
    name: 'First',
    value: 'first',
  },
  {
    name: 'Last',
    value: 'last',
  },
];

const BatchNodeValidationSchema = Yup.object({
  window_type: Yup.string().required(MISSING_FIELD_ERROR),
  fixed_window: Yup.string().test(
    'validateFixedWindow',
    MISSING_FIELD_ERROR,
    (value, fieldData) => {
      const parentData = fieldData.parent;
      if (parentData.window_type === 'fixed') {
        if (!value) return false;
        const comps = value.match(/[a-z]+|[^a-z]+/gi);
        return (
          parseInt(comps[0]) ||
          parseInt(comps[2]) ||
          parseInt(comps[4]) ||
          parseInt(comps[6])
        );
      } else {
        return true;
      }
    }
  ),
  dynamic_window_expr: Yup.string().test(
    'validateWindowExpr',
    MISSING_FIELD_ERROR,
    (value, fieldData) => {
      const parentData = fieldData.parent;
      return parentData.window_type === 'dynamic' ? !!value : true;
    }
  ),
  retain_order: Yup.string().required(MISSING_FIELD_ERROR),
  retain_count: Yup.number()
    .min(1, 'Minimum count should be 1')
    .max(100, 'Maximum allowed count is 100')
    .required(MISSING_FIELD_ERROR),
});

export default function BatchNodeForm() {
  const [showFormErrors, setShowFormErrors] = useState();
  const {
    selectedNode,
    editMode,
    slug,
    version,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const nodeProperties = selectedNode?.properties || {};
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);

  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );

  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  return (
    <Formik
      initialValues={{
        window_type: nodeProperties?.window_type || 'fixed',
        fixed_window: nodeProperties?.fixed_window || '0d0h0m0s',
        dynamic_window_expr: nodeProperties?.dynamic_window_expr || '',
        batch_key: nodeProperties?.batch_key || '',
        retain_order: nodeProperties?.retain_order || 'first',
        retain_count: nodeProperties?.retain_count || 10,
      }}
      validationSchema={BatchNodeValidationSchema}
      enableReinitialize={true}
      onSubmit={async values => {
        try {
          setLoading(true);
          await updateProperties.mutateAsync({ properties: values });
          toast('Settings saved successfully', '', { autoClose: 1000 });
          setLoading(false);
          setShowFormErrors(true);
        } catch (e) {
          setLoading(false);
        }
      }}
    >
      {({ values, setFieldValue }) => {
        const selectedWindowType = WINDOW_TYPE.find(
          item => item.value === values.window_type
        );
        const selectedRetainBatchType = RETAIN_BATCH_TYPE.find(
          item => item.value === values.retain_order
        );
        const disabled = !editMode;

        return (
          <Form className="overflow-scroll h-full">
            <div className="m-4 mt-6">
              <NodeFormHeader
                name="Batch"
                helptext="Batch events for a duration to send consolidated notifications"
                Icon={BatchingNodeIcon}
                docLink="https://docs.suprsend.com/docs/batch"
              />
              <div className="mt-5 mb-24">
                {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
                  <div className="mb-6 text-xs -mt-2 text-red-500 bg-red-50 p-3 rounded">
                    <ul>
                      {Object.keys(fieldErrors)?.map((item, index) => {
                        return (
                          <li key={index}>
                            - {item}: {fieldErrors[item]}
                          </li>
                        );
                      })}
                      {nonFieldErrors?.map((item, index) => {
                        return <li key={index}>- {item}</li>;
                      })}
                    </ul>
                  </div>
                )}
                <p className="text-[#1E293B] font-semibold text-base mb-4">
                  Batch window
                </p>
                <div>
                  <ListBox
                    label="Type"
                    id="window_type"
                    value={values.window_type}
                    disabled={disabled}
                    handleOnchange={value => {
                      if (values.window_type !== value) {
                        setFieldValue('window_type', value);
                        setFieldValue('fixed_window', '');
                        setFieldValue('dynamic_window_expr', '');
                      }
                    }}
                    displayValue={selectedWindowType?.name}
                    options={WINDOW_TYPE}
                    getLabel={e => {
                      return e.name;
                    }}
                    getValue={e => {
                      return e.value;
                    }}
                  />
                  <div className="mt-4 border-b pb-6">
                    {values.window_type === 'fixed' ? (
                      <div>
                        <label
                          className="block text-sm text-gray-700 my-2 font-medium"
                          htmlFor="fixed_window"
                        >
                          Value<span className="text-red-500">*</span>
                        </label>
                        <DurationInput
                          value={values.fixed_window}
                          setValue={value => {
                            setFieldValue('fixed_window', value);
                          }}
                          disabled={disabled}
                        />
                        <ErrorMessage
                          name="fixed_window"
                          render={msg => (
                            <p className="text-red-500 text-sm pt-2">{msg}</p>
                          )}
                        />
                      </div>
                    ) : (
                      <TextField
                        label="Duration key"
                        id="dynamic_window_expr"
                        placeholder=".timestamp_key"
                        disabled={disabled}
                        helpText={
                          <p className="text-[#64748B] text-xs mt-1">
                            add the property key from your event call having
                            delay duration in{' '}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://jqlang.github.io/jq/manual/"
                              className="hover:underline text-indigo-600 cursor-pointer"
                            >
                              jq format.
                            </a>
                          </p>
                        }
                      />
                    )}
                  </div>
                  <p className="text-[#1E293B] font-semibold text-base mb-2 mt-4">
                    Advanced configuration
                  </p>
                  <TextField
                    label="Batch Key"
                    id="batch_key"
                    mandatory={false}
                    placeholder=".conversation_id"
                    disabled={disabled}
                    helpText={
                      <p className="text-[#64748B] text-xs mt-1">
                        add the property key from your event call to define
                        unique batch other than distinct_id.
                      </p>
                    }
                  />
                  <div className="mt-4">
                    <label
                      className="block text-sm text-gray-700 font-medium"
                      htmlFor="retain-batch-events"
                    >
                      Retain Batch Events<span className="text-red-500">*</span>
                    </label>
                    <div className="grid grid-flow-col grid-cols-2 items-center mt-1">
                      <ListBox
                        id="retain_order"
                        value={values.retain_order}
                        handleOnchange={value => {
                          setFieldValue('retain_order', value);
                        }}
                        disabled={disabled}
                        displayValue={selectedRetainBatchType?.name}
                        options={RETAIN_BATCH_TYPE}
                        getLabel={e => e.name}
                        getValue={e => e.value}
                      />
                      <TextField
                        id="retain_count"
                        placeholder="2"
                        type="number"
                        min={1}
                        max={100}
                        disabled={disabled}
                        inputClassName="h-9 ml-2 mt-1"
                        showError={false}
                      />
                    </div>
                    <p className="text-[#64748B] text-xs mt-1">
                      return first or last n items in batch array variable
                    </p>
                    <ErrorMessage
                      name="retain_count"
                      render={msg => (
                        <p className="text-red-500 text-sm pt-2">{msg}</p>
                      )}
                    />
                  </div>
                </div>
              </div>
              {editMode && (
                <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t">
                  <Button
                    className="flex-grow justify-center"
                    as="button"
                    onClick={() => {
                      setSelectedForm('nodes_list');
                      setSelectedNode({});
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    as="submit"
                    type="primary"
                    className="flex-grow justify-center ml-2"
                    disabled={updateProperties.isLoading}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

var _ = require('lodash');

export function toFlatPropertyMap(obj) {
  const keySeparator = '.';
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty
        ? `${parentProperty}${keySeparator}${key}`
        : key;
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}
export default function LocalDB(db, flat = false) {
  if (!_.isArray(db)) {
    throw new Error("LocalDB doesn't accept initiation from non array data");
  }
  if (flat) {
    this.data = _.map(db, toFlatPropertyMap);
  } else {
    this.data = db;
  }
}

LocalDB.prototype.findOne = function (...args) {
  return _.find(this.data, args[0]);
};

LocalDB.prototype.find = function (...args) {
  const newData = _.filter(this.data, args[0]);
  if (_.isEmpty(newData)) {
    return new LocalDB([]);
  } else {
    return new LocalDB(newData);
  }
};

LocalDB.prototype.remove = function (...args) {
  const newData = _.reject(this.data, args[0]);
  if (_.isEmpty(newData)) {
    return new LocalDB([]);
  } else {
    return new LocalDB(newData);
  }
};

LocalDB.prototype.sort = function (...args) {
  const newData = _.sortBy(this.data, args[0]);
  return new LocalDB(newData);
};

LocalDB.prototype.first = function () {
  const newData = _.head(this.data);
  return new LocalDB([newData]);
};

LocalDB.prototype.count = function () {
  return this.data.length;
};

LocalDB.prototype.clear = function () {
  this.data = [];
};

LocalDB.prototype.getValue = function (arg) {
  if (_.isEmpty(arg)) {
    return this.data;
  }
  const data = _.map(this.data, _.property(arg));
  return _.compact(data);
};

LocalDB.prototype.insert = function (...args) {
  const data = args[0];
  if (!_.isArray(data) || _.isEmpty(data)) {
    throw new Error("Can't insert non array data or empty data");
  }
  this.data = [].concat(this.data, data);
};

import { useState, useEffect, useMemo } from 'react';
import { useChannelListAPI, useSaveDraftTemplate } from 'apis';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { QueryBuilder } from '@cubejs-client/react';
import { Tooltip } from 'react-tooltip';
import cubejs from '@cubejs-client/core';
import { useEnvState, useAuthState } from 'stores/AuthStore';
import { format } from 'date-fns';
import { Transition } from '@headlessui/react';
import { useQueryClient } from 'react-query';
import { PublishDraftModal, TimeAgo, Spinner } from 'components';
import { classNames, cubeJWTSign } from 'utils';

function getPastDate(noOfDays) {
  const today = format(new Date(), 'yyyy-MM-dd');
  let date = new Date();
  date.setDate(date.getDate() - noOfDays);
  const formattedPastDate = format(date, 'yyyy-MM-dd');
  return [formattedPastDate, today];
}

const template_status = [
  {
    title: 'Live',
    status: 'active',
    color: 'green',
    bg: 'bg-green-100',
    dot: 'text-green-500',
    textColor: 'text-green-800',
  },
  {
    title: 'Draft',
    status: 'draft',
    color: 'yellow',
    bg: 'bg-yellow-100',
    dot: 'text-yellow-500',
    textColor: 'text-yellow-800',
  },
  {
    title: 'Sent for approval',
    status: 'sent_for_approval',
    color: 'blue',
    bg: 'bg-blue-100',
    dot: 'text-blue-500',
    textColor: 'text-blue-800',
  },

  {
    title: 'Approval Pending',
    status: 'approval_pending',
    color: 'blue',
    bg: 'bg-blue-100',
    dot: 'text-blue-500',
    textColor: 'text-blue-800',
  },
  {
    title: 'Rejected',
    status: 'rejected',
    color: 'red',
    bg: 'bg-red-100',
    dot: 'text-red-500',
    textColor: 'text-red-800',
  },
];

const languageTemplateStatus = [
  {
    title: 'Live',
    status: 'approved',
    color: 'green',
    bg: '',
    dot: 'text-green-500',
    textColor: 'text-green-800',
  },
  {
    title: 'Live',
    status: 'auto_approved',
    color: 'green',
    bg: '',
    dot: 'text-green-500',
    textColor: 'text-green-800',
  },
  {
    title: 'In Approval',
    status: 'approval_pending',
    color: 'blue',
    bg: 'bg-blue-100',
    dot: 'text-blue-500',
    textColor: 'text-blue-800',
  },
  {
    title: 'Sent for approval',
    status: 'sent_for_approval',
    color: 'blue',
    bg: 'bg-blue-100',
    dot: 'text-blue-500',
    textColor: 'text-blue-800',
  },
  {
    title: 'Upload Failed',
    status: 'upload_failed',
    color: 'red',
    bg: 'bg-red-100',
    dot: 'text-red-500',
    textColor: 'text-red-800',
  },
  {
    title: 'In Approval',
    status: 'pending',
    color: 'blue',
    bg: 'bg-blue-100',
    dot: 'text-blue-500',
    textColor: 'text-blue-800',
  },
  {
    title: 'Rejected',
    status: 'rejected',
    color: 'red',
    bg: 'bg-red-100',
    dot: 'text-red-500',
    textColor: 'text-red-800',
  },
];

const TemplateAnalytics = ({ versionStatus, cubeData, templateId }) => {
  let analyticsData;
  let percentSeen = '--',
    percentInteracted = '--',
    triggeredCount = 0;
  const cubeRes = cubeData?.resultSet?.loadResponses;

  if (cubeRes) {
    analyticsData = cubeRes[0].data.find(item => {
      return item['WorkflowChannels.templateId'] === String(templateId);
    });
  }

  if (analyticsData) {
    const response = analyticsData;
    triggeredCount = response['WorkflowChannels.countTriggered'];
    percentSeen = response['WorkflowChannels.SeenRatio'];
    percentInteracted = response['WorkflowChannels.InteractedRatio'];

    percentSeen = percentSeen && percentSeen >= 0 ? percentSeen + ' %' : '--';
    percentInteracted =
      percentInteracted && percentInteracted >= 0
        ? percentInteracted + ' %'
        : '--';
  }

  if (!cubeRes) {
    return (
      <div className="flex items-center justify-center space-x-2 animate-pulse">
        <div className="w-2 h-2 bg-blue-400 rounded-full bounce1"></div>
        <div className="w-2 h-2 bg-blue-400 rounded-full bounce2"></div>
        <div className="w-2 h-2 bg-blue-400 rounded-full bounce3"></div>
      </div>
    );
  }
  return (
    <div className="flex">
      <div className="mr-10">
        <p
          className={classNames(
            'font-semibold',
            'text-sm',
            'text-center',
            versionStatus === 'active' ? 'text-indigo-600' : 'text-gray-700'
          )}
        >
          {percentSeen}
        </p>
        <div className="flex items-center">
          <div
            className="relative flex flex-col items-center group"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className="flex">
              <p className="text-xs text-gray-500">%Seen</p>
              <QuestionMarkCircleIcon
                height={14}
                width={14}
                className="text-gray-500 ml-0.5"
              />
            </div>
            <div className="absolute flex-col items-center hidden mb-6 group-hover:flex left-0 right-[-50px]">
              <div className="w-3 h-3 mt-3 rotate-45 bg-black"></div>
              <div className="relative z-10 p-1 text-[0.65rem] leading-tight text-white whitespace-pre-wrap bg-black shadow-lg rounded-md -mt-2 w-[170px] ml-10">
                <p>(Seen/Triggered)% - last 1 year</p>
                <p>{`Triggered count: ${triggeredCount}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p
          className={classNames(
            'font-semibold',
            'text-sm',
            'text-center',
            versionStatus === 'active' ? 'text-indigo-600' : 'text-gray-700'
          )}
        >
          {percentInteracted}
        </p>
        <div className="flex items-center">
          <div
            className="relative flex flex-col items-center group"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className="flex">
              <p className="text-xs text-gray-500">%Interacted</p>
              <QuestionMarkCircleIcon
                height={14}
                width={14}
                className="text-gray-500 ml-0.5"
              />
            </div>
            <div className="absolute flex-col items-center hidden mb-6 group-hover:flex">
              <div className="w-3 h-3 mt-3 rotate-45 bg-black"></div>
              <div className="relative z-50 p-1 bg-black shadow-lg rounded-md -mt-2 w-[170px] text-[0.65rem] leading-tight text-white whitespace-pre-wrap">
                <p>(Click/Triggered)% - last 1 year</p>
                <p>{`Triggered count: ${triggeredCount}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function ChannelVersionManager({
  type,
  setTemplateLanguageSlug,
  setVersionChange,
  versionChange,
  template,
  liveOnly,
  NotFound,
  lang,
  children,
  variables,
  successMessage,
  languageSchema,
  nonDltLanguageSchema,
  ...props
}) {
  const envState = useEnvState();
  const authState = useAuthState();
  const queryClient = useQueryClient();
  const currentEnv = envState.currentEnv.get();
  const envs = authState.organization.get()?.environments;
  const CUBE_API_SECRET = process.env.REACT_APP_ATHENA_CUBE_API_SECRET;

  const [formLoading, setFormLoading] = useState(false);
  const [selectedLanguageSlug, setSelectedLanguageSlug] = useState(lang);
  const [publishDraftOpen, setPublishDraftOpen] = useState(false);
  const [updatedInfo, setUpdatedInfo] = useState({});
  const [cubejsAPI, setCubejsAPI] = useState(null);
  const [emailSettingsOpen, setEmailSettingsOpen] = useState(false);
  const [cubeInstanceLoading, setCubeInstanceLoading] = useState(true);

  const {
    data: channelVersions,
    error: channelVLError,
    isFetching: isChannelVLFetching,
  } = useChannelListAPI(template, type, liveOnly);

  const [selectedIndex, setSelectedIndex] = useState(() => {
    const key = `${process.env.NODE_ENV}-${template}-${type}`;
    const versions =
      JSON.parse(sessionStorage.getItem('selectedVersion')) || {};
    const value = versions[key];
    if (value !== null && value !== undefined) {
      return value;
    } else {
      return -1;
    }
  });

  const selectedLanguageObj = channelVersions?.results[
    selectedIndex
  ]?.templates.filter(val => val.language.slug === selectedLanguageSlug);

  const saveVariableDraft = useSaveDraftTemplate(
    template,
    type,
    selectedLanguageObj?.[0]?.language?.slug
  );

  useEffect(() => {
    setCubeInstanceLoading(true);
    envs?.forEach(async env => {
      if (env.slug === currentEnv) {
        const cubejsToken = await cubeJWTSign(
          {
            env_id: env.id,
            member: 'WorkflowChannels.environmentID',
          },
          CUBE_API_SECRET
        );
        const cubeApi = cubejs(cubejsToken, {
          apiUrl: process.env.REACT_APP_ATHENA_CUBE_URL,
        });
        setCubejsAPI(cubeApi);
        setTimeout(() => {
          setCubeInstanceLoading(false);
        }, 500);
      }
    });
  }, [currentEnv]);

  const updateUpdatedByInfo = () => {
    setUpdatedInfo({
      updated_by: authState.user.get()?.name,
      updated_at: new Date().toString(),
    });
  };

  useEffect(() => {
    if (window.publishDraftActionDone) {
      window.publishDraftActionDone = null;
      setSelectedIndex(1);
    }
    if (
      isChannelVLFetching ||
      selectedIndex === null ||
      channelVersions === undefined
    ) {
      return;
    } else {
      const results = channelVersions.results;
      if (selectedIndex === -1 || selectedIndex >= results.length) {
        //Set live version (if available) as selected version otherwise set draft
        let activeIndex = -1;
        results.forEach((version, index) => {
          if (version.status === 'active') {
            activeIndex = index;
          }
        });
        if (activeIndex === -1) {
          activeIndex = 0;
        }
        setSelectedIndex(activeIndex);
      }
      if (selectedIndex === 1) {
        setSelectedLanguageSlug(lang);
      }
    }
  }, [isChannelVLFetching, channelVersions, selectedIndex]);

  useEffect(() => {
    const versions =
      JSON.parse(sessionStorage.getItem('selectedVersion')) || {};
    const key = `${process.env.NODE_ENV}-${template}-${type}`;
    versions[key] = selectedIndex;
    sessionStorage.setItem('selectedVersion', JSON.stringify(versions));
  }, [selectedIndex]);

  const versionMemo = useMemo(() => {
    if (channelVersions?.results) {
      return channelVersions?.results[selectedIndex];
    } else {
      return null;
    }
  }, [channelVersions, selectedIndex]);
  const variablesMemo = useMemo(() => variables, [variables]);

  if (isChannelVLFetching && !versionMemo) {
    return <Spinner></Spinner>;
  }
  if (
    (!isChannelVLFetching && channelVLError?.response?.status === 404) ||
    !versionMemo
  ) {
    return null;
  } else {
    if (!versionMemo) {
      return <Spinner></Spinner>;
    } else {
      let templateIDList = [];
      channelVersions?.results?.forEach(item => {
        if (item.status === 'active' || item.status === 'inactive') {
          item?.templates?.length > 0 &&
            templateIDList.push(String(item?.templates[0]?.id));
        }
      });

      if (cubeInstanceLoading) {
        return <Spinner />;
      }

      return (
        <div className="flex flex-row templatedetails-container bg-white">
          <div className="bg-white border-gray-200 border-r w-64">
            <nav className="h-full overflow-y-auto" aria-label="versions">
              <ul className="relative z-0">
                <QueryBuilder
                  cubejsApi={cubejsAPI}
                  query={{
                    measures: [
                      'WorkflowChannels.countTriggered',
                      'WorkflowChannels.countDelivered',
                      'WorkflowChannels.SeenRatio',
                      'WorkflowChannels.InteractedRatio',
                    ],
                    dimensions: ['WorkflowChannels.templateId'],
                    timeDimensions: [
                      {
                        dimension: 'WorkflowChannels.createdAt',
                        dateRange: getPastDate(365),
                      },
                    ],
                    order: [['WorkflowChannels.countTriggered', 'desc']],
                    filters: [
                      {
                        member: 'WorkflowChannels.templateId',
                        operator: 'contains',
                        values: templateIDList,
                      },
                    ],
                  }}
                  render={cubeData => {
                    return channelVersions.results.map((version, index) => {
                      const templateData = template_status.find(
                        item => item.status === version.status
                      );
                      let tooltipContent = '';

                      if (
                        version?.status === 'active' &&
                        version?.templates?.length === 1
                      ) {
                        tooltipContent =
                          version?.templates?.[0]?.content?.template_name;
                      }
                      if (
                        version?.status === 'rejected' &&
                        version?.templates?.length === 1
                      ) {
                        tooltipContent =
                          version?.templates?.[0]?.approval_cycle?.comment;
                      }

                      if (
                        version?.status === 'rejected' &&
                        version?.templates?.length > 1
                      ) {
                        tooltipContent =
                          'Rejected as english language is rejected';
                      }

                      return (
                        <li
                          className="cursor-pointer border-b border-b-gray-200"
                          key={index}
                          onClick={async ev => {
                            props?.setEmailEditorChanged?.(false);
                            props?.cancelDebounce?.();
                            setSelectedIndex(index);
                            setSelectedLanguageSlug(lang);
                            setTemplateLanguageSlug &&
                              setTemplateLanguageSlug(lang);
                            setVersionChange &&
                              setVersionChange(!versionChange);
                            if (
                              version?.status === 'draft' &&
                              index !== selectedIndex
                            ) {
                              setFormLoading(true);
                              await queryClient.invalidateQueries(
                                `${envState.currentEnv.get()}/template/${template}/${type}`
                              );
                              setFormLoading(false);
                            }
                            props?.formikRef?.current?.resetForm();
                          }}
                        >
                          <div
                            className={classNames(
                              index === selectedIndex
                                ? 'bg-[#F0F6FF]'
                                : 'bg-white hover:bg-[#D2E2FC]',
                              'relative py-5 flex items-center space-x-3 focus:outline-none px-4'
                            )}
                          >
                            <div className="flex-1 min-w-0">
                              <div className="focus:outline-none">
                                {/* Extend touch target to entire panel */}
                                {/* <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                  index={index}
                                /> */}
                                <div className="flex justify-between">
                                  <p className="text-sm font-medium text-gray-900 capitalize break-all">
                                    {version.version_tag_user || version.status}
                                  </p>
                                  <div className="flex-row">
                                    {templateData && (
                                      <div
                                        data-tooltip-id={index}
                                        data-tooltip-html={tooltipContent?.replace(
                                          /(.{1,20})/g,
                                          '$1<br />'
                                        )}
                                        data-tooltip-place="top"
                                        data-tooltip-variant="dark"
                                      >
                                        <p
                                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${templateData.bg} ${templateData.textColor}`}
                                        >
                                          <svg
                                            className={`-ml-0.5 mr-1.5 h-2 w-2 ${templateData.dot}`}
                                            fill="currentColor"
                                            viewBox="0 0 8 8"
                                          >
                                            <circle cx={4} cy={4} r={3} />
                                          </svg>
                                          {templateData.title}
                                        </p>
                                      </div>
                                    )}
                                    <Tooltip id={index} className="z-10" />
                                  </div>
                                </div>
                                <div className="flex my-1">
                                  <p className="text-sm text-gray-500 truncate">
                                    {version.status === 'draft'
                                      ? updatedInfo.updated_by ||
                                        version.updated_by.name
                                      : version.updated_by.name}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center mb-3 justify-start">
                                <p className="text-gray-400 italic text-xs mr-[3px]">
                                  edited
                                </p>
                                <TimeAgo
                                  className="text-gray-400 text-xs truncate italic"
                                  dateInput={
                                    version.status === 'draft'
                                      ? updatedInfo.updated_at ||
                                        version.updated_at
                                      : version.updated_at
                                  }
                                />
                              </div>
                              {(version.status === 'active' ||
                                version.status === 'inactive') && (
                                <TemplateAnalytics
                                  templateId={
                                    version?.templates?.length > 0 &&
                                    version.templates[0].id
                                  }
                                  versionStatus={version.status}
                                  cubeData={cubeData}
                                />
                              )}
                              {version.status === 'draft' &&
                                index === selectedIndex && (
                                  <div className="flex justify-center">
                                    <button
                                      type="primary"
                                      as="button"
                                      onClick={async event => {
                                        event.stopPropagation();
                                        if (
                                          type === 'email' &&
                                          !selectedLanguageObj?.[0]?.content
                                            ?.subject
                                        ) {
                                          setEmailSettingsOpen(true);
                                        } else {
                                          setPublishDraftOpen(true);
                                        }
                                      }}
                                      className="bg-[#2E70E8] text-white text-sm font-semibold py-2 px-8 rounded shadow-md hover:bg-[#184D99]"
                                    >
                                      Publish Draft
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                          <Transition
                            enter="transition duration-1000 ease-out"
                            enterFrom="transform opacity-0"
                            enterTo="transform opacity-100"
                            show={
                              index === selectedIndex &&
                              version &&
                              version?.templates &&
                              version.templates.length > 1
                            }
                          >
                            <div className="ml-6 border-l-2 flex flex-col">
                              {version &&
                                version?.templates.map((dataObj, i) => {
                                  const language = dataObj?.language;
                                  const slugCheck =
                                    selectedLanguageSlug === language?.slug;
                                  const templateData =
                                    languageTemplateStatus.find(
                                      item =>
                                        item.status === dataObj.approval_status
                                    );

                                  let languageTooltipContent = '';

                                  if (dataObj?.approval_status === 'approved') {
                                    languageTooltipContent =
                                      dataObj?.content?.template_name;
                                  }
                                  if (
                                    dataObj?.approval_status ===
                                      'upload_failed' ||
                                    dataObj?.approval_status === 'rejected'
                                  ) {
                                    languageTooltipContent =
                                      dataObj?.approval_cycle?.comment;
                                  }

                                  return (
                                    <button
                                      key={i}
                                      onClick={async e => {
                                        props?.setWarnings?.({});
                                        props?.setEmailEditorChanged?.(false);
                                        if (type === 'sms') {
                                          props?.formikRef?.current?.saveDraftMethod?.cancel();
                                        } else {
                                          props?.cancelDebounce?.();
                                        }
                                        props &&
                                          props.formikRef &&
                                          props?.formikRef.current.resetForm();
                                        e.stopPropagation();
                                        setSelectedLanguageSlug(language?.slug);
                                        setTemplateLanguageSlug &&
                                          setTemplateLanguageSlug(
                                            language?.slug
                                          );
                                        if (
                                          version?.status === 'draft' &&
                                          !slugCheck
                                        ) {
                                          setFormLoading(true);
                                          await queryClient.invalidateQueries(
                                            `${envState.currentEnv.get()}/template/${template}/${type}`
                                          );
                                          setFormLoading(false);
                                        }
                                      }}
                                    >
                                      <div
                                        className={classNames(
                                          'py-2 flex items-center',
                                          slugCheck
                                            ? 'bg-[#F0F6FF] border-l-4 border-[#2E70E8] -ml-[3px]'
                                            : 'bg-white hover:bg-[#F0F6FF]'
                                        )}
                                      >
                                        <p
                                          className={classNames(
                                            'text-sm font-medium text-gray-900 flex',
                                            slugCheck
                                              ? 'pl-[15px] text-[#2E70E8]'
                                              : 'pl-4'
                                          )}
                                        >
                                          {`${language?.name} (${language?.slug})`}
                                        </p>
                                        {version?.status !== 'draft' &&
                                          dataObj?.is_cloned_from_last_version && (
                                            <div className="relative flex flex-col items-center group">
                                              <div className="flex">
                                                <QuestionMarkCircleIcon className="w-4 h-4 text-gray-500 ml-1" />
                                              </div>
                                              <div className="absolute flex-col items-center hidden mb-6 group-hover:flex -top-12">
                                                <div className="relative z-10 p-1 text-xs leading-tight text-white whitespace-pre-wrap bg-black shadow-lg rounded-md w-[200px]">
                                                  <p>
                                                    content cloned from last
                                                    approved version
                                                  </p>
                                                </div>
                                                <div className="w-3 h-3 rotate-45 bg-black -mt-2"></div>
                                              </div>
                                            </div>
                                          )}
                                        <div
                                          className="ml-auto mr-1"
                                          data-tooltip-id={index}
                                          data-tooltip-html={languageTooltipContent?.replace(
                                            /(.{1,20})/g,
                                            '$1<br />'
                                          )}
                                          data-tooltip-place="top"
                                          data-tooltip-variant="dark"
                                        >
                                          {templateData &&
                                            version.status !== 'draft' && (
                                              <div
                                                className={`inline-flex px-1 items-start rounded-full text-xs ml-auto font-medium ${templateData.textColor}`}
                                              >
                                                <svg
                                                  className={`-ml-0.5 mr-1.5 h-2 w-2 mt-1 ${templateData.dot}`}
                                                  fill="currentColor"
                                                  viewBox="0 0 8 8"
                                                >
                                                  <circle cx={4} cy={4} r={3} />
                                                </svg>
                                                <p className="text-xs font-medium">
                                                  {templateData.title}
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                        <Tooltip id={index} className="z-10" />
                                      </div>
                                    </button>
                                  );
                                })}
                            </div>
                          </Transition>
                        </li>
                      );
                    });
                  }}
                />
              </ul>
            </nav>
          </div>
          {/* Pass next items to children */}
          <div className="flex-grow">
            {children({
              version: versionMemo,
              variables: variablesMemo,
              selectedLanguageSlug: selectedLanguageSlug,
              emailSettingsOpen: emailSettingsOpen,
              setEmailSettingsOpen: setEmailSettingsOpen,
              setPublishDraftOpen: setPublishDraftOpen,
              formLoading: formLoading,
              updateUpdatedByInfo,
            })}
          </div>
          {publishDraftOpen && (
            <PublishDraftModal
              open={publishDraftOpen}
              setOpen={setPublishDraftOpen}
              template={template}
              channel={type}
              lang={lang}
              version={channelVersions?.results[0]}
              saveDraft={saveVariableDraft}
              languageSchema={languageSchema}
              nonDltLanguageSchema={nonDltLanguageSchema}
              liveOnly={liveOnly}
            ></PublishDraftModal>
          )}
        </div>
      );
    }
  }
}

export default ChannelVersionManager;

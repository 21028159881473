import { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  Loader2,
  CircleHelpIcon,
  SquareArrowOutUpRightIcon,
  CircleCheckIcon,
  ExternalLinkIcon,
} from 'lucide-react';
import { useAuthState, useEnvState } from 'stores/AuthStore';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Label,
  Button,
  Input,
  CTooltip,
  Switch,
} from 'new-components';
import { CodeSnippet } from 'custom-components';
import urlUtils from 'utils/urlUtils';
import { MISSING_FIELD_ERROR, JsonEditor } from 'utils';
import {
  useTriggerTestWorkflow,
  useTenantsData,
  useWorkflowTestData,
  useTenantDetails,
  useSubscriberInfoSearch,
} from 'apis';
import 'react-phone-number-input/style.css';

const JSONErrorMessage = 'Add valid JSON value';

const testWorkflowValidationSchema = Yup.object().shape({
  triggerType: Yup.string(),
  recipient_value_type: Yup.string(),
  actor_value_type: Yup.string(),
  distinct_id: Yup.string().when('triggerType', {
    is: 'event',
    then: Yup.string().nullable().required(MISSING_FIELD_ERROR),
  }),
  data: Yup.string().test('is-valid-json', JSONErrorMessage, value =>
    isValidJson(value)
  ),
  actor: Yup.mixed().when('triggerType', {
    is: 'api',
    then: Yup.mixed().when('actor_value_type', {
      is: 'json',
      then: Yup.string().test('is-valid-json', JSONErrorMessage, value =>
        isValidJson(value)
      ),
    }),
  }),
});

function isValidJson(json) {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
}

const defaultRecipientJSON = JSON.stringify(
  [
    {
      distinct_id: 'recipient1',
      $email: ['abc@example.com'],
      name: 'recipient_name',
    },
  ],
  null,
  2
);

const defaultActorJSON = JSON.stringify(
  {
    distinct_id: 'actor1',
    name: 'actor_name',
  },
  null,
  2
);

const getInitialRecipient = (recipientValueType, recipients) => {
  let type;
  if (recipientValueType === 'string') {
    type =
      recipients?.map(recipient => ({
        distinct_id: recipient,
      })) ?? [];
  } else {
    type = recipients
      ? JSON.stringify(recipients, null, 2)
      : defaultRecipientJSON;
  }
  return type;
};

const getInitialActor = (actorValueType, actor) => {
  let type;
  if (actorValueType === 'string') {
    type = actor ?? '';
  } else {
    type =
      typeof actor === 'object'
        ? JSON.stringify(actor, null, 2)
        : defaultActorJSON;
  }
  return type;
};

function TestWorkflowModal({
  open,
  setOpen,
  wfDetails,
  wfVersionDetails,
  editMode,
}) {
  const envState = useEnvState();
  const authState = useAuthState();

  const [brandText, setBrandText] = useState('');
  const [distinctIDText, setDistinctIDText] = useState('');

  const { data: searchedDistinctIDs, isLoading: distinctIDLoading } =
    useSubscriberInfoSearch({
      distinctId: distinctIDText,
    });
  const { data: getWFTestData, refetch } = useWorkflowTestData(wfDetails?.slug);
  const wfTestData = getWFTestData?.data;

  const testWorkflow = useTriggerTestWorkflow(wfDetails?.slug);
  const brandList = useTenantsData(brandText);
  const { data: tenantDetails } = useTenantDetails(wfTestData?.tenant_id);

  const allEnv = authState.organization.get()?.environments;
  const currentEnv = allEnv?.find(
    obj => obj?.slug === envState.currentEnv.get()
  );
  const hasMultipleTenants = Number(currentEnv?.tenants_count) > 1;
  const initialrecipientValueType =
    wfTestData?.recipient_value_type || 'string';
  const initialActorValueType = wfTestData?.actor_value_type || 'string';
  const initialEventProperties = wfTestData?.properties || {
    k1: 'v1',
    nested_key: { k2: 'v2' },
  };
  const initialTenant = wfTestData?.tenant_id
    ? {
        tenant_id: tenantDetails?.tenant_id,
        tenant_name: tenantDetails?.tenant_name,
        logo: tenantDetails?.logo,
      }
    : '';
  const initialActor = getInitialActor(
    initialActorValueType,
    wfTestData?.actor
  );
  const initialRecipients = getInitialRecipient(
    initialrecipientValueType,
    wfTestData?.recipients
  );

  return (
    <>
      <Sheet
        open={open}
        onOpenChange={() => {
          setOpen(false);
          refetch();
        }}
      >
        <SheetContent className="sm:max-w-4xl overflow-y-scroll">
          <SheetHeader className="mb-2">
            <SheetTitle>
              <div className="flex items-center">
                Test Workflow
                <a
                  href="https://docs.suprsend.com/docs/trigger-workflow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalLinkIcon className="h-4 w-4 ml-2" />
                </a>
                {editMode ? (
                  <p className="flex self-center py-0.5 px-2  text-xs rounded bg-warning-muted text-warning ml-2">
                    Draft
                  </p>
                ) : (
                  <p className="flex self-center py-0.5 px-2  text-xs rounded bg-success-muted text-success ml-2">
                    Live
                  </p>
                )}
              </div>
            </SheetTitle>
            <SheetDescription>
              Trigger a test workflow from here or copy + paste code to trigger
              it from your codebase
            </SheetDescription>
          </SheetHeader>
          <Formik
            enableReinitialize
            initialValues={{
              distinct_id: wfTestData?.distinct_id || '',
              recipients: initialRecipients,
              recipient_value_type: initialrecipientValueType,
              actor: initialActor,
              actor_value_type: initialActorValueType,
              data: JSON.stringify(initialEventProperties, null, 2),
              tenant_id: initialTenant,
              triggerType: wfVersionDetails?.trigger_type,
            }}
            validationSchema={testWorkflowValidationSchema}
            validate={values => {
              const errorObj = {
                recipients: '',
              };
              if (values?.triggerType === 'api') {
                if (
                  values?.recipient_value_type === 'string' &&
                  values?.recipients?.length <= 0
                ) {
                  errorObj.recipients = MISSING_FIELD_ERROR;
                } else if (values?.recipient_value_type === 'json') {
                  if (!values?.recipients) {
                    errorObj.recipients = MISSING_FIELD_ERROR;
                  } else if (!isValidJson(values?.recipients)) {
                    errorObj.recipients = JSONErrorMessage;
                  }
                }
              }
              return errorObj.recipients ? errorObj : {};
            }}
            onSubmit={async values => {
              const payload = {
                trigger_type: values?.triggerType,
                version_id: wfVersionDetails?.id,
                ...values,
              };

              delete payload.triggerType;
              payload.recipients =
                values?.recipient_value_type === 'json'
                  ? JSON.parse(values?.recipients)
                  : values?.recipients?.map(
                      recipient => recipient?.distinct_id
                    );
              payload.actor =
                values?.actor_value_type === 'json'
                  ? JSON.parse(values?.actor)
                  : values?.actor;
              payload.data = values?.data ? JSON.parse(values?.data) : {};
              payload.tenant_id = values?.tenant_id?.tenant_id;

              await testWorkflow.mutateAsync(payload);
            }}
          >
            {({ values, setFieldValue, errors }) => {
              console.log('values?.recipients', values?.recipients);
              return (
                <Form>
                  <div className="flex w-full pt-5">
                    <div className="w-[50%] mr-3">
                      <div className="mb-6">
                        {values?.triggerType === 'event' ? (
                          <div>
                            <Label htmlFor="distinct_id">
                              <div className="flex items-center">
                                Recipient (Distinct ID)
                                <span className="text-destructive">*</span>
                                <CTooltip
                                  trigger={
                                    <CircleHelpIcon className="h-3.5 w-3.5 ml-2 text-muted-foreground" />
                                  }
                                >
                                  Unique identifier of the recipient. User needs
                                  to be created first for event based trigger.
                                </CTooltip>
                              </div>
                            </Label>
                            <CreatableSelect
                              id="distinct_id"
                              classNamePrefix="react-select"
                              className="mt-2 z-10"
                              value={
                                values?.distinct_id
                                  ? { distinct_id: values?.distinct_id }
                                  : ''
                              }
                              createOptionPosition="first"
                              openMenuOnClick={false}
                              isLoading={distinctIDLoading}
                              options={searchedDistinctIDs?.results}
                              onChange={selectedValue =>
                                setFieldValue(
                                  'distinct_id',
                                  selectedValue?.distinct_id
                                )
                              }
                              getNewOptionData={newOption => {
                                if (newOption) {
                                  return { distinct_id: newOption };
                                }
                              }}
                              getOptionLabel={item => item.distinct_id}
                              getOptionValue={item => item.distinct_id}
                              onInputChange={input => setDistinctIDText(input)}
                              isClearable
                            />
                            {errors?.distinct_id && (
                              <p className="text-sm text-destructive mt-1">
                                {errors?.distinct_id}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div>
                            <Label htmlFor="recipients">
                              <div className="flex items-center">
                                Recipients
                                <span className="text-destructive">*</span>
                                <CTooltip
                                  trigger={
                                    <CircleHelpIcon className="h-3.5 w-3.5 ml-2 text-muted-foreground" />
                                  }
                                >
                                  List of recipients to notify. You can add a
                                  new recipient with JSON editor.
                                </CTooltip>
                                <div className="flex items-center ml-auto outline-none">
                                  <Label
                                    htmlFor="recipient_editor_type"
                                    className="mr-2 cursor-pointer font-normal text-accent-foreground text-xs"
                                  >
                                    JSON editor
                                  </Label>
                                  <Switch
                                    id="recipient_editor_type"
                                    checked={
                                      values?.recipient_value_type === 'json'
                                    }
                                    onCheckedChange={value => {
                                      const type = value ? 'json' : 'string';
                                      const recipients =
                                        type === 'json'
                                          ? defaultRecipientJSON
                                          : [];

                                      setFieldValue(
                                        'recipient_value_type',
                                        type
                                      );
                                      setFieldValue('recipients', recipients);
                                    }}
                                    className="h-4 w-8"
                                    thumbStyle="h-3 w-3"
                                  />
                                </div>
                              </div>
                            </Label>
                            <div className="mt-1">
                              {values?.recipient_value_type === 'json' ? (
                                <JsonEditor
                                  name="recipients"
                                  jsonValue={values?.recipients}
                                  setFieldValue={setFieldValue}
                                />
                              ) : (
                                <CreatableSelect
                                  classNamePrefix="react-select"
                                  isMulti
                                  placeholder="Add recipients"
                                  value={values?.recipients}
                                  openMenuOnClick={false}
                                  isClearable
                                  isLoading={distinctIDLoading}
                                  getOptionLabel={e => e.distinct_id}
                                  getOptionValue={e => e.distinct_id}
                                  createOptionPosition="first"
                                  onChange={selectedItem => {
                                    setFieldValue('recipients', selectedItem);
                                  }}
                                  options={searchedDistinctIDs?.results}
                                  components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                  }}
                                  getNewOptionData={newOption => {
                                    if (newOption) {
                                      return { distinct_id: newOption };
                                    }
                                  }}
                                  onInputChange={input => {
                                    setDistinctIDText(input);
                                  }}
                                  className="z-10"
                                />
                              )}
                            </div>
                            <ErrorMessage
                              name="recipients"
                              component="div"
                              className="text-sm text-destructive mt-1"
                            />
                          </div>
                        )}
                        <a
                          className="flex items-center mt-1.5 text-primary w-fit"
                          href={urlUtils.makeURL(
                            'subscribers/?action=create_new_subscriber'
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="text-sm"> Create subscriber</p>
                          <SquareArrowOutUpRightIcon className="h-3 w-3 ml-2" />
                        </a>
                      </div>

                      {values?.triggerType === 'api' && (
                        <div className="mb-8">
                          <Label htmlFor="actor">
                            <div className="flex items-center">
                              Actor
                              <CTooltip
                                trigger={
                                  <CircleHelpIcon className="h-3.5 w-3.5 ml-2 text-muted-foreground" />
                                }
                              >
                                {`User who did the action. Add actor properties in template as $actor.<prop> for cross user notification.`}
                              </CTooltip>
                              <div className="flex items-center ml-auto outline-none mb-0.5">
                                <Label
                                  htmlFor="actor_editor_type"
                                  className="mr-2 cursor-pointer font-normal text-accent-foreground text-xs"
                                >
                                  JSON editor
                                </Label>
                                <Switch
                                  id="actor_editor_type"
                                  checked={values?.actor_value_type === 'json'}
                                  onCheckedChange={value => {
                                    const type = value ? 'json' : 'string';
                                    const actor =
                                      type === 'json' ? defaultActorJSON : '';
                                    setFieldValue('actor_value_type', type);
                                    setFieldValue('actor', actor);
                                  }}
                                  className="h-4 w-8"
                                  thumbStyle="h-3 w-3"
                                />
                              </div>
                            </div>
                          </Label>
                          <div className="mt-1">
                            {values?.actor_value_type === 'json' ? (
                              <>
                                <JsonEditor
                                  name="actor"
                                  jsonValue={values?.actor}
                                  setFieldValue={setFieldValue}
                                  height="100px"
                                />
                                <ErrorMessage
                                  name="actor"
                                  component="div"
                                  className="text-sm text-destructive mt-1"
                                />
                              </>
                            ) : (
                              <CreatableSelect
                                classNamePrefix="react-select"
                                placeholder="Add actor"
                                openMenuOnClick={false}
                                value={
                                  values?.actor
                                    ? { distinct_id: values?.actor }
                                    : ''
                                }
                                styles={{
                                  menu: provided => ({
                                    ...provided,
                                    zIndex: 100,
                                  }),
                                }}
                                isClearable
                                isLoading={distinctIDLoading}
                                getOptionLabel={e => e.distinct_id}
                                getOptionValue={e => e.distinct_id}
                                onChange={selectedItem => {
                                  setFieldValue(
                                    'actor',
                                    selectedItem?.distinct_id
                                  );
                                }}
                                createOptionPosition="first"
                                options={searchedDistinctIDs?.results}
                                getNewOptionData={newOption => {
                                  if (newOption) {
                                    return { distinct_id: newOption };
                                  }
                                }}
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                onInputChange={input => {
                                  setDistinctIDText(input);
                                }}
                                className="z-auto"
                              />
                            )}
                          </div>
                        </div>
                      )}

                      <div className="mb-6">
                        <Label htmlFor="event_properties">
                          <div className="flex items-center mb-2">
                            Event Properties
                            <CTooltip
                              trigger={
                                <CircleHelpIcon className="h-3.5 w-3.5 ml-2 text-muted-foreground" />
                              }
                            >
                              JSON data used in template and workflow variables
                            </CTooltip>
                          </div>
                        </Label>
                        <JsonEditor
                          name="data"
                          jsonValue={values?.data}
                          setFieldValue={setFieldValue}
                          height="200px"
                        />
                        <ErrorMessage
                          name="data"
                          component="div"
                          className="text-sm text-destructive mt-1"
                        />
                      </div>

                      {hasMultipleTenants && (
                        <div className="mb-6">
                          <Label>Tenant</Label>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Search"
                            defaultValue={values?.tenant_id}
                            value={values?.tenant_id}
                            getOptionLabel={item => {
                              return (
                                <p>{`${item.tenant_name} (${item.tenant_id})`}</p>
                              );
                            }}
                            getOptionValue={item => item.tenant_id}
                            options={brandList?.data}
                            onChange={value => {
                              setFieldValue('tenant_id', value);
                            }}
                            className="mt-1"
                            isSearchable
                            onInputChange={data => setBrandText(data)}
                            isClearable
                            maxMenuHeight={170}
                          />
                          <a
                            className="flex items-center mt-1.5 text-primary"
                            href={urlUtils.makeURL(
                              'brands/?action=create_new_brand'
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="text-sm"> Create new tenant</p>
                            <SquareArrowOutUpRightIcon className="h-3 w-3 ml-2" />
                          </a>
                        </div>
                      )}

                      <Button
                        type="submit"
                        className="mt-2"
                        disabled={testWorkflow?.isLoading}
                      >
                        {testWorkflow?.isLoading && (
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Trigger Test
                      </Button>
                      {testWorkflow?.isSuccess && (
                        <div className="flex items-center mt-4 mb-8">
                          <CircleCheckIcon className="h-4 w-4 text-success" />
                          <p className="text-sm ml-1.5 text-accent-foreground">
                            Triggered Test successfully!
                          </p>
                          <a
                            className="ml-1 text-sm text-primary"
                            href={urlUtils.makeURL(
                              `/workflows/${wfDetails?.slug}/requests?last_n_minutes=1440`
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Check logs
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="w-[50%]">
                      <div className="ml-3">
                        <CodeSnippet
                          snippet={
                            values?.triggerType === 'event'
                              ? eventSnippet
                              : apiSnippet
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </SheetContent>
      </Sheet>
    </>
  );
}

export default TestWorkflowModal;

const apiSnippet = {
  python: `from suprsend import Suprsend
from suprsend import WorkflowTriggerRequest
  
# Initialize SDK
supr_client = Suprsend("<WORKSPACE_KEY>", "<WORKSPACE_SECRET>")
  
# Prepare Workflow Payload
w1 = WorkflowTriggerRequest(
    body={
        "workflow": "workflow_slug",
        # actor object
        "actor":{
            "distinct_id":"actor-1",
            "user_prop_1":"v1"
        },
        # recipient object
        "recipients": [
            {
               "distinct_id": "recipient-1",
               "$email":["abc@example.com"],
               "user_prop_1":"v1"
            },
            {
               "distinct_id": "recipient-2",
               "$email":["cde@example.com"],
               "user_prop_1":"v2"
            }
        ],
        # dynamic data to render template and workflow variables
        "data": {
        "k1":"v1",
        "nested_var": {
            "k2":"v2"
        }
     } 
   }
)
  
response = supr_client.workflows.trigger(w1)
print(response)`,

  go: `package main

import (
    "log"
  
    suprsend "github.com/suprsend/suprsend-go"
)
  
// Initialize SDK
func main() {
    suprClient, err := suprsend.NewClient("<WORKSPACE_KEY>", "<WORKSPACE_SECRET>")
    if err != nil {
      log.Println(err)
    }
    _ = suprClient
    triggerWorkflowAPI(suprClient)
}
  
func triggerWorkflowAPI(suprClient *suprsend.Client) {
  
    // Prepare Workflow Payload
    wfReqBody := map[string]interface{}{
        "workflow": "_workflow_slug_",
        // actor object
        "actor": map[string]interface{}{
            "distinct_id": "actor-1",
            "user_prop_1": "v1",
        },
        // recipient object
        "recipients": []map[string]interface{}{
            {
                "distinct_id": "recipient-1",
                "$email":      []string{"abc@example.com"},
                "user_prop_1": "v1",
            },
            {
                "distinct_id": "recipient-2",
                "$email":      []string{"cde@example.com"},
                "user_prop_1": "v2",
            },
         },
         // dynamic data to render template and workflow variables
         "data": map[string]interface{}{
            "k1": "v1",
            "nested_var": map[string]interface{}{
            "k2": "v2",
         },
      },
    }
  
    w1 := &suprsend.WorkflowTriggerRequest{
      Body: wfReqBody,
    }
  
    resp, err := suprClient.Workflows.Trigger(w1)
    if err != nil {
      log.Fatalln(err)
    }
    log.Println(resp)
}`,

  java: `package test;

import java.io.IOException;
import java.io.UnsupportedEncodingException;
import java.util.Arrays;
  
import org.json.JSONArray;
import org.json.JSONObject;
  
import suprsend.Suprsend;
import suprsend.SuprsendException;
import suprsend.WorkflowTriggerRequest;
  
public class Workflow {
  
    public static void main(String[] args) throws Exception {
      WorkflowTrigger();
    }
  
    private static void WorkflowTrigger() throws SuprsendException, UnsupportedEncodingException {
        // Initialize SDK
        String apiKey = "<WORKSPACE_KEY>";
        String apiSecret = "<WORKSPACE_SECRET>";
        Suprsend suprClient = new Suprsend(apiKey, apiSecret);
      
        // payload
        JSONObject body = getWorkflowBody();
        WorkflowTriggerRequest wf = new WorkflowTriggerRequest(body);
      
        JSONObject resp = suprClient.workflows.trigger(wf);
        System.out.println(resp);
    }
  
    // Prepare Workflow Payload
    private static JSONObject getWorkflowBody() {
        JSONObject body = new JSONObject()
            .put("workflow", "__workflow_slug__")
            // actor object
            .put("actor", new JSONObject()
                .put("distinct_id", "actor-1")
                .put("user_prop_1", "v1")
            )
            // recipient object
            .put("recipients", new JSONArray()
                .put(new JSONObject()
                    .put("distinct_id", "recipient-1")
                    .put("$email", Arrays.asList("abc@example.com"))
                    .put("user_prop_1", "v1")
                )
            .put(new JSONObject()
                .put("distinct_id", "recipient-2")
                .put("$email", Arrays.asList("cde@example.com"))
                .put("user_prop_1", "v2"))
            )
            // dynamic data to render template and workflow variables
            .put("data", new JSONObject()
                .put("k1", "v1")
                .put("nested_var", new JSONObject()
                    .put("k2", "v2"))
            );
  
        return body;
      }
  }`,

  node: `const {Suprsend, WorkflowTriggerRequest} = require("@suprsend/node-sdk");

// Initialize SDK
const supr_client = new Suprsend("<WORKSPACE_KEY>", "<WORKSPACE_SECRET>");
  
// Prepare Workflow Payload
const workflow_payload = {
    "workflow": "workflow_slug",
    // actor object
    "actor": {
        "distinct_id":"actor-1",
        "user_prop_1":"v1"
    },
    // recipient object
    "recipients": [
        {
            "distinct_id": "recipient-1",
            "$email": ["abc@example.com"],
            "user_prop_1": "v1"
        },
        {
            "distinct_id": "recipient-2",
            "$email": ["cde@example.com"],
            "user_prop_1": "v2"
        }
    ],
    // dynamic data to render template and workflow variables
    "data": {
    "k1": "v1",
    "nested_var": {
        "k2": "v2"
     }
  }
}
  
const wf = new WorkflowTriggerRequest(workflow_payload)
  
const response = supr_client.workflows.trigger(wf);
response.then(res => console.log("response", res));`,

  curl: `curl --request POST \\
--url https://hub.suprsend.com/trigger/ \\
--header 'Authorization: Bearer <API_KEY>' \\
--header 'accept: application/json' \\
--header 'content-type: application/json' \\
--data '
{
    "workflow": "_workflow_slug_",
    "recipients": [
        {
            "distinct_id": "recipient-1",
            "$email": ["abc@example.com"],
            "user_prop1": "value_1"
        },
        {
            "distinct_id": "recipient-2",
            "$email": ["cde@example.com"],
            "user_prop1": "value_2"
        }
    ],
    "actor": {
    "distinct_id": "actor-1",
    "user_prop1": "value_1"
    },
    "data": {
        "key": {
            "k1": "v1",
            "k2": "v2"
        }
    }
}
'`,
};

const eventSnippet = {
  python: `from suprsend import Suprsend
from suprsend import Event

# Initialize SDK
supr_client = Suprsend("<WORKSPACE_KEY>", "<WORKSPACE_SECRET>")
  
distinct_id = "_distinct_id_" 
event_name = "_event_name_"   
  
# dynamic data to render template and workflow variables
properties = {													
   "key1":"value1",
   "key2":"value2"
} 
  
event = Event(distinct_id=distinct_id, event_name=event_name, properties=properties)
  
# Track event
response = supr_client.track_event(event)
print(response)`,

  go: `package main

import (
    "log"
  
    suprsend "github.com/suprsend/suprsend-go"
)
  
// Initialize SDK
func main() {
    suprClient, err := suprsend.NewClient("<WORKSPACE_KEY>", "<WORKSPACE_SECRET>")
    if err != nil {
      log.Println(err)
    }
    _ = suprClient
    trackEvent(suprClient)
}
  
func trackEvent(suprClient *suprsend.Client) {
    ev := &suprsend.Event{
       EventName:  "__event_name__",
       DistinctId: "__distinct_id__",  
      // dynamic data to render template and workflow variables
      Properties: map[string]interface{}{
        "k1": "v1",
        "k2":"v2",
      },
    }
      
    _, err := suprClient.TrackEvent(ev)
    if err != nil {
       log.Fatalln(err)
    }    
}`,

  java: `package suprsend;

import org.json.JSONObject;
import java.io.UnsupportedEncodingException;
  
import suprsend.Suprsend;
import suprsend.SuprsendException;
import suprsend.Event;
  
public class Event {
    public static void main(String[] args) throws Exception {
      trackEvent();
    }
  
    private static void trackEvent() throws SuprsendException, UnsupportedEncodingException{
       // Initialize SDK
       Suprsend suprClient = new Suprsend("<WORKSPACE_KEY>", "<WORKSPACE_SECRET>");
  
       String distinctId = "__distinct_id__";
       String eventName = "__event_name__";
  
       // dynamic data to render template and workflow variables
       JSONObject eventProps = new JSONObject()
           .put("key1", "value1")
           .put("key2", "value2");
  
       Event e = new Event(distinctId, eventName, eventProps);
       JSONObject response = suprClient.trackEvent(e);
       System.out.println(response);
     }
  }`,

  node: `const { Suprsend, Event } = require("@suprsend/node-sdk");

// Initialize SDK
const supr_client = new Suprsend("<WORKSPACE_KEY>", "<WORKSPACE_SECRET>");
  
const distinct_id = "_distinct_id_"
const event_name = "_event_name_"
  
// dynamic data to render template and workflow variables
const properties = {
    "key1": "value1",
    "key2": "value2"
}
  
const event = new Event(distinct_id, event_name, properties)
  
const response  = supr_client.track_event(event)
response.then((res) => console.log("response", res));`,

  curl: `curl --request POST \\
--url https://hub.suprsend.com/event/ \\
--header 'Authorization: Bearer <API_KEY>' \\
--header 'accept: application/json' \\
--header 'content-type: application/json' \\
--data '
{   
    "distinct_id": "_distinct_id_",
    "event": "_event_name_",
    "properties": {
         "key1": "value1",
         "key2": "value2"
    }
}
'`,
};

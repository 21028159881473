import { useEffect, useState, useMemo, useContext } from 'react';
import { ListBox } from './Forms/Common';
import CreatableSelect from 'react-select/creatable';
import { cn, MISSING_FIELD_ERROR, ValidateEventName } from 'utils';
import { useUniqueEventsAPI, useUpdateBranch } from 'apis';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
  Input,
  Label,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from 'new-components';
import { Plus, PencilIcon, Trash2Icon, X } from 'lucide-react';
import { cloneDeep } from 'lodash';
import { FlowContext } from './WorkflowDetails';
import { toast } from 'components';

const OPERATOR_OPTIONS = [
  { name: '==', value: '==' },
  { name: '!=', value: '!=' },
  { name: '>', value: '>' },
  { name: '>=', value: '>=' },
  { name: '<', value: '<' },
  { name: '<=', value: '<=' },
  { name: 'contains', value: 'CONTAINS' },
  { name: 'not contains', value: 'NOT_CONTAINS' },
  { name: 'is empty', value: 'EMPTY' },
  { name: 'is not empty', value: 'NON_EMPTY' },
];

export default function SubConditions({
  conditions,
  id,
  setConditionFormActive,
}) {
  const [conditionsData, setConditionsData] = useState();
  const [editModeConditionIndex, setEditModeConditionIndex] = useState(null);
  const [oldConditionBlock, setOldConditionBlock] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showConditionError, setShowConditionError] = useState(false);

  const { slug, version, setLoading, editMode } = useContext(FlowContext);

  const { status: uniqueEventStatus, data: uniqueEvents } =
    useUniqueEventsAPI();
  const updateBranch = useUpdateBranch(slug, version, id);

  useEffect(() => {
    setConditionsData(cloneDeep(conditions));
  }, [conditions]);

  const validateForm = condition => {
    let hasError = false;
    if (!condition?.event_name) {
      hasError = true;
      condition.error = true;
    }

    condition?.event_conditions?.map(subCondition => {
      const innerSubConditions = subCondition[subCondition.type]?.args || [];

      innerSubConditions?.map(innerSubCondition => {
        if (
          !innerSubCondition.variable ||
          !innerSubCondition?.op ||
          !innerSubCondition?.value
        ) {
          innerSubCondition.error = true;
          hasError = true;
        }
      });
    });

    setConditionsData([...conditionsData]);
    return hasError;
  };

  const formattedEventOptions = useMemo(() => {
    return (
      uniqueEvents?.map(event => ({
        label: event.event,
        value: event.event,
      })) || []
    );
  }, [uniqueEvents]);

  const disabled = !editMode;

  if (conditionsData?.length <= 0) {
    return (
      <div className="mt-1">
        <Button
          className="px-2 my-3 h-7"
          variant="outline"
          disabled={!editMode}
          onClick={() => {
            setConditionFormActive(true);
            setEditModeConditionIndex(0);
            setOldConditionBlock(null);
            conditionsData.push({
              type: 'future_event',
              event_name: '',
              event_conditions: [],
            });

            setConditionsData([...conditionsData]);
          }}
        >
          <Plus className="h-4 w-4 mr-2 text-accent-foreground" />
          <p className="text-accent-foreground">Add Condition</p>
        </Button>
      </div>
    );
  }
  return (
    <div>
      {conditionsData?.map((condition, conditionIndex) => {
        const eventName = condition?.event_name;
        const subConditions = condition?.event_conditions;
        const defaultCustomEvent = eventName
          ? { label: eventName, value: eventName }
          : null;
        const isLast = conditionsData.length === conditionIndex + 1;

        if (editModeConditionIndex !== conditionIndex) {
          return (
            <div key={conditionIndex}>
              <div className="border-l-[1.5px] pl-3 mb-2">
                <div
                  className="border text-sm bg-muted p-3 text-muted-foreground rounded"
                  onMouseEnter={() => {
                    setHoverIndex(conditionIndex);
                  }}
                  onMouseLeave={() => {
                    setHoverIndex(null);
                  }}
                >
                  <div className="flex items-center gap-2 justify-between">
                    <p>
                      <span className="font-medium text-foreground">
                        {eventName}{' '}
                      </span>
                      event is performed
                    </p>
                    {conditionIndex === hoverIndex &&
                      !disabled &&
                      editModeConditionIndex === null && (
                        <div className="flex gap-3 self-start mt-1">
                          <div
                            onClick={() => {
                              setConditionFormActive(true);
                              setEditModeConditionIndex(conditionIndex);
                              setOldConditionBlock(cloneDeep(condition));
                            }}
                            className="cursor-pointer"
                          >
                            <PencilIcon className="w-4 h-4" />
                          </div>
                          <DeleteConditionModal
                            disable={updateBranch.isLoading}
                            onDelete={async () => {
                              const clonedData = cloneDeep(conditionsData);
                              clonedData.splice(conditionIndex, 1);
                              setLoading(true);
                              await updateBranch.mutateAsync({
                                conditions: clonedData,
                              });
                              setLoading(false);
                              toast('Condition saved successfully', '', {
                                autoClose: 1000,
                              });
                              setConditionsData([...clonedData]);
                            }}
                            onClose={() => {}}
                          />
                        </div>
                      )}
                  </div>
                  {subConditions.length > 0 && (
                    <p className="mt-3 my-2">With property filters as</p>
                  )}

                  {subConditions?.map((subCondition, subConditionIndex) => {
                    const outerSubConditions = subCondition[subCondition.type];
                    const innerSubConditions = outerSubConditions.args;
                    const isLast =
                      subConditions.length === subConditionIndex + 1;

                    return (
                      <div key={subConditionIndex}>
                        <div className="border border-dashed rounded p-3">
                          {innerSubConditions?.map(
                            (innerSubCondition, innerSubConditionIndex) => {
                              const isLast =
                                innerSubConditions.length ===
                                innerSubConditionIndex + 1;
                              const operationLabel = OPERATOR_OPTIONS.find(
                                item => item.value === innerSubCondition.op
                              )?.name;

                              return (
                                <div key={innerSubConditionIndex}>
                                  <p className="text-foreground">
                                    {innerSubCondition.variable}{' '}
                                    {operationLabel} {innerSubCondition.value}
                                  </p>
                                  {!isLast && (
                                    <p className="text-sm font-medium text-muted-foreground my-1">
                                      {outerSubConditions.op}
                                    </p>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                        {!isLast && (
                          <p className="my-2 text-sm text-accent-foreground font-medium">
                            OR
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {isLast ? (
                <div>
                  {!disabled && (
                    <Button
                      className="px-1.5 h-6 flex gap-1"
                      type="button"
                      variant="outline"
                      disabled={
                        editModeConditionIndex !== null ||
                        conditions?.length === 5
                      }
                      onClick={() => {
                        setConditionFormActive(true);
                        setEditModeConditionIndex(conditionsData.length);
                        setOldConditionBlock(null);
                        conditionsData.push({
                          type: 'future_event',
                          event_name: '',
                          event_conditions: [],
                        });

                        setConditionsData([...conditionsData]);
                      }}
                    >
                      <Plus className="h-4 w-4 text-accent-foreground" />{' '}
                      <p className="text-xs text-accent-foreground">OR</p>
                    </Button>
                  )}
                </div>
              ) : (
                <p className="my-2 text-sm text-accent-foreground font-medium">
                  OR
                </p>
              )}
            </div>
          );
        }
        return (
          <div key={conditionIndex}>
            <div className="border-l-[1.5px] pl-3">
              <div>
                <ListBox
                  label="Type"
                  id="future_event"
                  disabled={true}
                  value="future_event"
                  displayValue="Event is performed"
                />
                <div className="mt-3">
                  <label
                    htmlFor="event_name"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Event name<span className="text-red-500">*</span>
                  </label>
                  <CreatableSelect
                    placeholder="Search or Type Event"
                    isLoading={uniqueEventStatus === 'loading'}
                    noOptionsMessage={() => 'No Events found'}
                    inputId="workflow-select-input"
                    defaultValue={defaultCustomEvent}
                    options={formattedEventOptions}
                    isDisabled={disabled}
                    styles={{
                      menuList: styles => {
                        return { ...styles, maxHeight: 200 };
                      },
                      placeholder: styles => ({
                        ...styles,
                        color: '#d4d4d4',
                        fontSize: 14,
                      }),
                      control: styles => {
                        return {
                          ...styles,
                          backgroundColor: disabled ? '#f9fafb' : '#fff',
                          border: '1px solid #d4d4d4',
                        };
                      },
                      singleValue: styles => {
                        return { ...styles, color: '#000', fontSize: 14 };
                      },
                    }}
                    getNewOptionData={v => {
                      if (v) {
                        const formattedEvent = ValidateEventName(v);
                        const upperCaseValue = formattedEvent?.toUpperCase();
                        return {
                          value: upperCaseValue,
                          label: upperCaseValue,
                        };
                      }
                    }}
                    onChange={selectedItem => {
                      if (selectedItem) {
                        condition.event_name = selectedItem.value;
                        setConditionsData([...conditionsData]);
                      }
                    }}
                  />
                  {condition?.error && (
                    <p className="mt-1 text-destructive text-sm">
                      {MISSING_FIELD_ERROR}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-3 mb-6">
                {subConditions.length > 0 ? (
                  <Label className="block text-sm font-medium text-gray-700">
                    with property filters as
                  </Label>
                ) : (
                  <Button
                    variant="outline"
                    className="py-0 h-7 mt-2"
                    onClick={() => {
                      condition.event_conditions = [
                        {
                          type: 'expression_v1',
                          expression_v1: {
                            op: 'AND',
                            args: [
                              {
                                variable_ns: '$future_event',
                                variable: '',
                                op: '==',
                                value: '',
                              },
                            ],
                          },
                        },
                      ];
                      setConditionsData([...conditionsData]);
                    }}
                  >
                    Filter on event properties
                  </Button>
                )}
                <div>
                  {subConditions?.map((subCondition, subConditionIndex) => {
                    const outerSubConditions = subCondition[subCondition.type];
                    const innerSubConditions = outerSubConditions.args;
                    const isLast =
                      subConditions.length === subConditionIndex + 1;

                    return (
                      <div key={subConditionIndex}>
                        <div className="border-l-[1.5px] pl-3">
                          {innerSubConditions?.map(
                            (innerSubCondition, innerSubConditionIndex) => {
                              const isLast =
                                innerSubConditions.length ===
                                innerSubConditionIndex + 1;

                              return (
                                <div key={innerSubConditionIndex}>
                                  <div className="flex items-center gap-1.5">
                                    <div
                                      className={cn(
                                        'border border-dashed p-4 my-3 rounded grow',
                                        innerSubCondition?.error &&
                                          'border-destructive border-solid'
                                      )}
                                    >
                                      <div className="flex gap-2 items-center mt-2">
                                        <div className="grow">
                                          <Input
                                            placeholder="key"
                                            value={innerSubCondition.variable}
                                            onChange={e => {
                                              innerSubCondition.variable =
                                                e.target.value;
                                              setConditionsData([
                                                ...conditionsData,
                                              ]);
                                            }}
                                          />
                                        </div>
                                        <div className="grow">
                                          <Select
                                            onValueChange={value => {
                                              innerSubCondition.op = value;
                                              setConditionsData([
                                                ...conditionsData,
                                              ]);
                                            }}
                                            value={innerSubCondition.op}
                                          >
                                            <SelectTrigger>
                                              <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                {OPERATOR_OPTIONS.map(
                                                  option => (
                                                    <SelectItem
                                                      value={option.value}
                                                      key={option.value}
                                                    >
                                                      {option.name}
                                                    </SelectItem>
                                                  )
                                                )}
                                              </SelectGroup>
                                            </SelectContent>
                                          </Select>
                                        </div>
                                      </div>
                                      {!['EMPTY', 'NON_EMPTY'].includes(
                                        innerSubCondition.op
                                      ) && (
                                        <div className="mt-4">
                                          <Input
                                            value={innerSubCondition.value}
                                            placeholder='"value"'
                                            onChange={e => {
                                              innerSubCondition.value =
                                                e.target.value;
                                              setConditionsData([
                                                ...conditionsData,
                                              ]);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <X
                                      className="w-4 h-4 cursor-pointer text-muted-foreground"
                                      onClick={() => {
                                        innerSubConditions.splice(
                                          innerSubConditionIndex,
                                          1
                                        );
                                        if (innerSubConditions?.length <= 0) {
                                          subConditions.splice(
                                            subConditionIndex,
                                            1
                                          );
                                        }
                                        setConditionsData([...conditionsData]);
                                      }}
                                    />
                                  </div>
                                  {isLast ? (
                                    <>
                                      {!disabled && (
                                        <Button
                                          type="button"
                                          className="px-1.5 h-6 flex gap-1"
                                          disabled={
                                            innerSubConditions?.length === 10
                                          }
                                          variant="outline"
                                          onClick={() => {
                                            innerSubConditions.push({
                                              variable_ns: '$future_event',
                                              variable: '',
                                              op: '==',
                                              value: '',
                                            });
                                            setConditionsData([
                                              ...conditionsData,
                                            ]);
                                          }}
                                        >
                                          <Plus className="h-4 w-4 text-accent-foreground" />{' '}
                                          <p className="text-xs text-accent-foreground">
                                            AND
                                          </p>
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <p className="text-sm pl-2 text-accent-foreground font-medium">
                                      {outerSubConditions.op}
                                    </p>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                        {isLast ? (
                          <>
                            {!disabled && (
                              <div>
                                <Button
                                  className="px-1.5 my-2 h-6 flex gap-1"
                                  variant="outline"
                                  type="button"
                                  disabled={subConditions?.length === 5}
                                  onClick={() => {
                                    subConditions.push({
                                      type: 'expression_v1',
                                      expression_v1: {
                                        op: 'AND',
                                        args: [
                                          {
                                            variable_ns: '$future_event',
                                            variable: '',
                                            op: '==',
                                            value: '',
                                          },
                                        ],
                                      },
                                    });
                                    setConditionsData([...conditionsData]);
                                  }}
                                >
                                  <Plus className="h-4 w-4 text-accent-foreground" />{' '}
                                  <p className="text-xs text-accent-foreground">
                                    OR
                                  </p>
                                </Button>
                              </div>
                            )}
                          </>
                        ) : (
                          <p className="text-sm my-2 text-accent-foreground font-medium">
                            OR
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {showConditionError && (
                <p className="text-destructive text-sm mb-2">
                  Mandatory Fields are missing
                </p>
              )}
              <div className="flex gap-4">
                <Button
                  className="py-0 h-7"
                  variant="outline"
                  onClick={() => {
                    setShowConditionError(false);
                    if (oldConditionBlock) {
                      conditionsData.splice(
                        editModeConditionIndex,
                        1,
                        oldConditionBlock
                      );
                    } else {
                      conditionsData.splice(editModeConditionIndex, 1);
                    }
                    setConditionFormActive(false);
                    setEditModeConditionIndex(null);
                    setOldConditionBlock(null);
                    setConditionsData([...conditionsData]);
                  }}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  className="py-0 h-7"
                  type="button"
                  disabled={updateBranch.isLoading}
                  onClick={async () => {
                    const hasErrors = validateForm(condition);
                    if (hasErrors) {
                      setShowConditionError(true);
                      return;
                    } else {
                      setShowConditionError(false);
                    }

                    setLoading(true);
                    await updateBranch.mutateAsync({
                      conditions: conditionsData,
                    });
                    setLoading(false);
                    setConditionFormActive(false);
                    setEditModeConditionIndex(null);
                    setOldConditionBlock(null);
                    toast('Condition saved successfully', '', {
                      autoClose: 1000,
                    });
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            {!isLast && (
              <p className="my-2 text-sm text-accent-foreground font-medium">
                OR
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
}

function DeleteConditionModal({ onDelete, disable }) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild className="cursor-pointer">
        <Trash2Icon className="w-4 h-4" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Delete Condition?</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          You'll not be able to undo this action.
        </DialogDescription>
        <DialogFooter>
          <DialogClose>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <Button
            type="submit"
            variant="destructive"
            onClick={async () => {
              await onDelete();
              setOpen(false);
            }}
            disabled={disable}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

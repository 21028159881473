import { createContext, useState } from 'react';
import { useAuthState } from 'stores/AuthStore';

const TimezonePreferenceContext = createContext();

function TimezonePreferenceProvider(props) {
  const authState = useAuthState();

  const storedTimezonePreference = localStorage.getItem(
    'suprsend-user-time-preference'
  );
  const parseStoredTimezonePreference = storedTimezonePreference
    ? JSON.parse(storedTimezonePreference)
    : '';
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userInfo = authState.user.get();

  let initialData = {};

  if (storedTimezonePreference) {
    initialData = parseStoredTimezonePreference;
  } else if (userInfo?.timezone) {
    initialData = {
      timezone: {
        id: 'preferred',
        value: userInfo?.timezone,
      },
      showRelative: false,
    };
  } else if (localTimezone) {
    initialData = {
      timezone: {
        id: 'local',
        value: localTimezone,
      },
      showRelative: false,
    };
  }

  const [timezone, setTimezone] = useState(initialData.timezone);
  const [showRelative, setShowRelative] = useState(initialData.showRelative);

  return (
    <TimezonePreferenceContext.Provider
      value={{ timezone, setTimezone, showRelative, setShowRelative }}
    >
      {props.children}
    </TimezonePreferenceContext.Provider>
  );
}

export { TimezonePreferenceContext, TimezonePreferenceProvider };

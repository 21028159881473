import { useState, useEffect, useCallback, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Modal, Button, TextField } from 'components';
import { useAuthState } from 'stores/AuthStore';
import { classNames } from 'utils';
import { useSearchedWorkflows, useCloneWorkflow } from 'apis';
import { ListBox } from './Forms/Common';
import { InformationCircleIcon, XIcon } from '@heroicons/react/solid';
import { ReactComponent as CheckCircleIcon } from 'assets/svgs/successTick.svg';

const CloneForm = ({
  wfDetails,
  setShowCloneModal,
  setCurrentStep,
  timer,
  setTimer,
  timerRef,
}) => {
  const authState = useAuthState();
  const history = useHistory();
  const currentLang = localStorage.getItem('i18nextLng') || 'en';

  const [workflowSearchText, setWorkflowSearchText] = useState('');
  const [workspaceSlug, setWorkspaceSlug] = useState('production');

  const { data: workflows, status: workflowStatus } = useSearchedWorkflows({
    searchedText: workflowSearchText,
    workspaceSlug: workspaceSlug,
    isDynamic: false,
  });
  const cloneWorkflow = useCloneWorkflow(wfDetails?.slug);

  const allEnv = authState.organization.get()?.environments;
  const workflowData = workflows?.results;

  return (
    <div className="pt-5 pb-4 sm:p-6 sm:pb-4">
      <p className="text-lg leading-6 font-medium text-gray-900">
        {`Clone - ${wfDetails?.name}`}
      </p>
      <Formik
        initialValues={{
          source_version: 'live',
          target_workflow_name: '',
          target_workflow_slug: '',
          workflow_name_type: 'ADD_NEW_WORKFLOW',
        }}
        onSubmit={async values => {
          const payload = {
            target_workspace_slug: workspaceSlug,
          };
          if (values?.workflow_name_type === 'ADD_NEW_WORKFLOW') {
            payload.target_workflow_name = values?.target_workflow_name;
          } else {
            payload.target_workflow_slug = values?.target_workflow_slug?.slug;
          }
          payload.source_version_id =
            values?.source_version === 'live'
              ? wfDetails?.live_version?.id
              : wfDetails?.draft_version?.id;
          const response = await cloneWorkflow.mutateAsync(payload);
          setCurrentStep(2);

          if (!timer) {
            setTimer(3);
          }

          const timeoutIds = setTimeout(() => {
            history.push(
              `/${currentLang}/${workspaceSlug}/workflows/${response?.data?.slug}/edit`
            );
            setCurrentStep(1);
          }, 3000);
          timerRef.current = timeoutIds;
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="flex flex-col mt-6">
                <p className="text-base font-medium">Source</p>
                <p className="text-sm text-gray-700 font-medium mt-2.5">
                  Workflow Version
                </p>
                <div className="flex mt-2 gap-5">
                  <button
                    type="button"
                    className="flex items-center w-[50%] border p-2 rounded-md ring-0 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed outline-none"
                    disabled={!wfDetails?.draft_version?.id}
                    onClick={() => {
                      setFieldValue('source_version', 'draft');
                    }}
                  >
                    <div
                      className={classNames(
                        values?.source_version === 'draft'
                          ? 'bg-[#2E70E8] border-[#2E70E8]'
                          : 'white border-gray-300',
                        'h-4 w-4 border rounded-full'
                      )}
                    >
                      <div
                        className={classNames(
                          'bg-white h-[7px] w-[7px] rounded-full mt-[3.5px] ml-[3.5px]'
                        )}
                      />
                    </div>

                    <p className="text-sm ml-2.5">Draft</p>
                  </button>
                  <button
                    type="button"
                    className="flex items-center w-[50%] border p-2 rounded-md cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!wfDetails?.live_version?.id}
                    onClick={() => {
                      setFieldValue('source_version', 'live');
                    }}
                  >
                    <div
                      className={classNames(
                        values?.source_version === 'live'
                          ? 'bg-[#2E70E8] border-[#2E70E8]'
                          : 'white border-gray-300',
                        'h-4 w-4 border rounded-full'
                      )}
                    >
                      <div
                        className={classNames(
                          'bg-white h-[7px] w-[7px] rounded-full mt-[3.5px] ml-[3.5px]'
                        )}
                      />
                    </div>
                    <p className="text-sm ml-2.5">Live</p>
                  </button>
                </div>
              </div>
              <div className="flex flex-col mt-8">
                <p className="text-base font-medium mb-2.5">Destination</p>
                <ListBox
                  label="Workspace"
                  id="workspace"
                  mandatory={false}
                  value={workspaceSlug}
                  handleOnchange={value => {
                    setWorkspaceSlug(value);
                  }}
                  getLabel={e => {
                    return e.name;
                  }}
                  getValue={e => {
                    return e.slug;
                  }}
                  options={allEnv}
                />
              </div>
              <div className="mt-[18px]">
                <p className="text-sm text-gray-700 font-medium">
                  Workflow name
                </p>
                <button
                  type="button"
                  className="mt-2 w-full border p-2.5 rounded-md ring-0 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed outline-none"
                  onClick={() => {
                    setFieldValue('workflow_name_type', 'ADD_NEW_WORKFLOW');
                    setFieldValue('target_workflow_slug', '');
                  }}
                >
                  <div className="flex items-center">
                    <div
                      className={classNames(
                        values?.workflow_name_type === 'ADD_NEW_WORKFLOW'
                          ? 'bg-[#2E70E8] border-[#2E70E8]'
                          : 'white border-gray-300',
                        'h-4 w-4 border  rounded-full'
                      )}
                    >
                      <div
                        className={classNames(
                          'bg-white h-[7px] w-[7px] rounded-full mt-[3.5px] ml-[3.5px]'
                        )}
                      />
                    </div>
                    <p className="text-sm ml-2.5">Add a new workflow</p>
                  </div>
                  {values?.workflow_name_type === 'ADD_NEW_WORKFLOW' && (
                    <TextField
                      name="target_workflow_name"
                      className="mt-4"
                      placeholder="Add a workflow name"
                      inputClassName="p-[6px]"
                    />
                  )}
                </button>
                <button
                  type="button"
                  className="mt-4 w-full border p-2.5 rounded-md ring-0 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed outline-none"
                  onClick={() => {
                    setFieldValue(
                      'workflow_name_type',
                      'UPDATE_EXISTING_WORKFLOW'
                    );
                    setFieldValue('target_workflow_name', '');
                  }}
                >
                  <div className="flex items-center">
                    <div
                      className={classNames(
                        values?.workflow_name_type ===
                          'UPDATE_EXISTING_WORKFLOW'
                          ? 'bg-[#2E70E8] border-[#2E70E8]'
                          : 'white border-gray-300',
                        'h-4 w-4 border rounded-full'
                      )}
                    >
                      <div
                        className={classNames(
                          'bg-white h-[7px] w-[7px] rounded-full mt-[3.5px] ml-[3.5px]'
                        )}
                      />
                    </div>

                    <p className="text-sm ml-2.5">
                      Update an existing workflow
                    </p>
                  </div>
                  {values?.workflow_name_type ===
                    'UPDATE_EXISTING_WORKFLOW' && (
                    <div className="mt-4">
                      <Select
                        placeholder="Search workflow name"
                        value={values?.target_workflow_slug}
                        isSearchable
                        inputId="workflow-select-input"
                        styles={{
                          multiValueLabel: base => ({
                            ...base,
                          }),
                          control: (styles, state) => {
                            return {
                              ...styles,
                              backgroundColor: '#fff',
                              border: '1px solid #d4d4d4',
                              textAlign: 'left',
                              '&:hover': {
                                border: '1px solid #d4d4d4',
                              },
                            };
                          },
                          placeholder: styles => ({
                            ...styles,
                            color: '#d4d4d4',
                            fontSize: 14,
                          }),
                          singleValue: styles => {
                            return {
                              ...styles,
                              color: '#000',
                              fontSize: 14,
                            };
                          },
                          option: styles => {
                            return {
                              ...styles,
                              textAlign: 'left',
                            };
                          },
                        }}
                        isLoading={workflowStatus === 'loading'}
                        noOptionsMessage={() => 'No workflows found'}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.slug}
                        onChange={selectedItem => {
                          if (selectedItem) {
                            setFieldValue('target_workflow_slug', selectedItem);
                          }
                        }}
                        options={workflowData}
                        onInputChange={inputValue => {
                          setWorkflowSearchText(inputValue);
                        }}
                      />
                    </div>
                  )}
                </button>
              </div>
              <div className="mt-8 flex items-center bg-indigo-100 px-4 py-[5px] rounded-md">
                <InformationCircleIcon className="h-3 w-3 text-indigo-600" />
                <p className="text-xs text-gray-600 ml-2">
                  Cloned workflow will be shown in draft mode
                </p>
              </div>
              <div className="flex mb-6 mt-8 gap-5">
                <Button
                  className="items-center justify-center text-center w-[50%]"
                  as="button"
                  onClick={() => {
                    setShowCloneModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  as="submit"
                  disabled={
                    cloneWorkflow?.isLoading ||
                    (values?.workflow_name_type ===
                      'UPDATE_EXISTING_WORKFLOW' &&
                      !values?.target_workflow_slug) ||
                    (values?.workflow_name_type === 'ADD_NEW_WORKFLOW' &&
                      !values?.target_workflow_name)
                  }
                  className="items-center justify-center w-[50%]"
                >
                  Clone
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

function CloneWorkflowModal({ showCloneModal, setShowCloneModal, wfDetails }) {
  const timerRef = useRef(null);

  const [timer, setTimer] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);

  const timeOutCallback = useCallback(
    () => setTimer(currTimer => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  return (
    <Modal open={showCloneModal} setOpen={setShowCloneModal}>
      <div className="max-w-md inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          {currentStep === 1 ? (
            <CloneForm
              wfDetails={wfDetails}
              setShowCloneModal={setShowCloneModal}
              setCurrentStep={setCurrentStep}
              timer={timer}
              setTimer={setTimer}
              timerRef={timerRef}
            />
          ) : (
            <div className="sm:p-4">
              <div
                onClick={() => {
                  setShowCloneModal(false);
                  clearInterval(timerRef.current);
                  setTimeout(() => {
                    setCurrentStep(1);
                  }, 500);
                }}
              >
                <XIcon className="h-5 w-5 text-gray-600 ml-auto cursor-pointer" />
              </div>
              <div className="flex flex-col items-center justify-center">
                <CheckCircleIcon className="h-10 w-10 mt-2 text-[#07AE2E]" />
                <p className="text-lg font-medium text-center mt-3">
                  Workflow cloned successfully
                </p>
                <p className="text-center mt-3 text-sm text-gray-800">
                  Redirecting to cloned workflow
                </p>
                <p className="text-center mb-3 text-sm mt-1 text-gray-800">{`in ${timer} seconds`}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CloneWorkflowModal;

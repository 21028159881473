import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Spinner } from './components';
import mixpanel from 'mixpanel-browser';
import zipy from 'zipyai';
import toast from 'react-hot-toast';
import package_json from '../package.json';

const ZipySdkKey = process.env.REACT_APP_ZIPY_SDK_KEY;
zipy.init(ZipySdkKey, { releaseVer: package_json.version });

const MixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(MixPanelToken);

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="h-screen">
          <Spinner />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register({
//   onUpdate: async registration => {
//     console.log('New Version Available Now!');
//     toast.custom(
//       <div className="flex bg-white px-6 py-3 items-center justify-between rounded-lg shadow border border-gray-100">
//         <p className="mr-8 text-gray-700">
//           New version of SuprSend is available.
//         </p>
//         <button
//           onClick={async () => {
//             if (registration && registration.waiting) {
//               await registration.unregister();
//               // Makes Workbox call skipWaiting()
//               registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//               // Once the service worker is unregistered, we can reload the page to let
//               // the browser download a fresh copy of our app (invalidating the cache)
//               window.location.reload();
//             }
//           }}
//           className="text-blue-500"
//         >
//           Reload
//         </button>
//       </div>,
//       {
//         duration: 5000,
//       }
//     );
//   },
// });

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'new-components';
import { useEditAccess } from 'utils';
import NodeDetail from './NodeDetail';
import WorkflowStartedRow from './WorkflowStartedRow';
import BasicLogRow from './BasicLogRow';
import NotificationGroupRow from './NotificationGroupRow';
var _ = require('lodash');

export default function ObservabilityLogs({ selectedNode, logs }) {
  const editAccess = useEditAccess();

  let filteredlogs = [];
  let defaultOpenState = true;

  if (_.isEmpty(selectedNode)) {
    filteredlogs = logs.sort('time').data;
    defaultOpenState = false;
  } else {
    switch (selectedNode.node_type) {
      case 'condition_node':
        filteredlogs = logs.data;
        break;
      case 'trigger':
        filteredlogs = logs
          .find({ logtype: 'workflow_start' })
          .sort('time').data;
        break;
      case 'exit':
        filteredlogs = logs.find({ logtype: 'workflow_end' }).sort('time').data;
        break;
      default:
        filteredlogs = logs
          .find({ node_id: selectedNode.id })
          .sort('time').data;
        break;
    }
  }

  return (
    <>
      <NodeDetail selectedNode={selectedNode} logs={logs} />
      {filteredlogs.length > 0 && (
        <div className="flex flex-col px-4 my-3">
          <p className="font-semibold text-sm mb-1">Execution History</p>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[10px]" />
                <TableHead className="w-[180px]">Timestamp</TableHead>
                <TableHead>Message</TableHead>
                <TableHead>{editAccess && 'Properties'}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredlogs.map((oblog, index) => {
                let barColor = `--muted-foreground`;
                switch (oblog['other_info_map.log_level']) {
                  case 'info':
                    barColor = `--success`;
                    break;
                  case 'error':
                    barColor = `--destructive`;
                    break;
                  case 'warning':
                    barColor = `--warning-subtle`;
                    break;
                  default:
                    break;
                }

                let RowComponent = null;
                if (!RowComponent) {
                  switch (oblog?.['logtype']) {
                    case 'workflow_start':
                      RowComponent = WorkflowStartedRow;
                      break;
                    default:
                      RowComponent = null;
                      break;
                  }
                }
                if (!RowComponent) {
                  switch (oblog?.['other_info_map.logsubtype']) {
                    case 'notification_group':
                      RowComponent = NotificationGroupRow;
                      break;
                    default:
                      RowComponent = BasicLogRow;
                      break;
                  }
                }
                return (
                  <RowComponent
                    key={`${oblog?.time}-${index}`}
                    oblog={oblog}
                    barColor={barColor}
                    defaultOpenState={defaultOpenState}
                    selectedNode={selectedNode}
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
}

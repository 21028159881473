import { useState } from 'react';
import { CircleCheck, Clock, CircleX, ExternalLinkIcon } from 'lucide-react';
import { useNotificationGroupDetail } from 'apis';
import { Spinner, TimeAgo } from 'components';
import { TimeDiff } from 'custom-components';
import { addSeconds, formatDistanceStrict } from 'date-fns';
import Xarrow, { Xwrapper } from 'react-xarrows';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableCell,
  TableRow,
} from 'new-components';
import LocalDB from 'utils/LocalDB';
import { classNames, cn, useEditAccess } from 'utils';
import { ReactComponent as WhatsappIcon } from 'assets/svgs/whatsappBlue.svg';
import { ReactComponent as SMSIcon } from 'assets/svgs/smsBlue.svg';
import { ReactComponent as EmailIcon } from 'assets/svgs/emailBlue.svg';
import { ReactComponent as AndroidpushIcon } from 'assets/svgs/androidpushBlue.svg';
import { ReactComponent as WebpushIcon } from 'assets/svgs/webpushBlue.svg';
import { ReactComponent as IOSpushIcon } from 'assets/svgs/iospushBlue.svg';
import { ReactComponent as InboxIcon } from 'assets/svgs/inboxBlue.svg';
import { ReactComponent as SlackIcon } from 'assets/svgs/slackBlue.svg';
import { ReactComponent as MSTeamsIcon } from 'assets/svgs/msTeamsIcon.svg';
var _ = require('lodash');

const ChannelIconsList = {
  whatsapp: <WhatsappIcon className="h-[16px] w-[16px]" />,
  sms: <SMSIcon className="h-[16px] w-[16px]" />,
  email: <EmailIcon className="h-[16px] w-[16px]" />,
  webpush: <WebpushIcon className="h-[16px] w-[16px]" />,
  inbox: <InboxIcon className="h-[16px] w-[16px]" />,
  androidpush: <AndroidpushIcon className="h-[16px] w-[16px]" />,
  iospush: <IOSpushIcon className="h-[16px] w-[16px]" />,
  slack: <SlackIcon className="h-[16px] w-[16px]" />,
  ms_teams: <MSTeamsIcon className="h-[16px] w-[16px]" />,
};
const channelsToDisplayIdentityValue = ['sms', 'email', 'whatsapp'];

const pairWise = a => a.slice(1).map((k, i) => [a[i], k]);

function humanizeNodeName(str) {
  //cleanup few words to make thing presentable
  str = str.replace('httpapi', '');
  if (str.startsWith('send')) str = str.replace('send', '');
  //remove underscore and capitalize string
  var i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  str = frags.join(' ').trim();

  if (str.toLowerCase() === 'sms') {
    return 'SMS';
  }
  return str;
}

const NotificationGroupRow = function ({ oblog, barColor, defaultOpenState }) {
  const editAccess = useEditAccess();
  const openState = defaultOpenState && editAccess;
  const [open, setOpen] = useState(openState);
  let notigrp_id = JSON.parse(oblog?.data)['ngid'];

  const { data: ngrpdetails, isFetching } = useNotificationGroupDetail({
    notigrp_id,
    enabled: open,
  });

  if (isFetching) {
    return <Spinner />;
  }

  if (!_.isEmpty(ngrpdetails)) {
    var ng_statuses = new LocalDB(ngrpdetails.ng_notification_statuses);
    window.ngrpdetails = ngrpdetails;
    window.ng_statuses = ng_statuses;
    var channels = ng_statuses.getValue('channel');
    var isSmartDelivery = ngrpdetails.ng_delivery.smart;
    var ngrp_triggred_at = oblog.time;
    var entries_with_error = _.reject(ng_statuses.data, function (o) {
      return _.isEmpty(o.err_log);
    });
  }

  return (
    <>
      <Xwrapper>
        <TableRow
          className={cn(editAccess && 'cursor-pointer')}
          onClick={() => {
            if (editAccess) {
              setOpen(!open);
            }
          }}
        >
          <TableCell>
            <div
              className="h-6 w-1 rounded-lg"
              style={{
                backgroundColor: `hsl(var(${barColor}))`,
              }}
            />
          </TableCell>
          <TableCell>
            <TimeAgo dateInput={oblog.time} className="py-3" />
          </TableCell>
          <TableCell colSpan="2">
            {!!channels
              ? `processing notifications - ${_.uniq(channels)?.join(', ')}`
              : `processing notifications`}
          </TableCell>
        </TableRow>
        {open && (
          <>
            <tr className="shadow-inner w-full border-b">
              <td colSpan="4">
                <div className="min-h-12 pt-6 ngdelivery pb-6">
                  <TooltipProvider>
                    <div className="flex flex-col space-y-5">
                      <div className="flex flex-grow place-content-around">
                        <div className="w-1/2 flex justify-center items-center"></div>
                        <div className=" w-1/2 flex flex-grow">
                          <div className="w-1/5 flex justify-center items-center">
                            <p className="text-xs font-medium">Vendor</p>
                          </div>
                          <div className="w-1/5 flex justify-center items-center">
                            <p className="text-xs font-medium">Triggered</p>
                          </div>
                          <div className="w-1/5 flex justify-center items-center">
                            <p className="text-xs font-medium">Delivered</p>
                          </div>
                          <div className="w-1/5 flex justify-center items-center">
                            <p className="text-xs font-medium">Seen</p>
                          </div>
                          <div className="w-1/5 flex justify-center items-center">
                            <p className="text-xs font-medium">Clicked</p>
                          </div>
                        </div>
                      </div>
                      {/* all entries which are not in "not to be triggred state" */}
                      {ng_statuses.data.map(item => {
                        if (STATUS.not_to_be_triggered.includes(item.status)) {
                          return <></>;
                        }
                        return renderLogRow(
                          item,
                          isSmartDelivery,
                          ngrp_triggred_at
                        );
                      })}
                      {isSmartDelivery &&
                        renderSuccessRow(
                          ngrpdetails?.ng_delivery,
                          notigrp_id,
                          ngrp_triggred_at
                        )}
                      {ng_statuses
                        .find({ status: 'not_to_be_triggered' })
                        .data.map(item => {
                          return renderLogRow(
                            item,
                            isSmartDelivery,
                            ngrp_triggred_at
                          );
                        })}
                      {renderVendorFallback(
                        ng_statuses.find({ vfallback_applicable: true })
                      )}
                      {isSmartDelivery &&
                        renderSmartTimeline(
                          ng_statuses.data,
                          notigrp_id,
                          !!ngrpdetails?.ng_delivery?.success_achieved_at
                        )}
                    </div>
                    {entries_with_error.length > 0 && (
                      <div className="mt-6 pt-3 border-t">
                        <div className="grid grid-cols-2 ml-6 mr-4">
                          <div className="text-base font-medium">
                            Failure Reason
                          </div>
                          <div className="">
                            <a
                              href="https://docs.suprsend.com/docs/error-guides"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary hover:underline flex gap-1 justify-end"
                            >
                              Refer error guide
                              <ExternalLinkIcon size={16}></ExternalLinkIcon>
                            </a>
                          </div>
                        </div>
                        <Table className="mt-4 border-r-0 border-l-0 border-b-0">
                          <TableHeader>
                            <TableRow>
                              <TableHead className="pl-6">Channel</TableHead>
                              <TableHead>Value</TableHead>
                              <TableHead>Reason</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {entries_with_error.map(item => {
                              return (
                                <TableRow>
                                  <TableCell className="content-start pl-6 text-primary">
                                    <p className="text-primary">
                                      {ChannelIconsList[item.channel]}
                                    </p>
                                  </TableCell>
                                  <TableCell className="content-start">
                                    {channelsToDisplayIdentityValue.includes(
                                      item.channel
                                    ) ? (
                                      <p>{item.channel_value}</p>
                                    ) : (
                                      <p>{humanizeNodeName(item.channel)}</p>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div className="">
                                      <p className="font-medium max-w-96 text-balance">
                                        {`${humanizeNodeName(
                                          item.status
                                        )} via ${
                                          item.tenant_vendor.vendor_name
                                        }`}
                                      </p>
                                      <p className="text-balance overflow-hidden max-w-96 mt-2">
                                        {item.err_log?.message}
                                      </p>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                  </TooltipProvider>
                </div>
              </td>
            </tr>
          </>
        )}
      </Xwrapper>
    </>
  );
};

function renderSmartTimeline(logs, notigrp_id, wasSuccessAchieved) {
  const triggered_logs = _.reject(logs, { status: 'not_to_be_triggered' });
  const triggered_logs_with_vfall = _.filter(triggered_logs, {
    vfallback_level: 0,
  });
  const not_to_be_triggered_logs = _.filter(logs, {
    status: 'not_to_be_triggered',
  });
  const last_triggered_log = _.last(triggered_logs_with_vfall);
  const first_not_triggred = _.first(not_to_be_triggered_logs);

  return (
    <>
      {pairWise(triggered_logs_with_vfall).map(lpair => {
        const log1 = lpair[0];
        const log2 = lpair[1];
        return (
          <>
            <Xarrow
              start={`${log1.nid}-icon`}
              end={`${log2.nid}-icon`}
              showHead={false}
              strokeWidth={3}
              color={STATUS.progress.includes(log2.status) ? 'gray' : '#3B80F2'}
            />
          </>
        );
      })}
      {wasSuccessAchieved && (
        <Xarrow
          start={`${last_triggered_log.nid}-icon`}
          end={`${notigrp_id}-success`}
          showHead={false}
          strokeWidth={3}
          color="#3B80F2"
        />
      )}
      {wasSuccessAchieved && first_not_triggred && (
        <Xarrow
          start={`${notigrp_id}-success`}
          end={`${first_not_triggred.nid}-icon`}
          showHead={false}
          strokeWidth={3}
          color="gray"
        />
      )}
      {pairWise(not_to_be_triggered_logs).map(lpair => {
        const log1 = lpair[0];
        const log2 = lpair[1];
        return (
          <>
            <Xarrow
              start={`${log1.nid}-icon`}
              end={`${log2.nid}-icon`}
              showHead={false}
              strokeWidth={3}
              color="gray"
            />
          </>
        );
      })}
    </>
  );
}

function renderSuccessRow(ngdelivery, notigrp_id, ngrp_triggred_at) {
  if (!ngdelivery.success_achieved_at) {
    return <></>;
  }
  return (
    <div className="flex flex-grow place-content-around">
      <div className="w-1/2 flex flex-col pl-6">
        <div className="flex w-full justify-center items-center space-x-3 ">
          <div className="text-right font-light text-xs text-muted-foreground w-12 max-w-12">
            <TimeDiff
              fromDate={ngrp_triggred_at}
              toDate={ngdelivery.success_achieved_at}
            />
          </div>
          <div
            className="rounded-full p-[2px] stroke-green-600"
            id={`${notigrp_id}-success`}
          >
            <CircleCheck color="green-600" />
          </div>
          <div className="flex flex-col flex-grow font-medium text-sm">
            <div>Success metric achieved</div>
            <div className="text-xs text-muted-foreground">
              Recieved event - "{ngdelivery?.success}"
            </div>
          </div>
        </div>
      </div>
      <div className=" w-1/2 flex flex-grow"></div>
    </div>
  );
}

function formattedTime(seconds) {
  let helperDate = addSeconds(new Date(), seconds);
  let str = formatDistanceStrict(helperDate, new Date(), 'mm:ss');
  str = str.replace('seconds', 's').replace('second', 's');
  str = str.replace('minutes', 'm').replace('minute', 'm');
  str = str.replace('days', 'd').replace('day', 'd');
  str = str.replace('months', 'mo').replace('month', 'mo');
  str = str.replace('years', 'y').replace('year', 'y');
  str = str.replace(' ', '');
  return str;
}

function renderLogRow(item, isSmartDelivery, ngrp_triggred_at) {
  const channel_type = item?.channel;

  return (
    <div className="flex flex-grow place-content-around" key={item.nid}>
      <div className="w-1/2 flex flex-col pl-6">
        {item.vfallback_level === 0 ? (
          <div className="flex w-full justify-center items-center space-x-3 ">
            {isSmartDelivery &&
            !STATUS.not_to_be_triggered.includes(item.status) ? (
              <div className="text-right font-light text-xs text-muted-foreground w-12 max-w-12">
                {!!item.wait_time_in_seconds ? (
                  formattedTime(item.wait_time_in_seconds)
                ) : (
                  <TimeDiff
                    fromDate={ngrp_triggred_at}
                    toDate={item?.triggered_at}
                  />
                )}
              </div>
            ) : (
              <div className="w-12"></div>
            )}
            <div
              className={classNames(
                'text-white rounded-full p-[6px] h-[28px] w-[28px]',
                STATUS.not_to_be_triggered.includes(item.status) ||
                  STATUS.progress.includes(item.status)
                  ? 'bg-gray-400'
                  : 'bg-blue-500'
              )}
              id={`${item.nid}-icon`}
            >
              {ChannelIconsList[channel_type]}
            </div>
            <div className="flex flex-col flex-grow font-medium text-sm">
              <div>{humanizeNodeName(channel_type)}</div>
              <div className="text-xs text-muted-foreground">
                {channelsToDisplayIdentityValue.includes(channel_type) && (
                  <p>{item.channel_value}</p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="w-1/2 flex flex-grow">
        <div className="w-1/5 flex justify-center items-center">
          {item.status !== 'not_to_be_triggered' && (
            <Tooltip>
              <TooltipTrigger asChild id={`${item.nid}-vendor`}>
                <div className="rounded-full border bg-white">
                  <img
                    src={`/vendors/${item?.tenant_vendor.vendor_name}.svg`}
                    alt={`Vendor: ${item?.tenant_vendor.vendor_name}`}
                    className="p-1"
                    height={32}
                    width={32}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="max-w-72 text-wrap space-y-2">
                  <p>Vendor: {item?.tenant_vendor.vendor_name}</p>
                  <p>Name: {item?.tenant_vendor.nickname}</p>
                </div>
              </TooltipContent>
            </Tooltip>
          )}
        </div>
        <div className="w-1/5 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            {renderTriggeredStatus(item)}
          </div>
        </div>
        <div className="w-1/5 flex justify-center items-center">
          <p className="text-xs font-medium">{renderDeliveredStatus(item)}</p>
        </div>
        <div className="w-1/5 flex justify-center items-center">
          <p className="text-xs font-medium">{renderSeenStatus(item)}</p>
        </div>
        <div className="w-1/5 flex justify-center items-center">
          <p className="text-xs font-medium">{renderClickedStatus(item)}</p>
        </div>
      </div>
    </div>
  );
}

const STATUS = {
  success: [
    'triggered',
    'sent_by_vendor',
    'delivered',
    'dismissed',
    'seen',
    'interacted',
    'failure_by_vendor',
  ],
  delivered: ['delivered', 'dismissed', 'seen', 'interacted'],
  seen: ['seen', 'interacted'],
  error: ['trigger_failed', 'not_delivered'],
  vendor_error: ['failure_by_vendor'],
  progress: ['to_be_triggered'],
  not_to_be_triggered: ['not_to_be_triggered'],
  clicked: ['interacted'],
};

function renderTriggeredStatus(item) {
  if (STATUS.not_to_be_triggered.includes(item.status)) {
    return (
      <>
        {/* <Tooltip>
          <TooltipTrigger asChild>
            <DotIcon color="green" size={50} />
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-72 text-wrap">
              Not to be triggered, {item.note}
            </p>
          </TooltipContent>
        </Tooltip> */}
      </>
    );
  }

  if (STATUS.progress.includes(item.status)) {
    return (
      <>
        <Tooltip>
          <TooltipTrigger asChild>
            <Clock size={20} color="gray" />
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-72 text-wrap">Waiting to be triggered</p>
          </TooltipContent>
        </Tooltip>
      </>
    );
  }
  if (STATUS.error.includes(item.status)) {
    return (
      <>
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleX size={20} color="red" id={`${item.nid}-trigger`} />
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-72 text-wrap mb-2">
              Error: {item?.err_log?.message}
            </p>
            <TimeAgo dateInput={item.triggered_at} />
          </TooltipContent>
        </Tooltip>
        <Xarrow
          start={`${item.nid}-vendor`}
          end={`${item.nid}-trigger`}
          showHead={true}
          strokeWidth={1}
          color="gray"
        />
      </>
    );
  }
  if (STATUS.success.includes(item.status)) {
    return (
      <>
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleCheck size={20} color="green" id={`${item.nid}-trigger`} />
          </TooltipTrigger>
          <TooltipContent>
            Triggered <TimeAgo dateInput={item.triggered_at} />
          </TooltipContent>
        </Tooltip>
        <Xarrow
          start={`${item.nid}-vendor`}
          end={`${item.nid}-trigger`}
          showHead={true}
          strokeWidth={1}
          color="gray"
        />
      </>
    );
  }

  return <></>;
}

function renderDeliveredStatus(item) {
  if (STATUS.vendor_error.includes(item.status)) {
    return (
      <>
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleX size={20} color="red" id={`${item.nid}-delivered`} />
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-72 text-wrap mb-2">
              Error: {item?.err_log?.message}
            </p>
            <TimeAgo dateInput={item.triggered_at}></TimeAgo>
          </TooltipContent>
        </Tooltip>
        <Xarrow
          start={`${item.nid}-trigger`}
          end={`${item.nid}-delivered`}
          showHead={false}
          strokeWidth={1}
          color="gray"
        />
      </>
    );
  }
  if (STATUS.delivered.includes(item.status)) {
    return (
      <>
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleCheck size={20} color="green" id={`${item.nid}-delivered`} />
          </TooltipTrigger>
          <TooltipContent>
            Delivered <TimeAgo dateInput={item.delivered_at} />
          </TooltipContent>
        </Tooltip>
        <Xarrow
          start={`${item.nid}-trigger`}
          end={`${item.nid}-delivered`}
          showHead={false}
          strokeWidth={1}
          color="gray"
          labels={{
            middle: (
              <TimeDiff
                fromDate={item.triggered_at}
                toDate={item.delivered_at}
                className="text-muted-foreground text-xs mt-5 font-light"
              />
            ),
          }}
        />
      </>
    );
  }
  return <></>;
}

function renderSeenStatus(item) {
  if (STATUS.seen.includes(item.status)) {
    return (
      <>
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleCheck size={20} color="green" id={`${item.nid}-seen`} />
          </TooltipTrigger>
          <TooltipContent>
            Seen <TimeAgo dateInput={item.seen_at} />
          </TooltipContent>
        </Tooltip>
        <Xarrow
          start={`${item.nid}-delivered`}
          end={`${item.nid}-seen`}
          showHead={false}
          strokeWidth={1}
          color="gray"
          labels={{
            middle: (
              <TimeDiff
                fromDate={item.delivered_at}
                toDate={item.seen_at}
                className="text-muted-foreground text-xs mt-5 font-light"
              />
            ),
          }}
        />
      </>
    );
  }
  return <></>;
}

function renderClickedStatus(item) {
  if (STATUS.clicked.includes(item.status)) {
    return (
      <>
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleCheck size={20} color="green" id={`${item.nid}-clicked`} />
          </TooltipTrigger>
          <TooltipContent>
            <TimeAgo dateInput={item.interacted_at}></TimeAgo>
          </TooltipContent>
        </Tooltip>
        <Xarrow
          start={`${item.nid}-seen`}
          end={`${item.nid}-clicked`}
          showHead={false}
          strokeWidth={1}
          color="gray"
          labels={{
            middle: (
              <TimeDiff
                fromDate={item.seen_at}
                toDate={item.interacted_at}
                className="text-muted-foreground text-xs mt-5 font-light"
              />
            ),
          }}
        />
      </>
    );
  }
  return <></>;
}

function renderVendorFallback(fallbacklogs) {
  if (fallbacklogs.count() === 0) {
    return <></>;
  }
  const uniqSubscriber = _.uniq(
    fallbacklogs.getValue('subscriber_identity_id')
  );
  return (
    <>
      {uniqSubscriber.map(subscriber => {
        const logsForUser = fallbacklogs
          .find({ subscriber_identity_id: subscriber })
          .sort('vfallback_level').data;
        const logPairs = pairWise(logsForUser);
        return (
          <>
            {logPairs.map(log_pair => {
              const nid1 = log_pair[0]?.nid;
              const nid2 = log_pair[1]?.nid;
              return (
                <>
                  <Xarrow
                    start={`${nid1}-vendor`}
                    end={`${nid2}-vendor`}
                    showHead={true}
                    strokeWidth={1}
                    color="gray"
                    labels={{
                      middle: (
                        <TimeDiff
                          fromDate={log_pair[0]?.triggered_at}
                          toDate={log_pair[1]?.triggered_at}
                          className="text-muted-foreground text-xs font-light mr-12"
                        />
                      ),
                    }}
                  />
                </>
              );
            })}
          </>
        );
      })}
    </>
  );
}

export default NotificationGroupRow;

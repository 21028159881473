import Handlebars from 'handlebars';
import * as jose from 'jose';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import AceEditor from 'react-ace';
import { useEnvState, useAuthState } from 'stores/AuthStore';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

export const urlify = text => {
  var urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return text.replace(urlRegex, function (url) {
    return '<span style="color : #1E90FF">' + url + '</span>';
  });
};

export const censor = function (str) {
  return str.replace(/.(.|$)/g, '*$1');
};

export const handlebarCompile = (variables = {}, text) => {
  if (variables && Object.getPrototypeOf(variables) === Object.prototype) {
    try {
      const template = Handlebars.compile(text, { strict: true });
      return template(variables);
    } catch (err) {
      return text;
    }
  }
  return text;
};

export const makeAbsoluteUrl = (logo, transform) => {
  if (!logo?.startsWith('http://') && !logo?.startsWith('https://')) {
    return `${process.env.REACT_APP_IMG_HOST}${
      transform ? transform : ''
    }${logo}`;
  } else {
    return logo;
  }
};

export function copyToClipboard(str) {
  navigator.clipboard.writeText(str).then(
    function () {
      //  success do nothing
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

export function isValidJson(json) {
  try {
    JSON.parse(json);
    return false;
  } catch (e) {
    return true;
  }
}

export function safeParseJson(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}

export const MISSING_FIELD_ERROR = 'This field is required';

export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const MOBILE_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const ALPHABETS_REGEX = /^[a-zA-Z ]*$/;

export const ASCII_REGEX = /^[\u0000-\u007f]*$/;

export function classNames(...classes) {
  return twMerge(clsx(classes));
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function ValidateEventName(eventName) {
  const illegalCharRemoved = eventName.replaceAll(
    /[^a-zA-Z0-9$()+\-*,./:;<=>@[\]_{|}\s]+/gi,
    ''
  );
  const squishedEvent = squishString(illegalCharRemoved);
  if (!squishedEvent) {
    return;
  }
  if (!isPredefinedEvent(squishedEvent)) {
    return squishedEvent;
  }
  return;
}

function squishString(str) {
  return str.replace(/\s+/g, ' ').trim();
}

export function isPredefinedEvent(eventName) {
  return (
    eventName.startsWith('$') ||
    eventName.startsWith('ss_') ||
    eventName.startsWith('SS_')
  );
}

export async function cubeJWTSign(payload, secret) {
  const secretText = new TextEncoder().encode(secret);
  const jwt = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .setExpirationTime('1d')
    .sign(secretText);
  return jwt;
}

const isAlphanumeric = c => {
  const x = c.charCodeAt();
  return (x >= 65 && x <= 90) || (x >= 97 && x <= 122) || (x >= 48 && x <= 57)
    ? true
    : false;
};

export const whatsappTextFormat = (format, wildcard, opTag, clTag) => {
  let dummy;
  const indices = [];
  for (let i = 0; i < format.length; i++) {
    if (format[i] === wildcard) {
      if (indices.length % 2) {
        dummy =
          format[i - 1] === ' '
            ? null
            : typeof format[i + 1] === 'undefined'
            ? indices.push(i)
            : isAlphanumeric(format[i + 1])
            ? null
            : indices.push(i);
      } else {
        dummy =
          typeof format[i + 1] === 'undefined'
            ? null
            : format[i + 1] === ' '
            ? null
            : typeof format[i - 1] === 'undefined'
            ? indices.push(i)
            : isAlphanumeric(format[i - 1])
            ? null
            : indices.push(i);
      }
    } else {
      dummy =
        format?.[i]?.charCodeAt() === 10 && indices.length % 2
          ? indices.pop()
          : null;
    }
  }
  dummy = indices.length % 2 ? indices.pop() : null;
  let e = 0;
  indices.forEach(function (v, i) {
    const t = i % 2 ? clTag : opTag;
    v += e;
    format = format.substr(0, v) + t + format.substr(v + 1);
    e += t.length - 1;
  });
  return format;
};

export const AWS_HTTPS_REGIONS = [
  { name: 'US East (Ohio)', id: 'us-east-2' },
  { name: 'US East (N. Virginia)', id: 'us-east-1' },
  { name: 'US West (N. California)', id: 'us-west-1' },
  { name: 'US West (Oregon)', id: 'us-west-2' },
  { name: 'Africa (Cape Town)', id: 'af-south-1' },
  { name: 'Asia Pacific (Mumbai)', id: 'ap-south-1' },
  { name: 'Asia Pacific (Osaka)', id: 'ap-northeast-3' },
  { name: 'Asia Pacific (Seoul)', id: 'ap-northeast-2' },
  { name: 'Asia Pacific (Singapore)', id: 'ap-southeast-1' },
  { name: 'Asia Pacific (Sydney)', id: 'ap-southeast-2' },
  { name: 'Asia Pacific (Tokyo)', id: 'ap-northeast-1' },
  { name: 'Canada (Central)', id: 'ca-central-1' },
  { name: 'Europe (Frankfurt)', id: 'eu-central-1' },
  { name: 'Europe (Ireland)', id: 'eu-west-1' },
  { name: 'Europe (London)', id: 'eu-west-2' },
  { name: 'Europe (Milan)', id: 'eu-south-1' },
  { name: 'Europe (Paris)', id: 'eu-west-3' },
  { name: 'Europe (Stockholm)', id: 'eu-north-1' },
  { name: 'Middle East (Bahrain)', id: 'me-south-1' },
  { name: 'South America (São Paulo)', id: 'sa-east-1' },
];

export function hasEmoji(str) {
  let ranges = [
    '(?:[\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))',
  ];
  return str?.match(ranges.join('|'));
}

export function compileHandlebar({ value, variables, compilerOptions }) {
  try {
    const template = Handlebars.compile(value, compilerOptions);
    const templateString = template(variables || {});
    if (templateString.includes('[object Object]')) {
      throw new Error();
    }
  } catch (err) {
    let error_msg = err.message;
    if (error_msg.includes('You specified knownHelpersOnly')) {
      error_msg = '';
    } else if (err.message.includes('Parse error')) {
      error_msg = 'Error while parsing handlebars. Please add valid template';
    } else if (err.message.includes('"if"')) {
      error_msg = '#if requires exactly one argument';
    } else if (err.message.includes('not defined in')) {
      error_msg = `Mock data for ${
        err.message.split(' not defined in')[0]
      } variable is not found`;
    }
    return error_msg;
  }
}

export const convertObjToString = obj => {
  Object.keys(obj)?.forEach(key => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  });
  const convertedString = new URLSearchParams(obj).toString();

  return convertedString ? `?${new URLSearchParams(obj)}` : '';
};

export function JsonEditor({ name, jsonValue, setFieldValue, ...props }) {
  const prettifyJson = () => {
    try {
      const prettyJson = JSON.stringify(JSON.parse(jsonValue), null, 2);
      setFieldValue(name, prettyJson);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <AceEditor
        mode="json"
        theme="github"
        value={jsonValue}
        onChange={value => setFieldValue(name, value)}
        name="json-editor"
        editorProps={{ $blockScrolling: true }}
        setOptions={{ useWorker: false }}
        width="100%"
        height="150px"
        className="border border-border rounded"
        {...props}
      />
      <div
        onClick={prettifyJson}
        className="text-xs mt-1.5 float-right text-primary cursor-pointer"
      >
        Beautify
      </div>
    </div>
  );
}

export const getOrdinalNumbers = number => {
  switch (number) {
    case 1:
    case 21:
      return number + 'st';
    case 2:
    case 22:
      return number + 'nd';
    case 3:
    case 23:
      return number + 'rd';
    default:
      return number + 'th';
  }
};

export const useEditAccess = () => {
  const envState = useEnvState();
  const authState = useAuthState();

  const currentclient = authState.user.get();
  const currentEnv = envState.currentEnv.get();
  let editAcess = false;

  if (currentclient?.role === 'admin') {
    editAcess = true;
  } else if (
    currentclient?.role === 'member' &&
    ['staging', 'sandbox'].includes(currentEnv)
  ) {
    editAcess = true;
  }
  return editAcess;
};

import { useState, useContext, useMemo, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TimePicker from 'rc-time-picker';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import ReactSelect from 'react-select';
import { useReactFlow } from 'reactflow';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { HelpCircleIcon, CalendarIcon, ClockIcon, Pencil } from 'lucide-react';
import {
  Label,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  Button,
  SelectValue,
  SelectGroup,
  ToggleGroup,
  ToggleGroupItem,
  CTooltip,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Calendar,
} from 'new-components';
import { toast } from 'components';
import { useAuthState } from 'stores/AuthStore';
import { useUpdateNodeProperties, useScheduleCalculator } from 'apis';
import { cn, MISSING_FIELD_ERROR, getOrdinalNumbers } from 'utils';
import urlUtils from 'utils/urlUtils';
import { ReactComponent as DigestNodeIcon } from 'assets/svgs/digestNodeIcon.svg';
import { NodeFormHeader } from './Common';
import { FlowContext } from '../WorkflowDetails';
import 'rc-time-picker/assets/index.css';

const digestValidationSchema = Yup.object().shape({
  schedule_type: Yup.string(),
  dynamic_schedule_expr: Yup.string().when('schedule_type', {
    is: 'dynamic',
    then: Yup.string().required(MISSING_FIELD_ERROR),
  }),
  schedule: Yup.object().when('schedule_type', {
    is: 'static',
    then: Yup.object().shape({
      frequency: Yup.string(),
      weeklyFrequency: Yup.string(),
      weekdays: Yup.array().test(
        'is-weekly',
        MISSING_FIELD_ERROR,
        function (value, context) {
          const { frequency, weeklyFrequency } = context.parent;
          if (
            frequency === 'weekly' &&
            weeklyFrequency === 'weekly' &&
            value?.length <= 0
          ) {
            return false;
          }
          return true;
        }
      ),
      interval: Yup.string()
        .required(MISSING_FIELD_ERROR)
        .test('limit interval', function (value, context) {
          const { frequency } = context.parent;
          const interval = Number(value);

          if (interval <= 0) {
            return context.createError({
              path: context.path,
              message: 'Interval should be greater than zero',
            });
          } else if (!Number.isInteger(interval)) {
            return context.createError({
              path: context.path,
              message: 'Add valid integer',
            });
          } else if (frequency === 'minutely' && interval > 1440) {
            return context.createError({
              path: context.path,
              message: 'Limit is 1440',
            });
          } else if (frequency === 'hourly' && interval > 100) {
            return context.createError({
              path: context.path,
              message: 'Limit is 100',
            });
          } else if (frequency === 'daily' && interval > 100) {
            return context.createError({
              path: context.path,
              message: 'Limit is 100',
            });
          } else if (frequency === 'weekly' && interval > 25) {
            return context.createError({
              path: context.path,
              message: 'Limit is 25',
            });
          } else if (frequency === 'monthly' && interval > 6) {
            return context.createError({
              path: context.path,
              message: 'Limit is 6',
            });
          }
          return true;
        }),
      monthdays: Yup.array().when('frequency', {
        is: 'monthly',
        then: Yup.array().of(
          Yup.object().shape({
            day: Yup.string(),
            pos: Yup.array()
              .min(1, MISSING_FIELD_ERROR)
              .required(MISSING_FIELD_ERROR)
              .test(
                'valid week of month',
                'Invalid selection, allowed values are between 1st - 4th and last',
                function (value, context) {
                  const { day } = context.parent;
                  if (day !== 'day') {
                    return !value?.some(num => num > 5);
                  }
                  return true;
                }
              ),
          })
        ),
      }),
    }),
  }),
});

export const FREQUENCY_LIST = [
  { label: 'Minute', value: 'minutely' },
  { label: 'Hour', value: 'hourly' },
  { label: 'Day', value: 'daily' },
  { label: 'Week', value: 'weekly' },
  { label: 'Month', value: 'monthly' },
];

const WEEK_DAYS_LIST = [
  { label: 'S', value: 'su' },
  { label: 'M', value: 'mo' },
  { label: 'T', value: 'tu' },
  { label: 'W', value: 'we' },
  { label: 'T', value: 'th' },
  { label: 'F', value: 'fr' },
  { label: 'S', value: 'sa' },
];

const MONTHLY_DAYS_LIST = [
  { label: 'Day', value: 'day' },
  { label: 'Sun', value: 'su' },
  { label: 'Mon', value: 'mo' },
  { label: 'Tue', value: 'tu' },
  { label: 'Wed', value: 'we' },
  { label: 'Thu', value: 'th' },
  { label: 'Fri', value: 'fr' },
  { label: 'Sat', value: 'sa' },
];

const WEEK_DAYS_MAPPING = {
  su: 'sunday',
  mo: 'monday',
  tu: 'tuesday',
  we: 'wednesday',
  th: 'thursday',
  fr: 'friday',
  sa: 'saturday',
};

const monthlyPositionList = maxDays => {
  let daysArray = [];

  for (let i = 1; i <= maxDays; i++) {
    daysArray.push({ label: getOrdinalNumbers(i), value: i });
  }
  daysArray = [...daysArray, { label: 'Last', value: -1 }];

  return daysArray;
};

const formatDateTime = (dateInput, timeInput) => {
  const date = new Date(dateInput);
  const [hours, minutes, seconds] = timeInput.split(':');
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");

  return formattedDate;
};

function TimeZoneContent({
  timezone,
  setFieldValue,
  timeZonesList,
  disabled,
  accountTimezone,
}) {
  return (
    <div className="mt-5">
      <Label className="mb-1.5">Timezone</Label>
      <ReactSelect
        classNamePrefix="react-select"
        value={timezone}
        options={timeZonesList}
        onChange={value => {
          setFieldValue('timezone', value);
        }}
        styles={{
          menuList: styles => {
            return { ...styles, maxHeight: 160 };
          },
        }}
        isDisabled={disabled}
      />
      <p className="mt-1.5 text-muted-foreground text-xs">
        Fallback to
        <span className="text-primary mx-1">
          <a
            href={urlUtils.makeCommonURL('/account-settings/general')}
            target="_blank"
            rel="noopener noreferrer"
          >
            account timezone
          </a>
        </span>
        if recipient's timezone is not set. Your account timezone is set to
        <span className=" mx-1 text-accent-foreground font-semibold">
          {accountTimezone || 'UTC (Universal Coordinated Time)'}
        </span>
      </p>
    </div>
  );
}

function TimePickerContent({ time, setFieldValue, disabled }) {
  const format = 'HH:mm';
  return (
    <TimePicker
      value={moment().hour(time?.[0]).minute(time?.[1]) || '00:00'}
      inputReadOnly
      clearIcon={<span />}
      disabled={disabled}
      showSecond={false}
      placeholder="hh:mm"
      onChange={value => {
        const formattedValue = value ? value.format(format) : '';
        setFieldValue(`schedule.time`, formattedValue);
      }}
      format={format}
    />
  );
}

export const digestRepeatText = nodeProperties => {
  const schedule = nodeProperties?.schedule;

  const frequencyList = [
    ...FREQUENCY_LIST,
    { label: 'Week', value: 'weekly_mo2fr' },
  ];

  const selectedFrequency = frequencyList.find(
    freq => freq.value === schedule?.frequency
  );

  const defaultText = `Every ${
    schedule?.interval > 1 ? schedule?.interval : ''
  } ${
    schedule?.interval > 1
      ? `${selectedFrequency?.label}s`
      : selectedFrequency?.label
  }`;

  let text = '';

  if (
    !['monthly', 'weekly', 'weekly_mo2fr'].includes(selectedFrequency?.value)
  ) {
    text = defaultText;
  } else if (selectedFrequency?.value === 'weekly') {
    const weekNames = [];
    schedule?.weekdays?.forEach(week =>
      weekNames.push([WEEK_DAYS_MAPPING[week]])
    );
    text = `${defaultText} on ${weekNames?.join(', ')} `;
  } else if (selectedFrequency?.value === 'weekly_mo2fr') {
    text = `${defaultText} on Monday to Friday`;
  } else if (selectedFrequency?.value === 'monthly') {
    let positions = schedule?.monthdays?.map(item =>
      getOrdinalNumbers(item.pos)
    );
    let day = schedule?.monthdays?.[0]?.day;
    text = `${defaultText} on ${positions?.join(', ')} ${
      WEEK_DAYS_MAPPING[day] || 'day'
    }`;
  }
  return text;
};

export default function DigestNodeForm() {
  const [showFrequencyForm, setShowFrequencyForm] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [showUpcomingScheduleLoader, setShowUpcomingScheduleLoader] =
    useState(false);
  const [showFormErrors, setShowFormErrors] = useState();

  const authState = useAuthState();
  const orgData = authState.organization.get();
  const {
    selectedNode,
    editMode,
    slug,
    version,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const flowInstance = useReactFlow();

  const nodeData = flowInstance.getNode(selectedNode.id);
  const nodeProperties = nodeData?.data?.properties;
  const hasNodeProperties = Object.keys(nodeProperties)?.length > 0;

  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );
  const scheduleCalculator = useScheduleCalculator();

  const getUpcomingSchedule = async () => {
    setShowUpcomingScheduleLoader(true);
    await scheduleCalculator.mutateAsync({
      rrules: nodeProperties?.schedule?._rrules,
      timezone:
        nodeProperties?.schedule?.tz_fixed || orgData?.timezone || 'UTC',
      time: nodeProperties?.schedule?.time,
      dtstart: nodeProperties?.schedule?.dtstart,
    });
    setShowUpcomingScheduleLoader(false);
  };

  useEffect(() => {
    if (nodeProperties?.schedule_type === 'static') {
      getUpcomingSchedule();
    }
  }, []);

  const timeZonesList = useMemo(() => {
    const timezones = moment.tz.names();
    let modifiedTimezones = timezones?.map(timezone => ({
      label: timezone,
      value: timezone,
    }));
    modifiedTimezones = [
      { label: "Recipient's Timezone", value: 'recipient' },
      ...modifiedTimezones,
    ];
    return modifiedTimezones;
  }, []);

  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  const submitHandler = async values => {
    const schedule = values?.schedule;
    let payload = cloneDeep(values);

    payload.retain_count = Number(values?.retain_count);
    payload.trigger_min_count = Number(values?.trigger_min_count);

    if (values?.schedule_type === 'static') {
      const isRecipientTimeZone = values?.timezone?.value === 'recipient';
      const dstartFormat = formatDateTime(
        schedule?.dtstart,
        `${schedule?.time}:00`
      );

      payload = {
        ...payload,
        schedule: {
          ...payload.schedule,
          interval: Number(payload?.schedule?.interval),
          dtstart: dstartFormat,
          frequency:
            schedule?.frequency === 'weekly'
              ? schedule?.weeklyFrequency
              : schedule?.frequency,
          tz_selection: isRecipientTimeZone ? 'recipient' : 'fixed',
          tz_fixed: isRecipientTimeZone ? '' : values?.timezone?.value,
        },
      };

      if (payload?.schedule?.frequency === 'monthly') {
        const outputArray = [];

        schedule?.monthdays?.forEach(item => {
          item?.pos?.forEach(position => {
            outputArray.push({
              pos: position,
              day: item.day === 'day' ? '' : item.day,
            });
          });
        });
        payload.schedule.monthdays = outputArray;
      }
      delete payload.schedule.weeklyFrequency;
    }

    delete payload.timezone;

    try {
      setLoading(true);
      await updateProperties.mutateAsync({
        properties: payload,
      });

      if (payload?.schedule_type === 'static') {
        setShowUpcomingScheduleLoader(true);
        setTimeout(async () => {
          const nodeData = flowInstance.getNode(selectedNode.id);
          const nodeProperties = nodeData?.data?.properties;

          await scheduleCalculator.mutateAsync({
            rrules: nodeProperties?.schedule?._rrules,
            timezone:
              nodeProperties?.schedule?.tz_fixed || orgData?.timezone || 'UTC',
            time: nodeProperties?.schedule?.time,
            dtstart: nodeProperties?.schedule?.dtstart,
          });
          setShowUpcomingScheduleLoader(false);
        }, 3000);
      }

      setShowFrequencyForm(false);
      setShowFormErrors(true);
      setLoading(false);
      toast('Settings saved successfully', '', { autoClose: 1000 });
    } catch (e) {
      setLoading(false);
    }
  };

  const initialContent = useMemo(() => {
    let initialValues = {};

    if (hasNodeProperties) {
      initialValues = cloneDeep(nodeProperties);

      if (initialValues?.schedule_type === 'static') {
        const schedule = initialValues?.schedule;
        const isWeekly =
          schedule?.frequency === 'weekly' ||
          schedule?.frequency === 'weekly_mo2fr';

        const getDtStart = schedule?.dtstart
          ? new Date(schedule?.dtstart)
          : new Date();
        const getHours = getDtStart?.getHours();
        const getMinutes = getDtStart?.getMinutes();
        const getMinHours =
          typeof getHours === 'number' && typeof getMinutes === 'number'
            ? `${getDtStart?.getHours()}:${getDtStart?.getMinutes()}`
            : '00:00';

        const getTimeZone =
          schedule?.tz_selection === 'recipient'
            ? { label: "Recipient's Timezone", value: 'recipient' }
            : timeZonesList?.find(
                timezone => timezone.value === schedule?.tz_fixed
              );

        initialValues = {
          ...initialValues,
          timezone: getTimeZone || {
            label: "Recipient's Timezone",
            value: 'recipient',
          },
          schedule: {
            ...schedule,
            frequency: isWeekly ? 'weekly' : schedule?.frequency,
            weeklyFrequency: isWeekly ? schedule?.frequency : 'weekly_mo2fr',
            dtstart: getDtStart,
            time: schedule?.time || getMinHours,
          },
        };

        if (schedule?.monthdays?.length > 0) {
          const groupedMonthdays = schedule?.monthdays?.reduce(
            (acc, monthday) => {
              let { day, pos } = monthday;
              day = day || 'day';
              if (!acc[day]) {
                acc[day] = [];
              }
              acc[day].push(pos);
              return acc;
            },
            {}
          );
          initialValues.schedule.monthdays = Object.keys(groupedMonthdays)?.map(
            day => ({
              day,
              pos: groupedMonthdays[day],
            })
          );
        }
        delete initialValues.schedule.tz_fixed;
        delete initialValues.schedule.tz_selection;
      }
    } else {
      initialValues = {
        schedule_type: 'static',
        dynamic_schedule_expr: '',
        schedule: {
          frequency: 'minutely',
          weeklyFrequency: 'weekly_mo2fr',
          interval: 1,
          time: '00:00',
          weekdays: ['mo', 'tu', 'we', 'th'],
          monthdays: [{ day: 'day', pos: [] }],
          dtstart: new Date(),
        },
        retain_count: 10,
        retain_order: 'first',
        trigger_min_count: 1,
        timezone: { label: "Recipient's Timezone", value: 'recipient' },
      };
    }
    return initialValues;
  }, []);

  return (
    <div className="overflow-scroll h-full">
      <div className="m-4 mt-6">
        <NodeFormHeader
          name="Digest"
          helptext="Batch triggers and send a summary at recurring digest schedule."
          Icon={DigestNodeIcon}
          docLink="https://docs.suprsend.com/docs/digest"
        />
        <div className="mt-5">
          {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
            <div className="mb-6 text-xs -mt-2 text-destructive bg-destructive-muted p-3 rounded">
              <ul>
                {fieldErrors &&
                  Object.keys(fieldErrors)?.map((item, index) => {
                    return (
                      <li key={index}>
                        - {item}: {fieldErrors[item]}
                      </li>
                    );
                  })}
                {nonFieldErrors?.map((item, index) => {
                  return <li key={index}>- {item}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
        <Formik
          initialValues={initialContent}
          validationSchema={digestValidationSchema}
          onSubmit={values => {
            submitHandler(values);
          }}
        >
          {({ values, setFieldValue }) => {
            const disabled = !editMode;
            const schedule = values?.schedule;
            const time = schedule?.time?.split(':');
            const timeFormat =
              schedule?.frequency === 'minutely' ||
              schedule?.frequency === 'hourly';
            const frequencyName = FREQUENCY_LIST.find(
              frequency => frequency.value === schedule?.frequency
            );

            const sheduletypeHandler = value => {
              const initialDynamicExp = values?.dynamic_schedule_expr || '';
              const initialSchedule = {
                frequency: schedule?.frequency || 'minutely',
                interval: schedule?.interval || 1,
                time: schedule?.time || '00:00',
                weekdays: schedule?.weekdays || ['mo', 'tu', 'we', 'th'],
                monthdays: schedule?.monthdays || [],
                dtstart: schedule?.dtstart || new Date(),
                weeklyFrequency: schedule?.weeklyFrequency || 'weekly_mo2fr',
              };
              const timezone = values?.timezone
                ? values?.timezone
                : { label: "Recipient's Timezone", value: 'recipient' };

              setFieldValue('schedule_type', value);

              if (value === 'dynamic') {
                setFieldValue('dynamic_schedule_expr', initialDynamicExp);
              } else {
                setFieldValue('schedule', initialSchedule);
                setFieldValue('timezone', timezone);
                setShowFrequencyForm(true);
              }
            };

            return (
              <Form className="mb-24">
                <p className="text-foreground font-semibold text-base mb-3">
                  Digest Schedule
                </p>

                <div className="mt-4">
                  <div className="flex items-center mb-[7px]">
                    <Label>Type</Label>
                    <CTooltip
                      trigger={
                        <HelpCircleIcon className="h-4 w-4 ml-1.5 text-muted-foreground" />
                      }
                    >
                      <div className="py-2 max-w-72">
                        <div>
                          <p className="text-xs font-semibold">• Fixed</p>
                          <p className="text-xs font-normal ml-2.5">
                            Set a fixed schedule for all users
                          </p>
                        </div>
                        <div className="mt-3">
                          <p className="text-xs font-semibold">• Dynamic</p>
                          <p className="text-xs font-normal ml-2.5">
                            Pass dynamic schedule for each user based on their
                            preference in the API trigger
                          </p>
                        </div>
                      </div>
                    </CTooltip>
                  </div>
                  <Select
                    value={values?.schedule_type}
                    disabled={disabled}
                    onValueChange={value => {
                      sheduletypeHandler(value);
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue>
                        {values?.schedule_type === 'dynamic'
                          ? 'Dynamic'
                          : 'Fixed'}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="dynamic">Dynamic</SelectItem>
                        <SelectItem value="static">Fixed</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {values?.schedule_type === 'dynamic' && (
                    <div className="mt-5">
                      <Label htmlFor="dynamic_schedule_expr">
                        Dynamic Schedule Key
                      </Label>
                      <Input
                        id="dynamic_schedule_expr"
                        value={values?.dynamic_schedule_expr}
                        onChange={e => {
                          setFieldValue(
                            'dynamic_schedule_expr',
                            e.target.value
                          );
                        }}
                        placeholder=".$recipient.digest_frequency"
                        className="mt-1"
                        disabled={disabled}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="dynamic_schedule_expr"
                        component="div"
                        className="text-sm mt-1 text-destructive"
                      />
                      <p className="text-xs text-muted-foreground mt-2">
                        Add the property key from your trigger or recipient
                        property having digest schedule in
                        <span className="mx-1 text-primary">
                          <a
                            href="https://jqlang.github.io/jq/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            jq format.
                          </a>
                        </span>
                      </p>
                    </div>
                  )}
                  {((!hasNodeProperties &&
                    values?.schedule_type === 'static') ||
                    (values?.schedule_type === 'static' &&
                      !disabled &&
                      showFrequencyForm)) && (
                    <div className="mt-6">
                      <div className="flex items-center mb-[7px]">
                        <Label>Frequency</Label>
                        <CTooltip
                          trigger={
                            <HelpCircleIcon className="h-4 w-4 ml-1.5 text-muted-foreground" />
                          }
                        >
                          Digest will repeat at this frequency
                        </CTooltip>
                      </div>
                      <div className="border border-input border-dashed rounded-md p-4 mt-1.5">
                        <Label>Repeat every</Label>
                        <div className="w-full flex items-start mt-1.5">
                          <div>
                            <Input
                              type="number"
                              value={schedule?.interval}
                              onChange={e => {
                                setFieldValue(
                                  'schedule.interval',
                                  e.target.value
                                );
                              }}
                              placeholder="1"
                              className="w-full"
                            />
                          </div>
                          <Select
                            className="w-[75%]"
                            value={schedule?.frequency}
                            onValueChange={value => {
                              const initialMonthDay =
                                schedule?.monthdays?.length > 0
                                  ? schedule?.monthdays
                                  : [{ day: 'day', pos: [] }];
                              const initialWeekly = schedule?.weeklyFrequency
                                ? schedule?.weeklyFrequency
                                : 'weekly_mo2fr';

                              setFieldValue('schedule.frequency', value);

                              if (value === 'monthly') {
                                setFieldValue(
                                  'schedule.monthdays',
                                  initialMonthDay
                                );
                              } else if (value === 'weekly') {
                                setFieldValue(
                                  'schedule.weeklyFrequency',
                                  initialWeekly
                                );
                              }
                            }}
                          >
                            <SelectTrigger className="ml-2.5">
                              <SelectValue>
                                {schedule?.interval > 1
                                  ? `${frequencyName?.label}s`
                                  : frequencyName?.label}
                              </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {FREQUENCY_LIST.map((frequency, index) => (
                                  <SelectItem
                                    value={frequency?.value}
                                    key={index}
                                  >
                                    {schedule?.interval > 1
                                      ? `${frequency.label}s`
                                      : frequency.label}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <ErrorMessage
                          name="schedule.interval"
                          component="div"
                          className="text-sm mt-1 text-destructive"
                        />
                        {!timeFormat && (
                          <div className="mt-5">
                            {schedule?.frequency === 'weekly' && (
                              <div>
                                <div>
                                  <Label>On</Label>
                                  <Select
                                    value={schedule?.weeklyFrequency}
                                    onValueChange={value => {
                                      setFieldValue(
                                        'schedule.weeklyFrequency',
                                        value
                                      );
                                    }}
                                  >
                                    <SelectTrigger
                                      className={cn(
                                        schedule?.weeklyFrequency === 'weekly'
                                          ? 'my-1'
                                          : 'mt-1 mb-5'
                                      )}
                                    >
                                      <SelectValue>
                                        {schedule?.weeklyFrequency ===
                                        'weekly_mo2fr'
                                          ? 'Weekdays (Monday to Friday)'
                                          : 'Selected days of the week'}
                                      </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectGroup>
                                        <SelectItem value="weekly_mo2fr">
                                          Weekdays (Monday to Friday)
                                        </SelectItem>
                                        <SelectItem value="weekly">
                                          Selected days of the week
                                        </SelectItem>
                                      </SelectGroup>
                                    </SelectContent>
                                  </Select>
                                </div>
                                <div>
                                  {schedule?.weeklyFrequency === 'weekly' && (
                                    <div className="mb-6 mt-2.5">
                                      <ToggleGroup
                                        variant="outline"
                                        type="multiple"
                                        onValueChange={value => {
                                          setFieldValue(
                                            'schedule.weekdays',
                                            value
                                          );
                                        }}
                                        className="justify-start gap-3"
                                        value={schedule?.weekdays}
                                        disabled={disabled}
                                      >
                                        {WEEK_DAYS_LIST?.map(week => (
                                          <ToggleGroupItem
                                            key={week.value}
                                            value={week.value}
                                            className="data-[state=on]:border-primary data-[state=on]:bg-primary-muted"
                                          >
                                            {week.label}
                                          </ToggleGroupItem>
                                        ))}
                                      </ToggleGroup>
                                      <ErrorMessage
                                        name={`schedule.weekdays`}
                                        component="div"
                                        className="text-sm mt-1 text-destructive"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {schedule?.frequency === 'monthly' && (
                              <>
                                {schedule?.monthdays?.map(
                                  (monthDay, innerIndex) => {
                                    const maxDays =
                                      monthDay?.day === 'day' ? 30 : 5;
                                    const monthPositions =
                                      monthlyPositionList(30);
                                    const getMonthPosition =
                                      monthPositions?.filter(obj =>
                                        monthDay?.pos?.includes(obj?.value)
                                      );
                                    const getDay = MONTHLY_DAYS_LIST?.find(
                                      list => list.value === monthDay?.day
                                    );

                                    return (
                                      <div key={innerIndex} className="mb-6">
                                        <div className="flex items-center w-full gap-2">
                                          <ReactSelect
                                            classNamePrefix="react-select"
                                            isMulti
                                            options={monthlyPositionList(
                                              maxDays
                                            )}
                                            className="w-[75%]"
                                            value={getMonthPosition}
                                            onChange={selectedValue => {
                                              const modifiedValue =
                                                selectedValue?.map(
                                                  obj => obj.value
                                                );
                                              setFieldValue(
                                                `schedule.monthdays[${innerIndex}].pos`,
                                                modifiedValue
                                              );
                                            }}
                                            isDisabled={disabled}
                                          />
                                          <Select
                                            value={monthDay?.day}
                                            disabled={disabled}
                                            onValueChange={value => {
                                              setFieldValue(
                                                `schedule.monthdays[${innerIndex}].day`,
                                                value
                                              );
                                            }}
                                          >
                                            <SelectTrigger className=" w-[25%]">
                                              <SelectValue>
                                                {getDay?.label}
                                              </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                {MONTHLY_DAYS_LIST?.map(
                                                  list => (
                                                    <SelectItem
                                                      value={list.value}
                                                      key={list.value}
                                                    >
                                                      {list.label}
                                                    </SelectItem>
                                                  )
                                                )}
                                              </SelectGroup>
                                            </SelectContent>
                                          </Select>
                                        </div>

                                        <ErrorMessage
                                          name={`schedule.monthdays[${innerIndex}].pos`}
                                          component="div"
                                          className="text-sm mt-1 text-destructive"
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}

                            <div className="flex flex-col">
                              <Label className="mb-1.5">At Time</Label>
                              <TimePickerContent
                                time={time}
                                setFieldValue={setFieldValue}
                                disabled={disabled}
                              />
                            </div>
                            <TimeZoneContent
                              timezone={values?.timezone}
                              setFieldValue={setFieldValue}
                              timeZonesList={timeZonesList}
                              disabled={disabled}
                              accountTimezone={orgData?.timezone}
                            />
                          </div>
                        )}
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center mb-[7px]">
                          <Label>Starting from</Label>
                          <CTooltip
                            trigger={
                              <HelpCircleIcon className="h-4 w-4 ml-1.5 text-muted-foreground" />
                            }
                          >
                            First digest will be sent on or after this date
                          </CTooltip>
                        </div>
                        <div className="flex items-center">
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant="outline"
                                className={cn(
                                  'w-[60%] justify-start text-left font-normal mr-2.5 shadow-none',
                                  !schedule?.dtstart && 'text-muted-foreground',
                                  timeFormat ? 'w-[60%]' : 'w-full'
                                )}
                              >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {schedule?.dtstart ? (
                                  format(schedule?.dtstart, 'PPP')
                                ) : (
                                  <span>Pick a date</span>
                                )}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                              <Calendar
                                mode="single"
                                selected={schedule?.dtstart}
                                onSelect={value => {
                                  setFieldValue('schedule.dtstart', value);
                                }}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                          {timeFormat && (
                            <TimePickerContent
                              time={time}
                              setFieldValue={setFieldValue}
                              disabled={disabled}
                            />
                          )}
                        </div>
                      </div>
                      {timeFormat && (
                        <TimeZoneContent
                          timezone={values?.timezone}
                          setFieldValue={setFieldValue}
                          timeZonesList={timeZonesList}
                          disabled={disabled}
                          accountTimezone={orgData?.timezone}
                        />
                      )}
                    </div>
                  )}

                  {((hasNodeProperties &&
                    values?.schedule_type === 'static' &&
                    !showFrequencyForm) ||
                    (hasNodeProperties &&
                      values?.schedule_type === 'static' &&
                      disabled)) && (
                    <div>
                      <div
                        className="border border-input border-dashed p-4 mt-5 rounded-md bg-muted"
                        onMouseEnter={() => {
                          setIsHovering(true);
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <ClockIcon className="h-4 w-4 text-muted-foreground" />
                          <p className="text-sm text-foreground font-medium">
                            Frequency
                          </p>
                          {!disabled && isHovering && (
                            <div
                              className="ml-auto"
                              onClick={() => {
                                setShowFrequencyForm(true);
                              }}
                            >
                              <Pencil className="h-4 w-4 text-muted-foreground cursor-pointer" />
                            </div>
                          )}
                        </div>
                        <p className="text-sm font-semibold text-muted-foreground my-2">
                          {digestRepeatText(nodeProperties)}
                        </p>
                        {!timeFormat && (
                          <p className="text-sm font-normal text-muted-foreground my-2">
                            at{' '}
                            <span className="font-semibold">
                              {schedule?.time}
                            </span>
                          </p>
                        )}
                        <p className="text-sm font-normal text-muted-foreground my-2">
                          in{' '}
                          <span className="font-semibold">
                            {values?.timezone?.value} timezone
                          </span>
                        </p>
                        <p className="text-sm font-normal text-muted-foreground my-2">
                          starting from{' '}
                          <span className="font-semibold">
                            {`${
                              nodeProperties?.schedule?.dtstart
                                ? format(
                                    new Date(nodeProperties?.schedule?.dtstart),
                                    'PP HH:mm'
                                  )
                                : ''
                            }`}
                          </span>
                        </p>
                      </div>

                      <div className="mt-6">
                        <p className="text-sm text-foreground font-medium mb-1">
                          Upcoming schedule
                        </p>
                        {values?.timezone?.value === 'recipient' && (
                          <>
                            <p className="mt-1.5 text-muted-foreground text-xs">
                              Below schedules are shown in your
                              <span>
                                <a
                                  href={urlUtils.makeCommonURL(
                                    '/account-settings/general'
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="mx-1 text-primary"
                                >
                                  account timezone
                                </a>
                              </span>
                            </p>
                            <p className="text-muted-foreground text-xs mb-4">
                              {orgData?.timezone}.
                              <span className="ml-1">
                                Actual timestamps may vary based on recipient's
                                timezone.
                              </span>
                            </p>
                          </>
                        )}
                        {scheduleCalculator?.isLoading ||
                        showUpcomingScheduleLoader ? (
                          <p className="text-xs text-muted-foreground mb-1.5">
                            Fetching upcoming schedule...
                          </p>
                        ) : (
                          scheduleCalculator?.data?.data?.results?.map(
                            (schedule, index) => {
                              const timeZone =
                                nodeProperties?.schedule?.tz_fixed ||
                                orgData?.timezone ||
                                'UTC';
                              const dateObject = parseISO(schedule);
                              const zonedDate = utcToZonedTime(
                                dateObject,
                                timeZone
                              );
                              const formattedDate = format(
                                zonedDate,
                                'PPPP HH:mm',
                                { timeZone }
                              );

                              return (
                                <p
                                  className="text-sm text-muted-foreground mb-1.5"
                                  key={index}
                                >
                                  {formattedDate}
                                </p>
                              );
                            }
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="h-[1px] w-full bg-border my-8" />
                <div>
                  <p className="text-foreground font-semibold text-base mb-3">
                    Advanced configuration
                  </p>
                  <div>
                    <Label>Output Variable List</Label>
                    <div className="border border-input border-dashed rounded-md p-4 mt-1.5">
                      <div className="flex items-center mb-[7px]">
                        <Label>Retain items</Label>
                        <CTooltip
                          trigger={
                            <HelpCircleIcon className="h-4 w-4 ml-1.5 text-muted-foreground" />
                          }
                        >
                          <div className="max-w-60">
                            <p className="text-xs mb-4">
                              The number of trigger data to retain in the output
                              data (digest) for use in template and workflow.
                            </p>
                            <p className="text-xs">
                              Example, "Last 10" will retain the 10 most recent
                              triggers data in output data.
                            </p>
                          </div>
                        </CTooltip>
                      </div>
                      <div className="flex">
                        <Select
                          className="mr-2.5"
                          value={values?.retain_order}
                          onValueChange={value => {
                            setFieldValue('retain_order', value);
                          }}
                          disabled={disabled}
                        >
                          <SelectTrigger className="capitalize">
                            <SelectValue>{values?.retain_order}</SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="first">First</SelectItem>
                              <SelectItem value="last">Last</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <Input
                          type="number"
                          className="ml-2.5"
                          value={values?.retain_count}
                          min={1}
                          max={100}
                          onChange={e => {
                            setFieldValue('retain_count', e.target.value);
                          }}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <div className="flex items-center mb-[7px]">
                        <Label htmlFor="trigger_min_count">
                          Min Trigger Count
                        </Label>
                        <CTooltip
                          trigger={
                            <HelpCircleIcon className="h-4 w-4 ml-1.5 text-muted-foreground" />
                          }
                        >
                          <div className="text-xs max-w-52">
                            Digest will be sent if number of triggers are
                            greater than or equal to this count, else workflow
                            will exit here and not go to the next step.
                          </div>
                        </CTooltip>
                      </div>
                      <Input
                        id="trigger_min_count"
                        value={values?.trigger_min_count}
                        onChange={e => {
                          setFieldValue('trigger_min_count', e.target.value);
                        }}
                        type="number"
                        min={1}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                {editMode && (
                  <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-background drop-shadow border-t z-50">
                    <Button
                      className="flex-grow justify-center"
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setSelectedForm('nodes_list');
                        setSelectedNode({});
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      className="flex-grow justify-center ml-2"
                      disabled={updateProperties.isLoading}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

import { useState } from 'react';
import { Clipboard } from 'lucide-react';
import AceEditor from 'react-ace';
import {
  Select,
  SelectGroup,
  SelectTrigger,
  SelectContent,
  SelectItem,
  CCopyTooltip,
} from 'new-components';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-one_dark';

const LANGUAGE_LIST = [
  {
    name: 'Python',
    value: 'python',
    editorMode: 'python',
  },
  {
    name: 'Go',
    value: 'go',
    editorMode: 'golang',
  },
  {
    name: 'Java',
    value: 'java',
    editorMode: 'java',
  },
  {
    name: 'Node',
    value: 'node',
    editorMode: 'javascript',
  },
  {
    name: 'Curl',
    value: 'curl',
    editorMode: 'python',
  },
];

function CodeSnippet({ snippet, languageList, ...props }) {
  const [snippetLanguage, setSnippetLanguage] = useState('curl');

  const selectedLanguage = LANGUAGE_LIST.find(
    language => language.value === snippetLanguage
  );

  const languages = languageList?.length > 0 ? languageList : LANGUAGE_LIST;

  return (
    <>
      <div className="w-full flex items-center border-l border-r border-t border-border rounded-t-lg px-2 bg-[#171F2B]">
        <Select
          defaultValue={snippetLanguage}
          onValueChange={value => setSnippetLanguage(value)}
          value={snippetLanguage}
        >
          <SelectTrigger className="w-fit bg-transparent border-0 shadow-none text-[#E2E8F0] focus:ring-0 outline-none focus:outline-none">
            <p className="mr-1 capitalize">{snippetLanguage}</p>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {languages?.map((language, index) => {
                return (
                  <SelectItem value={language.value} key={index}>
                    {language.name}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
        <div className="ml-auto mr-2">
          <CCopyTooltip
            trigger={
              <Clipboard className="h-4 w-4 text-[#94A3B8] cursor-pointer" />
            }
            copyContent={snippet[snippetLanguage]}
          />
        </div>
      </div>
      <AceEditor
        name="sdk_code_snippet"
        onLoad={function (editor) {
          editor.renderer.setPadding(12);
          editor.renderer.setScrollMargin(10, 10);
        }}
        mode={selectedLanguage?.editorMode}
        theme="one_dark"
        setOptions={{ useWorker: false }}
        width="100%"
        height="500px"
        tabSize={2}
        editorProps={{ $blockScrolling: true }}
        fontSize={14}
        showPrintMargin={false}
        wrapEnabled={false}
        showGutter={false}
        highlightActiveLine={false}
        className="rounded-b-lg border-l border-b border-r border-border bg-[#1D2635]"
        value={snippet[snippetLanguage]}
        readOnly={true}
        {...props}
      />
    </>
  );
}

export default CodeSnippet;

import { useContext, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { FlowContext } from '../WorkflowDetails';
import { NodeFormHeader, DurationInput, TextField, ListBox } from './Common';
import { ReactComponent as DelayNodeIcon } from 'assets/svgs/delayNodeIcon.svg';
import { Button, toast } from 'components';
import { useUpdateNodeProperties } from 'apis';
import * as Yup from 'yup';
import { MISSING_FIELD_ERROR } from 'utils';
import { useReactFlow } from 'reactflow';

const DELAY_TYPE = [
  {
    name: 'Fixed',
    value: 'fixed',
  },
  {
    name: 'Dynamic',
    value: 'dynamic',
  },
];

const DelayNodeValidationschema = Yup.object({
  delay_type: Yup.string().required(MISSING_FIELD_ERROR),
  value: Yup.string()
    .required(MISSING_FIELD_ERROR)
    .test('validateDelayValue', MISSING_FIELD_ERROR, (value, fieldData) => {
      const parentObj = fieldData.parent;
      if (parentObj.delay_type === 'dynamic') {
        return value;
      } else {
        if (!value) return false;
        const comps = value.match(/[a-z]+|[^a-z]+/gi);
        return (
          parseInt(comps[0]) ||
          parseInt(comps[2]) ||
          parseInt(comps[4]) ||
          parseInt(comps[6])
        );
      }
    }),
});

export default function DelayNodeForm() {
  const [showFormErrors, setShowFormErrors] = useState();
  const {
    selectedNode,
    slug,
    version,
    editMode,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const nodeProperties = selectedNode?.properties || {};
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);

  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );

  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  return (
    <div className="m-4 mt-6">
      <NodeFormHeader
        name="Delay"
        helptext="Pause for an interval and then send notifications."
        Icon={DelayNodeIcon}
        docLink="https://docs.suprsend.com/docs/delay"
      />
      <Formik
        initialValues={{
          delay_type: nodeProperties?.delay_type || 'fixed',
          value: nodeProperties?.value || '0d0h0m0s',
        }}
        validationSchema={DelayNodeValidationschema}
        enableReinitialize={true}
        onSubmit={async values => {
          try {
            setLoading(true);
            await updateProperties.mutateAsync({ properties: values });
            setShowFormErrors(true);
            setLoading(false);
            toast('Settings saved successfully', '', { autoClose: 1000 });
          } catch (e) {
            setLoading(false);
          }
        }}
      >
        {({ values, setFieldValue }) => {
          const selectedDelayType = DELAY_TYPE.find(
            item => item.value === values.delay_type
          );
          const disabled = !editMode;

          return (
            <Form className="mt-6">
              {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
                <div className="mb-6 text-xs -mt-2 text-red-500 bg-red-50 p-3 rounded">
                  <ul>
                    {Object.keys(fieldErrors)?.map((item, index) => {
                      return (
                        <li key={index}>
                          - {item}: {fieldErrors[item]}
                        </li>
                      );
                    })}
                    {nonFieldErrors?.map((item, index) => {
                      return <li key={index}>- {item}</li>;
                    })}
                  </ul>
                </div>
              )}
              <ListBox
                label="Delay Type"
                id="delay_type"
                value={values.delay_type}
                displayValue={selectedDelayType?.name}
                disabled={disabled}
                options={DELAY_TYPE}
                getLabel={e => {
                  return e.name;
                }}
                getValue={e => {
                  return e.value;
                }}
                handleOnchange={value => {
                  if (values.delay_type !== value) {
                    setFieldValue('delay_type', value);
                    setFieldValue('value', '');
                  }
                }}
              />
              {values.delay_type === 'fixed' ? (
                <div className="mt-6">
                  <label
                    className="block text-sm text-gray-700 my-2 font-medium"
                    htmlFor="wf-delay-type"
                  >
                    Duration<span className="text-red-500">*</span>
                  </label>
                  <DurationInput
                    value={values.value}
                    setValue={value => {
                      setFieldValue('value', value);
                    }}
                    disabled={disabled}
                  />
                  <ErrorMessage
                    name="value"
                    render={msg => (
                      <p className="text-red-500 text-sm pt-2">{msg}</p>
                    )}
                  />
                </div>
              ) : (
                <div className="mt-4">
                  <TextField
                    label="Duration key"
                    placeholder=".timestamp_key"
                    id="value"
                    disabled={disabled}
                    helpText={
                      <p className="text-[#64748B] text-xs mt-1">
                        add property key from event call having delay duration
                        in{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://jqlang.github.io/jq/manual/"
                          className="hover:underline text-indigo-600 cursor-pointer"
                        >
                          jq format.
                        </a>
                      </p>
                    }
                  />
                </div>
              )}
              {editMode && (
                <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t">
                  <Button
                    className="flex-grow justify-center"
                    as="button"
                    onClick={() => {
                      setSelectedForm('nodes_list');
                      setSelectedNode({});
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    as="submit"
                    className="rounded-md flex-grow justify-center ml-2"
                    disabled={updateProperties.isLoading}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEnvState, useAuthState } from 'stores/AuthStore';
import {
  Select,
  SelectGroup,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'new-components';
import urlUtils from 'utils/urlUtils';

function EnvSwitcher() {
  const history = useHistory();
  const envState = useEnvState();
  const authState = useAuthState();
  let { url } = useRouteMatch();

  const allEnv = authState.organization?.get()?.environments;
  const currentEnv = allEnv?.filter(
    obj => obj.slug === envState.currentEnv.get()
  );

  const changeEnv = newEnv => {
    let arr = url.split('/');
    let envSlug = arr[2];
    const pageSlug = arr[3];
    let newURLArr;
    let newURL;

    if (envSlug === newEnv) return;

    // change current env to new one
    arr[2] = newEnv;

    if (['vendors', 'developers', 'logs'].includes(pageSlug)) {
      newURLArr = arr.splice(0, 5); // take to inner page ie nested slug
      if (pageSlug === 'vendors') {
        newURLArr.push('?brand_id=default');
      }
    } else {
      newURLArr = arr.splice(0, 4); // take to listing page ie root slug
      if (pageSlug === 'workflow-executions') {
        newURLArr[3] = 'logs/executions'; // override page param as workflow-executions listing page isn't present
      }
    }

    newURL = newURLArr.join('/');
    history.push(newURL);
  };

  return (
    <div className="max-w-lg lg:max-w-xs mr-2">
      <Select value={currentEnv?.[0]?.slug} onValueChange={changeEnv}>
        <SelectTrigger className="data-[state=open]:bg-accent hover:bg-accent focus:ring-0 outline-none focus:outline-none shadow-none border-0 h-7 my-1.5 mr-2 p-0 px-3">
          {currentEnv?.[0]?.name}
        </SelectTrigger>
        <SelectContent className="mt-[4px]">
          <SelectGroup>
            {allEnv?.map(env => {
              return (
                <SelectItem
                  key={env.slug}
                  value={env.slug}
                  className="py-2 my-1"
                >
                  <div className="max-w-72">
                    <p className="text-popover-foreground text-sm font-normal">
                      {env.name}
                    </p>
                    {env.description && (
                      <p className="text-xs text-muted-foreground mt-1">
                        {env.description}
                      </p>
                    )}
                  </div>
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

export default EnvSwitcher;

import { useContext } from 'react';
import { Handle, Position } from 'reactflow';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import { FlowContext } from './WorkflowDetails';
import { ReactComponent as TriggerNodeIcon } from 'assets/svgs/triggerNodeIcon.svg';
import { ReactComponent as DelayNodeIcon } from 'assets/svgs/delayNodeIcon.svg';
import { ReactComponent as BatchingNodeIcon } from 'assets/svgs/batchingNodeIcon.svg';
import { ReactComponent as ExitNodeIcon } from 'assets/svgs/exitNodeIcon.svg';
import { ReactComponent as MultiChannelNodeIcon } from 'assets/svgs/multiChannelNodeIcon.svg';
import { ReactComponent as SmartRouteNodeIcon } from 'assets/svgs/smartRouteNodeIcon.svg';
import { ReactComponent as SMSNodeIcon } from 'assets/svgs/smsNodeIcon.svg';
import { ReactComponent as EmailNodeIcon } from 'assets/svgs/emailNodeIcon.svg';
import { ReactComponent as MobilePushNodeIcon } from 'assets/svgs/mobilePushNodeIcon.svg';
import { ReactComponent as WebPushNodeIcon } from 'assets/svgs/webPushNodeIcon.svg';
import { ReactComponent as InboxNodeIcon } from 'assets/svgs/inboxNodeIcon.svg';
import { ReactComponent as WhatsappNodeIcon } from 'assets/svgs/whatsappNodeIcon.svg';
import { ReactComponent as SlackNodeIcon } from 'assets/svgs/slackNodeIcon.svg';
import { ReactComponent as TeamsNodeIcon } from 'assets/svgs/teamsNodeIcon.svg';
import { ReactComponent as WaitUntilNodeIcon } from 'assets/svgs/waitUntilNodeIcon.svg';
import { ReactComponent as WebhookNodeIcon } from 'assets/svgs/webhookNodeIcon.svg';
import { ReactComponent as FetchNodeIcon } from 'assets/svgs/fetchNodeIcon.svg';
import { ReactComponent as BranchNodeIcon } from 'assets/svgs/branchNodeIcon.svg';
import { ReactComponent as DataTransformationIcon } from 'assets/svgs/dataTransformationIcon.svg';
import { ReactComponent as DigestNodeIcon } from 'assets/svgs/digestNodeIcon.svg';
import { ReactComponent as TimeWindowIcon } from 'assets/svgs/timeWindowIcon.svg';
import { CircleAlert } from 'lucide-react';
import urlUtils from 'utils/urlUtils';
import { classNames } from 'utils';
import NodeStatus from './Executions/NodeStatus';
import { digestRepeatText } from './Forms/DigestNode';

function getTriggerDescription(data) {
  const types = {
    event: 'Event',
    api: 'API',
    dynamic: 'API',
  };
  return `via ${types[data.trigger_type]}`;
}

function getDelayDescription(data) {
  let description = '';
  if (data?.description) {
    description = data.description;
  } else {
    const properties = data?.properties;
    if (properties?.delay_type === 'dynamic' && properties?.value) {
      description = properties.value;
    } else if (properties?.delay_type === 'fixed') {
      if (properties?.value) {
        const comps = properties.value.match(/[a-z]+|[^a-z]+/gi);
        const days = parseInt(comps[0]);
        const hours = parseInt(comps[2]);
        const minutes = parseInt(comps[4]);
        const seconds = parseInt(comps[6]);
        if (days) {
          description += `${days} day `;
        }
        if (hours) {
          description += `${hours} hrs `;
        }
        if (minutes) {
          description += `${minutes} min `;
        }
        if (seconds) {
          description += `${seconds} sec`;
        }
      }
    }
  }

  return description;
}

function getbatchDescription(data) {
  let description = '';
  if (data?.description) {
    description = data.description;
  } else {
    const properties = data?.properties;
    if (
      properties?.window_type === 'dynamic' &&
      properties?.dynamic_window_expr
    ) {
      description = properties.dynamic_window_expr;
    } else if (properties?.window_type === 'fixed') {
      if (properties?.fixed_window) {
        const comps = properties.fixed_window.match(/[a-z]+|[^a-z]+/gi);
        const days = parseInt(comps[0]);
        const hours = parseInt(comps[2]);
        const minutes = parseInt(comps[4]);
        const seconds = parseInt(comps[6]);
        if (days) {
          description += `${days} day `;
        }
        if (hours) {
          description += `${hours} hrs `;
        }
        if (minutes) {
          description += `${minutes} min `;
        }
        if (seconds) {
          description += `${seconds} sec`;
        }
      }
    }

    if (properties?.batch_key) {
      if (description) {
        description += `, batch_key: ${properties.batch_key}`;
      } else {
        description += `batch_key: ${properties.batch_key}`;
      }
    }
  }
  return description;
}

function getSendDescription(data) {
  let description = '';
  if (data?.description) {
    description = data.description;
  } else {
    const properties = data?.properties;
    if (properties?.template) {
      description = `template: ${properties.template}`;
    }
  }
  return description;
}

function getDigestDescription(data) {
  const properties = data?.properties;
  const schedule = properties?.schedule;
  const frequencyText = digestRepeatText(properties);

  const timeFormat =
    schedule?.frequency === 'minutely' || schedule?.frequency === 'hourly';

  let description = '';

  if (data?.description) {
    description = data.description;
  } else {
    if (properties?.schedule_type === 'dynamic') {
      description = `Dynamic frequency - ${properties?.dynamic_schedule_expr}`;
    } else {
      description = `
      ${frequencyText}${!timeFormat ? ` at ${schedule?.time}` : ''} ${
        schedule?.dtstart
          ? `starting from ${format(new Date(schedule?.dtstart), 'PP HH:mm')}`
          : ''
      }`;
    }
  }

  return description;
}

function getWebhookDescription(data) {
  let description;
  if (data?.description) {
    description = data.description;
  } else {
    const properties = data?.properties;
    description = `${properties.http_method} request to an endpoint`;
  }
  return description;
}

function getFetchDescription() {
  return 'GET data from an API endpoint';
}

function getBranchToolTipData(data) {
  const condition = data?.conditions?.[0];

  if (condition?.type === 'delay' && condition?.delay_properties?.value) {
    const properties = condition?.delay_properties;
    if (properties?.delay_type === 'fixed') {
      let description = '';
      const comps = properties.value.match(/[a-z]+|[^a-z]+/gi);
      const days = parseInt(comps[0]);
      const hours = parseInt(comps[2]);
      const minutes = parseInt(comps[4]);
      const seconds = parseInt(comps[6]);

      if (days) {
        description += `${days} day `;
      }
      if (hours) {
        description += `${hours} hrs `;
      }
      if (minutes) {
        description += `${minutes} min `;
      }
      if (seconds) {
        description += `${seconds} sec`;
      }

      return description;
    } else {
      return `Dynamic window - ${properties.value}`;
    }
  }
  return null;
}

export function TriggerNode({ data }) {
  const { selectedNode } = useContext(FlowContext);
  const isActive = selectedNode?.id === data?.id;
  const description = getTriggerDescription(data);
  const hasErrors =
    !data?.trigger_type ||
    (data?.trigger_type === 'event' && !data?.trigger_events);
  const borderColor = isActive
    ? hasErrors
      ? 'border-red-500'
      : 'border-indigo-600'
    : 'border';

  return (
    <>
      <div
        className={classNames(
          `border bg-white w-[250px] rounded-md p-3`,
          borderColor,
          description || hasErrors ? 'h-[75px]' : 'h-[55px]'
        )}
      >
        <div className="flex gap-2 items-center pb-2">
          <TriggerNodeIcon className="h-4 w-4" />
          <p className="text-sm">Trigger</p>
        </div>

        {hasErrors && (
          <p className="text-xs py-1.5 text-red-500 overflow-ellipsis overflow-hidden whitespace-nowrap border-t">
            Error in node settings
          </p>
        )}
        {description && !hasErrors && (
          <p className="text-xs pt-1.5 text-[#475569] overflow-ellipsis overflow-hidden whitespace-nowrap border-t">
            {description}
          </p>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} className="invisible" />
    </>
  );
}

const nodesData = {
  delay: {
    name: 'Delay',
    description: data => getDelayDescription(data),
    icon: DelayNodeIcon,
  },
  batch: {
    name: 'Batch',
    description: data => getbatchDescription(data),
    icon: BatchingNodeIcon,
  },
  httpapi_webhook: {
    name: 'Webhook',
    description: data => getWebhookDescription(data),
    icon: WebhookNodeIcon,
  },
  httpapi_fetch: {
    name: 'Fetch',
    description: data => getFetchDescription(data),
    icon: FetchNodeIcon,
  },
  multibranch_wait_until: {
    name: 'Wait Until',
    description: data =>
      data?.description || 'Conditions are met or max wait time is over',
    icon: WaitUntilNodeIcon,
  },
  branch_waituntil: {
    name: 'Wait Until',
    description: data =>
      data?.description || 'Conditions are met or max wait time is over',
    icon: WaitUntilNodeIcon,
  },
  branch: {
    name: 'Branch',
    description: data =>
      data?.description || 'Execute first branch that satisfies the condition',
    icon: BranchNodeIcon,
  },
  transform: {
    name: 'Data Transform',
    description: data =>
      data?.description || 'Generate or override variable using input',
    icon: DataTransformationIcon,
  },
  digest: {
    name: 'Digest',
    description: data =>
      getDigestDescription(data) ||
      'Batch triggers and send notifications at recurring digest schedule',
    icon: DigestNodeIcon,
  },
  timewindow: {
    name: 'Time Window',
    description: data =>
      data?.description ||
      'Notify recipients in their timezone and preferred duration. This will introduce a wait until the time window starts.',
    icon: TimeWindowIcon,
  },
  send_multi_channel: {
    name: 'Multi-Channel',
    description: data => getSendDescription(data),
    icon: MultiChannelNodeIcon,
  },
  send_smart_channel_routing: {
    name: 'Smart Channel Routing',
    description: data => getSendDescription(data),
    icon: SmartRouteNodeIcon,
  },
  send_email: {
    name: 'Email',
    description: data => getSendDescription(data),
    icon: EmailNodeIcon,
  },
  send_sms: {
    name: 'SMS',
    description: data => getSendDescription(data),
    icon: SMSNodeIcon,
  },
  send_mobile_push: {
    name: 'Mobile Push',
    description: data => getSendDescription(data),
    icon: MobilePushNodeIcon,
  },
  send_webpush: {
    name: 'Web Push',
    description: data => getSendDescription(data),
    icon: WebPushNodeIcon,
  },
  send_inbox: {
    name: 'Inbox',
    description: data => getSendDescription(data),
    icon: InboxNodeIcon,
  },
  send_whatsapp: {
    name: 'Whatsapp',
    description: data => getSendDescription(data),
    icon: WhatsappNodeIcon,
  },
  send_slack: {
    name: 'Slack',
    description: data => getSendDescription(data),
    icon: SlackNodeIcon,
  },
  send_ms_teams: {
    name: 'Microsoft teams',
    description: data => getSendDescription(data),
    icon: TeamsNodeIcon,
  },
};

export function CustomCommonNode({ data }) {
  const {
    setDragStart,
    editMode,
    selectedNode,
    setDraggingNodeId,
    isObservability,
    wfExecutionData,
  } = useContext(FlowContext);

  const isActive = selectedNode?.id === data?.id;
  const nodeMetaData = nodesData[data.node_type];

  const nodeName = data?.name || nodeMetaData.name;
  const description = nodeMetaData.description(data);

  const showNameTooltip = nodeName?.length > 18;
  const showDescriptionTooltip = description?.length > 35;

  const hasErrors = data?.errors && Object.keys(data.errors)?.length > 0;

  const borderColor = isActive
    ? hasErrors
      ? 'border-red-500'
      : 'border-indigo-600'
    : 'border';

  const NodeIcon = nodeMetaData.icon;
  return (
    <>
      <Handle type="target" position={Position.Top} className="invisible" />
      <div
        className={classNames(
          'border bg-white w-[250px] rounded-md p-3',
          borderColor,
          description || hasErrors ? 'h-[75px]' : 'h-[55px]'
        )}
        draggable={editMode}
        onDragStart={ev => {
          setDraggingNodeId(data.id);
          setDragStart(true);
          ev.dataTransfer.setData('dragAction', 'update');
          ev.dataTransfer.setData('nodeId', data.id);
        }}
        onDragEnd={() => {
          setDragStart(false);
          setDraggingNodeId();
        }}
      >
        <div className="flex items-center justify-between pb-2">
          <div className="flex items-center gap-2 overflow-hidden">
            <NodeIcon className="h-4 w-4 min-w-[16px]" />
            <p
              className="text-sm overflow-ellipsis overflow-hidden whitespace-nowrap"
              data-tooltip-id={`${data.id}-node-tooltip`}
              data-tooltip-content={nodeName}
              data-tooltip-delay-show={500}
              data-tooltip-hidden={!showNameTooltip}
            >
              {nodeName}
            </p>
          </div>
          {isObservability && (
            <NodeStatus
              logs={wfExecutionData}
              node_type={data?.node_type}
              node_id={data?.id}
            />
          )}
        </div>
        {hasErrors && (
          <p
            className="text-xs py-1.5 text-red-500 overflow-ellipsis overflow-hidden whitespace-nowrap border-t"
            data-tooltip-id={`${data.id}-node-tooltip`}
            data-tooltip-content={description}
            data-tooltip-delay-show={500}
            data-tooltip-hidden={!showDescriptionTooltip}
          >
            Error in node settings
          </p>
        )}
        {description && !hasErrors && (
          <p
            className="text-xs py-1.5 text-[#475569] overflow-ellipsis overflow-hidden whitespace-nowrap border-t"
            data-tooltip-id={`${data.id}-node-tooltip`}
            data-tooltip-content={description}
            data-tooltip-delay-show={500}
            data-tooltip-hidden={!showDescriptionTooltip}
          >
            {description}
          </p>
        )}
      </div>
      <Tooltip
        id={`${data.id}-node-tooltip`}
        style={{ fontSize: 12, padding: 5 }}
      />
      <Handle type="source" position={Position.Bottom} className="invisible" />
    </>
  );
}

export const SEND_NODES = {
  send_multi_channel: {
    id: 'send_multi_channel',
    name: 'Multi-Channel',
    icon: MultiChannelNodeIcon,
    formHelptext: 'Send multi-channel notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-multi-channel',
  },
  send_smart_channel_routing: {
    id: 'send_smart_channel_routing',
    name: 'Smart Channel Routing',
    icon: SmartRouteNodeIcon,
    formHelptext: 'AI powered routing across multiple channels',
    docLink: 'https://docs.suprsend.com/docs/smart-delivery',
  },
  send_email: {
    id: 'send_email',
    name: 'Email',
    icon: EmailNodeIcon,
    formHelptext: 'Send email notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
  send_sms: {
    id: 'send_sms',
    name: 'SMS',
    icon: SMSNodeIcon,
    formHelptext: 'Send sms notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
  send_mobile_push: {
    id: 'send_mobile_push',
    name: 'Mobile Push',
    icon: MobilePushNodeIcon,
    formHelptext: 'Send android and iOS push notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
  send_webpush: {
    id: 'send_webpush',
    name: 'Web Push',
    icon: WebPushNodeIcon,
    formHelptext: 'Send webpush notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
  send_inbox: {
    id: 'send_inbox',
    name: 'Inbox',
    icon: InboxNodeIcon,
    formHelptext: 'Send inbox notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
  send_whatsapp: {
    id: 'send_whatsapp',
    name: 'Whatsapp',
    icon: WhatsappNodeIcon,
    formHelptext: 'Send whatsapp notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
  send_slack: {
    id: 'send_slack',
    name: 'Slack',
    icon: SlackNodeIcon,
    formHelptext: 'Send slack notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
  send_ms_teams: {
    id: 'send_ms_teams',
    name: 'Microsoft teams',
    icon: TeamsNodeIcon,
    formHelptext: 'Send microsoft teams notification to recipient',
    docLink: 'https://docs.suprsend.com/docs/delivery-single-channel',
  },
};

export function ConditionNode({ data }) {
  const hasErrors = data?.errors
    ? Object.keys(data?.errors)?.length > 0
    : false;

  return (
    <div className="my-[-8px]">
      <Handle type="target" position={Position.Top} className="invisible" />
      <div
        className="w-[250px] flex justify-center"
        data-tooltip-id={`branch_conditions_${data.id}`}
        data-tooltip-content={getBranchToolTipData(data)}
        data-tooltip-place="top"
      >
        <div className="bg-[#EDF1F5] px-3 py-1 rounded-full flex justify-center gap-1 items-center border border-[#CBD5E1]">
          <p className="text-xs text-[#475569]">{data.name}</p>
          {hasErrors && <CircleAlert className="h-3 w-3 text-destructive" />}
        </div>
      </div>
      <Tooltip
        id={`branch_conditions_${data.id}`}
        style={{ fontSize: 12, padding: 5 }}
      />
      <Handle type="source" position={Position.Bottom} className="invisible" />
    </div>
  );
}

export function JoinNode() {
  return (
    <>
      <Handle type="target" position={Position.Top} className="invisible" />
      <div className="flex justify-center w-[250px] h-[0.1px] border rounded-md bg-transparent invisible"></div>
      <Handle type="source" position={Position.Bottom} className="invisible" />
    </>
  );
}

export function ExitNode() {
  return (
    <>
      <Handle type="target" position={Position.Top} className="invisible" />
      <div className="flex justify-center w-[250px] h-[75px]">
        <div className="border bg-white h-[35px] w-[100px] flex items-center gap-2 p-3 rounded-md">
          <ExitNodeIcon className="h-4 w-4" />
          <p className="text-sm">Exit</p>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} className="invisible" />
    </>
  );
}

export function DynamicWorkflowNode({ data }) {
  return (
    <>
      <div
        className={classNames(
          'flex w-[400px] border rounded-md bg-white flex-col p-4',
          data?.isBroadcast ? 'h-[140px]' : 'h-[115px]'
        )}
      >
        {data.isBroadcast && (
          <p className="text-sm mb-2">
            List ID:{' '}
            <Link
              to={urlUtils.makeURL(`lists/${data?.listId}`)}
              target="_blank"
              className="text-[#2e70e8]"
            >
              {data?.listId}
            </Link>
          </p>
        )}
        <p className="text-sm">
          Template:{' '}
          <Link
            to={urlUtils.makeURL(
              `templates/${data?.template}/${
                data.templateActiveChannel ? data.templateActiveChannel : ''
              }`
            )}
            target="_blank"
            className="text-[#2e70e8]"
          >
            {data?.template}
          </Link>
        </p>
        <p className="p-2 bg-[#F0F5FF] text-[#475569] text-sm rounded-md mt-4">
          Rest of the configuration is dynamic (passed via Api)
        </p>
      </div>
    </>
  );
}

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EmptyState, TimeAgo } from 'components';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  Button,
  CTooltip,
  COverflowTooltip,
} from 'new-components';
import { HelpCircle, ArrowDownWideNarrow } from 'lucide-react';
import { cn } from 'utils';
import { MainFilters, MainModalFilter } from './Filters';
import APILogsModal from './DetailsModal';

const statusTheme = {
  success: {
    label: 'Success',
    color: 'success-subtle',
    textColor: 'success',
    bgColor: 'success-muted',
  },
  warning: {
    label: 'Partial Failure',
    color: 'warning-subtle',
    textColor: 'warning',
    bgColor: 'warning-muted',
  },
  error: {
    label: 'Failure',
    color: 'destructive-subtle',
    textColor: 'destructive',
    bgColor: 'destructive-muted',
  },
};

function LogRow({ log, hasTenant, showDistinctID }) {
  let logStatus = 'success';

  if (log?.status === 'failure') {
    logStatus = 'error';
  } else if (log?.status === 'success' && log?.errors?.length > 0) {
    logStatus = 'warning';
  }

  const barColor = `--${statusTheme[logStatus]['color']}`;

  return (
    <>
      <TableCell className="w-[2.5%]">
        <div
          className="h-6 w-1 rounded-lg ml-2.5"
          style={{
            backgroundColor: `hsl(var(${barColor}))`,
          }}
        />
      </TableCell>
      <TableCell className="p-3 text-foreground align-middle w-[14%] overflow-hidden">
        <TimeAgo
          dateInput={log?.requested_at}
          className="overflow-hidden text-ellipsis whitespace-nowrap"
        />
      </TableCell>
      <TableCell className="p-3 text-foreground align-middle w-[11%] overflow-hidden">
        {log?.api_type ? (
          <COverflowTooltip
            trigger={
              <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                {log?.api_type}
              </p>
            }
          >
            {log?.api_type}
          </COverflowTooltip>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className="p-3 text-foreground align-middle w-[17.8%] overflow-hidden">
        {log?.api_name ? (
          <COverflowTooltip
            trigger={
              <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                {log?.api_name}
              </p>
            }
          >
            {log?.api_name}
          </COverflowTooltip>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className="w-[22.8%] overflow-hidden truncate whitespace-no-wrap">
        {log?.idempotency_key}
      </TableCell>
      {showDistinctID && (
        <TableCell className="w-[19.8%] overflow-hidden whitespace-no-wrap truncate">
          {log?.distinct_ids?.length > 0 ? (
            <>
              {log?.distinct_ids?.[0]}
              <span className="font-medium text-accent-foreground">
                {log?.distinct_ids?.length > 1 &&
                  ` + ${log?.distinct_ids?.length - 1} more`}
              </span>
            </>
          ) : (
            '-'
          )}
        </TableCell>
      )}
      {hasTenant && (
        <TableCell className="p-3 text-foreground align-middle w-[13%] overflow-hidden">
          {log?.tenant ? (
            <COverflowTooltip
              trigger={
                <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {log?.tenant}
                </p>
              }
            >
              {log?.tenant}
            </COverflowTooltip>
          ) : (
            '-'
          )}
        </TableCell>
      )}
    </>
  );
}

export default function RequestLogsTable({
  requestsData,
  queryParam,
  chartData,
  hasTenant,
  RequestChart,
  openLogDetails,
  setOpenLogDetails,
  setShowLoader,
  apiTypeOptions,
  type,
  filters,
  setFilters,
  showDistinctID = true,
}) {
  const history = useHistory();

  const [selectedLogIndex, setSelectedLogIndex] = useState('');

  const pageNumber = Number(queryParam.get('page')) || 0;
  const apiLogs = requestsData?.results;
  const metaData = requestsData?.meta;
  const isEmpty = apiLogs.length === 0;
  const logDetails = requestsData?.results?.[selectedLogIndex];

  return (
    <div className="grid grid-cols-10 gap-4">
      {type !== 'SUBSCRIBERS' && (
        <div className="col-span-2">
          <div className="border rounded">
            <MainFilters
              queryParam={queryParam}
              chartData={chartData}
              hasTenant={hasTenant}
              apiTypeOptions={apiTypeOptions}
            />
          </div>
        </div>
      )}
      <div
        className={cn(type === 'SUBSCRIBERS' ? 'col-span-10' : 'col-span-8')}
      >
        {isEmpty ? (
          <div>
            <EmptyState
              url="https://docs.suprsend.com/reference/overview"
              className="rounded mt-0 mx-0 border-solid border"
            />
          </div>
        ) : (
          <div
            className={cn(
              'border rounded overflow-hidden',
              !RequestChart && 'border-t-0'
            )}
          >
            {RequestChart && <div className="py-2">{RequestChart}</div>}
            <Table className="min-w-full table-fixed border-none">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[2.5%]"></TableHead>
                  <TableHead className="w-[16%]">
                    <div className="flex space-x-1">
                      <ArrowDownWideNarrow className="w-5 h-5" />
                      <p className="flex-grow">Received at</p>
                    </div>
                  </TableHead>
                  <TableHead className="w-[11%] ">Type</TableHead>
                  <TableHead className="w-[17.8%]">Name</TableHead>
                  <TableHead className="w-[22.8%]">
                    <div className="flex items-center">
                      <p>Idempotency Key</p>
                      <CTooltip
                        trigger={
                          <HelpCircle className="h-4 w-4 ml-1.5 text-muted-foreground" />
                        }
                      >
                        <p>
                          Unique identifier of the request. Duplicate requests
                          with the same idempotency key are not processed within
                          24 hours
                        </p>
                      </CTooltip>
                    </div>
                  </TableHead>
                  {showDistinctID && (
                    <TableHead className="w-[19.8%] ">
                      <div className="flex items-center">
                        <p>Distinct ID</p>
                        <CTooltip
                          trigger={
                            <HelpCircle className="h-4 w-4 ml-1.5 text-muted-foreground" />
                          }
                        >
                          <p>
                            Unique identifier of the user. Acts as the recipient
                            id if recipient isn't overriden in the workflow
                          </p>
                        </CTooltip>
                      </div>
                    </TableHead>
                  )}
                  {hasTenant && (
                    <TableHead className="w-[13%]">
                      <div className="flex items-center">
                        <p>Tenant ID</p>
                        <CTooltip
                          trigger={
                            <HelpCircle className="h-4 w-4 ml-1.5 text-muted-foreground" />
                          }
                        >
                          <p>Unique identifier of the tenant</p>
                        </CTooltip>
                      </div>
                    </TableHead>
                  )}
                </TableRow>
              </TableHeader>

              <TableBody>
                {apiLogs?.map((log, index) => {
                  return (
                    <TableRow
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenLogDetails(true);
                        setSelectedLogIndex(index);
                      }}
                      key={index}
                    >
                      <LogRow
                        log={log}
                        hasTenant={hasTenant}
                        showDistinctID={showDistinctID}
                      />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <nav
              className="min-w-full px-4 py-3 flex items-center justify-between border-t border-border sm:px-6 "
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-accent-foreground">
                  Showing{' '}
                  <span className="font-medium">{pageNumber * 20 + 1}</span> to{' '}
                  <span className="font-medium">
                    {Math.min(pageNumber * 20 + 20, metaData?.count)}
                  </span>{' '}
                  of <span className="font-medium">{metaData?.count}</span>{' '}
                  results
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <Button
                  variant="outline"
                  onClick={() => {
                    setShowLoader(true);
                    queryParam.set('page', pageNumber - 1);
                    history.push({ search: queryParam.toString() });
                  }}
                  disabled={!metaData.previous}
                >
                  Previous
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    setShowLoader(true);
                    queryParam.set('page', pageNumber + 1);
                    history.push({ search: queryParam.toString() });
                  }}
                  disabled={!metaData.next}
                  className="ml-3"
                >
                  Next
                </Button>
              </div>
            </nav>
          </div>
        )}
      </div>
      <APILogsModal
        openLogDetails={openLogDetails}
        setOpenLogDetails={setOpenLogDetails}
        log={logDetails}
        statusTheme={statusTheme}
      />
      {type === 'SUBSCRIBERS' && (
        <MainModalFilter
          queryParam={queryParam}
          chartData={chartData}
          hasTenant={hasTenant}
          apiTypeOptions={apiTypeOptions}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </div>
  );
}

import { lazy } from 'react';

export const Login = lazy(() => import('./Login/Login'));
export const SigningCallBack = lazy(() => import('./Login/SigningCallBack'));
export const SignUp = lazy(() => import('./Login/SignUp'));
export const AcceptInviteLink = lazy(() => import('./Login/AcceptInviteLink'));
export const EmailVerificationPending = lazy(() =>
  import('./Login/EmailVerificationPending')
);
export const SignupUserEmailRedirection = lazy(() =>
  import('./Login/SignupUserEmailRedirection')
);
export const AdminApprovalPending = lazy(() =>
  import('./Login/AdminApprovalPending')
);
export const AdminApprovalRedirection = lazy(() =>
  import('./Login/AdminApprovalRedirection')
);
export const LoginUserEmailRedirection = lazy(() =>
  import('./Login/LoginUserEmailRedirection')
);
export const Dashboard = lazy(() => import('./Dashboard'));
export const Workflows = lazy(() => import('./Workflow'));
export const Templates = lazy(() => import('./Templates/TemplatesList'));
export const APILogs = lazy(() => import('./Logs/RequestLogs'));
export const WorkflowLogs = lazy(() => import('./Logs/Workflow'));
export const BroadcastLogs = lazy(() => import('./Logs/Broadcast'));
export const Vendors = lazy(() => import('./Vendors'));
export const NotFound = lazy(() => import('./NotFound'));
export const TemplateDetails = lazy(() =>
  import('./Templates/TemplateDetails')
);
export const UserSettings = lazy(() =>
  import('./DashboardSettings/UserSettings')
);
export const AccountSettings = lazy(() =>
  import('./DashboardSettings/AccountSettings')
);
export const ApiKeys = lazy(() => import('./Settings/ApiKeys'));
export const NotificationCategories = lazy(() =>
  import('./Settings/NotificationCategories')
);
export const ConnectorsList = lazy(() => import('./Connectors/ConnectorsList'));
export const ExistingConnectorList = lazy(() =>
  import('./Connectors/ExistingConnectorsList')
);
export const MixpanelForm = lazy(() => import('./Connectors/MixpanelForm'));
export const MixpanelLogs = lazy(() => import('./Connectors/MixpanelLogs'));
export const SegmentForm = lazy(() => import('./Connectors/SegmentForm'));
export const AmazonS3Form = lazy(() => import('./Connectors/AmazonS3Form'));
export const PostgresSQL = lazy(() => import('./Connectors/PostgresSQLForm'));
export const MySQL = lazy(() => import('./Connectors/MySQLForm'));
export const BigQuery = lazy(() => import('./Connectors/BigQueryForm'));
export const Webhooks = lazy(() => import('./Settings/Webhooks'));
export const Integrations = lazy(() => import('./Integrations'));
export const UserSegments = lazy(() => import('./UserSegments'));
export const Subscribers = lazy(() => import('./NewSubscribers'));
export const SubscriberDetails = lazy(() =>
  import('./NewSubscribers/SubscriberDetails')
);
export const Lists = lazy(() => import('./Subscribers/Lists'));
export const ListDetails = lazy(() => import('./Subscribers/ListDetails'));
export const ListLogs = lazy(() => import('./Subscribers/ListLogs'));
export const SyncTaskList = lazy(() => import('./Subscribers/SyncTaskList'));
export const EditSyncTask = lazy(() => import('./Subscribers/EditSyncTask'));
export const SyncTaskDetails = lazy(() =>
  import('./Subscribers/SyncTaskDetails')
);
export const Brands = lazy(() => import('./Brands'));
export const BrandDetails = lazy(() => import('./Brands/BrandDetails'));
export const BrandPreferences = lazy(() => import('./Brands/BrandPreferences'));
export const Onboarding = lazy(() => import('./Onboarding'));
export const WorkflowDetails = lazy(() => import('./Workflow/WorkflowDetails'));
export const Vercel = lazy(() => import('./Vercel'));

import { cn } from 'utils';

export default function EmptyState({
  url = 'https://docs.suprsend.com/',
  className,
}) {
  return (
    <div
      className={cn(
        'mx-4 mt-4 border-2 border-border border-dashed rounded-lg p-12 text-center',
        className
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto h-12 w-12 text-muted-foreground"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 10h16M4 14h16M4 18h16"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-foreground">
        No Data Available
      </h3>
      <p className="mt-1 text-sm text-muted-foreground">
        Please check{' '}
        <a
          className="text-primary underline"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          documentation
        </a>{' '}
        to know more.
      </p>
    </div>
  );
}

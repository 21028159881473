import { useState, useEffect } from 'react';
import { Clipboard, Check } from 'lucide-react';
import { copyToClipboard } from 'utils';
import { Button } from 'new-components';

export default function CopyButton({
  textToCopy = '',
  variant = 'outline',
  size = 16,
  ...props
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <Button
      variant={variant}
      size="icon"
      onClick={ev => {
        copyToClipboard(textToCopy);
        setCopied(true);
        ev.preventDefault();
      }}
      {...props}
    >
      {!copied ? (
        <Clipboard className="text-accent-foreground" size={size} />
      ) : (
        <Check className="text-accent-foreground" size={size} />
      )}
    </Button>
  );
}

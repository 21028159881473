import React, { Fragment, Suspense, lazy } from 'react';
import { Spinner } from 'components';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import 'emoji-mart/css/emoji-mart.css';

const Picker = lazy(() =>
  import('emoji-mart').then(({ Picker }) => ({
    default: Picker,
  }))
);

function EmojiButton({ onSelect, disabled, set = 'native' }) {
  return (
    <div className="p-2">
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button
              disabled={disabled}
              className={`
                ${open ? '' : 'text-opacity-70'} ${
                disabled ? 'text-opacity-20' : ''
              }
                text-black align-middle group rounded-md inline-flex items-center hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-lg`}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-1 h-5 w-4 text-gray-600 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 px-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <Suspense fallback={<Spinner />}>
                    <Picker
                      onSelect={onSelect}
                      title="Select Emoji"
                      set={set}
                    />
                  </Suspense>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default EmojiButton;

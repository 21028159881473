import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Clipboard,
  ExternalLink,
  ChevronDown,
  ChevronUp,
  LockIcon,
} from 'lucide-react';
import { TimeAgo } from 'components';
import { JSONDisplay } from 'custom-components';
import { Sheet, SheetContent, CCopyTooltip, CTooltip } from 'new-components';
import { convertObjToString, useEditAccess } from 'utils';
import urlUtils from 'utils/urlUtils';

const summaryContent = (log, payload) => {
  const summaryOptions = {
    eventName: {
      name: 'Event Name',
      value: log?.api_name,
      hasCopyOption: true,
      navigate: false,
      key: 'event_name',
    },
    idempotencyKey: {
      name: 'Idempotency key',
      value: log?.idempotency_key,
      hasCopyOption: true,
      navigate: false,
      key: 'idempotency_key',
    },
    tenantID: {
      name: 'Tenant ID',
      value: log?.tenant,
      hasCopyOption: false,
      navigate: true,
      url: urlUtils.makeURL(`brands/${log?.tenant}`),
      key: 'tenant_id',
    },
    distinctID: {
      name: 'Distinct ID',
      value: log?.distinct_ids,
      hasCopyOption: false,
      navigate: true,
      url: urlUtils.makeURL(`subscribers/`),
      key: 'distinct_id',
    },
    processedAt: {
      name: 'Received at',
      value: <TimeAgo dateInput={log?.requested_at} />,
      hasCopyOption: false,
      navigate: false,
      key: 'received_at',
    },
    workflowName: {
      name: 'Workflow Name',
      value: log?.api_name,
      hasCopyOption: true,
      navigate: !!payload?.body?.$type,
      url: urlUtils.makeURL(`workflows/${payload?.body?.workflow}/`),
      key: 'workflow_name',
    },
    broadcastName: {
      name: 'Broadcast name',
      value: log?.api_name,
      hasCopyOption: true,
      navigate: false,
      key: 'broadcast_name',
    },
    listID: {
      name: 'List ID',
      value: payload?.body?.list_id,
      hasCopyOption: false,
      navigate: false,
      key: 'list_id',
    },
  };

  switch (log?.api_type) {
    case 'event':
      return [
        summaryOptions?.eventName,
        summaryOptions?.idempotencyKey,
        summaryOptions?.tenantID,
        summaryOptions?.distinctID,
        summaryOptions.processedAt,
      ];
    case 'user_update':
      return [
        summaryOptions?.distinctID,
        summaryOptions?.idempotencyKey,
        summaryOptions?.processedAt,
      ];
    case 'workflow':
      return [
        summaryOptions?.workflowName,
        summaryOptions?.idempotencyKey,
        summaryOptions?.tenantID,
        summaryOptions?.distinctID,
        summaryOptions?.processedAt,
      ];

    case 'broadcast':
      return [
        summaryOptions?.broadcastName,
        summaryOptions?.listID,
        summaryOptions?.idempotencyKey,
        summaryOptions?.processedAt,
        summaryOptions?.tenantID,
      ];

    case 'subscriber_preference_update':
      return [
        summaryOptions?.idempotencyKey,
        summaryOptions?.tenantID,
        summaryOptions?.distinctID,
        summaryOptions?.processedAt,
      ];
    case 'tenant_preference_update':
      return [
        summaryOptions?.idempotencyKey,
        summaryOptions?.tenantID,
        summaryOptions?.processedAt,
      ];

    default:
      return [];
  }
};

const payloadCustomization = log => {
  const payload = log?.payload ? JSON.parse(log?.payload) : {};
  const payloadBody = payload?.body;

  delete payloadBody?.env;

  switch (log?.api_type) {
    case 'event':
      delete payloadBody?.$schema;
      delete payloadBody?.$insert_id;
      delete payloadBody?.$time;
      delete payloadBody?.properties?.$browser;
      delete payloadBody?.properties?.$browser_version;
      delete payloadBody?.properties?.$current_url;
      delete payloadBody?.properties?.$device_id;
      delete payloadBody?.properties?.$location;
      delete payloadBody?.properties?.$os;
      delete payloadBody?.properties?.$sdk_type;
      delete payloadBody?.properties?.$sdk_version;
      break;
    case 'user_update':
      delete payloadBody?.$insert_id;
      delete payloadBody?.$time;
      delete payloadBody?.$schema;
      delete payloadBody?.properties?.$location;
      delete payloadBody?.properties?.$ss_sdk_version;
      break;
    case 'workflow':
      delete payloadBody?.$insert_id;
      delete payloadBody?.$time;
      break;
    case 'broadcast':
      delete payloadBody?.$insert_id;
      delete payloadBody?.$time;
      break;
    default:
      break;
  }

  return payload;
};

function APILogsModal({ openLogDetails, setOpenLogDetails, log, statusTheme }) {
  const editAccess = useEditAccess();

  const [viewMoreWorkflowRuns, setViewMoreWorkflowRuns] = useState(false);
  const { slug } = useParams();

  let logStatus = 'success';
  if (log?.status === 'failure') {
    logStatus = 'error';
  } else if (log?.status === 'success' && log?.errors?.length > 0) {
    logStatus = 'warning';
  }

  const dotColor = `--${statusTheme[logStatus]['color']}`;
  const textColor = `--${statusTheme[logStatus]['textColor']}`;
  const bgColor = `--${statusTheme[logStatus]['bgColor']}`;
  const payload = payloadCustomization(log);
  const summary = summaryContent(log, payload);

  const query = payload?.query ? convertObjToString(payload?.query) : '';

  const workflowRuns =
    !viewMoreWorkflowRuns && log?.execution_ids?.length > 2
      ? log?.execution_ids?.slice(0, 2)
      : log?.execution_ids;

  return (
    <Sheet
      open={openLogDetails}
      defaultOpen={openLogDetails}
      onOpenChange={() => setOpenLogDetails(false)}
    >
      <SheetContent className="min-w-[600px] overflow-y-scroll">
        <div>
          <div className="mt-6">
            <div className="flex items-center">
              <p className="text-md text-foreground">Response</p>
              <div className="ml-auto">
                <CCopyTooltip
                  trigger={
                    <Clipboard className="h-4 w-4 text-accent-foreground cursor-pointer" />
                  }
                  copyContent={
                    logStatus === 'success'
                      ? log?.response
                      : JSON.stringify(log?.errors)
                  }
                />
              </div>
            </div>
            <div className="border border-border rounded-md mt-1.5 overflow-hidden">
              <div className="flex items-center w-full py-1.5 px-2 border-b border-b-border">
                <div
                  className="rounded inline-flex items-center py-0.5 px-2"
                  style={{
                    backgroundColor: `hsl(var(${bgColor}))`,
                  }}
                >
                  <div
                    className="h-2 w-2 rounded-full mr-1.5"
                    style={{
                      backgroundColor: `hsl(var(${dotColor}))`,
                    }}
                  />
                  <p
                    className="text-sm"
                    style={{
                      color: `hsl(var(${textColor}))`,
                    }}
                  >
                    {statusTheme[logStatus]['label']}
                  </p>
                </div>
                {(logStatus === 'error' || logStatus === 'warning') && (
                  <a
                    href="https://docs.suprsend.com/docs/error-guides#api-errors"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center text-sm ml-auto text-primary"
                  >
                    <p>Resolution</p>
                    <ExternalLink className="h-3 w-3 ml-1" />
                  </a>
                )}
              </div>
              <div className="text-sm text-accent-foreground bg-muted p-2 overflow-x-scroll max-h-44 overflow-y-scroll">
                <div>
                  {logStatus === 'success' ? (
                    <pre>
                      <JSONDisplay jsonObj={log?.response} wrap={true} />
                    </pre>
                  ) : (
                    log?.errors?.map((error, index) => (
                      <pre>
                        <JSONDisplay jsonObj={error} wrap={true} />
                      </pre>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          {log?.execution_ids?.length > 0 && (
            <div className="mt-6">
              <div className="flex">
                <p className="text-md text-foreground flex-grow">
                  Workflow Executions
                </p>
                {/* <a
                  target="_blank"
                  rel="noreferrer"
                  href={urlUtils.makeURL(
                    `/logs/workflow/?last_n_minutes=custom&start_ts=${startDateToUnix}&end_ts=${endDateToUnix}&status=all&filter_type=idempotency_key&filter_value=${log.idempotency_key}&page=0`
                  )}
                >
                  <ExternalLink className="h-4 w-4 mt-1" />
                </a> */}
              </div>
              <div className="border-t border-l border-r border-border rounded mt-1.5 overflow-hidden text-accent-foreground">
                {workflowRuns?.map((item, index) => {
                  return (
                    <div
                      className="p-2 border-b border-border overflow-scroll"
                      key={index}
                    >
                      <a
                        href={urlUtils.makeURL(
                          `/workflow-executions/${item.exec_id}`
                        )}
                        className="text-sm text-primary cursor-pointer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.exec_id}
                      </a>
                    </div>
                  );
                })}
                {!viewMoreWorkflowRuns && log?.execution_ids?.length > 2 && (
                  <div
                    onClick={() => {
                      setViewMoreWorkflowRuns(true);
                    }}
                    className="flex items-center text-sm p-2 border-b border-border cursor-pointer text-primary"
                  >
                    {`View ${log?.execution_ids?.length - 2} more`}
                    <ChevronDown className="h-4 w-4 ml-1" />
                  </div>
                )}
                {viewMoreWorkflowRuns && (
                  <div
                    onClick={() => {
                      setViewMoreWorkflowRuns(false);
                    }}
                    className="flex items-center text-sm p-2 border-b border-border cursor-pointer text-primary"
                  >
                    {`View less`}
                    <ChevronUp className="h-4 w-4 ml-1" />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="mt-6">
            <p className="text-md text-foreground">Input</p>

            {editAccess ? (
              <div className="border border-border rounded-md mt-1.5 overflow-hidden text-accent-foreground ">
                <div className="flex items-center w-full py-1.5 px-2 border-b border-b-border">
                  <div className="bg-border max-w-[12%] rounded-l-md py-1 px-2">
                    <p className="text-sm text-accent-foreground font-semibold">
                      {payload?.method}
                    </p>
                  </div>

                  <CTooltip
                    trigger={
                      <div className="rounded-r-md py-1 pl-2 px-4 bg-accent max-w-[85%]">
                        <pre className="text-sm text-accent-foreground truncate">
                          {`${payload?.url}${query}`}
                        </pre>
                      </div>
                    }
                  >
                    <p>{`${payload?.url}${query}`}</p>
                  </CTooltip>

                  <div className="ml-auto cursor-pointer">
                    <CCopyTooltip
                      trigger={
                        <p>
                          <Clipboard className="h-4 w-4 text-accent-foreground" />
                        </p>
                      }
                      copyContent={JSON.stringify(payload?.body)}
                    />
                  </div>
                </div>
                <div className="text-sm bg-muted p-2 max-h-44 overflow-y-scroll">
                  <pre>
                    <JSONDisplay jsonObj={payload?.body} wrap={true} />
                  </pre>
                </div>
              </div>
            ) : (
              <div className="border border-border rounded-md mt-1.5 overflow-hidden py-8 text-accent-foreground bg-muted">
                <div className="flex flex-col items-center justify-center">
                  <LockIcon className="h-5 w-5 mb-2 text-foreground" />
                  <p className="text-sm text-foreground">
                    You don't have permission to access this content
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="mt-6">
            <p className="text-md text-foreground">Summary</p>
            <div className="border-t border-l border-r border-border rounded mt-1.5">
              {summary?.map(option => {
                return (
                  <div
                    className="border-b border-border w-full flex"
                    key={option?.name}
                  >
                    <div className="w-3/12 bg-muted p-2">
                      <p className="text-sm text-accent-foreground">
                        {option?.name}
                      </p>
                    </div>
                    <div className="w-9/12 flex flex-wrap p-2 break-all">
                      {option.key === 'distinct_id' ? (
                        option?.value?.map((value, index) => (
                          <a
                            href={`${option?.url}${value}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-sm text-primary inline-block"
                            key={index}
                          >
                            {`${value}${
                              option?.value?.length !== index + 1 ? ', ' : ''
                            }`}
                          </a>
                        ))
                      ) : option?.navigate ? (
                        <a
                          href={option?.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm text-primary truncate"
                        >
                          {option?.value}
                        </a>
                      ) : (
                        <p className="text-sm text-accent-foreground truncate">
                          {option?.value}
                        </p>
                      )}

                      {option?.hasCopyOption && (
                        <div className="mx-2 cursor-pointer">
                          <CCopyTooltip
                            trigger={
                              <p>
                                <Clipboard className="h-4 w-4 text-accent-foreground" />
                              </p>
                            }
                            copyContent={option?.value}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default APILogsModal;

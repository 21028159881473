import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { usePublishWorkflow } from 'apis';
import { Modal, Button } from 'components';
import urlUtils from 'utils/urlUtils';
import { ReactComponent as CommitIcon } from 'assets/svgs/commitIcon.svg';
import { FlowContext } from './WorkflowDetails';

export default function CommitWorkflow({
  showPublishModal,
  setShowPublishModal,
  refetchWfDetails,
}) {
  const { slug, version } = useContext(FlowContext);
  const history = useHistory();

  const [wfVersionDescription, setWfVersionDescription] = useState('');

  const publishWorkflow = usePublishWorkflow(slug, version);

  return (
    <Modal open={showPublishModal} setOpen={setShowPublishModal}>
      <div className="max-w-md inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <div className="pt-5 pb-4 sm:p-6 sm:pb-4">
            <p className="text-lg leading-6 font-medium text-gray-900">
              Commit workflow changes
            </p>
            <p className="text-sm text-gray-700 pr-4 mt-2">
              This will make the latest workflow changes live
            </p>

            <div className="flex flex-col mt-4">
              <label
                className="block text-sm text-gray-500 my-2 font-medium"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                id="description"
                rows={4}
                autoComplete="off"
                onChange={e => {
                  setWfVersionDescription(e.target.value);
                }}
                className="border-gray-300 rounded border p-[5px] text-sm disabled:bg-gray-50 outline-offset-2 outline-indigo-600 placeholder-gray-300"
              />
            </div>

            <div className="flex mt-6 mb-6 gap-5">
              <Button
                className="items-center justify-center text-center w-[50%]"
                as="button"
                onClick={() => {
                  setShowPublishModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                as="submit"
                className="items-center justify-center w-[50%]"
                onClick={async () => {
                  await publishWorkflow.mutateAsync({
                    description: wfVersionDescription,
                  });
                  await refetchWfDetails();
                  setShowPublishModal(false);
                  history.push(urlUtils.makeURL(`workflows/${slug}`));
                }}
              >
                <CommitIcon className="h-5 w-5 text-indigo-600 mr-3" />
                Commit Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import { useQuery, useQueryClient, useMutation } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from 'stores/AuthStore';
import { toast } from 'components';
import { convertObjToString } from 'utils';

const getConnectorsList = async () => {
  const url = '/integration/';
  const { data } = await loggedInAPI.get(url);
  return data;
};

const useConnectorsListAPI = () => {
  return useQuery('connectors', getConnectorsList);
};

const getConnectorDetails = async (page, integrationType) => {
  const params = {
    limit: 20,
    offset: page * 20 || 0,
    integration_type: integrationType,
  };

  const url = buildAPIURL(`/client_integration/${convertObjToString(params)}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

const useGetConnectorDetails = (page, integrationType) => {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/get/client_integration/`,
      page,
      integrationType,
    ],
    () => getConnectorDetails(page, integrationType)
  );
};

const getConnectorLogs = async connectorType => {
  const url = buildAPIURL(`${connectorType}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

const useConnectorLogs = connectorType => {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}${connectorType}`, () =>
    getConnectorLogs(connectorType)
  );
};

const useEditConnectors = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/client_integration/${id}/`);

  return useMutation(data => loggedInAPI.put(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/client_integration/${id}`
      );
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/get/client_integration/`
      );
    },
    onError: error => {
      toast(
        `Error while saving connector details: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

const useAddConnectors = () => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/client_integration/`);

  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/client_integration/`
      );
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/get/client_integration/`
      );
    },
    onError: error => {
      toast(
        `Error while saving connector details: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

const useAddConnectorsWithUpload = () => {
  const queryClient = useQueryClient();
  const envState = useEnvState();

  const url = buildAPIURL(`/client_integration_with_upload/`);

  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/client_integration/`
      );
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/get/client_integration/`
      );
    },
    onError: error => {
      toast(
        `Error while saving connector details: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

const useEditConnectorsWithUpload = id => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/client_integration_with_upload/${id}/`);

  return useMutation(data => loggedInAPI.put(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/client_integration/${id}`
      );
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/get/client_integration/`
      );
    },
    onError: error => {
      toast(
        `Error while saving connector details: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
};

export {
  useConnectorsListAPI,
  useEditConnectors,
  useAddConnectors,
  useGetConnectorDetails,
  useConnectorLogs,
  useAddConnectorsWithUpload,
  useEditConnectorsWithUpload,
};

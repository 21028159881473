import { useQuery, useQueryClient, useMutation } from 'react-query';
import toast from 'react-hot-toast';
import {
  loggedInAPI,
  buildAPIURL,
  useEnvState,
  envStore,
} from 'stores/AuthStore';

function useCSVDownloadReport() {
  const url = buildAPIURL(`/export/workflow/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast.error(`Error in download report : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

const getWorkflows = async apiQueryString => {
  const url = `/v2/${envStore.currentEnv.get()}/workflow/?${apiQueryString}`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useWorkflowListAPI({
  queryString,
  limit = 20,
  offset = 0,
  enabled = true,
}) {
  const queryMap = new URLSearchParams(queryString);
  const queryEntries = queryMap.entries();
  const newQs = new URLSearchParams();
  const page = queryMap.get('page') || 0;
  const multipleSupport = [
    'template',
    'event',
    'category',
    'node_type',
    'tag',
    'status',
  ];

  for (let item of queryEntries) {
    if (multipleSupport.includes(item[0])) {
      const decodeValue = JSON.parse(decodeURIComponent(item?.[1]));
      decodeValue?.map(option => {
        newQs.append(`${item[0]}[]`, option?.value);
      });
    } else {
      newQs.append(`${item[0]}`, item[1]);
    }
  }

  newQs.append('limit', limit);
  newQs.append('offset', offset || page * 20 || 0);

  const apiQueryString = newQs.toString();

  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/workflow/`, page, apiQueryString],
    () => getWorkflows(apiQueryString),
    { keepPreviousData: true, enabled: enabled }
  );
}

const getWorkflowDetails = async slug => {
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useWorkflowDetails(slug, config = {}) {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/workflow/${slug}`,
    () => getWorkflowDetails(slug),
    config
  );
}

function useModifyWorkflow(workflowslug) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/workflow/${workflowslug}/`);
  return useMutation(data => loggedInAPI.put(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries([`${envState.currentEnv.get()}/workflow/`]);
      queryClient.invalidateQueries([
        `${envState.currentEnv.get()}/workflow/${workflowslug}`,
      ]);
    },
    onError: error => {
      toast.error(`Error saving workflow : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

function useCreateWorkflow() {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = `/v2/${envStore.currentEnv.get()}/workflow/`;
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries([`${envState.currentEnv.get()}/workflow/`]);
    },
    onError: error => {
      toast.error(`Error creating workflow : ${error.response.data.message}`);
    },
  });
}

function useUpdateWorkflow(workflowSlug, workflowVersion) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return loggedInAPI.patch(
        `/v2/${envState.currentEnv.get()}/workflow/${workflowSlug}/`,
        data
      );
    },
    {
      onSettled: data => {
        if (data?.data?.id) {
          queryClient.setQueryData(
            [`${envState.currentEnv.get()}/workflow/${workflowSlug}`],
            data.data
          );
        } else {
          queryClient.invalidateQueries(
            `${envState.currentEnv.get()}/workflow/${workflowSlug}`
          );
        }
      },
      onError: error => {
        toast.error(`Error updating node: ${error.response.data.message}`);
      },
    }
  );
}

function useTestWorkflow(templateslug) {
  const url = buildAPIURL(
    `/template/${templateslug}/developer_template_testing/`
  );
  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast.error(`Error triggering test template`);
      throw new Error(error);
    },
  });
}

const getNewWorkflowVersionDetails = async (slug, version) => {
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/version/${version}/`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useNewWorkflowVersionDetails(
  slug,
  version,
  config = { enabled: true }
) {
  const envState = useEnvState();
  return useQuery(
    `${envState.currentEnv.get()}/workflow/${slug}/version/${version}`,
    () => getNewWorkflowVersionDetails(slug, version),
    config
  );
}

function useUpdateWFVersion(workflowSlug, workflowVersion) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return loggedInAPI.patch(
        `/v2/${envState.currentEnv.get()}/workflow/${workflowSlug}/version/_/`,
        data
      );
    },
    {
      onSettled: data => {
        if (data?.data?.id) {
          queryClient.setQueryData(
            [
              `${envState.currentEnv.get()}/workflow/${workflowSlug}/version/${workflowVersion}`,
            ],
            data.data
          );
        } else {
          queryClient.invalidateQueries([
            `${envState.currentEnv.get()}/workflow/${workflowSlug}/version/${workflowVersion}`,
          ]);
        }
      },
      onError: error => {
        toast.error(`Error updating details: ${error.response.data.message}`);
      },
    }
  );
}

function useCreateWorkflowNode(workflowslug, version) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = `/v2/${envState.currentEnv.get()}/workflow/${workflowslug}/version/_/node/`;
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: data => {
      if (data?.data?.id) {
        queryClient.setQueryData(
          [
            `${envState.currentEnv.get()}/workflow/${workflowslug}/version/${version}`,
          ],
          data.data
        );
      } else {
        queryClient.invalidateQueries([
          `${envState.currentEnv.get()}/workflow/${workflowslug}/version/${version}`,
        ]);
      }
    },
    onError: error => {
      toast.error(`Error adding node: ${error.response.data.message}`);
    },
  });
}

function useModifyWorkflowNode(workflowslug, version) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      const nodeId = data.nodeId;
      delete data.nodeId;
      return loggedInAPI.patch(
        `/v2/${envState.currentEnv.get()}/workflow/${workflowslug}/version/_/node/${nodeId}/move/`,
        data
      );
    },
    {
      onSettled: data => {
        if (data?.data?.id) {
          queryClient.setQueryData(
            [
              `${envState.currentEnv.get()}/workflow/${workflowslug}/version/${version}`,
            ],
            data.data
          );
        } else {
          queryClient.invalidateQueries([
            `${envState.currentEnv.get()}/workflow/${workflowslug}/version/${version}`,
          ]);
        }
      },
      onError: error => {
        toast.error(`Error updating node: ${error.response.data.message}`);
      },
    }
  );
}

function useDeleteWorkflowNode(workflowslug, version) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return loggedInAPI.delete(
        `/v2/${envState.currentEnv.get()}/workflow/${workflowslug}/version/_/node/${
          data.nodeId
        }/`
      );
    },
    {
      onSettled: data => {
        if (data?.data?.id) {
          queryClient.setQueryData(
            [
              `${envState.currentEnv.get()}/workflow/${workflowslug}/version/${version}`,
            ],
            data.data
          );
        } else {
          queryClient.invalidateQueries([
            `${envState.currentEnv.get()}/workflow/${workflowslug}/version/${version}`,
          ]);
        }
      },
      onError: error => {
        toast.error(`Error deleting node: ${error.response.data.message}`);
      },
    }
  );
}

function useUpdateNodeProperties(workflowSlug, workflowVersion, nodeId) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  return useMutation(
    data => {
      return loggedInAPI.patch(
        `/v2/${envState.currentEnv.get()}/workflow/${workflowSlug}/version/_/node/${nodeId}/`,
        data
      );
    },
    {
      onSettled: data => {
        if (data?.data?.id) {
          queryClient.setQueryData(
            [
              `${envState.currentEnv.get()}/workflow/${workflowSlug}/version/${workflowVersion}`,
            ],
            data.data
          );
        } else {
          queryClient.invalidateQueries([
            `${envState.currentEnv.get()}/workflow/${workflowSlug}/version/${workflowVersion}`,
          ]);
        }
      },
      onError: error => {
        toast.error(
          `Error updating properties: ${error.response.data.message}`
        );
      },
    }
  );
}

function usePublishWorkflow(workflowSlug, workflowVersion) {
  const envState = useEnvState();

  return useMutation(
    data => {
      return loggedInAPI.patch(
        `/v2/${envState.currentEnv.get()}/workflow/${workflowSlug}/version/_/activate/`,
        data
      );
    },
    {
      onSettled: () => {},
      onError: error => {
        toast.error(
          `Error publishing workflow: ${error.response.data.message}`
        );
      },
    }
  );
}

function useUpdateBranchConditions(workflowSlug, workflowVersion, branchId) {
  const envState = useEnvState();
  const queryClient = useQueryClient();

  return useMutation(
    data => {
      return loggedInAPI.patch(
        `/v2/${envState.currentEnv.get()}/workflow/${workflowSlug}/version/${workflowVersion}/branch/${branchId}/`,
        data
      );
    },
    {
      onSettled: data => {
        if (data?.data?.id) {
          queryClient.setQueryData(
            [
              `${envState.currentEnv.get()}/workflow/${workflowSlug}/version/${workflowVersion}`,
            ],
            data.data
          );
        } else {
          queryClient.invalidateQueries([
            `${envState.currentEnv.get()}/workflow/${workflowSlug}/version/${workflowVersion}`,
          ]);
        }
      },
      onError: error => {
        toast.error(
          `Error saving workflow conditions: ${error.response.data.message}`
        );
      },
    }
  );
}

function useCloneWorkflow(slug) {
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/clone/`;
  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast.error(
        `Error while cloning workflow : ${error.response.data.message}`
      );
    },
  });
}

function useAddBranch(slug, version) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/version/${version}/branch/`;

  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: data => {
      if (data?.data?.id) {
        queryClient.setQueryData(
          [`${envState.currentEnv.get()}/workflow/${slug}/version/${version}`],
          data.data
        );
      } else {
        queryClient.invalidateQueries([
          `${envState.currentEnv.get()}/workflow/${slug}/version/${version}`,
        ]);
      }
    },
    onError: error => {
      toast.error(
        `Error updating branch properties: ${error.response.data.message}`
      );
    },
  });
}

function useUpdateBranch(slug, version, branch) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/version/${version}/branch/${branch}/`;

  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: data => {
      if (data?.data?.id) {
        queryClient.setQueryData(
          [`${envState.currentEnv.get()}/workflow/${slug}/version/${version}`],
          data.data
        );
      } else {
        queryClient.invalidateQueries([
          `${envState.currentEnv.get()}/workflow/${slug}/version/${version}`,
        ]);
      }
    },
    onError: error => {
      toast.error(
        `Error updating branch properties: ${error.response.data.message}`
      );
    },
  });
}

function useMoveBranch(slug, version) {
  const envState = useEnvState();
  const queryClient = useQueryClient();

  return useMutation(
    data => {
      const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/version/${version}/branch/${
        data.branchID
      }/move/`;
      return loggedInAPI.patch(url, data);
    },
    {
      onSettled: data => {
        if (data?.data?.id) {
          queryClient.setQueryData(
            [
              `${envState.currentEnv.get()}/workflow/${slug}/version/${version}`,
            ],
            data.data
          );
        } else {
          queryClient.invalidateQueries([
            `${envState.currentEnv.get()}/workflow/${slug}/version/${version}`,
          ]);
        }
      },
      onError: error => {
        toast.error(`Error moving branch: ${error.response.data.message}`);
      },
    }
  );
}

function useDeleteBranch(slug, version, branchID) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/version/${version}/branch/${branchID}/`;

  return useMutation(data => loggedInAPI.delete(url), {
    onSettled: data => {
      if (data?.data?.id) {
        queryClient.setQueryData(
          [`${envState.currentEnv.get()}/workflow/${slug}/version/${version}`],
          data.data
        );
      } else {
        queryClient.invalidateQueries([
          `${envState.currentEnv.get()}/workflow/${slug}/version/${version}`,
        ]);
      }
    },
    onError: error => {
      toast.error(`Error deleting branch: ${error.response.data.message}`);
    },
  });
}

function useTriggerTestWorkflow(slug) {
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/test_run/`;
  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast.error(
        `Error while triggering test workflow : ${error.response.data.message}`
      );
    },
  });
}

const getWorkflowTestData = async slug => {
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/sample_data/`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useWorkflowTestData(slug, config = {}) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/workflow/test/sample_data`],
    () => getWorkflowTestData(slug),
    config
  );
}

function useSaveWFSampleData(slug) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = `/v2/${envStore.currentEnv.get()}/workflow/${slug}/sample_data/`;
  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries([
        `${envState.currentEnv.get()}/workflow/test/sample_data`,
      ]);
    },
    onError: error => {
      toast.error(
        `Error while saving sample data : ${error.response.data.message}`
      );
    },
  });
}

function useScheduleCalculator() {
  const url = `${process.env.REACT_APP_API_BASE_URL}/v1/rrule/schedule_calculator/`;
  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast.error(
        `Error in fetching schedules: ${error.response.data.message}`
      );
      throw new Error(error.response.data.message);
    },
  });
}

const getWorkflowSearchedTags = async searchedText => {
  const url = buildAPIURL(`/simple_tag/?search=${searchedText}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useWorkflowSearchedTags(searchedText) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/workflowSearchedTags`, searchedText],
    () => getWorkflowSearchedTags(searchedText)
  );
}

export {
  useWorkflowListAPI,
  useModifyWorkflow,
  useCreateWorkflow,
  useWorkflowDetails,
  useCSVDownloadReport,
  useTestWorkflow,
  useNewWorkflowVersionDetails,
  useCreateWorkflowNode,
  useModifyWorkflowNode,
  useDeleteWorkflowNode,
  useUpdateNodeProperties,
  useUpdateWFVersion,
  useUpdateWorkflow,
  usePublishWorkflow,
  useUpdateBranchConditions,
  useCloneWorkflow,
  useUpdateBranch,
  useAddBranch,
  useDeleteBranch,
  useMoveBranch,
  useTriggerTestWorkflow,
  useWorkflowTestData,
  useSaveWFSampleData,
  useScheduleCalculator,
  useWorkflowSearchedTags,
};

import { useEffect } from 'react';
import { useAuthState, useEnvState } from 'stores/AuthStore';
import { updateBeamerUserProps } from 'utils/Analytics';
import TopBar from './Topbar';
import SideBar from './Sidebar';

function NavBar({ component: Component, sandboxExpired, isCommonRoute }) {
  const authState = useAuthState();
  const envState = useEnvState();

  useEffect(() => {
    updateBeamerUserProps(authState);
  }, []);

  const allEnv = authState.organization.get()?.environments;
  const currentEnv = allEnv?.filter(
    obj => obj?.slug === envState.currentEnv.get()
  );

  const isSandbox = currentEnv?.[0]?.slug === 'sandbox';

  return (
    <div className="flex">
      <SideBar currentEnv={currentEnv} />
      <TopBar
        Component={Component}
        isSandbox={isSandbox}
        sandboxExpired={sandboxExpired}
        isCommonRoute={isCommonRoute}
      />
    </div>
  );
}
export default NavBar;

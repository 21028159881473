import { useQuery } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from '../stores/AuthStore';

const getUniqueEvents = async () => {
  const url = buildAPIURL(`/unique_events/`);
  const { data } = await loggedInAPI.get(url);
  return data.results;
};

function useUniqueEventsAPI() {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/unique_events/`, () =>
    getUniqueEvents()
  );
}

export { useUniqueEventsAPI };

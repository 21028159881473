import { useState, useContext, useMemo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TimePicker from 'rc-time-picker';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import ReactSelect from 'react-select';
import { useReactFlow } from 'reactflow';
import { InfoIcon, Plus, XIcon } from 'lucide-react';
import { useAuthState } from 'stores/AuthStore';
import {
  Label,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  Button,
  SelectValue,
  SelectGroup,
  ToggleGroup,
  ToggleGroupItem,
  CTooltip,
} from 'new-components';
import { toast } from 'components';
import { useUpdateNodeProperties } from 'apis';
import { cn, MISSING_FIELD_ERROR } from 'utils';
import urlUtils from 'utils/urlUtils';
import { ReactComponent as TimeWindowIcon } from 'assets/svgs/timeWindowIcon.svg';
import { NodeFormHeader } from './Common';
import { FlowContext } from '../WorkflowDetails';
import 'rc-time-picker/assets/index.css';

const twValidationSchema = Yup.object().shape({
  windows: Yup.array().of(
    Yup.object().shape({
      frequency: Yup.string(),
      weekdays: Yup.array().when('frequency', {
        is: 'weekly',
        then: Yup.array()
          .min(1, MISSING_FIELD_ERROR)
          .required(MISSING_FIELD_ERROR),
      }),
      monthdays: Yup.array().when('frequency', {
        is: 'monthly',
        then: Yup.array().of(
          Yup.object().shape({
            day: Yup.string(),
            pos: Yup.array()
              .min(1, MISSING_FIELD_ERROR)
              .required(MISSING_FIELD_ERROR)
              .test(
                'valid week of month',
                'Invalid selection, allowed values are between 1st - 4th and last',
                function (value) {
                  const { day } = this.parent;
                  if (day !== 'day') {
                    return !value?.some(num => num > 5);
                  }
                  return true;
                }
              ),
          })
        ),
      }),
      stime: Yup.string().matches(/^\d{2}:\d{2}$/, 'Invalid time format'),
      etime: Yup.string()
        .matches(/^\d{2}:\d{2}$/, 'Invalid time format')
        .test(
          'is-greater',
          'End time must be greater than start time',
          function (value) {
            const { stime } = this.parent;
            if (!stime || !value) return true;
            return value > stime;
          }
        ),
    })
  ),
});

const getOrdinalNumbers = number => {
  switch (number) {
    case 1:
    case 21:
      return number + 'st';
    case 2:
    case 22:
      return number + 'nd';
    case 3:
    case 23:
      return number + 'rd';
    default:
      return number + 'th';
  }
};

const FREQUENCY_LIST = [
  { label: 'Any day', value: 'daily' },
  { label: 'Weekdays (Monday to Friday)', value: 'weekly_mo2fr' },
  { label: 'Selected days of the week', value: 'weekly' },
  { label: 'Selected days of the month', value: 'monthly' },
];

const WEEK_DAYS_LIST = [
  { label: 'S', value: 'su' },
  { label: 'M', value: 'mo' },
  { label: 'T', value: 'tu' },
  { label: 'W', value: 'we' },
  { label: 'T', value: 'th' },
  { label: 'F', value: 'fr' },
  { label: 'S', value: 'sa' },
];

const MONTHLY_DAYS_LIST = [
  { label: 'Day', value: 'day' },
  { label: 'Sun', value: 'su' },
  { label: 'Mon', value: 'mo' },
  { label: 'Tue', value: 'tu' },
  { label: 'Wed', value: 'we' },
  { label: 'Thu', value: 'th' },
  { label: 'Fri', value: 'fr' },
  { label: 'Sat', value: 'sa' },
];

const monthlyPositionList = maxDays => {
  let daysArray = [];

  for (let i = 1; i <= maxDays; i++) {
    daysArray.push({ label: getOrdinalNumbers(i), value: i });
  }
  daysArray = [...daysArray, { label: 'Last', value: -1 }];

  return daysArray;
};

const DEFAULT_WINDOWS = {
  frequency: 'daily',
  timeFrequency: 'any_time',
  weekdays: [],
  monthdays: [{ day: 'day', pos: [] }],
  stime: '00:00',
  etime: '00:30',
};

export default function TimeWindow() {
  const authState = useAuthState();
  const getOrgDetails = authState.organization.get();

  const [showFormErrors, setShowFormErrors] = useState();
  const {
    selectedNode,
    editMode,
    slug,
    version,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);
  const nodeProperties = nodeData?.data?.properties;

  const timeZonesList = useMemo(() => {
    const timezones = moment.tz.names();
    let modifiedTimezones = timezones?.map(timezone => ({
      label: timezone,
      value: timezone,
    }));
    modifiedTimezones = [
      { label: "Recipient's Timezone", value: 'recipient' },
      ...modifiedTimezones,
    ];
    return modifiedTimezones;
  }, []);

  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );

  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  const initialContent = useMemo(() => {
    let initialValues = {};

    if (Object.keys(nodeProperties)?.length > 0) {
      initialValues = cloneDeep(nodeProperties);

      initialValues?.windows?.map((item, index) => {
        initialValues.windows[index].timeFrequency =
          item?.stime && item?.etime ? 'selected_time' : 'any_time';

        if (item?.monthdays?.length > 0) {
          const groupedMonthdays = item.monthdays.reduce((acc, monthday) => {
            let { day, pos } = monthday;
            day = day || 'day';
            if (!acc[day]) {
              acc[day] = [];
            }
            acc[day].push(pos);
            return acc;
          }, {});

          item.monthdays = Object.keys(groupedMonthdays)?.map(day => ({
            day,
            pos: groupedMonthdays[day],
          }));
        }
        initialValues.windows[index].monthdays = item?.monthdays;
      });

      const getTimeZone =
        initialValues?.tz_selection === 'recipient'
          ? { label: "Recipient's Timezone", value: 'recipient' }
          : timeZonesList?.find(
              timezone => timezone.value === initialValues?.tz_fixed
            );

      initialValues.timezone = getTimeZone;
      delete initialValues.tz_fixed;
      delete initialValues.tz_selection;
    } else {
      initialValues = {
        windows: [DEFAULT_WINDOWS],
        timezone: { label: "Recipient's Timezone", value: 'recipient' },
      };
    }
    return initialValues;
  }, []);

  return (
    <div className="overflow-scroll h-full">
      <div className="m-4 mt-6">
        <NodeFormHeader
          name="Time Window"
          helptext="Notify recipients in their timezone and preferred duration. This will introduce a wait until the time window starts."
          Icon={TimeWindowIcon}
          docLink="https://docs.suprsend.com/docs/time-window"
        />
        <div className="mt-5">
          {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
            <div className="mb-6 text-xs -mt-2 text-destructive bg-destructive-muted p-3 rounded">
              <ul>
                {fieldErrors &&
                  Object.keys(fieldErrors)?.map((item, index) => {
                    return (
                      <li key={index}>
                        - {item}: {fieldErrors[item]}
                      </li>
                    );
                  })}
                {nonFieldErrors?.map((item, index) => {
                  return <li key={index}>- {item}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
        <Formik
          initialValues={initialContent}
          enableReinitialize={true}
          validationSchema={twValidationSchema}
          onSubmit={async values => {
            const payload = cloneDeep(values);
            const isRecipientTimeZone = values?.timezone?.value === 'recipient';

            payload.tz_selection = isRecipientTimeZone ? 'recipient' : 'fixed';
            payload.tz_fixed = isRecipientTimeZone
              ? ''
              : values?.timezone?.value;

            payload.windows.forEach((window, index) => {
              if (window.timeFrequency === 'any_time') {
                delete payload.windows[index].stime;
                delete payload.windows[index].etime;
              }

              if (window.frequency === 'monthly') {
                const outputArray = [];

                window?.monthdays?.forEach(item => {
                  item?.pos?.forEach(position => {
                    outputArray.push({
                      pos: position,
                      day: item.day === 'day' ? '' : item.day,
                    });
                  });
                });
                payload.windows[index].monthdays = outputArray;
              }
              delete payload.windows[index].timeFrequency;
            });
            delete payload.timezone;

            try {
              setLoading(true);
              await updateProperties.mutateAsync({ properties: payload });
              setShowFormErrors(true);
              setLoading(false);
              toast('Settings saved successfully', '', { autoClose: 1000 });
            } catch (e) {
              setLoading(false);
            }
          }}
        >
          {({ values, setFieldValue }) => {
            const format = 'HH:mm';
            const disabled = !editMode;
            return (
              <Form className="mb-24">
                <p className="text-foreground font-medium text-sm mb-2">
                  Notify between
                </p>
                {values?.windows?.map((window, index) => {
                  const frequencyName = FREQUENCY_LIST.find(
                    list => list.value === window.frequency
                  );
                  const startDate = window?.stime?.split(':');
                  const endDate = window?.etime?.split(':');

                  const isLast = values?.windows?.length === index + 1;

                  return (
                    <div key={index}>
                      <div className="border border-dashed border-input rounded-lg w-full p-4 mb-3">
                        {editMode && values?.windows?.length > 1 && (
                          <Button
                            variant="link"
                            type="button"
                            disabled={disabled}
                            className="hover:no-underline p-0 m-0 h-0 float-right mt-2 mb-5"
                            onClick={() => {
                              const removeWindow = [...values?.windows];
                              removeWindow.splice(index, 1);
                              setFieldValue(`windows`, removeWindow);
                            }}
                          >
                            <XIcon className="h-4 w-4 cursor-pointer text-muted-foreground" />
                          </Button>
                        )}
                        <div>
                          <Select
                            value={window?.frequency}
                            disabled={disabled}
                            onValueChange={value => {
                              const initialMonthDay =
                                window?.monthdays?.length > 0
                                  ? window?.monthdays
                                  : [{ day: 'day', pos: [] }];

                              setFieldValue(
                                `windows[${index}].frequency`,
                                value
                              );
                              if (value === 'monthly') {
                                setFieldValue(
                                  `windows[${index}].monthdays`,
                                  initialMonthDay
                                );
                              } else if (value === 'weekdays') {
                                setFieldValue('weekdays', []);
                              }
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue>{frequencyName?.label}</SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {FREQUENCY_LIST?.map(list => (
                                  <SelectItem
                                    value={list.value}
                                    key={list.value}
                                  >
                                    {list.label}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        {window?.frequency === 'monthly' && (
                          <>
                            {window?.monthdays?.map((monthDay, innerIndex) => {
                              const maxDays = monthDay?.day === 'day' ? 30 : 5;
                              const monthPositions = monthlyPositionList(30);
                              const getMonthPosition = monthPositions?.filter(
                                obj => monthDay?.pos?.includes(obj?.value)
                              );
                              const getDay = MONTHLY_DAYS_LIST?.find(
                                list => list.value === monthDay?.day
                              );
                              const isLastMonthDay =
                                window?.monthdays?.length === innerIndex + 1;

                              return (
                                <div key={innerIndex}>
                                  <div className="flex items-center w-full mt-2 gap-2">
                                    <ReactSelect
                                      classNamePrefix="react-select"
                                      isMulti
                                      options={monthlyPositionList(maxDays)}
                                      className={cn(
                                        window?.monthdays?.length > 1
                                          ? 'w-[70%]'
                                          : 'w-[75%]'
                                      )}
                                      value={getMonthPosition}
                                      onChange={selectedValue => {
                                        const modifiedValue =
                                          selectedValue?.map(obj => obj.value);
                                        setFieldValue(
                                          `windows[${index}].monthdays[${innerIndex}].pos`,
                                          modifiedValue
                                        );
                                      }}
                                      isDisabled={disabled}
                                    />
                                    <Select
                                      value={monthDay?.day}
                                      disabled={disabled}
                                      onValueChange={value => {
                                        setFieldValue(
                                          `windows[${index}].monthdays[${innerIndex}].day`,
                                          value
                                        );
                                      }}
                                    >
                                      <SelectTrigger className=" w-[25%]">
                                        <SelectValue>
                                          {getDay?.label}
                                        </SelectValue>
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {MONTHLY_DAYS_LIST?.map(list => (
                                            <SelectItem
                                              value={list.value}
                                              key={list.value}
                                            >
                                              {list.label}
                                            </SelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>
                                    {window?.monthdays?.length > 1 && (
                                      <div
                                        onClick={() => {
                                          const removeMonthDay = [
                                            ...window?.monthdays,
                                          ];
                                          removeMonthDay.splice(innerIndex, 1);
                                          setFieldValue(
                                            `windows[${index}].monthdays`,
                                            removeMonthDay
                                          );
                                        }}
                                      >
                                        <XIcon className="h-4 w-4 cursor-pointer text-muted-foreground" />
                                      </div>
                                    )}
                                  </div>

                                  <ErrorMessage
                                    name={`windows[${index}].monthdays[${innerIndex}].pos`}
                                    component="div"
                                    className="text-sm mt-1 text-destructive"
                                  />

                                  {isLastMonthDay ? (
                                    <>
                                      {editMode && (
                                        <Button
                                          type="button"
                                          className="px-1.5 my-2 h-6 flex gap-1 mb-4"
                                          variant="outline"
                                          disabled={disabled}
                                          onClick={() => {
                                            const addMonthPositon = [
                                              ...window?.monthdays,
                                              { day: 'day', pos: [] },
                                            ];
                                            setFieldValue(
                                              `windows[${index}].monthdays`,
                                              addMonthPositon
                                            );
                                          }}
                                        >
                                          <Plus className="h-4 w-4 text-accent-foreground" />{' '}
                                          <p className="text-xs text-accent-foreground">
                                            OR
                                          </p>
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <p className="text-sm my-2 text-muted-foreground font-medium">
                                      OR
                                    </p>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}
                        {window?.frequency === 'weekly' && (
                          <div className="flex flex-col mb-5 mt-2.5 float-left">
                            <ToggleGroup
                              variant="outline"
                              type="multiple"
                              onValueChange={value => {
                                setFieldValue(
                                  `windows[${index}].weekdays`,
                                  value
                                );
                              }}
                              className="gap-3"
                              value={window?.weekdays}
                              disabled={disabled}
                            >
                              {WEEK_DAYS_LIST?.map(week => (
                                <ToggleGroupItem
                                  key={week.value}
                                  value={week.value}
                                  className="data-[state=on]:border-primary data-[state=on]:bg-primary-muted"
                                >
                                  {week.label}
                                </ToggleGroupItem>
                              ))}
                            </ToggleGroup>
                            <ErrorMessage
                              name={`windows[${index}].weekdays`}
                              component="div"
                              className="text-sm mt-1 text-destructive"
                            />
                          </div>
                        )}
                        <div className="mt-5">
                          <Select
                            value={window?.timeFrequency}
                            onValueChange={value => {
                              const startTime =
                                nodeProperties?.windows?.[index]?.stime ||
                                '00:00';
                              const endTime =
                                nodeProperties?.windows?.[index]?.etime ||
                                '00:30';

                              setFieldValue(
                                `windows[${index}].timeFrequency`,
                                value
                              );
                              if (value === 'selected_time') {
                                setFieldValue(
                                  `windows[${index}].stime`,
                                  startTime
                                );
                                setFieldValue(
                                  `windows[${index}].etime`,
                                  endTime
                                );
                              }
                            }}
                            disabled={disabled}
                          >
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="any_time">
                                  Any time
                                </SelectItem>
                                <SelectItem value="selected_time">
                                  Selected time
                                </SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>

                          {window.timeFrequency === 'selected_time' && (
                            <>
                              <div className="flex items-center mt-2.5">
                                <TimePicker
                                  value={moment()
                                    .hour(startDate?.[0])
                                    .minute(startDate?.[1])}
                                  inputReadOnly
                                  clearIcon={<span />}
                                  disabled={disabled}
                                  placeholder="hh:mm"
                                  showSecond={false}
                                  onChange={value => {
                                    const formattedValue = value
                                      ? value.format(format)
                                      : '';
                                    setFieldValue(
                                      `windows[${index}].stime`,
                                      formattedValue
                                    );
                                  }}
                                  format={format}
                                />
                                <p className="text-sm text-accent-foreground mx-2">
                                  to
                                </p>
                                <TimePicker
                                  value={moment()
                                    .hour(endDate?.[0])
                                    .minute(endDate?.[1])}
                                  inputReadOnly
                                  clearIcon={<span />}
                                  disabled={disabled}
                                  placeholder="hh:mm"
                                  showSecond={false}
                                  onChange={value => {
                                    const formattedValue = value
                                      ? value.format(format)
                                      : '';
                                    setFieldValue(
                                      `windows[${index}].etime`,
                                      formattedValue
                                    );
                                  }}
                                  format={format}
                                />
                              </div>
                              <ErrorMessage
                                name={`windows[${index}].etime`}
                                component="div"
                                className="text-sm mt-1 text-destructive"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      {isLast ? (
                        <>
                          {editMode && (
                            <Button
                              type="button"
                              className="px-1.5 my-2 h-6 flex gap-1 mb-4"
                              variant="outline"
                              disabled={disabled}
                              onClick={() => {
                                const addWindow = [
                                  ...values?.windows,
                                  DEFAULT_WINDOWS,
                                ];
                                setFieldValue('windows', addWindow);
                              }}
                            >
                              <Plus className="h-4 w-4 text-accent-foreground" />{' '}
                              <p className="text-xs text-accent-foreground">
                                OR
                              </p>
                            </Button>
                          )}
                        </>
                      ) : (
                        <p className="text-sm my-2 text-muted-foreground font-medium">
                          OR
                        </p>
                      )}
                    </div>
                  );
                })}

                <div className="mt-6 mb-52">
                  <Label className="flex items-center mb-1.5">
                    Timezone
                    <CTooltip
                      trigger={
                        <InfoIcon className="h-4 w-4 ml-1.5 text-accent-foreground" />
                      }
                    >
                      <p>
                        Recipient's timezone is picked from user profile. You
                        can set it using user.set_timezone(…) method.
                      </p>
                    </CTooltip>
                  </Label>
                  <ReactSelect
                    classNamePrefix="react-select"
                    value={values?.timezone}
                    options={timeZonesList}
                    onChange={value => {
                      setFieldValue('timezone', value);
                    }}
                    styles={{
                      menuList: styles => {
                        return { ...styles, maxHeight: 160 };
                      },
                    }}
                    isDisabled={disabled}
                  />
                  <p className="mt-1.5 text-muted-foreground text-xs">
                    Fallback to
                    <span className="text-primary mx-1">
                      <a
                        href={urlUtils.makeCommonURL(
                          '/account-settings/general'
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        account timezone
                      </a>
                    </span>
                    if recipient's timezone is not set. Your account timezone is
                    set to
                    <span className=" mx-1 text-accent-foreground font-semibold">
                      {getOrgDetails?.timezone ||
                        ' UTC (Universal Coordinated Time)'}
                    </span>
                  </p>
                </div>
                {editMode && (
                  <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-background drop-shadow border-t z-50">
                    <Button
                      className="flex-grow justify-center"
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setSelectedForm('nodes_list');
                        setSelectedNode({});
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      className="flex-grow justify-center ml-2"
                      disabled={updateProperties.isLoading}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

import { NavLink, useRouteMatch } from 'react-router-dom';
import { classNames } from 'utils';
import urlUtils from 'utils/urlUtils';

const LogsTab = () => {
  let { url } = useRouteMatch();
  const splitURL = url.split('/');

  const logOptions = [
    {
      name: 'Requests',
      value: 'api',
      url: `${urlUtils.makeURL(`/logs/api/?last_n_minutes=1440`)}`,
    },
    {
      name: 'Workflow Executions',
      value: 'executions',
      url: `${urlUtils.makeURL(`/logs/executions/?last_n_minutes=1440`)}`,
    },
    {
      name: 'Broadcast Executions',
      value: 'broadcast',
      url: `${urlUtils.makeURL(`/logs/broadcast/`)}`,
    },
    {
      name: 'Messages',
      value: 'workflow',
      url: `${urlUtils.makeURL(`/logs/workflow/`)}`,
    },
  ];

  return (
    <div className="flex items-center">
      {logOptions.map(log => {
        return (
          <div className="flex" key={log.name}>
            <NavLink
              to={log.url}
              key={log.name}
              className={classNames(
                'px-8 py-2 text-sm font-medium',
                splitURL?.[4] === log.value
                  ? 'text-primary border-b-2 border-primary'
                  : 'text-muted-foreground'
              )}
            >
              <p>{log.name}</p>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};
export default LogsTab;

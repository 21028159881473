import mixpanel from 'mixpanel-browser';

export const MixPanelTrack = (eventName, eventProps) => {
  mixpanel.track(eventName, eventProps);
};

export const MixPanelIdentify = userEmail => {
  mixpanel.identify(userEmail);
};

export const MixPanelUserProfile = userProfile => {
  mixpanel.people.set(userProfile);
};

export const MixPanelIncrement = val => {
  mixpanel.people.increment(val);
};

export const MixPanelSetOnce = val => {
  mixpanel.people.set_once(val);
};

export const MixPanelResetUser = () => {
  mixpanel.reset();
};

import { useState } from 'react';
import { CalendarIcon } from '@heroicons/react/outline';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import OutsideClickHandler from '../../components/OuterClickDetect';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DATE_RANGE = [
  { label: 'Custom', value: [], icon: CalendarIcon },
  { label: 'Today', value: 0, display_name: 'Today' },
  { label: 'Yesterday', value: 1, display_name: 'Yesterday' },
  { label: '7D', value: 6, display_name: 'Last 7 days' },
  { label: '14D', value: 13, display_name: 'Last 14 days' },
  { label: '30D', value: 29, display_name: 'Last 30 days' },
  { label: '3M', value: 90, display_name: 'Last 3 months' },
];

export const DAFAULT_DATE_RANGE = DATE_RANGE[5];

const MAX_DATE = new Date();
const MIN_DATE = new Date('2021-01-01');

function DateFilter({ selectedDate, setDate, type = 'bottom', page }) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [calendar, setCalendar] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDateSelect = selectedItem => {
    if (selectedItem.label === 'Custom') {
      setShowDatePicker(prev => !prev);
    } else {
      setDate({ ...selectedItem, value: selectedItem.value });
    }
  };

  const handleCustomDateChange = selectedItem => {
    setCalendar([selectedItem.selection]);
  };

  return (
    <div
      className={classNames(
        'flex',
        'items-center',
        type === 'bottom' && 'flex-col'
      )}
    >
      <div className="flex self-start border border-gray-300 rounded-md cursor-pointer">
        {DATE_RANGE.map((item, index) => {
          const Icon = item?.icon;
          let selected =
            !!(Array.isArray(selectedDate) && item.label === 'Custom') ||
            selectedDate === item.value;
          return (
            <div className="relative" key={index}>
              <div
                className={classNames(
                  'px-4',
                  'py-1',
                  'border-gray-300',
                  'flex',
                  'items-center',
                  'justify-center text-sm',
                  selected ? 'bg-gray-200' : 'bg-white',
                  index === 0 && 'rounded-l',
                  index + 1 === DATE_RANGE.length && 'rounded-r',
                  index + 1 !== DATE_RANGE.length && 'border-r'
                )}
                onClick={() => {
                  handleDateSelect(item);
                }}
              >
                {Icon && (
                  <Icon height={18} width={18} className="text-gray-400 mr-2" />
                )}
                <p
                  className={classNames(
                    selected ? 'font-semibold' : 'font-normal',
                    selected ? 'text-black' : 'text-gray-500'
                  )}
                >
                  {item.label}
                </p>
              </div>
              {item.label === 'Custom' && showDatePicker && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowDatePicker(false);
                  }}
                >
                  <div
                    className={classNames(
                      'absolute z-50 border top-12 bg-white shadow',
                      page && page === 'dashboard' && '-ml-8'
                    )}
                  >
                    <DateRangePicker
                      ranges={calendar}
                      onChange={handleCustomDateChange}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      direction="vertical"
                      maxDate={MAX_DATE}
                      minDate={MIN_DATE}
                    />
                    <div
                      className="float-right m-2 mr-10 mt-0"
                      onClick={() => {
                        setDate({
                          ...item,
                          value: [
                            format(calendar[0].startDate, 'yyyy-MM-dd'),
                            format(calendar[0].endDate, 'yyyy-MM-dd'),
                          ],
                        });
                        setShowDatePicker(false);
                      }}
                    >
                      <p className="px-10 py-3 bg-blue-500 text-white rounded">
                        OK
                      </p>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          );
        })}
      </div>
      <div className="self-end ml-3 mt-2">
        <p className="text-xs text-gray-500">
          (All Charts are in UTC time zone)
        </p>
      </div>
    </div>
  );
}

export default DateFilter;

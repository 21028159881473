import { useQuery } from 'react-query';
import { useDebounce } from 'ahooks';
import { loggedInAPI, buildAPIURL, useEnvState } from 'stores/AuthStore';

const getWorkflowExecutionLogs = async queryString => {
  let url = buildAPIURL(`/workflow_run/?${queryString}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useWorkflowExecutionLogs({
  queryString,
  mandatoryFilters,
  automaticRefetchTime,
  enabled = true,
}) {
  const envState = useEnvState();
  const queryMap = new URLSearchParams(queryString);
  const queryEntries = queryMap.entries();
  const pageNo = Number(queryMap.get('page')) || 0;
  const newQs = new URLSearchParams();
  const multipleSupport = [
    'tenant',
    'distinct_id',
    'status',
    'workflow_slug',
    'execution_id',
    'idempotency_key',
  ];

  if (mandatoryFilters) {
    for (let key in mandatoryFilters) {
      const value = mandatoryFilters[key];
      if (multipleSupport.includes(key)) {
        if (Array.isArray(value)) {
          value.forEach(item => {
            newQs.append(`${key}[]`, item);
          });
        } else {
          newQs.append(`${key}[]`, value);
        }
      } else {
        newQs.append(`${key}`, value);
      }
    }
  }

  for (let item of queryEntries) {
    if (multipleSupport.includes(item[0])) {
      newQs.append(`${item[0]}[]`, item[1]);
    } else {
      newQs.append(`${item[0]}`, item[1]);
    }
  }

  newQs.append('limit', 20);
  newQs.append('offset', pageNo * 20 || 0);

  const apiQueryString = newQs.toString();
  return useQuery(
    [
      `${envState.currentEnv.get()}/workflow_run`,
      apiQueryString,
      automaticRefetchTime,
    ],
    () => getWorkflowExecutionLogs(apiQueryString),
    {
      keepPreviousData: true,
      refetchInterval: automaticRefetchTime,
      enabled: enabled,
    }
  );
}

const getWorkflowExecutionLogDetails = async ({ execution_id }) => {
  let url = buildAPIURL(`/workflow_run/${execution_id}/`);

  const { data } = await loggedInAPI.get(url);
  return data;
};

function useWorkflowExecutionLogsDetail({
  execution_id,
  automaticRefetchTime,
  enabled = true,
}) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/workflow_run_details/`,
      execution_id,
      automaticRefetchTime,
    ],
    () => getWorkflowExecutionLogDetails({ execution_id }),
    {
      keepPreviousData: true,
      refetchInterval: automaticRefetchTime,
      enabled: enabled,
    }
  );
}

const getNotificationGroupDetails = async ({ notigrp_id }) => {
  let url = buildAPIURL(`/ng_log/${notigrp_id}/`);

  const { data } = await loggedInAPI.get(url);
  return data;
};

function useNotificationGroupDetail({
  notigrp_id,
  automaticRefetchTime,
  enabled = true,
}) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/ng_log/`, notigrp_id, automaticRefetchTime],
    () => getNotificationGroupDetails({ notigrp_id }),
    {
      keepPreviousData: true,
      refetchInterval: automaticRefetchTime,
      enabled: enabled,
    }
  );
}

const getExecutionFilterCount = async apiQueryString => {
  let url = buildAPIURL(`/workflow_run/stats/?${apiQueryString}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useExecutionFilterCount({
  queryString,
  mandatoryFilters,
  enabled = true,
}) {
  const envState = useEnvState();
  const queryMap = new URLSearchParams(queryString);
  const queryEntries = queryMap.entries();
  const pageNo = Number(queryMap.get('page')) || 0;
  const newQs = new URLSearchParams();
  const multipleSupport = [
    'tenant',
    'distinct_id',
    'status',
    'workflow_slug',
    'execution_id',
    'idempotency_key',
  ];

  if (mandatoryFilters) {
    for (let key in mandatoryFilters) {
      const value = mandatoryFilters[key];
      if (multipleSupport.includes(key)) {
        if (Array.isArray(value)) {
          value.forEach(item => {
            newQs.append(`${key}[]`, item);
          });
        } else {
          newQs.append(`${key}[]`, value);
        }
      } else {
        newQs.append(`${key}`, value);
      }
    }
  }

  for (let item of queryEntries) {
    if (multipleSupport.includes(item[0])) {
      newQs.append(`${item[0]}[]`, item[1]);
    } else {
      newQs.append(`${item[0]}`, item[1]);
    }
  }

  newQs.append('limit', 20);
  newQs.append('offset', pageNo * 20 || 0);

  const apiQueryString = useDebounce(newQs.toString(), 500);
  return useQuery(
    [
      `${envState.currentEnv.get()}/workflow_run/execution/count`,
      apiQueryString,
    ],
    () => getExecutionFilterCount(apiQueryString),
    { enabled: enabled }
  );
}

export {
  useWorkflowExecutionLogs,
  useWorkflowExecutionLogsDetail,
  useNotificationGroupDetail,
  useExecutionFilterCount,
};

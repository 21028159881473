import { useState, useEffect, useContext } from 'react';
import { CalendarRange } from 'lucide-react';
import { addDays, format, subMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { cn } from 'utils';
import {
  Button,
  Label,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  RadioGroup,
  RadioGroupItem,
} from 'new-components';
import moment from 'moment';
import { TimezonePreferenceContext } from 'custom-components';

const defaultRelativeValue = '1_days';
const absoluteDateFormat = 'yyyy-MM-dd, HH:mm:ss';
const displayAbsoluteFormat = 'dd/MM HH:mm';

const relativeOptions = {
  '5_mins': { label: 'Last 5 minutes', value: 5 },
  '15_mins': { label: 'Last 15 minutes', value: 15 },
  '30_mins': { label: 'Last 30 minutes', value: 30 },
  '1_hour': { label: 'Last 1 hour', value: 60 },
  '2_hours': { label: 'Last 2 hours', value: 2 * 60 },
  '1_days': { label: 'Last 1 day', value: 24 * 60 },
  '2_days': { label: 'Last 2 days', value: 2 * 24 * 60 },
  '3_days': { label: 'Last 3 days', value: 3 * 24 * 60 },
};

function formatDateRange({ from, to }) {
  if (from && to) {
    return `${format(from, displayAbsoluteFormat)} - ${format(
      to,
      displayAbsoluteFormat
    )}`;
  }
  return '';
}

export function DateTimeRangePicker({ rangeValue, onChangeData }) {
  const { timezone } = useContext(TimezonePreferenceContext);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState(
    relativeOptions[defaultRelativeValue].label
  );
  const [selectedTab, setSelectedTab] = useState('relative');
  const [relativeRange, setRelativeRange] = useState(defaultRelativeValue);
  const [absoluteRange, setAbsoluteRange] = useState({
    from: new Date(),
    to: addDays(new Date(), -7),
  });

  useEffect(() => {
    if (!popoverOpen) {
      setInitialDate();
    }
  }, [rangeValue, timezone]);

  const setInitialDate = () => {
    if (typeof rangeValue === 'number') {
      let selectedDateKey = '';
      Object.keys(relativeOptions).forEach(key => {
        if (relativeOptions[key].value === rangeValue) {
          selectedDateKey = key;
        }
      });
      setSelectedTab('relative');
      if (selectedDateKey) {
        // if provided option present in existing values then set it
        setRelativeRange(selectedDateKey);
        setDisplayValue(relativeOptions[selectedDateKey].label);
      } else {
        // if not present then set our default value
        setRelativeRange(defaultRelativeValue);
        setDisplayValue(relativeOptions[defaultRelativeValue].label);
      }
    } else if (typeof rangeValue === 'object') {
      setSelectedTab('absolute');
      const finalRange = {
        from: new Date(
          formatInTimeZone(
            rangeValue.from * 1000,
            timezone?.value,
            absoluteDateFormat
          )
        ),
        to: new Date(
          formatInTimeZone(
            rangeValue.to * 1000,
            timezone?.value,
            absoluteDateFormat
          )
        ),
      };
      setAbsoluteRange(finalRange);
      setDisplayValue(formatDateRange(finalRange));
    }
  };

  const setDefaultRelativeDate = () => {
    const selectedDateData = relativeOptions[defaultRelativeValue];

    setSelectedTab('relative');
    setRelativeRange(defaultRelativeValue);
    setDisplayValue(selectedDateData.label);
    onChangeData({
      type: 'relative',
      values: selectedDateData.value,
    });
  };

  const handlePopoverStateChange = value => {
    setPopoverOpen(value);
    if (value) {
      // if popover is opened
      setInitialDate();
    } else {
      // if popover is closed
      if (
        (selectedTab === 'relative' && !relativeRange) ||
        (selectedTab === 'absolute' &&
          (!absoluteRange?.from || !absoluteRange?.to))
      ) {
        setDefaultRelativeDate();
      }
    }
  };

  const handleTabChange = value => {
    if (value === 'absolute') {
      const fromDate = subMinutes(
        new Date(
          formatInTimeZone(Date.now(), timezone?.value, absoluteDateFormat)
        ),
        relativeOptions[relativeRange].value
      );
      const toDate = formatInTimeZone(
        Date.now(),
        timezone?.value,
        absoluteDateFormat
      );
      setAbsoluteRange({
        from: new Date(fromDate),
        to: new Date(toDate),
      });
    }
    setSelectedTab(value);
  };

  const handleSetAbsoluteDate = () => {
    const initialFromDate = moment(Date.now()).tz(timezone?.value);
    const initialToDate = moment(Date.now()).tz(timezone?.value);
    const selectedFrom = absoluteRange.from;
    const selectedTo = absoluteRange.to;

    const finalFromDate = initialFromDate.set({
      year: selectedFrom.getFullYear(),
      month: selectedFrom.getMonth(),
      date: selectedFrom.getDate(),
      hour: selectedFrom.getHours(),
      minute: selectedFrom.getMinutes(),
      second: selectedFrom.getSeconds(),
    });
    const finalToDate = initialToDate.set({
      year: selectedTo.getFullYear(),
      month: selectedTo.getMonth(),
      date: selectedTo.getDate(),
      hour: selectedTo.getHours(),
      minute: selectedTo.getMinutes(),
      second: selectedTo.getSeconds(),
    });

    const finalRange = {
      from: Date.parse(finalFromDate),
      to: Date.parse(finalToDate),
    };

    setDisplayValue(formatDateRange(finalRange));

    onChangeData({
      type: 'absolute',
      values: {
        from: Math.floor(finalRange.from / 1000),
        to: Math.floor(finalRange.to / 1000),
      },
    });
  };

  return (
    <Popover onOpenChange={handlePopoverStateChange}>
      <PopoverTrigger asChild>
        <Button
          id="date"
          variant={'outline'}
          className={cn(
            'w-[300px] justify-start text-left font-normal',
            !absoluteRange && 'text-muted-foreground'
          )}
        >
          <CalendarRange className="mr-2 h-4 w-4" />
          <span>{displayValue || 'Pick a date range'}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[350px]">
        <Tabs defaultValue={selectedTab} onValueChange={handleTabChange}>
          <TabsList className="grid grid-cols-2 mx-2">
            <TabsTrigger value="relative">Relative Range</TabsTrigger>
            <TabsTrigger value="absolute">Absolute Range</TabsTrigger>
          </TabsList>

          {/* TAB 1 content */}
          <TabsContent value="relative" className="flex flex-col mx-2">
            <RadioGroup
              defaultValue={relativeRange}
              onValueChange={setRelativeRange}
              className="py-4"
            >
              {Object.entries(relativeOptions).map(([key, option]) => {
                return (
                  <div className="flex items-center space-x-2 py-1" key={key}>
                    <RadioGroupItem value={key} id={key} />
                    <Label htmlFor={key}>{option.label}</Label>
                  </div>
                );
              })}
            </RadioGroup>
            <div className="flex self-end gap-4">
              <PopoverClose>
                <Button variant="outline" onClick={setDefaultRelativeDate}>
                  Clear Filter
                </Button>
              </PopoverClose>
              <PopoverClose>
                <Button
                  onClick={() => {
                    const selectedDateData = relativeOptions[relativeRange];

                    setDisplayValue(selectedDateData.label);
                    onChangeData({
                      type: 'relative',
                      values: selectedDateData.value,
                    });
                  }}
                >
                  Apply Filter
                </Button>
              </PopoverClose>
            </div>
          </TabsContent>

          {/* TAB 2 content */}
          <TabsContent
            value="absolute"
            className="flex flex-col justify-center items-center"
          >
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={absoluteRange?.from}
              selected={absoluteRange}
              onSelect={setAbsoluteRange}
              toDate={new Date()}
            />

            <div className="flex flex-row gap-3 my-3 mx-3">
              <div className="flex flex-col gap-1">
                <label className="text-xs font-medium text-foreground">
                  Start Time
                </label>
                <TimePicker
                  use12Hours
                  inputReadOnly
                  clearIcon={<span />}
                  value={absoluteRange?.from ? moment(absoluteRange.from) : ''}
                  placeholder="hh:mm:ss"
                  disabled={!absoluteRange?.from}
                  onChange={value => {
                    setAbsoluteRange(prev => {
                      const newFrom = prev.from.setHours(
                        value.hours(),
                        value.minutes(),
                        value.seconds()
                      );
                      return {
                        from: new Date(newFrom),
                        to: prev?.to,
                      };
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-xs font-medium text-foreground">
                  End Time
                </label>
                <TimePicker
                  use12Hours
                  inputReadOnly
                  clearIcon={<span />}
                  value={absoluteRange?.to ? moment(absoluteRange.to) : ''}
                  placeholder="hh:mm:ss"
                  disabled={!absoluteRange?.to}
                  onChange={value => {
                    setAbsoluteRange(prev => {
                      const newTo = prev.to.setHours(
                        value.hours(),
                        value.minutes(),
                        value.seconds()
                      );
                      return {
                        from: prev.from,
                        to: new Date(newTo),
                      };
                    });
                  }}
                />
              </div>
            </div>

            <p className="text-sm text-muted-foreground self-start px-3">
              Selecting in {timezone?.value} timezone
            </p>

            <div className="flex gap-4 my-2 mt-5 self-end">
              <PopoverClose>
                <Button variant="outline" onClick={setDefaultRelativeDate}>
                  Clear Filter
                </Button>
              </PopoverClose>
              <PopoverClose
                disabled={!absoluteRange?.from || !absoluteRange.to}
              >
                <Button
                  disabled={!absoluteRange?.from || !absoluteRange.to}
                  onClick={handleSetAbsoluteDate}
                >
                  Apply Filter
                </Button>
              </PopoverClose>
            </div>
          </TabsContent>
        </Tabs>
      </PopoverContent>
    </Popover>
  );
}

import { classNames } from 'utils';

function JSONDisplay({
  jsonObj,
  format = true,
  wrap = false,
  clip = false,
  ...props
}) {
  let jsonObject = null;
  let jsonStr = null;
  let toBeProcessed = true;

  switch (typeof jsonObj) {
    case 'string':
      try {
        jsonObject = JSON.parse(jsonObj);
      } catch (error) {
        jsonStr = decodeURI(jsonObj);
        toBeProcessed = false;
      }
      break;
    case 'object':
      jsonObject = jsonObj;
      break;
    default:
      jsonObject = null;
      break;
  }

  if (toBeProcessed) {
    if (format) {
      jsonStr = JSON.stringify(jsonObject, null, 2);
    } else {
      jsonStr = JSON.stringify(jsonObject);
    }
  }

  const syntaxHighlightJSON = function (json) {
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = 'json-number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'json-key';
          } else {
            cls = 'json-string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'json-boolean';
        } else if (/null/.test(match)) {
          cls = 'json-null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      }
    );
  };

  const syntaxHighlightJSONNET = function (str) {
    str = syntaxHighlightJSON(str);
    str = str.replace('{', '{\n');
    str = str.replace('}', '\n}');
    return str;
  };

  return (
    <div className="p-1 dark:bg-[#1D2635] bg-[#F8FAFC]">
      <div
        className={classNames(
          'font-mono text-[#64738B] dark:text[#8BE1E9]',
          wrap ? 'text-wrap' : ''
        )}
        dangerouslySetInnerHTML={{
          __html: toBeProcessed
            ? syntaxHighlightJSON(jsonStr)
            : syntaxHighlightJSONNET(jsonStr),
        }}
      />
    </div>
  );
}

export default JSONDisplay;

import { useEffect, useMemo, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import TextField from './TextField';
import { compileHandlebar } from 'utils';

const isObject = value => Object.getPrototypeOf(value) === Object.prototype;

const isArray = value => Array.isArray(value);

const flattenObj = obj => {
  if (!obj) {
    return [];
  }
  let result = [];
  for (const key in obj) {
    if (obj[key] && isObject(obj[key])) {
      const temp = flattenObj(obj[key]);
      for (const j of temp) {
        result.push(key + ' > ' + j);
      }
    } else if (obj[key] && !isArray(obj[key])) {
      result.push(key);
    }
  }
  return result;
};

function flatten(data) {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + ' > [' + i + ']');
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + ' > ' + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
}

function setCaretPosition(el, caretPos) {
  var elem = el;

  if (elem != null) {
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else {
      if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(caretPos, caretPos);
      } else elem.focus();
    }
  }
}

const convertToHandlebarVariable = current => {
  const arr = current.split(' > ');
  const response = arr.reduce((prev, item) => {
    if (item.includes(' ')) {
      return prev ? `${prev}.[${item}]` : `[${item}]`;
    } else {
      return prev ? `${prev}.${item}` : item;
    }
  }, '');
  return response;
};

const makeBold = (input, keyword) =>
  input.replaceAll(keyword, '<b>' + keyword + '</b>');

const SuggestionList = ({ list, onSelectSuggestion, typedText }) => {
  return list.map((item, index) => {
    const selectedValue = convertToHandlebarVariable(item);
    return (
      <p
        key={index}
        className="py-1 px-4 border-b items-center hover:bg-indigo-100 text-sm"
        onClick={() => {
          onSelectSuggestion(selectedValue);
        }}
        onMouseDown={event => event.preventDefault()}
        dangerouslySetInnerHTML={{ __html: makeBold(item, typedText) }}
      />
    );
  });
};

function SuggestionsTextField({
  variables,
  value,
  hideWarning = false,
  ...otherProps
}) {
  const inputRef = useRef();
  const { setFieldValue } = useFormikContext();

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [focus, setFocus] = useState(false);
  const [typedValue, setTypedValue] = useState('');
  const [cursorPosition, setCursorPosition] = useState();
  const [error, setError] = useState('');

  useEffect(() => {
    if (value) {
      let beforeStr = value?.slice(0, cursorPosition);
      const startVar = beforeStr.includes('{');
      const allVar = beforeStr.split('{');
      const lastVarEnd = allVar[allVar.length - 1];
      if (focus && startVar && allVar.length) {
        setShowSuggestions(true);
        setTypedValue(lastVarEnd);
      } else {
        setShowSuggestions(false);
      }
    } else {
      setShowSuggestions(false);
    }
  }, [cursorPosition]);

  useEffect(() => {
    setError('');
  }, [value]);

  const handleSelectSuggestion = selectedValue => {
    let finalValue = '';
    let position = 0;
    let afterStr = value?.slice(cursorPosition);
    // changing in between handlebar variable
    if (
      afterStr.indexOf('}') < afterStr.indexOf('{') ||
      (afterStr.includes('}') && !afterStr.includes('{'))
    ) {
      let beforeStr = value?.slice(0, cursorPosition);
      const allVar = beforeStr.split('{');
      allVar[allVar.length - 1] = selectedValue + '}}';
      const afterValueIndex = afterStr.indexOf('}');
      const afterValue = afterStr.slice(afterValueIndex + 2);
      const beforeValue = allVar.join('{');
      position = beforeValue.length;
      finalValue = beforeValue + afterValue;
    } else {
      // adding handlebar variabla at any position without }} string
      let beforeStr = value?.slice(0, cursorPosition);
      const allVar = beforeStr.split('{');
      const bracketPosition = cursorPosition - typedValue.length;
      if (beforeStr.substr(bracketPosition - 2, 2) !== '{{') {
        allVar[allVar.length - 1] = '{' + selectedValue + '}}';
      } else if (beforeStr.substr(bracketPosition - 3, 3) === '{{{') {
        allVar[allVar.length - 1] = selectedValue + '}}}';
      } else {
        allVar[allVar.length - 1] = selectedValue + '}}';
      }
      const beforeValue = allVar.join('{');
      position = beforeValue.length;
      finalValue = beforeValue + afterStr;
    }
    if (otherProps.onChange) {
      otherProps.onChange({ target: { value: finalValue } });
    } else {
      setFieldValue(otherProps.name, finalValue);
      if (otherProps.formChanged) {
        otherProps.formChanged();
      }
    }
    setTimeout(() => {
      setCaretPosition(inputRef.current, position);
    }, 0);
    setShowSuggestions(false);
  };

  const handleBlur = () => {
    setShowSuggestions(false);
    setFocus(false);
    let compilerOptions = { strict: true };
    if (
      otherProps.channelType === 'sms' ||
      otherProps.channelType === 'whatsapp'
    ) {
      compilerOptions = {
        ...compilerOptions,
        knownHelpersOnly: true,
        knownHelpers: {
          helperMissing: false,
          blockHelperMissing: false,
          each: false,
          if: false,
          unless: false,
          with: false,
          log: false,
          lookup: false,
        },
      };
    }
    setError(compileHandlebar({ value, compilerOptions, variables }));
  };

  // flatten nested objects
  const suggestions = useMemo(() => {
    const flattenedData = flatten(variables);
    return Object.keys(flattenedData);
  }, [variables]);

  // filter and sort suggestions
  const formatSuggestions = useMemo(() => {
    let result = suggestions;
    if (typedValue) {
      let formattedTypedValue = '';
      for (let word of typedValue) {
        if (word === '.') {
          formattedTypedValue += ' > ';
        } else {
          formattedTypedValue += word;
        }
      }
      result = suggestions.filter(item => {
        let itemFormatted = item.replaceAll(' > ', '>');
        return (
          item.includes(formattedTypedValue) ||
          itemFormatted.includes(formattedTypedValue)
        );
      });
    }

    const sortedResults = result?.sort(Intl.Collator().compare);

    let sgtnWithDollar = [];
    let sgtnWithoutDollar = [];

    sortedResults?.forEach(suggestion => {
      if (suggestion?.startsWith('$')) {
        sgtnWithDollar.push(suggestion);
      } else {
        sgtnWithoutDollar.push(suggestion);
      }
    });

    return [...sgtnWithoutDollar, ...sgtnWithDollar];
  }, [typedValue, suggestions]);

  const { handleBlur: formikBlur } = useFormikContext();

  return (
    <div className="relative w-full">
      <TextField
        value={value}
        autoComplete="off"
        onBlur={e => {
          formikBlur?.(e);
          handleBlur?.();
        }}
        ref={inputRef}
        onKeyDown={e => {
          setTimeout(() => {
            setCursorPosition(e.target.selectionStart);
          }, 0);
        }}
        error={error}
        onFocus={() => {
          setFocus(true);
          setError('');
        }}
        onMouseDown={e => {
          setTimeout(() => {
            setCursorPosition(e.target.selectionStart);
          }, 0);
        }}
        {...otherProps}
      />
      {error && !otherProps?.warning && !hideWarning && (
        <p className="mt-1 text-red-600 text-sm">{error}</p>
      )}
      {showSuggestions && (
        <div className="absolute bg-white border rounded z-50 shadow cursor-pointer max-h-52 overflow-scroll top-[100%]">
          <SuggestionList
            list={formatSuggestions}
            onSelectSuggestion={handleSelectSuggestion}
            typedText={typedValue}
          />
        </div>
      )}
    </div>
  );
}

export default SuggestionsTextField;

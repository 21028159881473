import React, { useState } from 'react';
import { classNames } from 'utils';

const Tooltip = props => {
  const { showTooltip = true, closeOnClick = false } = props;
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  if (showTooltip && props.content) {
    return (
      <div
        className={classNames('Tooltip-Wrapper', props?.inputClass)}
        // When to show the tooltip
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        onClick={closeOnClick ? hideTip : undefined}
      >
        {/* Wrapping */}
        {props.children}
        {active && (
          <div
            className={`Tooltip-Tip ${props.direction || 'top'} normal-case`}
          >
            {/* Content */}
            {props.content}
          </div>
        )}
      </div>
    );
  } else {
    return props.children;
  }
};

export default Tooltip;

import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  subscriptionLoggedInAPI,
  buildSubscriptionAPIURL,
  useEnvState,
} from '../stores/AuthStore';
import { useToastManager } from '../components/ToastManager';

const getNotificationCategories = async status => {
  const url = buildSubscriptionAPIURL(`/nc_client/status/${status}`);
  const { data } = await subscriptionLoggedInAPI.get(url);
  return data;
};

function useNotifCategoriesAPI(status) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/notification_categories`, status],
    () => getNotificationCategories(status)
  );
}

function useCreateSection(setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(`/nc_client/status/draft/section/`);
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.post(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error adding section`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useEditSection(sectionId, setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(
    `/nc_client/status/draft/section/${sectionId}`
  );
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.put(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error Editing section`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useCreateSubCategory(setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(`/nc_client/status/draft/category/`);
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.post(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error adding sub-category`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useEditSubCategory(categoryId, setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(
    `/nc_client/status/draft/category/${categoryId}`
  );
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.put(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error editing sub-category`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

const getSections = async category => {
  const url = buildSubscriptionAPIURL(
    `/nc_client/status/draft/section/?limit=50&root_category=${category}`
  );
  const { data } = await subscriptionLoggedInAPI.get(url);
  return data;
};

function useGetSections(category) {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/draft/section`, () =>
    getSections(category)
  );
}

function useDeleteSection(sectionId, setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(
    `/nc_client/status/draft/section/${sectionId}`
  );
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.delete(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error deleting section`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useDeleteSubCategory(categoryId, setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(
    `/nc_client/status/draft/category/${categoryId}`
  );
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.delete(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error deleting sub-category`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

function useCloneCategory(version, setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(`/nc_client/status/${version}/clone/`);
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.post(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error cloning category`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

function usePublishDraft(setIsLoading) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(`/nc_client/status/draft/publish/`);
  const { addToast } = useToastManager();

  return useMutation(section => subscriptionLoggedInAPI.patch(url, section), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_categories`
      );
    },
    onError: error => {
      setIsLoading(false);
      addToast({
        title: `Error publishing category`,
        desc: error.response.data.message,
        type: 'error',
        autoClose: true,
      });
      throw new Error(error.response.data.message);
    },
  });
}

const getDeletedActiveWorkflows = async () => {
  const url = buildSubscriptionAPIURL(
    `/nc_client/status/draft/pre_publish_check/`
  );
  const { data } = await subscriptionLoggedInAPI.get(url);
  return data;
};

function useDeletedActiveWorkflows() {
  const envState = useEnvState();
  return useQuery(`${envState.currentEnv.get()}/pre_publish_check`, () =>
    getDeletedActiveWorkflows()
  );
}

const getSubCategories = async () => {
  const url = buildSubscriptionAPIURL(`/nc_client/status/active/category/`);
  const { data } = await subscriptionLoggedInAPI.get(url);
  return data;
};

function useSubCategories() {
  const envState = useEnvState();
  return useQuery([`${envState.currentEnv.get()}/category`], () =>
    getSubCategories()
  );
}

export {
  useNotifCategoriesAPI,
  useCloneCategory,
  usePublishDraft,
  useDeletedActiveWorkflows,
  useCreateSection,
  useEditSection,
  useGetSections,
  useDeleteSection,
  useCreateSubCategory,
  useEditSubCategory,
  useSubCategories,
  useDeleteSubCategory,
};

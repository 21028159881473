import { useState, Suspense, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useKeyPress } from 'ahooks';
import SuprSendInbox from '@suprsend/react-inbox';
import { useInboxInfo, useSandboxInboxInfo } from 'apis';
import { useAuthState, useEnvState } from 'stores/AuthStore';
import { ToastManager, Spinner } from 'components';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { BellIcon } from 'lucide-react';
import { useTheme } from 'new-components/theme-provider';
import { cn } from 'utils';
import EnvSwitcher from './EnvSwitcher';
import TimeZoneSwitcher from './TimeZoneSwitcher';
import Support from './Support';
import SearchWidget from './SearchWidget';
import Profile from './Profile';

const cmdOrCtrl = () => (window.navigator.platform.match(/^Mac/) ? '⌘' : '⌃');

function TopBar({ Component, isSandbox, sandboxExpired, isCommonRoute }) {
  const authState = useAuthState();
  const envState = useEnvState();

  const [searchOpen, setSearchOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { theme, setTheme } = useTheme();

  useKeyPress(
    [window.navigator.platform.match(/^Mac/) ? 'meta.k' : 'ctrl.k'],
    event => {
      event.preventDefault();
      setSearchOpen(true);
    }
  );

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', updatePosition);

    updatePosition();

    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  const { data } = useInboxInfo(envState.currentEnv.get() === 'sandbox');
  const { data: sanboxDetails } = useSandboxInboxInfo(
    authState.user.get()?.email,
    envState.currentEnv.get() === 'sandbox'
  );

  let subscriberId = null;

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    subscriberId = isSandbox ? sanboxDetails?.inbox_id : data?.inbox_id;
  }

  const suprsendInboxWSKey = isSandbox
    ? sanboxDetails?.workspace_key
    : process.env.REACT_APP_INBOX_WORKSPACE_KEY;

  return (
    <div className="relative flex flex-col w-0 flex-1 h-screen">
      <div
        className={cn(
          'fixed w-full z-10 bg-background',
          scrollPosition
            ? 'shadow-[0px_8px_16px_-4px_rgba(15,23,42,0.04),0px_2px_2px_0px_rgba(15,23,42,0.04),0px_0px_0px_1px_rgba(15,23,42,0.08)] dark:shadow-[0px_8px_16px_-4px_rgba(39,50,68,0.04),0px_2px_2px_0px_rgba(39,50,68,0.04),0px_0px_0px_1px_rgba(39,50,68,1)]'
            : 'shadow-[0px_0px_0px_1px_rgba(15,23,42,0.08)] dark:shadow-[0px_0px_0px_1px_rgba(39,50,68,1)]',
          !isCommonRoute && isSandbox && !sandboxExpired ? 'top-5' : 'top-0'
        )}
      >
        <div className="flex items-center mr-4">
          {isCommonRoute ? (
            <div className="flex items-center space-x-2 w-full cursor-pointer ml-[80px]" />
          ) : (
            <div
              className="flex items-center space-x-2 w-full cursor-pointer ml-[80px]"
              onClick={() => setSearchOpen(true)}
            >
              <MagnifyingGlassIcon className="h-4 w-4 text-muted-foreground" />
              <span className="text-accent-foreground/65 text-sm font-normal">
                Search
              </span>
              <span className="hidden sm:block text-xs font-medium text-muted-foreground">
                <span className="sr-only">Press </span>
                <kbd className="font-sans">{cmdOrCtrl()}</kbd>
                <span className="sr-only"> and </span>
                <kbd className="font-sans">K</kbd>
                <span className="sr-only"> to search</span>
              </span>
            </div>
          )}
          <TimeZoneSwitcher />
          {!isCommonRoute && <EnvSwitcher />}
          <Support />
          {suprsendInboxWSKey && (
            <SuprSendInbox
              workspaceKey={suprsendInboxWSKey}
              subscriberId={subscriberId}
              distinctId={
                isSandbox ? sanboxDetails?.distinct_id : data?.distinct_id
              }
              hideToast={true}
              themeType={theme}
              theme={{
                notification: {
                  avatar: {
                    objectFit: 'cover',
                    maxWidth: 'none',
                  },
                },
                notificationsContainer: {
                  container: {
                    marginTop: 2,
                  },
                },
              }}
              bellComponent={() => (
                <div className="mx-1.5 p-1.5 -mt-2.5 hover:bg-accent rounded-full">
                  <BellIcon className="h-4 w-4 text-muted-foreground" />
                </div>
              )}
              badgeComponent={() => (
                <div className="absolute h-[7px] w-[7px] rounded-full bg-primary ml-[19px] mt-[5px]" />
              )}
            />
          )}
          <Profile theme={theme} setTheme={setTheme} />
        </div>
      </div>
      <main
        className={cn(
          'flex-1 relative z-0 overflow-y-auto focus:outline-none ml-[60px]',
          isSandbox && !sandboxExpired ? 'mt-[56px]' : 'mt-10'
        )}
      >
        <Suspense
          fallback={
            <div className="mt-24">
              <Spinner />
            </div>
          }
        >
          <Component />
        </Suspense>
      </main>
      <ToastManager />
      <SearchWidget searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
    </div>
  );
}

export default TopBar;

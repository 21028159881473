import { useQuery, useQueryClient, useMutation } from 'react-query';
import { subscriptionLoggedInAPI, useEnvState } from '../stores/AuthStore';
import toast from 'react-hot-toast';

const getNotificationPreference = async () => {
  const url = '/v1/nc_self/';
  const { data } = await subscriptionLoggedInAPI.get(url);
  return data;
};

function useNotificationPreference() {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/notification_preference`],
    () => getNotificationPreference()
  );
}

const useChangeChannelPreference = () => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = '/v1/nc_self/channel_preference';
  return useMutation(data => subscriptionLoggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_preference`
      );
    },
    onError: error => {
      toast.error(`Error saving : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
};

const useChangeCategoryPreference = category => {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = `/v1/nc_self/category/${category}`;
  return useMutation(data => subscriptionLoggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/notification_preference`
      );
    },
    onError: error => {
      toast.error(`Error saving : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
};

export {
  useNotificationPreference,
  useChangeChannelPreference,
  useChangeCategoryPreference,
};

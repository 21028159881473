import { useEffect, useState } from 'react';
import {
  Route,
  Redirect,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { Spinner } from 'components';
import { NavBar } from 'custom-components';
import { useAuthState, useEnvState } from 'stores/AuthStore';
import { useOnboardingStep, useOrgAPI, useOrgUserAPI } from 'apis';
import urlUtils from './urlUtils';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [redirectTo, setRedirectTo] = useState('');

  const currentURLPath = window.location.href.split(window.location.origin)[1];
  let history = useHistory();
  const location = useLocation();
  const match = matchPath(currentURLPath, {
    path: '/:locale/:env',
    exact: false,
    strict: false,
  });
  const envState = useEnvState();
  const authState = useAuthState();
  const currentEnv = envState.currentEnv.get();
  const isLoggedIn = authState.isLoggedIn.get();

  const {
    data: orgData,
    isSuccess: orgSuccess,
    isFetched: orgDataFetched,
  } = useOrgAPI({ enabled: isLoggedIn });
  const { data: orgUserData } = useOrgUserAPI({ enabled: orgSuccess });
  const { data: onboardingData } = useOnboardingStep({
    enabled: orgSuccess,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      const loginURL = urlUtils.makePublicURL('/login');
      history.replace(loginURL, {
        from: `${location?.pathname}${location?.search}`,
      });
    }
  }, []);

  useEffect(() => {
    if (orgDataFetched) {
      if (orgSuccess) {
        authState.isLoggedIn.set(true);
      } else {
        const loginURL = urlUtils.makePublicURL('/login');
        history.replace(loginURL, {
          from: `${location?.pathname}${location?.search}`,
        });
      }
    }
  }, [orgSuccess]);

  useEffect(() => {
    if (orgData) {
      authState.organization.set(orgData);
    }
    if (orgUserData) {
      const modifiedOrgUserData = { ...orgUserData, ...orgUserData.user };
      delete modifiedOrgUserData.user;
      authState.user.set(modifiedOrgUserData);
    }
  }, [orgUserData]);

  useEffect(() => {
    // If current env doesn't match already set env but if new env exists in the list, change it
    if (authState.isLoggedIn.get()) {
      const currentLang = localStorage.getItem('i18nextLng') || 'en';
      const allEnv = authState.organization.get()?.environments;
      const urlEnvObj = allEnv?.filter(obj => obj.slug === match.params.env);
      const currentEnvObj = allEnv?.filter(obj => obj.slug === currentEnv);

      if (currentEnv !== match.params.env) {
        if (urlEnvObj.length === 1) {
          envState.currentEnv.set(urlEnvObj[0].slug);
          localStorage.setItem(
            'suprsend-current-env',
            JSON.stringify({ currentEnv: urlEnvObj[0].slug })
          );
        } else if (currentEnvObj.length === 1) {
          let arr = currentURLPath.split('/');
          if (arr[2] !== currentEnv) {
            arr[2] = currentEnv;
            const newURL = arr.join('/');
            history.replace(newURL);
          }
        } else {
          history.replace(`/${currentLang}/${allEnv[0].slug}/`);
        }
      } else {
        if (currentEnvObj.length < 1) {
          history.replace(`/${currentLang}/${allEnv[0].slug}/`);
        }
      }
    }
  }, [authState.isLoggedIn, currentEnv, match.params.env]);

  useEffect(() => {
    const currentEnv = envState.currentEnv.get();
    if (currentEnv === 'sandbox' && onboardingData?.sandbox) {
      const currentLang = localStorage.getItem('i18nextLng') || 'en';
      const sandboxEnvData = onboardingData.sandbox;
      const sandboxExpired =
        new Date() > new Date(sandboxEnvData.expiry_at) ||
        sandboxEnvData.has_limit_reached;
      if (sandboxExpired) {
        if (location.pathname.endsWith('/onboarding')) {
          history.replace(`/${currentLang}/sandbox/`);
        }
      } else {
        if (onboardingData?.step_no < 6) {
          history.push(`/${currentLang}/sandbox/onboarding`);
        } else if (redirectTo) {
          history.push(redirectTo);
          setRedirectTo('');
        }
      }
    }
  }, [location.pathname, onboardingData]);

  const loginURL = urlUtils.makePublicURL('/login');
  const orgState = authState.organization || {};
  const orgUserState = authState.user || {};

  if (onboardingData && !isEmpty(orgState) && !isEmpty(orgUserState)) {
    return (
      <Route
        {...rest}
        render={props => {
          if (authState.isLoggedIn.get()) {
            const sandboxEnvData = onboardingData?.sandbox;
            if (currentEnv === 'sandbox' && sandboxEnvData) {
              const expiredDate = format(
                new Date(sandboxEnvData.expiry_at),
                'do LLL, yy'
              );
              const sandboxExpired =
                new Date() > new Date(sandboxEnvData.expiry_at);
              if (sandboxExpired) {
                return (
                  <NavBar
                    component={() => {
                      return (
                        <div className="flex justify-center items-center flex-col h-screen -mt-20">
                          <div className="bg-red-100 p-12 text-red-700 rounded font-medium text-center">
                            <p>
                              Your Sandbox validity expired on{' '}
                              <span className="font-bold">{expiredDate}</span>.
                            </p>
                            <p>
                              If you want to extend your validity, contact us at{' '}
                              <a
                                href="mailto:support@suprsend.com"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-700 underline"
                              >
                                support@suprsend.com
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    }}
                    sandboxExpired={sandboxExpired}
                    {...props}
                  />
                );
              } else if (sandboxEnvData.has_limit_reached) {
                return (
                  <NavBar
                    component={() => {
                      return (
                        <div className="flex justify-center items-center flex-col h-screen -mt-20">
                          <div className="bg-red-100 p-12 text-red-700 rounded font-medium text-center">
                            <p>Your Sandbox notification quota is exhausted</p>
                            <p>
                              If you need additional notification quota to test,
                              contact us at{' '}
                              <a
                                href="mailto:support@suprsend.com"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-700 underline"
                              >
                                support@suprsend.com
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    }}
                    sandboxExpired={sandboxExpired}
                    {...props}
                  />
                );
              } else if (props.location.pathname.endsWith('/onboarding')) {
                return (
                  <Component
                    {...props}
                    stepNo={onboardingData.step_no}
                    setRedirectTo={setRedirectTo}
                  />
                );
              } else {
                return (
                  <div className="relative">
                    <div className="fixed top-0 w-screen bg-yellow-100 z-[20]">
                      <p className="text-sm text-yellow-800 text-center">
                        This is a trial workspace with{' '}
                        <span className="font-semibold">
                          {sandboxEnvData.notifications_limit}
                        </span>{' '}
                        free notifications to test. Trial will end on{' '}
                        <span className="font-semibold">{expiredDate}</span>
                      </p>
                    </div>
                    <NavBar
                      component={Component}
                      sandboxExpired={sandboxExpired}
                      {...props}
                    />
                  </div>
                );
              }
            } else {
              return <NavBar component={Component} {...props} />;
            }
          } else {
            return <Redirect to={{ pathname: loginURL }} />;
          }
        }}
      />
    );
  }
  return (
    <div className="h-screen">
      <Spinner />
    </div>
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export const CommonPrivateRoute = ({ component: Component, ...rest }) => {
  let history = useHistory();
  const location = useLocation();
  const authState = useAuthState();
  const isLoggedIn = authState.isLoggedIn.get();

  const {
    data: orgData,
    isSuccess: orgSuccess,
    isFetched: orgDataFetched,
  } = useOrgAPI({ enabled: isLoggedIn });
  const { data: orgUserData } = useOrgUserAPI({ enabled: orgSuccess });

  useEffect(() => {
    if (!isLoggedIn) {
      const loginURL = urlUtils.makePublicURL('/login');
      history.replace(loginURL, {
        from: `${location?.pathname}${location?.search}`,
      });
    }
  }, []);

  useEffect(() => {
    if (orgDataFetched) {
      if (orgSuccess) {
        authState.isLoggedIn.set(true);
      } else {
        const loginURL = urlUtils.makePublicURL('/login');
        history.replace(loginURL, {
          from: `${location?.pathname}${location?.search}`,
        });
      }
    }
  }, [orgSuccess]);

  useEffect(() => {
    if (orgData) {
      authState.organization.set(orgData);
    }
    if (orgUserData) {
      const modifiedOrgUserData = { ...orgUserData, ...orgUserData.user };
      delete modifiedOrgUserData.user;
      authState.user.set(modifiedOrgUserData);
    }
  }, [orgUserData]);

  if (!isEmpty(orgData) && !isEmpty(orgUserData)) {
    return <NavBar component={Component} isCommonRoute={true} {...rest} />;
  }
  return (
    <div className="h-screen">
      <Spinner />
    </div>
  );
};

import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RefreshCcw, Filter, X } from 'lucide-react';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Spinner } from 'components';
import {
  Button,
  Label,
  Switch,
  DateTimeRangePicker,
  Checkbox,
  Input,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
} from 'new-components';
import {
  useTenantsData,
  useRequestLogsName,
  useSubscriberInfoSearch,
} from 'apis';
import { cn } from 'utils';

const validMainFiltersList = [
  'status',
  'error_severity',
  'api_type',
  'api_name',
  'tenant',
  'distinct_id',
  'idempotency_key',
];

const getFilterCount = qs => {
  return validMainFiltersList.reduce((acc, current) => {
    return qs.has(current) ? ++acc : acc;
  }, 0);
};

export function TopFilters({
  queryString,
  autoRefresh,
  setAutoRefresh,
  handleRefresh,
  loader,
  filterModal,
}) {
  const history = useHistory();

  let date = 1440;
  if (queryString.get('start_ts') && queryString.get('end_ts')) {
    date = {
      from: queryString.get('start_ts'),
      to: queryString.get('end_ts'),
    };
  } else if (queryString.get('last_n_minutes')) {
    date = Number(queryString.get('last_n_minutes'));
  }

  const filterCount = getFilterCount(queryString);

  return (
    <div className="flex space-x-4 -mb-8">
      {loader && (
        <div className="-m-2 mr-2">
          <Spinner />
        </div>
      )}
      <DateTimeRangePicker
        rangeValue={date}
        onChangeData={({ type, values }) => {
          if (type === 'absolute') {
            queryString.delete('last_n_minutes');
            queryString.set('start_ts', values.from);
            queryString.set('end_ts', values.to);
          } else {
            queryString.set('last_n_minutes', values);
            queryString.delete('start_ts');
            queryString.delete('end_ts');
          }
          queryString.set('page', 0);
          history.push({ search: queryString.toString() });
        }}
      />
      {filterModal && (
        <SheetTrigger asChild>
          <Button variant="outline">
            <Filter className="h-4 w-4 mr-2" />
            Filters
            {filterCount !== 0 && (
              <div className="flex items-center">
                <p className="ml-1 bg-primary rounded-full inline-flex items-center px-[6px] py-[1px] text-xs font-medium text-white">
                  {filterCount}
                </p>
                <p className="pl-2.5 pr-2 ">|</p>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    validMainFiltersList.forEach(item => {
                      queryString.delete(item);
                    });
                    queryString.set('page', 0);
                    history.push({ search: queryString.toString() });
                  }}
                >
                  <X className="h-4 w-4" />
                </div>
              </div>
            )}
          </Button>
        </SheetTrigger>
      )}
      <Button
        variant="outline"
        size="icon"
        onClick={() => {
          handleRefresh();
        }}
      >
        <RefreshCcw className="h-5 w-5" />
      </Button>
      <div className="flex items-center space-x-2">
        <Label htmlFor="auto-refresh" className=" text-accent-foreground">
          Auto-refresh
        </Label>
        <Switch
          id="auto-refresh"
          onCheckedChange={() => {
            setAutoRefresh(!autoRefresh);
          }}
          checked={autoRefresh}
        />
      </div>
    </div>
  );
}

const STATUS_OPTIONS = [
  {
    name: 'Completed',
    value: 'success',
    bgColor: 'bg-success-subtle',
    totalCountKey: 'total_completed',
  },
  {
    name: 'Partial Failure',
    value: 'partial_failure',
    bgColor: 'bg-warning-subtle',
    totalCountKey: 'total_partial_failures',
  },
  {
    name: 'Failed',
    value: 'failure',
    bgColor: 'bg-destructive-subtle',
    totalCountKey: 'total_failed',
  },
];

const ERROR_SEVERITY_OPTIONS = [
  { name: 'Errors', value: 'error', totalCountKey: 'total_errors' },
  { name: 'Warnings', value: 'warning', totalCountKey: 'total_warnings' },
];

const API_TYPE_OPTIONS = [
  { name: 'Event', value: 'event' },
  { name: 'Workflow', value: 'workflow' },
  { name: 'User Update', value: 'user_update' },
  { name: 'Broadcast', value: 'broadcast' },
  { name: 'Tenant Preference Update', value: 'tenant_preference_update' },
  {
    name: 'Subscriber Preference Update',
    value: 'subscriber_preference_update',
  },
];

export function MainFilters({
  queryParam,
  chartData,
  hasTenant,
  apiTypeOptions = API_TYPE_OPTIONS,
  isModal,
  filters,
  setFilters,
}) {
  const history = useHistory();
  const filtersData = isModal ? new URLSearchParams(filters) : queryParam;
  const tenant = filtersData.get('tenant');
  const distinctID = filtersData.get('distinct_id');
  const idempotencyKey = filtersData.get('idempotency_key');
  const apiName = filtersData.get('api_name');
  let apiType = [];
  const paramsEntries = new URLSearchParams(filtersData).entries();
  for (let item of paramsEntries) {
    if (item[0] === 'api_type') {
      apiType.push({
        name: apiTypeOptions.find(i => i.value === item[1]).name,
        value: item[1],
      });
    }
  }

  const idempotencyRef = useRef();

  const [tenantInfo, setTenant] = useState();
  const [distinctIDInfo, setDistinctIDInfo] = useState();
  const [requestNameInfo, setRequestName] = useState();
  const [idempotencyFocus, setIdempotencyFocus] = useState(false);

  const { data: tenantData, isLoading: tenantLoading } = useTenantsData(
    tenantInfo,
    !!tenantInfo
  );
  const { data: searchedDistinctIDs, isLoading: DistinctIdLoading } =
    useSubscriberInfoSearch({
      distinctId: distinctIDInfo,
    });
  const { data: requestNameData, isLoading: requestNameLoading } =
    useRequestLogsName(requestNameInfo, { enabled: !!requestNameInfo });

  return (
    <div>
      {!isModal && (
        <div className="flex items-center justify-between border-b px-4 py-1">
          <p className="text-foreground font-semibold text-base">Filters</p>
          <Button
            variant="link"
            className="hover:no-underline p-0"
            onClick={() => {
              validMainFiltersList.forEach(item => {
                filtersData.delete(item);
              });
              filtersData.set('page', 0);
              idempotencyRef.current.value = '';
              history.push({ search: filtersData.toString() });
            }}
          >
            Clear All
          </Button>
        </div>
      )}
      <div className={cn('pb-8', isModal ? 'p-0 pt-4' : 'p-4')}>
        <div>
          <div>
            <p className="font-medium text-sm text-foreground">Status</p>
          </div>
          <div className="py-2">
            {STATUS_OPTIONS.map((status, index) => {
              let checked = false;
              const entries = filtersData.entries();
              for (let filter of entries) {
                if (filter[0] === 'status' && filter[1] === status.value) {
                  checked = true;
                }
              }
              return (
                <div
                  className="flex items-center justify-between py-1"
                  key={index}
                >
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={status.value}
                      checked={checked}
                      onCheckedChange={value => {
                        if (value) {
                          filtersData.append('status', status.value);
                          filtersData.set('page', 0);
                          if (isModal) {
                            setFilters(filtersData.toString());
                          } else {
                            history.push({ search: filtersData.toString() });
                          }
                        } else {
                          const entries = filtersData.entries();
                          const newQueryParam = new URLSearchParams();
                          for (let filter of entries) {
                            if (
                              !(
                                filter[0] === 'status' &&
                                filter[1] === status.value
                              )
                            ) {
                              newQueryParam.append(filter[0], filter[1]);
                            }
                          }
                          newQueryParam.set('page', 0);
                          if (isModal) {
                            setFilters(newQueryParam.toString());
                          } else {
                            history.push({ search: newQueryParam.toString() });
                          }
                        }
                      }}
                    />
                    <Label
                      htmlFor={status.value}
                      className="text-sm text-foreground font-normal"
                    >
                      <div className="flex gap-2 items-center">
                        <div
                          className={cn(
                            'h-3 w-3 rounded-[2px]',
                            status.bgColor
                          )}
                        />
                        <p>{status.name}</p>
                      </div>
                    </Label>
                  </div>
                  <p className="text-xs text-muted-foreground font-normal">
                    {chartData?.[status.totalCountKey]}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <div>
            <p className="font-medium text-sm text-foreground">
              Error Severity
            </p>
          </div>
          <div className="py-2">
            {ERROR_SEVERITY_OPTIONS.map(status => {
              let checked = false;
              const entries = filtersData.entries();
              for (let filter of entries) {
                if (
                  filter[0] === 'error_severity' &&
                  filter[1] === status.value
                ) {
                  checked = true;
                }
              }
              return (
                <div
                  className="flex items-center justify-between py-1"
                  key={status.value}
                >
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={status.value}
                      checked={checked}
                      onCheckedChange={value => {
                        if (value) {
                          filtersData.append('error_severity', status.value);
                          filtersData.set('page', 0);
                          if (isModal) {
                            setFilters(filtersData.toString());
                          } else {
                            history.push({ search: filtersData.toString() });
                          }
                        } else {
                          const entries = filtersData.entries();
                          const newQueryParam = new URLSearchParams();
                          for (let filter of entries) {
                            if (
                              !(
                                filter[0] === 'error_severity' &&
                                filter[1] === status.value
                              )
                            ) {
                              newQueryParam.append(filter[0], filter[1]);
                            }
                          }
                          newQueryParam.set('page', 0);
                          if (isModal) {
                            setFilters(newQueryParam.toString());
                          } else {
                            history.push({ search: newQueryParam.toString() });
                          }
                        }
                      }}
                    />
                    <Label
                      htmlFor={status.value}
                      className="text-sm text-foreground font-normal"
                    >
                      {status.name}
                    </Label>
                  </div>
                  <p className="text-xs text-muted-foreground font-normal">
                    {chartData?.[status.totalCountKey]}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-4">
          <div className="mb-1">
            <Label className="font-medium text-sm text-foreground">
              API Type
            </Label>
          </div>
          <Select
            classNamePrefix="react-select"
            isMulti
            placeholder="Select"
            defaultValue={apiType}
            value={apiType}
            getOptionLabel={item => item.name}
            getOptionValue={item => item.value}
            noOptionsMessage={() => null}
            isClearable={true}
            options={apiTypeOptions}
            onChange={(_, event) => {
              if (event.action === 'select-option') {
                filtersData.append('api_type', event.option.value);
                filtersData.set('page', 0);
                if (isModal) {
                  setFilters(filtersData.toString());
                } else {
                  history.push({ search: filtersData.toString() });
                }
              } else if (event.action === 'remove-value') {
                const entries = filtersData.entries();
                const newQueryParam = new URLSearchParams();
                for (let filter of entries) {
                  if (
                    !(
                      filter[0] === 'api_type' &&
                      filter[1] === event.removedValue.value
                    )
                  ) {
                    newQueryParam.append(filter[0], filter[1]);
                  }
                }
                newQueryParam.set('page', 0);
                if (isModal) {
                  setFilters(newQueryParam.toString());
                } else {
                  history.push({ search: newQueryParam.toString() });
                }
              } else if (event.action === 'clear') {
                filtersData.delete('api_type');
                filtersData.set('page', 0);
                if (isModal) {
                  setFilters(filtersData.toString());
                } else {
                  history.push({ search: filtersData.toString() });
                }
              }
            }}
          />
        </div>

        {/* <div className="mt-4">
            <div className="mb-1">
              <Label className="font-medium text-sm text-foreground">Name</Label>
            </div>
            <CreatableSelect
              classNamePrefix="react-select"
              placeholder="Type to search"
              defaultValue={apiName ? { name: apiName } : null}
              value={apiName ? { name: apiName } : null}
              isLoading={requestNameLoading}
              isClearable
              options={requestNameInfo ? requestNameData : []}
              getOptionLabel={i => i?.name}
              getOptionValue={i => i?.name}
              onInputChange={data => {
                setRequestName(data);
              }}
              createOptionPosition="first"
              onChange={(data, event) => {
                if (
                  event.action === 'select-option' ||
                  event.action === 'create-option'
                ) {
                  filtersData.set('api_name', data.name);
                  filtersData.set('page', 0);
                  history.push({ search: filtersData.toString() });
                } else if (event.action === 'clear') {
                  filtersData.delete('api_name');
                  filtersData.set('page', 0);
                  history.push({ search: filtersData.toString() });
                }
              }}
              getNewOptionData={newOption => {
                if (newOption) {
                  return { name: newOption };
                }
              }}
              noOptionsMessage={() => null}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div> */}

        <div className="mt-4">
          <div className="mb-1">
            <Label className="font-medium text-sm text-foreground">
              Idempotency Key
            </Label>
          </div>
          <div
            className={cn(
              'flex items-center border border-input bg-transparent rounded',
              idempotencyFocus && 'outline-none ring-1 ring-ring'
            )}
          >
            <Input
              defaultValue={idempotencyKey}
              className="border-none focus-visible:ring-0"
              onFocus={() => {
                setIdempotencyFocus(true);
              }}
              ref={idempotencyRef}
              onBlur={() => {
                setIdempotencyFocus(false);
              }}
              id="subscriber_idempotency_filter"
              onChange={e => {
                if (!e.target.value) {
                  filtersData.delete('idempotency_key');
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                } else {
                  if (isModal) {
                    filtersData.set('idempotency_key', e.target.value);
                    filtersData.set('page', 0);
                    setFilters(filtersData.toString());
                  }
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' && e.target.value) {
                  filtersData.set('idempotency_key', e.target.value);
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                }
              }}
            />
            {!isModal && (
              <div
                className="bg-border/50 rounded-r h-9 px-2 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  const value = idempotencyRef.current.value;
                  if (value) {
                    filtersData.set('idempotency_key', value);
                    filtersData.set('page', 0);
                    if (isModal) {
                      setFilters(filtersData.toString());
                    } else {
                      history.push({ search: filtersData.toString() });
                    }
                  }
                }}
              >
                <MagnifyingGlassIcon className={'h-5 w-5'} />
              </div>
            )}
          </div>
        </div>

        {!isModal && (
          <div className="mt-4">
            <div className="mb-1">
              <Label className="font-medium text-sm text-foreground">
                Distinct ID
              </Label>
            </div>

            <CreatableSelect
              classNamePrefix="react-select"
              placeholder="Type to search"
              defaultValue={distinctID ? { distinct_id: distinctID } : null}
              value={distinctID ? { distinct_id: distinctID } : null}
              isLoading={DistinctIdLoading}
              openMenuOnClick={false}
              isClearable
              options={searchedDistinctIDs ? searchedDistinctIDs?.results : []}
              getOptionLabel={i => i?.distinct_id}
              getOptionValue={i => i?.distinct_id}
              onInputChange={data => {
                setDistinctIDInfo(data);
              }}
              createOptionPosition="first"
              onChange={(data, event) => {
                if (
                  event.action === 'select-option' ||
                  event.action === 'create-option'
                ) {
                  filtersData.set('distinct_id', data.distinct_id);
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                } else if (event.action === 'clear') {
                  filtersData.delete('distinct_id');
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                }
              }}
              getNewOptionData={newOption => {
                if (newOption) {
                  return { distinct_id: newOption };
                }
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
        {hasTenant && (
          <div className="mt-4">
            <div className="mb-1">
              <Label className="font-medium text-sm text-foreground">
                Tenant ID
              </Label>
            </div>
            <CreatableSelect
              classNamePrefix="react-select"
              placeholder="Type to search"
              defaultValue={tenant ? { tenant_id: tenant } : null}
              value={tenant ? { tenant_id: tenant } : null}
              isLoading={tenantLoading}
              isClearable
              options={tenantInfo ? tenantData : []}
              createOptionPosition="first"
              getOptionLabel={i => i?.tenant_id}
              getOptionValue={i => i?.tenant_id}
              onInputChange={data => {
                setTenant(data);
              }}
              onChange={(data, event) => {
                if (
                  event.action === 'select-option' ||
                  event.action === 'create-option'
                ) {
                  filtersData.set('tenant', data.tenant_id);
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                } else if (event.action === 'clear') {
                  filtersData.delete('tenant');
                  filtersData.set('page', 0);
                  if (isModal) {
                    setFilters(filtersData.toString());
                  } else {
                    history.push({ search: filtersData.toString() });
                  }
                }
              }}
              getNewOptionData={newOption => {
                if (newOption) {
                  return { tenant_id: newOption };
                }
              }}
              noOptionsMessage={() => null}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function MainModalFilter({
  queryParam,
  chartData,
  hasTenant,
  filters,
  setFilters,
}) {
  const history = useHistory();

  return (
    <>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Filters</SheetTitle>
        </SheetHeader>
        <MainFilters
          queryParam={queryParam}
          chartData={chartData}
          hasTenant={hasTenant}
          filters={filters}
          setFilters={setFilters}
          isModal
        />
        <div className="flex items-center justify-between absolute bottom-0 left-0 right-0 border-t p-4">
          <Button
            variant="link"
            onClick={() => {
              const filtersData = new URLSearchParams(filters);
              validMainFiltersList.forEach(item => {
                filtersData.delete(item);
              });
              const idempotenctInput = document.getElementById(
                'subscriber_idempotency_filter'
              );
              idempotenctInput.value = '';
              setFilters(filtersData.toString());
            }}
          >
            Clear Filters
          </Button>
          <SheetClose>
            <Button
              onClick={() => {
                history.push({ search: filters });
              }}
            >
              View {chartData?.total_count || 0} results
            </Button>
          </SheetClose>
        </div>
      </SheetContent>
    </>
  );
}

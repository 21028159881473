import { formatDistanceToNow, add, formatDistanceToNowStrict } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { useEffect, useState } from 'react';

export default function DurationDiff({ duration, ...props }) {
  let date = add(new Date(), { seconds: duration });
  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  let str = formatDistanceToNowStrict(date, {
    addSuffix: true,
    locale: enGB,
  });
  str = str.replace('seconds', 's').replace('second', 's');
  str = str.replace('minutes', 'm').replace('minute', 'm');
  str = str.replace('days', 'd').replace('day', 'd');
  return <span {...props}>{str}</span>;
}

import React, { useState, useEffect, useRef } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './tooltip';
import { copyToClipboard } from 'utils';

const useOverflow = ref => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (ref.current) {
        setIsOverflowing(ref.current.scrollWidth > ref.current.clientWidth);
      }
    };
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [ref]);

  return isOverflowing;
};

export const COverflowTooltip = ({
  trigger,
  alignment = 'start',
  className = '',
  side = 'top',
  children,
}) => {
  const textRef = useRef(null);
  const isOverflowing = useOverflow(textRef);

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild ref={textRef}>
          {trigger}
        </TooltipTrigger>
        {isOverflowing && (
          <TooltipContent align={alignment} className={className} side={side}>
            {children}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export const CTooltip = ({
  trigger,
  alignment = 'start',
  className = '',
  side = 'top',
  children,
}) => {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>{trigger}</TooltipTrigger>
        <TooltipContent align={alignment} className={className} side={side}>
          {children}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const CCopyTooltip = ({
  trigger,
  copyContent,
  alignment = 'start',
  side = 'top',
  className = '',
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [tooltipContent, setTooltipContent] = React.useState('Click to copy');

  const handleTriggerClick = () => {
    setIsOpen(true);
    copyToClipboard(copyContent);
    setTooltipContent('Copied!');
    setTimeout(() => {
      setTooltipContent('Click to copy');
    }, 3000); // Reset content after 3 seconds
  };

  return (
    <div>
      <TooltipProvider delayDuration={800}>
        <Tooltip open={isOpen}>
          <TooltipTrigger
            asChild
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            onClick={e => {
              e.stopPropagation();
              handleTriggerClick();
            }}
          >
            {trigger}
          </TooltipTrigger>
          <TooltipContent
            alignment={alignment}
            className={className}
            side={side}
          >
            <p>{tooltipContent}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import urlUtils from '../utils/urlUtils';

export default function EnvNavLink({ to, currentEnv, isRenderProp, ...props }) {
  const location = useLocation();
  const [finalURL, setFinalURL] = useState('');
  useEffect(() => {
    //const currentPath = location.pathname;
    setFinalURL(urlUtils.makeURL(to));
  }, [to, currentEnv?.id]);

  const handleActive = () => {
    if (to === '/') {
      const location_path = location.pathname.endsWith('/')
        ? location.pathname
        : `${location.pathname}/`;
      return location_path === finalURL;
    } else if (to?.includes('/logs/')) {
      return (
        finalURL.includes('/logs/') &&
        (location.pathname.includes('/logs/broadcast') ||
          location.pathname.includes('/logs/workflow') ||
          location.pathname.includes('/logs/api') ||
          location.pathname.includes('/logs/executions'))
      );
    } else {
      return location.pathname.includes(finalURL);
    }
  };

  if (isRenderProp) {
    const isActive = handleActive();
    return (
      <NavLink exact to={finalURL} {...props} isActive={handleActive}>
        {props.children(isActive)}
      </NavLink>
    );
  }
  return (
    <NavLink exact to={finalURL} {...props} isActive={handleActive}></NavLink>
  );
}

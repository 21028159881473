import { createState, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { Broadcasted } from '@hookstate/broadcasted';
import axios from 'axios';
import {
  MixPanelResetUser,
  MixPanelIdentify,
  MixPanelUserProfile,
  MixPanelIncrement,
  MixPanelSetOnce,
} from 'utils/mixPanel';
import { zipyAnonymize, zipyIdentifyUser } from 'utils/zipy';
import urlUtils from 'utils/urlUtils';
import { toast } from 'components';

// initial state of auth
const AuthInitialState = {
  isLoggedIn: false,
  user: null,
  organization: null,
};
const authState = createState(AuthInitialState); // auth store
authState.attach(Persistence('suprsend-current-user'));

export const useAuthState = () => useState(authState); // create react hook for consuming

//----------------------------------------

// initial state of environment
const EnvInitialState = {
  currentEnv: 'staging',
};

const EnvExistingState = localStorage.getItem('suprsend-current-env');
const EnvData = EnvExistingState
  ? JSON.parse(EnvExistingState)
  : EnvInitialState;

export const envStore = createState(EnvData); // environment store
envStore.attach(
  Broadcasted('suprsend-env-channel-topic', () => {
    window.console.log('This tab is a leader now');
    envStore.attach(Persistence('suprsend-current-env'));
  })
);

export const useEnvState = () => useState(envStore); // create react hook for consuming

//--------------------------------

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// public api's axios instance
export const publicAPI = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// private api's axios instance
export const loggedInAPI = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

publicAPI.interceptors.request.use(
  config => config,
  function (error) {
    toast('Something went wrong. Please try after sometime', 'error');
    return Promise.reject(error);
  }
);

publicAPI.interceptors.response.use(
  response => response,
  error => {
    if (
      !['/v2/user/token/logout/', '/v2/user/token/refresh/'].includes(
        error.response.config.url
      )
    ) {
      if (error?.response?.status === 429) {
        const retryAfter = error.response.headers?.['retry-after'];
        const retryText = retryAfter ? `${retryAfter} seconds` : 'sometime';
        toast(
          `You've reached the maximum attempts. Please try after ${retryText}`,
          'error'
        );
      } else {
        error?.response?.data?.message
          ? toast(error.response.data.message, 'error')
          : toast('Something went wrong. Please try after sometime', 'error');
      }
    }
    return Promise.reject(error);
  }
);

//-----------------------

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
}

async function refreshToken(error) {
  const { config: originalRequest } = error;

  if (error.response.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      publicAPI
        .post('/v2/user/token/refresh/')
        .then(newToken => {
          isRefreshing = false;
          onRrefreshed(newToken);
          refreshSubscribers = [];
        })
        .catch(() => logout({ storePrevLink: true }));
    }

    const retryOrigReq = new Promise((resolve, reject) => {
      subscribeTokenRefresh(token => {
        resolve(loggedInAPI(originalRequest));
      });
    });
    return retryOrigReq;
  } else {
    return Promise.reject(error);
  }
}

loggedInAPI.interceptors.response.use(response => response, refreshToken); // private api's response interceptor

//-----------------------------------

const SUBSCRIPTION_BASE_URL = process.env.REACT_APP_SUBSCRIPTION_API_BASE_URL;

// subscription api's axios instance
export const subscriptionLoggedInAPI = axios.create({
  baseURL: SUBSCRIPTION_BASE_URL,
  withCredentials: true,
});

// subscription api's response interceptor
subscriptionLoggedInAPI.interceptors.response.use(
  response => response,
  refreshToken
);

//--------------------------------------

export function buildAPIURL(path, custom_env = '') {
  const apiVersion = 'v1';
  let env = custom_env ? custom_env : envStore.currentEnv.get();
  return `${BASE_URL}/${apiVersion}/${env}${path}`;
}

export function buildSubscriptionAPIURL(path, custom_env = '') {
  const apiVersion = 'v1';
  let env = custom_env ? custom_env : envStore.currentEnv.get();
  return `${SUBSCRIPTION_BASE_URL}/${apiVersion}/${env}${path}`;
}

export function setUserEnvironment() {
  const environments = authState.organization.get()?.environments || [];
  const storedEnv = envStore.currentEnv.get();
  const sandboxEnv = environments.find(item => item.slug === 'sandbox');

  if (sandboxEnv) {
    const sandboxExpired = new Date() > new Date(sandboxEnv.expiry_at);
    if (sandboxEnv.has_limit_reached || sandboxExpired) {
      if (storedEnv === 'sandbox') {
        envStore.currentEnv.set('staging');
      } else {
        envStore.currentEnv.set(storedEnv);
      }
    } else {
      envStore.currentEnv.set('sandbox');
    }
  } else if (storedEnv === 'sandbox') {
    envStore.currentEnv.set('staging');
  }
}

export function loginSuccessfullAnalytics(userData, orgData) {
  zipyIdentifyUser(userData?.email, {
    firstName: userData?.name,
    email: userData?.email,
    customerName: orgData?.name,
  });

  MixPanelIdentify(userData?.email);

  MixPanelUserProfile({
    $name: userData?.name,
    $email: userData?.email,
    'organization name': orgData?.name,
  });

  MixPanelIncrement({ login_count: 1 });

  MixPanelSetOnce({ first_login_at: new Date() });
}

export async function logout(options) {
  const storePrevLink = options?.storePrevLink || false;

  try {
    await publicAPI.post('/v2/user/token/logout/');
  } catch (err) {
  } finally {
    authState.set({
      isLoggedIn: false,
      organization: {},
      user: {},
    });
    envStore.set(EnvInitialState);
    MixPanelResetUser();
    zipyAnonymize();
    localStorage.removeItem('ss-recent-test-user');

    let loginURL = urlUtils.makePublicURL('/login');

    if (storePrevLink && window.location.pathname) {
      loginURL = urlUtils.makePublicURL(
        `/login?from=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`
      );
    }

    window.location.replace(loginURL);
  }
}

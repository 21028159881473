import { useContext } from 'react';
import { ReactComponent as WaitUntilNodeIcon } from 'assets/svgs/waitUntilNodeIcon.svg';
import { ReactComponent as DelayNodeIcon } from 'assets/svgs/delayNodeIcon.svg';
import { ReactComponent as BatchingNodeIcon } from 'assets/svgs/batchingNodeIcon.svg';
import { ReactComponent as DragIcon } from 'assets/svgs/dragIcon.svg';
import BatchNodeImage from 'assets/svgs/batchNode.svg';
import DelayNodeImage from 'assets/svgs/delayNode.svg';
import EmailNodeImage from 'assets/svgs/emailNode.svg';
import InboxNodeImage from 'assets/svgs/inboxNode.svg';
import MobilepushImage from 'assets/svgs/mobilepushNode.svg';
import MSteamsImage from 'assets/svgs/msteamsNode.svg';
import MultiChannelImage from 'assets/svgs/multiChannelNode.svg';
import SlackImage from 'assets/svgs/slackNode.svg';
import SmartImage from 'assets/svgs/smartNode.svg';
import WebhookToolTipIcon from 'assets/svgs/webhookTooTipIcon.svg';
import FetchToolTipIcon from 'assets/svgs/fetchTooltipIcon.svg';
import BranchToolTipIcon from 'assets/svgs/branchTooltipIcon.svg';
import DataTransformationTooltipIcon from 'assets/svgs/dataTransformationTooltipIcon.svg';
import TimeWindowTooltipIcon from 'assets/svgs/timeWindowTooltipIcon.svg';
import DigestTooltipIcon from 'assets/svgs/digestTooltipIcon.svg';
import SMSNode from 'assets/svgs/smsNode.svg';
import { ReactComponent as BranchNodeIcon } from 'assets/svgs/branchNodeIcon.svg';
import { ReactComponent as DataTransformationIcon } from 'assets/svgs/dataTransformationIcon.svg';
import { ReactComponent as TimeWindowIcon } from 'assets/svgs/timeWindowIcon.svg';
import WaitUntilImage from 'assets/svgs/waitUntilNode.svg';
import WebpushImage from 'assets/svgs/webpushNode.svg';
import WhatsappImage from 'assets/svgs/whatsappNode.svg';
import { ReactComponent as WebhookNodeIcon } from 'assets/svgs/webhookNodeIcon.svg';
import { ReactComponent as FetchNodeIcon } from 'assets/svgs/fetchNodeIcon.svg';
import { ReactComponent as DigestNodeIcon } from 'assets/svgs/digestNodeIcon.svg';
import { InnerTabBar } from '../TabBar';
import { FlowContext } from '../WorkflowDetails';
import { SEND_NODES } from '../CustomNodes';

const TOOLTIP_CONTENT = {
  delay: { image: DelayNodeImage, height: 81, width: 100 },
  batch: { image: BatchNodeImage, height: 101, width: 204 },
  digest: { image: DigestTooltipIcon, height: 84, width: 120 },
  multibranch_wait_until: { image: WaitUntilImage, height: 81, width: 188 },
  branch_waituntil: { image: WaitUntilImage, height: 81, width: 188 },
  send_multi_channel: { image: MultiChannelImage, height: 82, width: 181 },
  send_smart_channel_routing: { image: SmartImage, height: 124, width: 100 },
  send_email: { image: EmailNodeImage, height: 84, width: 100 },
  send_sms: { image: SMSNode, height: 84, width: 100 },
  send_mobile_push: { image: MobilepushImage, height: 84, width: 100 },
  send_webpush: { image: WebpushImage, height: 84, width: 100 },
  send_inbox: { image: InboxNodeImage, height: 84, width: 100 },
  send_whatsapp: { image: WhatsappImage, height: 84, width: 100 },
  send_slack: { image: SlackImage, height: 84, width: 100 },
  send_ms_teams: { image: MSteamsImage, height: 84, width: 100 },
  httpapi_webhook: { image: WebhookToolTipIcon, height: 84, width: 100 },
  httpapi_fetch: { image: FetchToolTipIcon, height: 84, width: 100 },
  branch: { image: BranchToolTipIcon, height: 81, width: 188 },
  transform: { image: DataTransformationTooltipIcon, height: 81, width: 160 },
  timewindow: { image: TimeWindowTooltipIcon, height: 80, width: 100 },
};

function NodeTile({ node }) {
  const tooltipImage = TOOLTIP_CONTENT[node.id]['image'];
  const tooltipHeight = TOOLTIP_CONTENT[node.id]['height'];
  const tooltipWidth = TOOLTIP_CONTENT[node.id]['width'];
  const Icon = node.icon;
  const { setDragStart } = useContext(FlowContext);

  return (
    <>
      <div
        className="border rounded-md px-2 py-2 mb-4 hover:bg-[#F7F8FA] cursor-pointer flex justify-between"
        draggable
        onDragStart={ev => {
          setDragStart(true);
          ev.dataTransfer.setData('nodeType', node.id);
          ev.dataTransfer.setData('dragAction', 'create');
        }}
        onDragEnd={() => {
          setDragStart(false);
        }}
        data-tooltip-id="node-description"
        data-tooltip-html={`<div style="display : flex; flex-direction: column; align-items: center; justify-items: center;"><img src=${tooltipImage} alt="node_image" height=${tooltipHeight} width=${tooltipWidth}></img><p style="font-size:12px; line-height:16px; margin-top: 10px; margin-bottom: 4px;">${node.formHelptext}</p></div>`}
      >
        <div className="flex gap-2 items-center">
          <Icon height={18} width={18} />
          <p className="text-[#1E293B] text-sm font-medium">{node.name}</p>
        </div>
        <DragIcon />
      </div>
    </>
  );
}

const FunctionNodes = [
  {
    name: 'Delay',
    id: 'delay',
    icon: DelayNodeIcon,
    formHelptext: 'Pause workflow execution for a specified amount of time',
  },
  {
    name: 'Batch',
    id: 'batch',
    icon: BatchingNodeIcon,
    formHelptext:
      'Batch events for a duration to send consolidated notifications',
  },
  {
    name: 'Digest',
    id: 'digest',
    icon: DigestNodeIcon,
    formHelptext:
      'Batch triggers and send a summary at recurring digest schedule.',
  },
  {
    name: 'Webhook',
    id: 'httpapi_webhook',
    icon: WebhookNodeIcon,
    formHelptext: 'Make HTTP request to an endpoint',
  },
  {
    name: 'Fetch',
    id: 'httpapi_fetch',
    icon: FetchNodeIcon,
    formHelptext: 'Fetch data from an API endpoint',
  },
  {
    name: 'Wait Until',
    id: 'branch_waituntil',
    icon: WaitUntilNodeIcon,
    formHelptext:
      'Wait until a condition is met or the maximum time is reached',
  },
  {
    name: 'Branch',
    id: 'branch',
    icon: BranchNodeIcon,
    formHelptext: 'Follow the first branch that satisfies the condition',
  },
  {
    name: 'Data Transform',
    id: 'transform',
    icon: DataTransformationIcon,
    formHelptext: 'Generate or override variable using input',
  },
  {
    name: 'Time Window',
    id: 'timewindow',
    icon: TimeWindowIcon,
    formHelptext:
      'Notify recipients in their timezone and preferred duration. This will introduce a wait until the time window starts.',
  },
];

const DeliveryNodes = Object.values(SEND_NODES);

export default function WorkflowNodeList() {
  const { editMode, setSelectedForm, selectedForm } = useContext(FlowContext);

  return (
    <div className="overflow-scroll h-full">
      {editMode && (
        <InnerTabBar
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
        />
      )}
      <div className="px-4 py-4">
        <p className="text-gray-900 font-medium text-lg mb-2">Nodes</p>
        <p className="text-[#475569] text-sm mb-8">
          Nodes are workflow steps defining workflow actions.
        </p>
        <div className="mb-8">
          <p className="text-[#475569] text-xs font-semibold mb-2">Functions</p>
          {FunctionNodes.map(node => {
            return <NodeTile node={node} key={node.id} />;
          })}
        </div>
        <div>
          <p className="text-[#475569] text-xs font-semibold mb-2">Delivery</p>
          {DeliveryNodes.map(node => {
            return <NodeTile node={node} key={node.id} />;
          })}
        </div>
      </div>
    </div>
  );
}

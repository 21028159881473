import { useState, useEffect } from 'react';
import { useParams, NavLink, useLocation, useHistory } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { ChevronLeftIcon } from 'lucide-react';
import {
  useNewWorkflowVersionDetails,
  useWorkflowExecutionLogsDetail,
} from 'apis';
import { ScrollArea } from 'new-components';
import { StatusBadge } from 'custom-components';
import { Spinner, TimeAgo } from 'components';
import LocalDB from 'utils/LocalDB';
import urlUtils from 'utils/urlUtils';
import { Flow, FlowContext } from '../WorkflowDetails';
import { convertTreeToObj, convertTreeToGraph, layoutELK } from '../utils';
import ObservabilityLogs from './ObservabilityLogs';
import CopyButton from './CopyButton';
var _ = require('lodash');

export const DEFAULT_NODE_WIDTH = 250;
export const DEFAULT_NODE_HEIGHT = 75;

export default function ExecutionDetails() {
  const history = useHistory();
  const { execution_id } = useParams();
  const { state } = useLocation();

  const [nodes, setNodes] = useState();
  const [edges, setEdges] = useState();
  const [nodesObj, setNodesObj] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [selectedForm, setSelectedForm] = useState('workflow_settings');
  const [loading, setLoading] = useState();

  const { data: wfRunData, isFetching: wfRunDataLoading } =
    useWorkflowExecutionLogsDetail({
      execution_id,
      enabled: !!execution_id,
    });

  const workflow_exec_logs = wfRunData?.results;
  const wf_slug =
    workflow_exec_logs?.[0]?.['other_info_map']?.['workflow_slug'];
  const version_id =
    workflow_exec_logs?.[0]?.['other_info_map']?.['workflow_version'];

  const { data: wfVersionDetails, isFetching: wfVersionDetailsLoading } =
    useNewWorkflowVersionDetails(wf_slug, version_id, {
      enabled: !!(version_id && wf_slug),
    });

  async function getLayout() {
    if (!wfVersionDetails) return [];

    const data = wfVersionDetails;
    let finalData = [{ ...data, node_type: 'trigger' }, ...data.tree.nodes];

    let nodesObject = convertTreeToObj(finalData); // convert tree to object for get operations

    const [nodesData, edgesData] = convertTreeToGraph(finalData, nodesObject); // convert tree to nodes and edges

    const leafNode = nodesData[nodesData.length - 1]; // adding leaf node exit at end
    const exitNodeObj = {
      id: 'exit',
      type: 'exit',
      parent_node_id: leafNode.id,
    };

    // if parent node is multibranch make parent node as join node
    const parentNodeData = nodesObj?.[leafNode.id];
    if (
      parentNodeData?.type === 'multibranch_wait_until' ||
      parentNodeData?.type === 'branch_waituntil'
    ) {
      exitNodeObj.parent_node_id = `join_node_${parentNodeData.id}`;
    }
    nodesObject[exitNodeObj.id] = exitNodeObj;
    nodesData.push(exitNodeObj);
    edgesData.push({
      id: `${leafNode.id}-exit`,
      source: leafNode.id,
      target: 'exit',
    });

    const [fnodes, fedges] = await layoutELK(nodesData, edgesData); // layout of graph

    // set final data in state
    setNodes(fnodes);
    setEdges(fedges);
    setNodesObj(nodesObject);
  }

  useEffect(() => {
    getLayout();
  }, [wfVersionDetails]);

  if (loading || !wfVersionDetails || !nodes || !edges || !nodesObj) {
    return <Spinner />;
  }

  let logs;
  if (!_.isEmpty(workflow_exec_logs)) {
    logs = new LocalDB(workflow_exec_logs, true);
  }
  window.logs = logs;

  const distinct_id = logs.findOne()?.['distinct_id'];
  const tenant_id = logs.findOne()?.['tenant'];

  const all_erros = logs.find({ 'other_info_map.log_level': 'error' });
  const all_warnings = logs.find({ 'other_info_map.log_level': 'warning' });
  let workflow_status = 'completed';

  if (all_warnings.count() > 0) {
    workflow_status = 'partial_failure';
  }
  if (all_erros.count() > 0) {
    workflow_status = 'failed';
  }
  if (logs.find({ logtype: 'workflow_end' }).count() === 0) {
    workflow_status = 'processing';
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <ReactFlowProvider>
          {state?.from && (
            <div
              className="ml-4 mt-4 flex items-center cursor-pointer"
              onClick={() => {
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="h-5 w-5" />
              <p>Back</p>
            </div>
          )}

          <div className="grid grid-cols-2 mx-6 mb-4 text-sm mt-4">
            <div className="flex space-x-2">
              <p className="font-medium">Workflow Slug:</p>
              <NavLink
                to={urlUtils.makeURL(`/workflows/${wf_slug}`)}
                className="text-primary hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {wf_slug}
              </NavLink>
            </div>
            <div className="flex space-x-2">
              <p className="font-medium">Workflow Status:</p>
              <div>
                <StatusBadge status={workflow_status} />
              </div>
            </div>
            <div className="flex space-x-2 mt-2">
              <p className="font-medium">Started At:</p>
              <div>
                <TimeAgo
                  dateInput={
                    logs.findOne({ logtype: 'workflow_start' })?.['time']
                  }
                />
              </div>
            </div>
            <div className="flex space-x-2 mt-2">
              <p className="font-medium">Distinct ID:</p>
              <div>
                <NavLink
                  to={urlUtils.makeURL(`/subscribers/${distinct_id}`)}
                  className="text-primary hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {distinct_id}
                </NavLink>
              </div>
            </div>
            <div className="flex space-x-2 mt-2">
              <p className="font-medium">Tenant ID:</p>
              <div>
                <NavLink
                  to={urlUtils.makeURL(`/brands/${tenant_id}`)}
                  className="text-primary hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tenant_id}
                </NavLink>
              </div>
            </div>
            {logs.findOne()?.['idempotency_key'] !== '' && (
              <div className="flex space-x-2 items-center mt-2">
                <p className="font-medium">Idempotency Key:</p>
                <div className="flex pr-16 items-center">
                  <p className="pr-2">{logs.findOne()?.['idempotency_key']}</p>
                  <CopyButton
                    variant="ghost"
                    textToCopy={logs.findOne()?.['idempotency_key']}
                    className="h-5 w-5"
                  />
                </div>
              </div>
            )}
            <div className="flex space-x-2 items-center mt-2">
              <p className="font-medium">Execution ID:</p>
              <div className="flex pr-16 items-center">
                <p className="pr-2">{logs.findOne()?.['exec_id']}</p>
                <CopyButton
                  variant="ghost"
                  textToCopy={logs.findOne()?.['exec_id']}
                  className="h-5 w-5"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-grow overflow-hidden">
            <FlowContext.Provider
              value={{
                version_id, // wf version
                slug: wf_slug, // wf slug
                editMode: false,
                selectedNode, // selected node data
                setSelectedNode,
                selectedForm,
                setSelectedForm,
                setLoading,
                nodesObj,
                isObservability: true,
                wfExecutionData: logs,
                setEdges,
              }}
            >
              <div className="w-5/12 h-full border-t">
                <Flow
                  nodes={nodes}
                  edges={edges}
                  onPaneClick={e => {
                    e.stopPropagation();
                    setSelectedNode({});
                  }}
                  onNodeClick={(e, nodeData) => {
                    e.stopPropagation();
                    if (nodeData.type === 'condition_node') {
                      return;
                    } else if (nodeData.type === 'exit') {
                      setSelectedNode({ node_type: 'exit' });
                    } else {
                      setSelectedForm(); // needed to reset form
                      setSelectedNode(nodeData.data);
                    }
                  }}
                  panOnDrag={true}
                  panOnScroll={true}
                />
              </div>
              <div className="w-7/12 border-l">
                <ScrollArea className="h-full border-t">
                  <ObservabilityLogs selectedNode={selectedNode} logs={logs} />
                </ScrollArea>
              </div>
            </FlowContext.Provider>
          </div>
        </ReactFlowProvider>
      </div>
    </>
  );
}

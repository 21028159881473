import { useQuery } from 'react-query';
import {
  loggedInAPI,
  buildAPIURL,
  useEnvState,
  envStore,
} from 'stores/AuthStore';
import { useDebounce } from 'ahooks';

const getLogs = async (
  page = 0,
  time = 30,
  envState,
  filter = 'all',
  filterValue = '',
  filterKey,
  customStartDate,
  customEndDate
) => {
  let offset = page * 20;

  let url = `/v2/${envStore.currentEnv.get()}/wf_exec_log/?limit=20&offset=${offset}`;

  if (time === 'custom' && customStartDate && customEndDate) {
    url = `${url}&start_ts=${customStartDate}&end_ts=${customEndDate}`;
  } else {
    url = `${url}&last_n_minutes=${time}`;
  }
  if (filter !== 'all') {
    url = `${url}&status=${filter}`;
  }
  if (filterKey && filterValue) {
    url = `${url}&${filterKey}=${encodeURIComponent(filterValue)}`;
  }
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useLogsAPI({
  page = 0,
  enabled = true,
  time = 30,
  filter = 'all',
  filterValue = '',
  filterKey,
  automaticRefetch,
  refetchInterval = 15000,
  customStartDate,
  customEndDate,
}) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/logs`,
      page,
      time,
      filter,
      filterValue,
      customStartDate,
    ],
    () =>
      getLogs(
        page,
        time,
        envState.currentEnv.get(),
        filter,
        filterValue,
        filterKey,
        customStartDate,
        customEndDate
      ),
    {
      keepPreviousData: true,
      refetchInterval: automaticRefetch && refetchInterval,
      enabled: enabled,
    }
  );
}

const getBroadcastLogs = async (
  page = 0,
  time = 30,
  filter = 'all',
  filterValue = '',
  filterKey,
  customStartDate,
  customEndDate
) => {
  let offset = page * 20;
  let url = buildAPIURL(`/broadcast_exec_log/?limit=20&offset=${offset}`);
  if (customStartDate && customEndDate) {
    url = `${url}&start_ts=${customStartDate}&end_ts=${customEndDate}`;
  } else {
    url = `${url}&last_n_minutes=${time}`;
  }
  if (filter !== 'all') {
    url = `${url}&status=${filter}`;
  }
  if (filterKey && filterValue) {
    url = `${url}&${filterKey}=${encodeURIComponent(filterValue)}`;
  }
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useBroadcastAPI({
  page = 0,
  enabled = true,
  time = 30,
  filter = 'all',
  filterValue = '',
  filterKey,
  automaticRefetch,
  refetchInterval = 15000,
  customStartDate,
  customEndDate,
}) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/broadcastLogs`,
      page,
      time,
      filter,
      filterValue,
      customStartDate,
    ],
    () =>
      getBroadcastLogs(
        page,
        time,
        filter,
        filterValue,
        filterKey,
        customStartDate,
        customEndDate
      ),
    {
      keepPreviousData: true,
      refetchInterval: automaticRefetch && refetchInterval,
      enabled: enabled,
    }
  );
}

const getAPILogs = async queryString => {
  let url = buildAPIURL(`/requestlog/?${queryString}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useAPILogs({
  queryString,
  mandatoryFilters,
  automaticRefetchTime,
  enabled = true,
}) {
  const envState = useEnvState();
  const queryMap = new URLSearchParams(queryString);
  const queryEntries = queryMap.entries();
  const pageNo = Number(queryMap.get('page')) || 0;
  const newQs = new URLSearchParams();
  const multipleSupport = [
    'tenant',
    'distinct_id',
    'status',
    'api_type',
    'api_name',
    'error_severity',
  ];

  if (mandatoryFilters) {
    for (let key in mandatoryFilters) {
      const value = mandatoryFilters[key];
      if (multipleSupport.includes(key)) {
        if (Array.isArray(value)) {
          value.forEach(item => {
            newQs.append(`${key}[]`, item);
          });
        } else {
          newQs.append(`${key}[]`, value);
        }
      } else {
        newQs.append(`${key}`, value);
      }
    }
  }

  if (queryMap.has('api_type')) {
    newQs.delete('api_type[]');
  }

  for (let item of queryEntries) {
    if (multipleSupport.includes(item[0])) {
      newQs.append(`${item[0]}[]`, item[1]);
    } else {
      newQs.append(`${item[0]}`, item[1]);
    }
  }

  newQs.append('limit', 20);
  newQs.append('offset', pageNo * 20 || 0);

  const apiQueryString = newQs.toString();

  return useQuery(
    [
      `${envState.currentEnv.get()}/requestlogs`,
      apiQueryString,
      automaticRefetchTime,
    ],
    () => getAPILogs(apiQueryString),
    {
      keepPreviousData: true,
      refetchInterval: automaticRefetchTime,
      enabled: enabled,
    }
  );
}

const getAPILogsChart = async queryString => {
  let url = buildAPIURL(`/requestlog/stats/?${queryString}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useAPILogsChart({
  queryString,
  mandatoryFilters,
  automaticRefetchTime,
  enabled,
}) {
  const envState = useEnvState();
  const queryMap = new URLSearchParams(queryString);
  const queryEntries = queryMap.entries();
  const newQs = new URLSearchParams();
  const multipleSupport = [
    'tenant',
    'distinct_id',
    'status',
    'api_type',
    'api_name',
    'error_severity',
  ];

  if (mandatoryFilters) {
    for (let key in mandatoryFilters) {
      const value = mandatoryFilters[key];
      if (multipleSupport.includes(key)) {
        if (Array.isArray(value)) {
          value.forEach(item => {
            newQs.append(`${key}[]`, item);
          });
        } else {
          newQs.append(`${key}[]`, value);
        }
      } else {
        newQs.append(`${key}`, value);
      }
    }
  }

  if (queryMap.has('api_type')) {
    newQs.delete('api_type[]');
  }

  for (let item of queryEntries) {
    if (multipleSupport.includes(item[0])) {
      newQs.append(`${item[0]}[]`, item[1]);
    } else {
      newQs.append(`${item[0]}`, item[1]);
    }
  }

  queryMap.delete('page');
  const apiQueryString = newQs.toString();

  return useQuery(
    [
      `${envState.currentEnv.get()}/requestlogs-chartdata`,
      apiQueryString,
      automaticRefetchTime,
    ],
    () => getAPILogsChart(apiQueryString),
    {
      keepPreviousData: true,
      refetchInterval: automaticRefetchTime,
      enabled: enabled,
    }
  );
}

const getBrandLogs = async brandValue => {
  const url = buildAPIURL(`/client_tenant_short/?search=${brandValue}`);
  const { data } = await loggedInAPI.get(url);
  return data?.results;
};

const useBrandLogs = brandValue => {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/client_tenant_short/`, brandValue],
    () => getBrandLogs(brandValue)
  );
};

const getRequestLogsName = async name => {
  if (!name) return;
  const url = buildAPIURL(`/search/logentity/?search=${name}`);
  const { data } = await loggedInAPI.get(url);
  return data?.results;
};

const useRequestLogsName = (name, { enabled }) => {
  const envState = useEnvState();
  const debouncedName = useDebounce(name, 100);

  return useQuery(
    [`${envState.currentEnv.get()}/search/logentity/`, debouncedName],
    () => getRequestLogsName(debouncedName),
    { enabled }
  );
};

export {
  useLogsAPI,
  useBrandLogs,
  useBroadcastAPI,
  useAPILogs,
  useAPILogsChart,
  useRequestLogsName,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Button({
  title,
  type,
  className,
  as = 'submit',
  ...props
}) {
  if (type === 'primary') {
    return (
      <button
        type={as}
        {...props}
        className={classNames(
          'disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-[#2E70E8] hover:bg-[#184D99] focus:outline-none',
          className
        )}
      >
        {props.children}
      </button>
    );
  }
  if (type === 'secondary') {
    return (
      <button
        type={as}
        {...props}
        className={classNames(
          className,
          'disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded text-[#2E70E8] bg-indigo-100 hover:bg-[#F0F6FF] focus:outline-none'
        )}
      >
        {props.children}
      </button>
    );
  }
  return (
    <button
      type={as}
      {...props}
      className={classNames(
        className,
        'disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none'
      )}
    >
      {props.children}
    </button>
  );
}

import { Avatar, AvatarFallback, AvatarImage } from 'new-components';
const md5 = require('md5');

const getColorAndBackground = ({ namemd5 }) => {
  const matches = namemd5.match(/.{2}/g);
  const [red, green, blue] = matches.map(hex => parseInt(hex, 16));
  // Formula from https://www.w3.org/TR/AERT/#color-contrast
  const luminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255;
  const color = luminance > 0.6 ? '#222' : '#fff';

  return {
    background: `rgb(${[red, green, blue]})`,
    color,
  };
};

const getInitials = ({ name }) => {
  name = name.trim();
  if (name.length <= 3) return name;
  return name
    .split(/\s+/)
    .map(w => [...w][0])
    .slice(0, 2)
    .join('');
};

export default function Gravatar({
  email = '',
  name = '',
  size = 'w-6 h-6',
  picture = null,
  ...rest
}) {
  const namemd5 = md5(name);
  let emailMd5 = md5(email);
  const colors = getColorAndBackground({ namemd5 });
  const initals = getInitials({ name });
  return (
    <div className="inline-flex items-center justify-center uppercase">
      <Avatar className={size}>
        <AvatarImage src={picture} alt={name} />
        <AvatarFallback
          style={{
            backgroundColor: `${colors.background}`,
            color: `${colors.color}`,
            fontSize: '10px',
          }}
        >
          {initals}
        </AvatarFallback>
      </Avatar>
    </div>
  );
}

import { useState, useMemo, useEffect, useRef } from 'react';
import getCaretCoordinates from 'textarea-caret';
import { Input, Label, Textarea } from 'new-components';
import { cn, compileHandlebar } from 'utils';
import { cloneDeep, isEmpty } from 'lodash';

const DATA_TYPE_SECTIONS = [
  { id: 'data', label: 'Input Payload' },
  { id: '$actor', label: 'Actor' },
  { id: '$recipient', label: 'Recipient' },
  { id: '$brand', label: 'Brand' },
  { id: 'preference', label: 'Preference' },
];

const CustomHelpers = {
  default: "{{default key 'default_value'}}",
  compare:
    "{{#compare key '==' 'value'}}true_block{{else}}false_block{{/compare}}",
  if: '{{#if key}}true_block{{else}}false_block{{/if}}',
  each: '{{#each array_object}} {{variable_key}} {{/each}}',
  'datetime-format': '{{datetime-format variable "format string" "timezone"}}',
  add: '{{add number1 number2}}',
  subtract: '{{subtract number1 number2}}',
  multiply: '{{multiply number1 number2}}',
  divide: '{{divide number1 number2}}',
  round: '{{round float}}',
  mod: '{{mod dividend divisor}}',
  unique: '{{unique variable "key" }}',
  itemAt: '{{itemAt variable index }}',
  join: '{{join variable "separator"}}',
  length: '{{length variable}}',
  jsonStringify: '{{jsonStringify json}}',
  lowercase: '{{lowercase "string"}}',
  uppercase: '{{uppercase "string"}}',
  capitalize: '{{capitalize "string"}}',
};

export default function SuggestionInput({
  variables,
  label,
  mandatory = true,
  onChange,
  value,
  error,
  as,
  validateOnBlur = true,
  ...rest
}) {
  const inputRef = useRef();
  const suggestionRef = useRef(false); // need to access showSuggestions state inside settimeout

  const [showSuggestions, setShowSuggestions] = useState(false); // show suggestions dialog box
  const [inputValue, setInputValue] = useState('');
  const [currentCaretPos, setCurrentCaretPos] = useState(); // current index position of caret in input field
  const [carotCoordinates, setCaretCoordinates] = useState(); // current coordinates of caret in input
  const [handlebarWarning, setHandlebarWarning] = useState('');

  const handleShowSuggestion = event => {
    const inputValue = event.target.value;
    const caretIndex = event.target.selectionEnd;

    setInputValue(event.target.value);

    // set coordinates wrt input field
    let caretCoordinates = getCaretCoordinates(event.target, caretIndex);
    setCurrentCaretPos(caretIndex);

    const xOverflow = event.target.offsetWidth < event.target.scrollWidth;
    const yOverflow = event.target.offsetHeight < event.target.scrollHeight;

    if (xOverflow && caretCoordinates.left > event.target.offsetWidth) {
      caretCoordinates.left = event.target.offsetWidth;
    } else if (yOverflow && caretCoordinates.top > event.target.offsetHeight) {
      caretCoordinates.top = event.target.offsetHeight;
    }
    setCaretCoordinates(caretCoordinates);

    // handle show/hide suggestions
    const strippedValue = inputValue.slice(0, caretIndex);

    const startBracketIndex = strippedValue.lastIndexOf('{{');
    const endBracketIndex = strippedValue.lastIndexOf('}}');

    const leftCharacter = strippedValue[caretIndex - 1];
    const isLeftCharBracket = leftCharacter === '}';

    if (leftCharacter && isLeftCharBracket) {
      setShowSuggestions(false);
    } else {
      if (startBracketIndex > endBracketIndex) {
        setShowSuggestions(true);
      } else {
        setShowSuggestions(false);
      }
    }
  };

  useEffect(() => {
    setInputValue(value);
    setHandlebarWarning('');
  }, [value]);

  useEffect(() => {
    suggestionRef.current = showSuggestions;
  }, [showSuggestions]);

  const InputComponent = as === 'textarea' ? Textarea : Input;
  return (
    <>
      {label && (
        <Label>
          {label}
          {mandatory && <span className="text-destructive">*</span>}
        </Label>
      )}
      <div
        className="relative mt-1"
        onBlur={e => {
          setCurrentCaretPos(e.target.selectionEnd);
          if (
            e.currentTarget &&
            e.relatedTarget &&
            e.currentTarget.contains(e.relatedTarget)
          ) {
            // pass
          } else {
            setShowSuggestions(false);
          }
        }}
      >
        <InputComponent
          value={inputValue}
          ref={inputRef}
          onInput={handleShowSuggestion}
          onClick={handleShowSuggestion}
          onKeyUp={handleShowSuggestion}
          onChange={e => {
            onChange(e.target.value);
          }}
          onFocus={() => {
            setHandlebarWarning('');
          }}
          onBlur={() => {
            setTimeout(() => {
              if (!validateOnBlur || suggestionRef.current) return;

              const error = compileHandlebar({
                value: inputValue,
                compilerOptions: { strict: true },
                variables,
              });
              if (error) {
                setHandlebarWarning(error);
              }
            }, 0);
          }}
          {...rest}
        />
        {(error || handlebarWarning) && (
          <p className="text-sm mt-1 text-destructive">
            {error || handlebarWarning}
          </p>
        )}
        {showSuggestions && (
          <Suggestions
            inputValue={inputValue}
            setInputValue={setInputValue}
            variables={variables}
            currentCaretPos={currentCaretPos}
            carotCoordinates={carotCoordinates}
            setShowSuggestions={setShowSuggestions}
            inputRef={inputRef}
            onChange={onChange}
          />
        )}
      </div>
    </>
  );
}

function Suggestions({
  inputValue,
  setInputValue,
  variables,
  currentCaretPos,
  carotCoordinates,
  setShowSuggestions,
  inputRef,
  onChange,
}) {
  const [selectedSection, setSelectedSection] = useState(
    DATA_TYPE_SECTIONS[0].id
  );
  const [optionsList, setOptionsList] = useState();

  // filter sections based on input
  useEffect(() => {
    for (let item of DATA_TYPE_SECTIONS) {
      const str1 = inputValue.substring(0, currentCaretPos);
      const LI = str1.lastIndexOf('{{');
      const str2 = inputValue.substring(LI + 2, currentCaretPos);
      const idLength = item.id.length;

      if (
        str2 &&
        item.id.startsWith('$') &&
        (str2.length <= idLength
          ? item.id.startsWith(str2)
          : str2.startsWith(item.id))
      ) {
        setSelectedSection(item.id);
        return;
      } else if (
        str2 &&
        ('$embedded_preference_url'.startsWith(str2) ||
          '$hosted_preference_url'.startsWith(str2))
      ) {
        setSelectedSection('preference');
      } else {
        setSelectedSection('data');
      }
    }
  }, [inputValue, currentCaretPos]);

  // all options list of selected section
  useEffect(() => {
    setOptionsList(); // needed to reset scroll to solve when other section is selected
    const selectedOptionsData = getOptionsList({ variables, selectedSection });
    setTimeout(() => setOptionsList(selectedOptionsData), 0);
  }, [selectedSection]);

  // filter options based on input
  const filteredOptionsList = useMemo(() => {
    if (selectedSection === 'custom_helpers') return optionsList;

    const str1 = inputValue.substring(0, currentCaretPos);
    const LI = str1.lastIndexOf('{{');
    const inputVariable = inputValue.substring(LI + 2, currentCaretPos);
    const clonedOptions = cloneDeep(optionsList);
    if (!optionsList) return {};
    Object.keys(optionsList)?.map(item => {
      if (inputVariable && !item.startsWith(inputVariable)) {
        delete clonedOptions[item];
      }
    });
    return clonedOptions;
  }, [optionsList, inputValue, currentCaretPos, selectedSection]);

  const noOptions = isEmpty(filteredOptionsList);
  return (
    <div
      className="absolute z-50 border rounded bg-muted flex-row flex shadow"
      tabIndex="-1"
      style={{
        left: carotCoordinates.left,
        top: carotCoordinates.top + 15,
      }}
    >
      <div className="border-r py-1">
        <div className="border-b pb-1 w-[140px]">
          {DATA_TYPE_SECTIONS.map(option => {
            return (
              <div
                key={option.id}
                className={cn(
                  'px-3 mx-1 py-2 my-0.5 text-sm hover:bg-[#EDF1F5] hover:rounded cursor-pointer',
                  selectedSection === option.id && 'bg-[#EDF1F5] rounded'
                )}
                onClick={() => {
                  setSelectedSection(option.id);
                }}
              >
                <p>{option.label}</p>
              </div>
            );
          })}
        </div>

        <div
          className={cn(
            'px-3 mx-1 py-2 my-1 text-sm hover:bg-[#EDF1F5] hover:rounded cursor-pointer',
            selectedSection === 'custom_helpers' && 'bg-[#EDF1F5] rounded'
          )}
          onClick={() => {
            setSelectedSection('custom_helpers');
          }}
        >
          <p>Custom helpers</p>
        </div>
      </div>
      <div className="py-1 max-h-[280px] min-w-[400px] overflow-scroll bg-background rounded-r">
        {noOptions ? (
          <p className="px-3 mx-1 py-2 my-0.5 text-muted-foreground text-sm text-center mt-4">
            No variables found matching the search
          </p>
        ) : (
          <>
            {Object.keys(filteredOptionsList).map(option => {
              const isCustomHelper = selectedSection === 'custom_helpers';
              const label = isCustomHelper ? option : getLabel(option);
              const subLabel = isCustomHelper
                ? CustomHelpers[option]
                : `{{${option}}}`;

              if (!option || !label) return null;

              return (
                <div
                  key={option}
                  className={cn(
                    'px-3 mx-1 py-2 my-0.5 text-sm hover:bg-[#EDF1F5] hover:rounded cursor-pointer'
                  )}
                  onClick={() => {
                    handleSelectOption({
                      selectedValue: subLabel,
                      inputValue,
                      setInputValue,
                      currentCaretPos,
                      setShowSuggestions,
                      inputRef,
                      onChange,
                    });
                  }}
                >
                  <p className="text-sm">{label}</p>
                  <p className="text-xs text-muted-foreground mt-0.5">
                    {subLabel}
                  </p>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

function replaceBetween(input, start, end, what) {
  return input.substring(0, start) + what + input.substring(end);
}

function handleSelectOption({
  selectedValue,
  inputValue,
  setInputValue,
  currentCaretPos,
  setShowSuggestions,
  inputRef,
  onChange,
}) {
  const str1 = inputValue.substring(0, currentCaretPos);
  const LI = str1.lastIndexOf('{{');
  const str2 = inputValue.substring(LI);
  const FI = str2.indexOf('}}');

  if (FI < 0) {
    // asdfasdf {{asdfasdfsdf|
    const result = replaceBetween(
      inputValue,
      LI,
      currentCaretPos,
      selectedValue
    );
    setInputValue(result);
    onChange(result);
  } else {
    const str3 = str2.substring(0, FI);
    const LI1 = str3.lastIndexOf('{{');

    if (LI1 > 0) {
      // asdfsdf {{asd|fasdf {{asdfasdf}} asdfasdf
      const result = replaceBetween(
        inputValue,
        LI,
        currentCaretPos,
        selectedValue
      );
      setInputValue(result);
      onChange(result);
    } else {
      // asdfsdf {{asdfasdf}} {{asd|fasdf}} asdfasdf
      const result = replaceBetween(inputValue, LI, LI + FI + 2, selectedValue);
      setInputValue(result);
      onChange(result);
    }
  }
  const afterSelectCaretPosition = LI + selectedValue.length;
  setShowSuggestions(false);

  setTimeout(() => {
    // settimeout needed to set cursor properly
    setCaretPosition(inputRef.current, afterSelectCaretPosition);
  }, 0);
}

function getOptionsList({ variables, selectedSection }) {
  if (selectedSection === 'custom_helpers') {
    return CustomHelpers;
  } else if (selectedSection === 'preference') {
    return {
      $embedded_preference_url: '{{$embedded_preference_url}}',
      $hosted_preference_url: '{{$hosted_preference_url}}',
    };
  } else if (selectedSection === 'data') {
    if (variables.$batched_events_count) {
      return flatten({
        $batched_events: variables.$batched_events,
        $batched_events_count: variables.$batched_events_count,
      });
    } else {
      const clonedData = cloneDeep(variables);
      const keys = Object.keys(clonedData);

      for (let key of keys) {
        if (key.startsWith('$')) {
          delete clonedData[key];
        }
      }
      return flatten(clonedData);
    }
  } else {
    const selectedVariables = variables?.[selectedSection];
    if (isEmpty(selectedVariables)) return {};

    return flatten({ [selectedSection]: selectedVariables });
  }
}

function validObjectKey(str) {
  return /^[A-Za-z0-9_$]*$/.test(str);
}

function flatten(data) {
  var result = {};

  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const length = cur.length;
      for (let i = 0; i < length; i++) {
        recurse(cur[i], `${prop}.[0]`);
      }
      if (length === 0) {
        result[prop] = [];
      }
    } else {
      let isEmpty = true;

      for (let p in cur) {
        isEmpty = false;
        let newKey = p;
        if (prop) {
          newKey = validObjectKey(newKey) ? `${prop}.${p}` : `${prop}.[${p}]`;
        }
        recurse(cur[p], newKey);
      }

      if (isEmpty && prop) {
        result[prop] = {};
      }
    }
  }

  let modifiedData = {};
  for (let key in data) {
    if (validObjectKey(key)) {
      modifiedData[key] = data[key];
    } else {
      modifiedData[`[${key}]`] = data[key];
    }
  }

  recurse(modifiedData, '');
  return result;
}

function getLabel(value) {
  if (!value) return null;

  // handle remove [] in string literals
  let splitDotted = value.split('.');
  splitDotted.forEach((item, index) => {
    if (item.startsWith('[') && item !== '[0]') {
      const actualString = item.substring(1, item.length - 1);
      splitDotted[index] = actualString;
    }
  });

  let formattedValue = splitDotted.join('.');
  formattedValue = formattedValue.split('.[0]').join('[]'); // format arrays
  formattedValue = formattedValue.split('.').join(' > '); // format objects

  return formattedValue;
}

function setCaretPosition(el, caretPos) {
  var elem = el;

  if (elem != null) {
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else {
      if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(caretPos, caretPos);
      } else elem.focus();
    }
  }
}

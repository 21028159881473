import { useContext, useState, useMemo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { FlowContext } from '../WorkflowDetails';
import { SEND_NODES } from '../CustomNodes';
import { ListBox, NodeFormHeader } from './Common';
import {
  useUpdateNodeProperties,
  useSearchTemplatesAPI,
  useUniqueEventsAPI,
  useTemplateDetailAPI,
} from 'apis';
import CreatableSelect from 'react-select/creatable';
import { ValidateEventName, MISSING_FIELD_ERROR } from 'utils';
import { Button, toast } from 'components';
import * as Yup from 'yup';
import urlUtils from 'utils/urlUtils';
import { useReactFlow } from 'reactflow';
import { SquareArrowOutUpRightIcon } from 'lucide-react';

const SUCCESS_IS_EVENT = [
  { name: 'Notification Status', value: false },
  { name: 'Custom Event', value: true },
];
const NOTIFICATION_STATUS_EVENTS = [
  { label: 'Delivered', value: 'delivered' },
  { label: 'Seen', value: 'seen' },
  { label: 'Interaction', value: 'interaction' },
];

const ChannelSendNodeValidationSchema = Yup.object({
  template: Yup.string().required(MISSING_FIELD_ERROR),
  success_is_event: Yup.boolean().required(MISSING_FIELD_ERROR),
  success: Yup.string().required(MISSING_FIELD_ERROR),
});

export default function ChannelSendNodeForm() {
  const [showFormErrors, setShowFormErrors] = useState();
  const {
    selectedNode,
    slug,
    version,
    editMode,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const nodeMetaData = SEND_NODES[selectedNode.node_type];
  const nodeProperties = selectedNode?.properties || {};
  const flowInstance = useReactFlow();
  const nodeData = flowInstance.getNode(selectedNode.id);

  const [templateSearchText, setTemplateSearchText] = useState('');
  const [selectedtemplate, setSelectedTemplate] = useState(
    nodeProperties?.template || ''
  ); // needed to make api call to get active channels for redirection on view template button as formik ref is not causing rerendering

  const updateProperties = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );
  const { status: searchTemplateStatus, data: templateSearchResults } =
    useSearchTemplatesAPI(templateSearchText, true);
  const { status: uniqueEventStatus, data: uniqueEvents } =
    useUniqueEventsAPI();
  const { data: templateDetails } = useTemplateDetailAPI(selectedtemplate);
  const channelSlug = templateDetails?.channels?.[0]?.channel?.slug;

  const formattedEventOptions = useMemo(() => {
    return (
      uniqueEvents?.map(event => ({
        label: event.event,
        value: event.event,
      })) || []
    );
  }, [uniqueEvents]);

  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;
  return (
    <div className="m-4 mt-6">
      <NodeFormHeader
        name={nodeMetaData.name}
        helptext={nodeMetaData.formHelptext}
        Icon={nodeMetaData.icon}
        docLink={nodeMetaData.docLink}
      />
      <Formik
        initialValues={{
          template: nodeProperties?.template || '',
          success_is_event: nodeProperties?.success_is_event || false,
          success: nodeProperties?.success || 'seen',
        }}
        validationSchema={ChannelSendNodeValidationSchema}
        enableReinitialize={true}
        onSubmit={async values => {
          try {
            setLoading(true);
            await updateProperties.mutateAsync({ properties: values });
            setLoading(false);
            setShowFormErrors(true);
            toast('Settings saved successfully', '', { autoClose: 1000 });
          } catch (e) {
            setLoading(false);
          }
        }}
      >
        {({ values, setFieldValue }) => {
          const disabled = !editMode;
          const successIsEvent = SUCCESS_IS_EVENT.find(
            item => item.value === values.success_is_event
          );

          const templateName = values?.template
            ? { name: values.template, slug: values.template }
            : '';
          const defaultCustomEvent = values.success
            ? { label: values.success, value: values.success }
            : null;
          const defaultNotifStatus = NOTIFICATION_STATUS_EVENTS.find(
            item => item.value === values.success
          );

          return (
            <Form className="mt-6">
              {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
                <div className="mb-6 text-xs -mt-2 text-red-500 bg-red-50 p-3 rounded">
                  <ul>
                    {Object.keys(fieldErrors)?.map((item, index) => {
                      return (
                        <li key={index}>
                          - {item}: {fieldErrors[item]}
                        </li>
                      );
                    })}
                    {nonFieldErrors?.map((item, index) => {
                      return <li key={index}>- {item}</li>;
                    })}
                  </ul>
                </div>
              )}
              <div className="pb-8">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="wf-template"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Template<span className="text-red-500">*</span>
                  </label>
                  {values?.template && (
                    <Link
                      className="text-xs text-[#2e70e8] cursor-pointer"
                      to={urlUtils.makeURL(
                        `templates/${values?.template}/${
                          channelSlug ? channelSlug : ''
                        }`
                      )}
                      target="_blank"
                    >
                      View Template
                    </Link>
                  )}
                </div>
                <Select
                  placeholder="Select active template"
                  value={templateName}
                  isDisabled={disabled}
                  styles={{
                    control: styles => {
                      return {
                        ...styles,
                        backgroundColor: disabled ? '#f9fafb' : '#fff',
                        border: '1px solid #d4d4d4',
                      };
                    },
                    placeholder: styles => ({
                      ...styles,
                      color: '#d4d4d4',
                      fontSize: 14,
                    }),
                    singleValue: styles => {
                      return { ...styles, color: '#000', fontSize: 14 };
                    },
                  }}
                  isLoading={searchTemplateStatus === 'loading'}
                  noOptionsMessage={() => 'No templates found'}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.slug}
                  onChange={(selectedItem, actionTypes) => {
                    if (actionTypes.action === 'clear') {
                      setFieldValue('template', '');
                      setSelectedTemplate('');
                    }
                    if (selectedItem) {
                      setFieldValue('template', selectedItem.slug);
                      setSelectedTemplate(selectedItem.slug);
                    }
                  }}
                  options={templateSearchResults}
                  onInputChange={inputValue => {
                    setTemplateSearchText(inputValue);
                  }}
                />
                <ErrorMessage
                  name="template"
                  render={msg => (
                    <p className="text-red-500 text-sm pt-2">{msg}</p>
                  )}
                />
                {editMode && (
                  <Link
                    className="flex items-center mt-1.5 text-primary"
                    to={urlUtils.makeURL(
                      `templates/?action=create_new_template`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="text-sm">Create new template</p>
                    <SquareArrowOutUpRightIcon className="h-3 w-3 ml-2" />
                  </Link>
                )}
              </div>
              <div className="border-t pb-6">
                <p className="text-[#1E293B] font-semibold text-base mb-4 mt-6">
                  Success metric
                </p>
                <div className="mt-2 mb-6">
                  <ListBox
                    label="Type"
                    id="success_is_event"
                    disabled={disabled}
                    value={values.success_is_event}
                    displayValue={successIsEvent?.name}
                    handleOnchange={value => {
                      if (values.success_is_event !== value) {
                        setFieldValue('success_is_event', value);
                        setFieldValue('success', '');
                      }
                    }}
                    options={SUCCESS_IS_EVENT}
                    getLabel={e => e.name}
                    getValue={e => e.value}
                  />
                </div>
                {values.success_is_event ? (
                  <div>
                    <label
                      htmlFor="event"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Event<span className="text-red-500">*</span>
                    </label>
                    <CreatableSelect
                      placeholder="Search or Type Event"
                      isLoading={uniqueEventStatus === 'loading'}
                      noOptionsMessage={() => 'No Events found'}
                      inputId="workflow-select-input"
                      defaultValue={defaultCustomEvent}
                      options={formattedEventOptions}
                      isDisabled={disabled}
                      styles={{
                        menuList: styles => {
                          return { ...styles, maxHeight: 200 };
                        },
                        placeholder: styles => ({
                          ...styles,
                          color: '#d4d4d4',
                          fontSize: 14,
                        }),
                        control: styles => {
                          return {
                            ...styles,
                            backgroundColor: disabled ? '#f9fafb' : '#fff',
                            border: '1px solid #d4d4d4',
                          };
                        },
                        singleValue: styles => {
                          return { ...styles, color: '#000', fontSize: 14 };
                        },
                      }}
                      getNewOptionData={v => {
                        if (v) {
                          const formattedEvent = ValidateEventName(v);
                          const upperCaseValue = formattedEvent?.toUpperCase();
                          return {
                            value: upperCaseValue,
                            label: upperCaseValue,
                          };
                        }
                      }}
                      onChange={selectedItem => {
                        if (selectedItem) {
                          setFieldValue('success', selectedItem.value);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="success"
                      render={msg => (
                        <p className="text-red-500 text-sm pt-2">{msg}</p>
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <label
                      htmlFor="event"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Event<span className="text-red-500">*</span>
                    </label>
                    <Select
                      placeholder="Select Event"
                      inputId="workflow-select-input"
                      value={defaultNotifStatus}
                      options={NOTIFICATION_STATUS_EVENTS}
                      isDisabled={disabled}
                      styles={{
                        control: styles => {
                          return {
                            ...styles,
                            backgroundColor: disabled ? '#f9fafb' : '#fff',
                            border: '1px solid #d4d4d4',
                          };
                        },
                        placeholder: styles => ({
                          ...styles,
                          color: '#d4d4d4',
                          fontSize: 14,
                        }),
                        singleValue: styles => {
                          return { ...styles, color: '#000', fontSize: 14 };
                        },
                      }}
                      getNewOptionData={value => {
                        if (value) {
                          return {
                            value: value,
                            label: value,
                          };
                        }
                      }}
                      onChange={selectedItem => {
                        if (selectedItem) {
                          setFieldValue('success', selectedItem.value);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="success"
                      render={msg => (
                        <p className="text-red-500 text-sm pt-2">{msg}</p>
                      )}
                    />
                  </div>
                )}
              </div>
              {editMode && (
                <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t">
                  <Button
                    className="flex-grow justify-center"
                    as="button"
                    onClick={() => {
                      setSelectedForm('nodes_list');
                      setSelectedNode({});
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    as="submit"
                    className="flex-grow justify-center ml-2"
                    disabled={updateProperties.isLoading}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export const timeDurations = [
  { label: '5 Mins  ', value: 5 },
  { label: '15 Mins ', value: 15 },
  { label: '30 Mins ', value: 30 },
  { label: '1 Hour  ', value: 60 },
  { label: '2 Hours ', value: 120 },
  { label: '6 Hours ', value: 360 },
  { label: '12 Hours', value: 720 },
  { label: '1 Day   ', value: 1440 },
  { label: '2 Days  ', value: 2880 },
  { label: 'Custom', value: 'custom' },
];

export const breadcrumbs = [{ name: 'Logs', href: '#', current: false }];

export const statusFiltersOptions = [
  { value: 'all', label: 'All logs' },
  { value: 'failure', label: 'Failure logs' },
];

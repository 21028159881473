import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import { formatDistanceToNow } from 'date-fns';
import { enGB } from 'date-fns/locale';

export default function DateTimeInLocalTZ({
  dateInput,
  formatStr,
  short = false,
  ...props
}) {
  const date = new Date(dateInput);
  const localdate = utcToZonedTime(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  if (short) {
    return (
      <>
        {formatDistanceToNow(date, {
          addSuffix: true,
          locale: enGB,
        })}
      </>
    );
  } else {
    return <>{format(localdate, formatStr || 'yyyy-MM-dd HH:mm:ss')}</>;
  }
}

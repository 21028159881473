import { useState, useRef, useEffect } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Textarea,
  Button,
} from 'new-components';
import {
  QuestionMarkCircledIcon,
  FileTextIcon,
  ChatBubbleIcon,
  EnvelopeClosedIcon,
  CalendarIcon,
  Pencil2Icon,
} from '@radix-ui/react-icons';
import { FileClockIcon } from 'lucide-react';
import { cn } from 'utils';
import { useFeedback } from 'apis';
import { toast } from 'components';
import { ReactComponent as SlackLogo } from 'assets/svgs/slack.svg';

function FeedbackModal({ openFeedbackForm, setOpenFeedbackform }) {
  const inputElement = useRef(null);

  const [feedbackText, setFeedbackText] = useState('');

  const sendFeedback = useFeedback();

  return (
    <Popover
      open={openFeedbackForm}
      onOpenChange={() => {
        if (inputElement.current) {
          inputElement.current.focus();
        }
      }}
    >
      <PopoverTrigger asChild>
        <div />
      </PopoverTrigger>
      <PopoverContent
        className="w-80 mt-[3px] mr-4"
        onPointerDownOutside={() => {
          setOpenFeedbackform(false);
        }}
      >
        <div className="w-full">
          <Textarea
            ref={inputElement}
            type="text"
            onChange={e => {
              setFeedbackText(e.target.value);
            }}
            rows={5}
            placeholder="Have feedback? We'd love to hear about your experience."
            className="placeholder:text-muted-foreground/50"
          />
          <div className="flex items-center mt-3 space-x-2 justify-end">
            <Button
              variant="outline"
              onClick={() => {
                setOpenFeedbackform(false);
                setFeedbackText('');
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await sendFeedback.mutateAsync({
                  feedback: feedbackText,
                });
                toast('Feedback sent successfully');
                setOpenFeedbackform(false);
                setFeedbackText('');
              }}
              disabled={!feedbackText || sendFeedback?.isLoading}
            >
              Send feedback
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

function Support() {
  const [openFeedbackForm, setOpenFeedbackform] = useState(false);

  const supportOptions = [
    {
      name: 'Documentation',
      icon: FileTextIcon,
      clickHandler: () => window.open('https://docs.suprsend.com/docs'),
    },
    {
      name: 'Changelog',
      icon: FileClockIcon,
      clickHandler: () => window.open('https://docs.suprsend.com/changelog'),
    },
    {
      name: 'Chat with us',
      icon: ChatBubbleIcon,
      clickHandler: () => window.$chatwoot.toggle(),
    },
    {
      name: 'Ask in Slack community',
      icon: SlackLogo,
      clickHandler: () =>
        window.open(process.env.REACT_APP_SLACK_COMMUNITY_URL),
    },
    {
      name: 'Write to us on Email',
      icon: EnvelopeClosedIcon,
      clickHandler: () => window.open('mailto:support@suprsend.com'),
    },
    {
      name: 'Book a demo',
      icon: CalendarIcon,
      clickHandler: () =>
        window.open('https://calendly.com/nikita-suprsend/30min'),
    },
    {
      name: 'Share feedback',
      icon: Pencil2Icon,
      clickHandler: () => {
        setOpenFeedbackform(true);
      },
    },
  ];

  return (
    <div className="max-w-lg lg:max-w-xs">
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger
          className={cn(
            'data-[state=open]:bg-accent p-1.5 rounded-full outline-none mr-2 hover:bg-accent',
            openFeedbackForm && 'bg-accent'
          )}
        >
          <QuestionMarkCircledIcon className="h-[18px] w-[18px] text-muted-foreground" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 mt-[3px]">
          {supportOptions.map(option => (
            <DropdownMenuItem onClick={option.clickHandler} key={option.name}>
              <option.icon className="h-4 w-4 mr-2 text-muted-foreground" />
              <span className="text-popover-foreground">{option.name}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <FeedbackModal
        openFeedbackForm={openFeedbackForm}
        setOpenFeedbackform={setOpenFeedbackform}
      />
    </div>
  );
}

export default Support;

import { Tooltip } from '.';
import { CBreadcrumb } from 'new-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useEnvState } from 'stores/AuthStore';
import { Button, CTooltip } from 'new-components';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CopyToClipboard = function (str) {
  navigator.clipboard.writeText(str).then(
    function () {
      //  success do nothing
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
};

export default function PageHeading({
  title = '',
  breadcrumbs = [],
  tags = [],
  actions = [],
  textToCopy = '',
  totalCount,
  ...props
}) {
  const envState = useEnvState();
  const currentEnv = envState.currentEnv.get();
  const isSandbox = currentEnv === 'sandbox';

  return (
    <div
      className={classNames(
        'lg:flex lg:items-center lg:justify-between',
        isSandbox && 'pt-2'
      )}
      {...props}
    >
      <div className="flex-1 min-w-0">
        <CBreadcrumb routes={breadcrumbs} />
        <div className="flex space-x-2 align-bottom">
          <h2 className="mt-2 text-xl font-bold leading-7 sm:text-2xl sm:truncate">
            {title}
          </h2>
          {!!totalCount && (
            <CTooltip
              trigger={
                <span className="mt-[14px] text-md font-medium cursor-default">
                  ({totalCount})
                </span>
              }
            >
              Total Count
            </CTooltip>
          )}
          {textToCopy !== '' && (
            <Tooltip content="Click to Copy!" direction="right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mt-[10px] cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
                onClick={() => CopyToClipboard(textToCopy)}
              >
                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
              </svg>
            </Tooltip>
          )}
        </div>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          {tags.map(tag => (
            <div className="mt-2 flex items-center text-sm" key={tag.name}>
              <tag.icon
                className="flex-shrink-0 mr-1.5 h-5 w-5 "
                aria-hidden="true"
              />
              {tag.name}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4 space-x-4">
        {props.children}
        {actions?.map(action => {
          return (
            <span className="hidden sm:block ml-3" key={action?.name}>
              <Button onClick={action?.fn}>
                <>
                  {action?.icon && (
                    <action.icon
                      className={classNames(
                        '-ml-1  h-5 w-5',
                        action?.name ? 'mr-2' : '-mr-1'
                      )}
                      aria-hidden="true"
                    />
                  )}
                  {action?.name}
                </>
              </Button>
            </span>
          );
        })}

        {/* Dropdown */}
        {/* <Menu as="span" className="ml-3 relative sm:hidden">
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                More
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
              </Menu.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        Edit
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        View
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu> */}
      </div>
      <ReactTooltip id="total_count" />
    </div>
  );
}

import { useState } from 'react';
import TextField from './TextField';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { classNames } from 'utils';
import { useFormikContext } from 'formik';

export default function PasswordField(props) {
  const [hide, setHide] = useState(true);
  const [focused, setFocused] = useState(false);

  const Icon = hide ? (
    <EyeOffIcon
      className="h-4 w-4 cursor-pointer"
      onClick={() => {
        setHide(false);
      }}
    />
  ) : (
    <EyeIcon
      className="h-4 w-4 cursor-pointer"
      onClick={() => {
        setHide(true);
      }}
    />
  );

  const { handleBlur: formikBlur } = useFormikContext();

  return (
    <TextField
      {...props}
      onFocus={() => setFocused(true)}
      onBlur={e => {
        formikBlur?.(e);
        setFocused(false);
      }}
      type={hide ? 'password' : 'text'}
      inputClassName="border-none focus:ring-0"
      autoComplete="new-password"
      inputContainerClassName={classNames(
        'bg-white',
        'border-gray-300',
        'rounded',
        'border',
        'flex',
        'items-center',
        'pr-3',
        'focus:ring-1',
        focused && 'ring-2 ring-indigo-600',
        props?.disabled && 'bg-gray-50'
      )}
      rightComponent={() => Icon}
    />
  );
}

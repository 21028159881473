import { useContext, useMemo, useState } from 'react';
import { Button, toast } from 'components';
import { FlowContext } from '../WorkflowDetails';
import { NodeFormHeader, TextField, ListBox } from './Common';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { useUniqueEventsAPI, useUpdateWFVersion } from 'apis';
import { ValidateEventName, MISSING_FIELD_ERROR, copyToClipboard } from 'utils';
import { ReactComponent as TriggerNodeIcon } from 'assets/svgs/triggerNodeIcon.svg';
import * as Yup from 'yup';
import { ClipboardIcon } from '@heroicons/react/solid';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-one_dark';
import { Label, CCopyTooltip } from 'new-components';
import { Clipboard } from 'lucide-react';
import Conditions from '../Conditions';
import { useReactFlow } from 'reactflow';

const TriggerValidationSchema = Yup.object({
  trigger_type: Yup.string().required(MISSING_FIELD_ERROR),
  trigger_events: Yup.array().test(
    'validateEventValue',
    MISSING_FIELD_ERROR,
    (value, fieldData) => {
      const parentObj = fieldData.parent;
      if (parentObj.trigger_type === 'event' && value?.length < 1) {
        return false;
      } else {
        return true;
      }
    }
  ),
  distinct_id_expr: Yup.string().test(
    'validateDistExprValue',
    MISSING_FIELD_ERROR,
    (value, fieldData) => {
      const parentObj = fieldData.parent;
      if (
        parentObj.trigger_type === 'event' &&
        parentObj.distinct_id_expr_enabled &&
        !value
      ) {
        return false;
      } else {
        return true;
      }
    }
  ),
});

const TRIGGER_TYPE_OPTIONS = [
  { label: 'Event', value: 'event' },
  { label: 'API', value: 'api' },
];

const selectStyles = disabled => {
  return {
    multiValueLabel: base => ({
      ...base,
    }),
    placeholder: styles => ({ ...styles, color: '#d4d4d4', fontSize: 14 }),
    control: base => ({
      ...base,
      border: '1px solid #d4d4d4',
      '&:hover': {
        border: '1px solid #d4d4d4',
      },
      boxShadow: 'none',
      backgroundColor: disabled ? 'rgb(249 250 251);' : '#FFFFFF',
    }),
  };
};

export default function TriggerNodeForm() {
  const [copied, setCopied] = useState();
  const {
    selectedNode,
    version,
    slug,
    editMode,
    setSelectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);
  const { getNode } = useReactFlow();
  const nodeData = getNode(selectedNode.id);

  const { status: uniqueEventStatus, data: uniqueEvents } =
    useUniqueEventsAPI();
  const updateWFVersion = useUpdateWFVersion(slug, version);

  const MultiValueLabel = data => {
    if (editMode) {
      return <components.MultiValueLabel {...data} />;
    } else {
      return (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            copyToClipboard(data?.data?.value);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
          }}
        >
          <components.MultiValueLabel {...data} />
          <ClipboardIcon className="h-3 w-3 text-gray-600 ml-0.5 mr-1" />
        </div>
      );
    }
  };

  const MultiValueRemove = data =>
    editMode ? <components.MultiValueRemove {...data} /> : null;

  const Menu = data => (editMode ? <components.Menu {...data} /> : null);

  const formattedOptions = useMemo(() => {
    return (
      uniqueEvents?.map(event => ({
        label: event.event,
        value: event.event,
      })) || []
    );
  }, [uniqueEvents]);

  return (
    <Formik
      initialValues={{
        trigger_type: nodeData?.data?.trigger_type || 'event',
        trigger_events: nodeData?.data?.trigger_events || [],
        distinct_id_expr_enabled: !!nodeData?.data?.distinct_id_expr,
        distinct_id_expr: nodeData?.data?.distinct_id_expr || '',
      }}
      validationSchema={TriggerValidationSchema}
      enableReinitialize={true}
      onSubmit={async values => {
        const formPayload = { ...values };
        delete formPayload.distinct_id_expr_enabled;

        try {
          setLoading(true);
          await updateWFVersion.mutateAsync(values);
          toast('Settings saved successfully', '', { autoClose: 1000 });
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }}
    >
      {({ values, setFieldValue, setFieldTouched }) => {
        let defaultCustomEvents = '';
        if (values.trigger_events?.length > 0) {
          defaultCustomEvents = values.trigger_events?.map(item => ({
            value: item,
            label: item,
          }));
        }
        const triggerTypeData = TRIGGER_TYPE_OPTIONS.find(
          item => item.value === values.trigger_type
        );
        const disabled = !editMode;
        return (
          <div className="overflow-scroll h-full">
            <Form>
              <div className="m-4 mt-6">
                <NodeFormHeader
                  name="Trigger"
                  helptext="Trigger this workflow via direct API or through an event."
                  Icon={TriggerNodeIcon}
                  docLink="https://docs.suprsend.com/docs/trigger-workflow"
                />
                <div className="mt-6">
                  <div className="mb-4">
                    <ListBox
                      label="Type"
                      id="trigger_type"
                      value={values.trigger_type}
                      displayValue={triggerTypeData.label}
                      disabled={disabled}
                      options={TRIGGER_TYPE_OPTIONS}
                      getLabel={e => e.label}
                      getValue={e => e.value}
                      handleOnchange={value => {
                        setFieldValue('trigger_type', value);
                      }}
                    />
                  </div>
                  {values.trigger_type === 'event' && (
                    <div className="">
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="event"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Event Name<span className="text-red-500">*</span>
                        </label>
                        {copied && (
                          <p className="text-xs text-gray-500">Copied</p>
                        )}
                      </div>
                      <CreatableSelect
                        inputId="workflow-select-input"
                        isMulti
                        placeholder="Search or add a new event"
                        isLoading={uniqueEventStatus === 'loading'}
                        noOptionsMessage={() => 'No Events found'}
                        defaultValue={defaultCustomEvents}
                        values={defaultCustomEvents}
                        isSearchable={editMode}
                        isClearable={editMode}
                        getNewOptionData={v => {
                          if (v) {
                            const formattedEvent = ValidateEventName(v);
                            const upperCaseValue =
                              formattedEvent?.toUpperCase();
                            return {
                              value: upperCaseValue,
                              label: upperCaseValue,
                            };
                          }
                        }}
                        onChange={(_, actionData) => {
                          setFieldTouched('trigger_events', true);
                          if (
                            actionData.action === 'select-option' ||
                            actionData.action === 'create-option'
                          ) {
                            setFieldValue('trigger_events', [
                              ...values.trigger_events,
                              actionData.option.value,
                            ]);
                          } else if (
                            actionData.action === 'remove-value' ||
                            actionData.action === 'pop-value'
                          ) {
                            const removedEvent =
                              actionData?.removedValue?.value;
                            if (!removedEvent) return;
                            const existingEvents = [...values?.trigger_events];

                            const newEvents = existingEvents.filter(item => {
                              return item !== removedEvent;
                            });

                            setFieldValue('trigger_events', [...newEvents]);
                          } else if (actionData.action === 'clear') {
                            setFieldValue('trigger_events', []);
                          }
                        }}
                        options={formattedOptions}
                        styles={selectStyles(!editMode)}
                        components={{
                          MultiValueLabel,
                          MultiValueRemove,
                          Menu,
                        }}
                      />
                      <ErrorMessage
                        name="trigger_events"
                        render={msg => (
                          <p className="text-red-500 text-sm pt-2">{msg}</p>
                        )}
                      />

                      <div className="mb-8">
                        <p className="text-[#1E293B] font-semibold text-base mb-4 mt-8">
                          Advanced configuration
                        </p>
                        <div className="my-4">
                          <div className="flex my-4 items-center">
                            <Field
                              id="override_recipients_checkbox"
                              type="checkbox"
                              disabled={disabled}
                              className="form-check-input rounded border-gray-300 w-[18px] h-[18px] cursor-pointer focus:ring-0 focus:ring-offset-0"
                              checked={values.distinct_id_expr_enabled}
                              onChange={e => {
                                setFieldValue(
                                  'distinct_id_expr_enabled',
                                  e.target.checked
                                );
                                setFieldValue('distinct_id_expr', '');
                              }}
                            />
                            <label
                              htmlFor="override_recipients_checkbox"
                              className="text-sm text-gray-700 ml-2 cursor-pointer"
                            >
                              Override recipient
                            </label>
                          </div>
                          {values.distinct_id_expr_enabled && (
                            <TextField
                              id="distinct_id_expr"
                              placeholder=".conversation_id"
                              disabled={disabled}
                              helpText={
                                <p className="text-[#64748B] text-xs mt-1">
                                  add property key from event call having
                                  recipient distinct id in{' '}
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://jqlang.github.io/jq/manual/"
                                    className="hover:underline text-indigo-600 cursor-pointer"
                                  >
                                    jq format.
                                  </a>
                                </p>
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {values.trigger_type === 'api' && (
                    <div>
                      <div className="mb-4">
                        <div className="flex gap-2 items-center">
                          <Label className="block text-sm text-gray-700 my-2 font-medium">
                            Workflow Slug
                          </Label>
                          <CCopyTooltip
                            trigger={
                              <Clipboard className="h-4 w-4 text-accent-foreground" />
                            }
                            copyContent={slug}
                          />
                        </div>
                        <TextField
                          id="workflow_slug"
                          value={slug}
                          disabled={true}
                          mandatory={false}
                        />
                        <p className="text-[#64748B] text-xs mt-1">
                          use this slug to trigger the workflow via API
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {editMode && (
                  <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t z-50">
                    <Button
                      className="flex-grow justify-center"
                      as="button"
                      onClick={() => {
                        setSelectedForm('nodes_list');
                        setSelectedNode({});
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="primary"
                      className="flex-grow justify-center ml-2"
                      disabled={updateWFVersion.isLoading}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </Form>
            <div className="pb-24 mx-4 mt-8">
              <p className="font-medium">Conditions</p>
              <Conditions
                conditions={nodeData?.data?.conditions}
                isWorkflowCondition
                id={nodeData?.id}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

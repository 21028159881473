import { StatusBadge } from 'custom-components';

export function getNodeStatus({ logs, node_type, node_id }) {
  let flogs = logs.find({ node_id: node_id }).sort('time');
  let nodeStatus = null;
  let statusDesc = null;
  let shortDesc = null;

  if (['trigger', 'exit'].includes(node_type)) {
    return { nodeStatus, statusDesc, shortDesc };
  }

  //check if there are any logs for this node
  if (flogs.count() > 0) {
    if (flogs.find({ 'other_info_map.log_level': 'error' }).count() === 0) {
      nodeStatus = 'completed';
      statusDesc = 'Success';
      if (
        logs
          .find({ node_id: node_id })
          .find({ logtype: 'node_end' })
          .count() === 0
      ) {
        //to handle smart delivery in porgress case
        nodeStatus = 'processing';
        statusDesc = 'Running';
      }
    }
    if (flogs.find({ 'other_info_map.log_level': 'warning' }).count() > 0) {
      nodeStatus = 'partial_failure';
      statusDesc = 'Warning';
    }
    if (flogs.find({ 'other_info_map.log_level': 'error' }).count() > 0) {
      nodeStatus = 'failed';
      statusDesc = 'Failure';
    }
  } else {
    if (
      logs
        .find({
          'other_info_map.log_level': 'error',
          'other_info_map.log_type': 'node_end',
        })
        .count() > 0
    ) {
      //check workflow end but there is no event for the node - TODO
      nodeStatus = 'not_executed';
      statusDesc = 'Skipped';
    }
    if (logs.find({ logtype: 'workflow_end' }).count() > 0) {
      //check workflow end but there is no event for the node - TODO
      nodeStatus = 'not_executed';
      statusDesc = 'Skipped';
    }
  }

  return { nodeStatus, statusDesc };
}

export default function NodeStatus({ logs, node_type, node_id }) {
  const { nodeStatus, statusDesc } = getNodeStatus({
    logs,
    node_type,
    node_id,
  });

  if (nodeStatus) {
    return <StatusBadge status={nodeStatus}>{statusDesc}</StatusBadge>;
  } else {
    return null;
  }
}

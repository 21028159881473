import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ChatWootWrapper } from 'components';
import { PrivateRoute, PublicRoute, CommonPrivateRoute } from 'utils/Routes';
import { useEnvState } from 'stores/AuthStore';
import initCustomHelpers from 'utils/handlebarHelpers';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './i18n';
import {
  Login,
  SigningCallBack,
  Workflows,
  NotFound,
  Templates,
  APILogs,
  WorkflowLogs,
  BroadcastLogs,
  UserSegments,
  Integrations,
  SignUp,
  EmailVerificationPending,
  SignupUserEmailRedirection,
  Onboarding,
  Brands,
  BrandDetails,
  BrandPreferences,
  AdminApprovalPending,
  AdminApprovalRedirection,
  ApiKeys,
  NotificationCategories,
  ConnectorsList,
  ExistingConnectorList,
  MixpanelForm,
  MixpanelLogs,
  SegmentForm,
  AmazonS3Form,
  PostgresSQL,
  MySQL,
  BigQuery,
  Webhooks,
  Subscribers,
  SyncTaskList,
  SyncTaskDetails,
  EditSyncTask,
  Lists,
  ListDetails,
  ListLogs,
  AcceptInviteLink,
  LoginUserEmailRedirection,
  TemplateDetails,
  Vendors,
  Dashboard,
  WorkflowDetails,
  UserSettings,
  AccountSettings,
  SubscriberDetails,
  Vercel,
} from 'pages';
import WorkflowDetailsAnalytics from 'pages/Workflow/WorkflowDetailsAnalytics';
import WorkflowRunList from 'pages/Workflow/Executions/';
import WorkflowRequests from 'pages/Workflow/Requests';
import ExecutionDetails from 'pages/Workflow/Executions/ExecutionDetails';
import LogsExecutionsList from 'pages/Logs/Executions';
import { ThemeProvider } from 'new-components/theme-provider';
import { TimezonePreferenceProvider } from 'custom-components';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      //  only show error toasts if we already have data in the cache, which indicates a failed background update
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: false,
      useErrorBoundary: false,
    },
  },
});

function LocaleManager() {
  let { path, url } = useRouteMatch();
  const envState = useEnvState();
  const currentEnv = envState.currentEnv.get();
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <PublicRoute exact path={`${path}/signup`} component={SignUp} />
          <PublicRoute
            exact
            path={`${path}/callback`}
            component={SigningCallBack}
          />
          <PublicRoute
            exact
            path={`${path}/verification`}
            component={EmailVerificationPending}
          />
          <PublicRoute
            exact
            path={`${path}/verify_email`}
            component={SignupUserEmailRedirection}
          />
          <PublicRoute
            exact
            path={`${path}/approval_pending`}
            component={AdminApprovalPending}
          />
          <PublicRoute exact path={`${path}/login`} component={Login} />
          <PublicRoute
            exact
            path={`${path}/login_by_link`}
            component={LoginUserEmailRedirection}
          />
          <PublicRoute
            exact
            path={`${path}/accept_invite`}
            component={AcceptInviteLink}
          />
          <PublicRoute
            exact
            path={`${path}/verification_by_org_admin`}
            component={AdminApprovalRedirection}
          />
          <CommonPrivateRoute path={`${path}/vercel`} component={Vercel} />
          <PrivateRoute
            exact
            path={`${path}/:env/onboarding`}
            component={Onboarding}
          />
          <PrivateRoute exact path={`${path}/:env/brands`} component={Brands} />
          <PrivateRoute
            exact
            path={`${path}/:env/brands/:slug`}
            component={BrandDetails}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/brands/:slug/brand_preference`}
            component={BrandPreferences}
          />
          <Redirect
            exact
            from={`${path}/user-settings`}
            to={`${path}/user-settings/general`}
          />
          <CommonPrivateRoute
            path={`${path}/user-settings`}
            component={UserSettings}
          />
          <Redirect
            exact
            from={`${path}/account-settings`}
            to={`${path}/account-settings/general`}
          />
          <CommonPrivateRoute
            path={`${path}/account-settings`}
            component={AccountSettings}
          />
          <PrivateRoute exact path={`${path}/:env`} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${path}/:env/workflows`}
            component={Workflows}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/workflows/:slug`}
            component={() => <WorkflowDetails key="workflow-detail" />}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/workflows/:slug/edit`}
            component={() => <WorkflowDetails key="workflow-edit" />}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/workflows/:slug/analytics`}
            component={WorkflowDetailsAnalytics}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/workflows/:slug/executions`}
            component={WorkflowRunList}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/workflow-executions/:execution_id/`}
            component={ExecutionDetails}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/workflows/:slug/requests`}
            component={WorkflowRequests}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/templates`}
            component={Templates}
          />
          <Redirect
            exact
            from={`${path}/:env/templates/:templateslug/`}
            to={`${path}/:env/templates/:templateslug/add_channel`}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/templates/:templateslug/:currentChannelSlug`}
            component={TemplateDetails}
          />
          <Redirect
            exact
            from={`${path}/:env/logs`}
            to={`${path}/:env/logs/api/`}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/logs/executions/`}
            component={LogsExecutionsList}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/logs/workflow/`}
            component={WorkflowLogs}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/logs/api/`}
            component={APILogs}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/logs/broadcast/`}
            component={BroadcastLogs}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/user-segments`}
            component={UserSegments}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/subscribers`}
            component={Subscribers}
          />
          <PrivateRoute
            path={`${path}/:env/subscribers/:distinct_id`}
            component={SubscriberDetails}
          />
          <PrivateRoute exact path={`${path}/:env/lists`} component={Lists} />
          <PrivateRoute
            exact
            path={`${path}/:env/lists/tasks`}
            component={SyncTaskList}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/lists/tasks/:id`}
            component={SyncTaskDetails}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/lists/tasks/:id/edit`}
            component={EditSyncTask}
          />
          <Redirect
            exact
            from={`${path}/:env/lists/:id`}
            to={`${path}/:env/lists/:id/users`}
          />
          <PrivateRoute
            path={`${path}/:env/lists/:id`}
            component={ListDetails}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/lists/logs`}
            component={ListLogs}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/integrations`}
            component={Integrations}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/vendors/:channel?/:type?/:vendor?/:vendor_id?`}
            component={Vendors}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/developers/api-keys`}
            component={ApiKeys}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/developers/webhooks`}
            component={Webhooks}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/developers/preference-categories`}
            component={NotificationCategories}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/add`}
            component={ConnectorsList}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors`}
            component={ExistingConnectorList}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/mixpanel`}
            component={MixpanelForm}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/mixpanel/:id`}
            component={MixpanelForm}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/mixpanel/:id/logs`}
            component={MixpanelLogs}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/segment`}
            component={SegmentForm}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/segment/:id`}
            component={SegmentForm}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/amazon_s3`}
            component={AmazonS3Form}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/amazon_s3/:id`}
            component={AmazonS3Form}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/postgres`}
            component={PostgresSQL}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/postgres/:id`}
            component={PostgresSQL}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/mysql`}
            component={MySQL}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/mysql/:id`}
            component={MySQL}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/bigquery`}
            component={BigQuery}
          />
          <PrivateRoute
            exact
            path={`${path}/:env/connectors/bigquery/:id`}
            component={BigQuery}
          />
          <Redirect exact from={`${url}`} to={`${url}/${currentEnv}/`} />
          <Route path="*" component={NotFound} />
        </Switch>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <ChatWootWrapper></ChatWootWrapper>
      </QueryClientProvider>
    </Router>
  );
}

function App() {
  const currentLang = localStorage.getItem('i18nextLng') || 'en';

  useEffect(() => {
    initCustomHelpers();
  }, []);

  return (
    <ThemeProvider defaultTheme="light">
      <TimezonePreferenceProvider>
        <HelmetProvider>
          <Helmet>
            <title>SuprSend App</title>
          </Helmet>
          <Router>
            <Switch>
              <Route path="/:locale(en|hi)" component={LocaleManager} />
              <Redirect exact from="/" to={`/${currentLang}`} />
            </Switch>
            <Toaster position="bottom-right" reverseOrder={false} />
            <ToastContainer />
          </Router>
        </HelmetProvider>
      </TimezonePreferenceProvider>
    </ThemeProvider>
  );
}

export default App;

import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from 'new-components';
import { HomeIcon } from '@radix-ui/react-icons';
import { NavLink } from 'react-router-dom';

export function CBreadcrumb({ routes, ...props }) {
  if (routes?.length < 1) {
    return null;
  }
  return (
    <Breadcrumb className="mt-2">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon className="h-4 w-4" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        {routes?.map((route, index) => (
          <div key={index} className="flex items-center gap-1">
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              {route.current ? (
                <BreadcrumbPage>{route?.name}</BreadcrumbPage>
              ) : (
                <BreadcrumbLink asChild>
                  <NavLink to={route?.href}>{route?.name}</NavLink>
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          </div>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}

import { useState, useRef, useContext } from 'react';
import { InnerTabBar } from '../TabBar';
import CreatableSelect from 'react-select/creatable';
import { Button, toast } from 'components';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  useNotificationCategoriesListAPI,
  useUpdateWFVersion,
  useUpdateWorkflow,
} from 'apis';
import { FlowContext } from '../WorkflowDetails';
import { MISSING_FIELD_ERROR, classNames } from 'utils';
import * as Yup from 'yup';
import { ListBox, TextField, DurationInput } from './Common';
import { Label, Switch } from 'new-components';
import { ExternalLinkIcon } from '@heroicons/react/solid';

const WorkflowSettingsValidationSchema = Yup.object({
  name: Yup.string().required(MISSING_FIELD_ERROR),
  category: Yup.string().required(MISSING_FIELD_ERROR),
  count: Yup.number()
    .min(1)
    .nullable()
    .test('validateCountValue', MISSING_FIELD_ERROR, (value, fieldData) => {
      const parentObj = fieldData.parent;
      return !(parentObj.throttle_enabled && !value);
    }),
  sliding_window: Yup.string()
    .nullable()
    .test('validateWindowValue', MISSING_FIELD_ERROR, (value, fieldData) => {
      const parentObj = fieldData.parent;
      if (parentObj.throttle_enabled && !value) {
        return false;
      }
      if (parentObj.count > 0) {
        if (!value) return true;

        const comps = value.match(/[a-z]+|[^a-z]+/gi);
        return (
          parseInt(comps[0]) ||
          parseInt(comps[2]) ||
          parseInt(comps[4]) ||
          parseInt(comps[6])
        );
      } else {
        return true;
      }
    }),
});

const selectStyles = disabled => {
  return {
    control: (base, state) => ({
      ...base,
      backgroundColor: disabled ? 'rgb(249 250 251);' : 'white',
      border: '1px solid #d4d4d4',
      boxShadow: state.isFocused ? '0 0 0 2px #2E70E8' : 'none',
      '&:hover': {
        border: '1px solid #d4d4d4',
      },
    }),
  };
};

export default function WorkflowSettingsForm({
  wfVersionDetails,
  wfDetails,
  isDynamic,
}) {
  const [inputValue, setInputValue] = useState('');
  const formRef = useRef();
  const {
    editMode,
    slug,
    version,
    setSelectedForm,
    selectedForm,
    setSelectedNode,
    setLoading,
  } = useContext(FlowContext);

  const { data: ncategories } = useNotificationCategoriesListAPI();
  const updateWFDetails = useUpdateWorkflow(slug, version);
  const updateWFVersion = useUpdateWFVersion(slug, version);

  const handleKeyDown = event => {
    if (!inputValue) return;

    const prevTags = formRef.current?.values?.tags;
    const setFieldValue = formRef.current?.setFieldValue;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (!prevTags.includes(inputValue.toLowerCase())) {
          setFieldValue('tags', [...prevTags, inputValue]);
          setInputValue('');
        } else {
          setFieldValue('tags', [...prevTags]);
          setInputValue('');
        }
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  return (
    <Formik
      initialValues={{
        name: wfDetails.name,
        slug: wfDetails.slug,
        description: wfDetails?.description || '',
        category: wfVersionDetails?.category || '',
        tags: wfVersionDetails?.tags || [],
        throttle_enabled:
          wfVersionDetails?.ratelimit &&
          typeof wfVersionDetails?.ratelimit === 'object' &&
          Object.keys(wfVersionDetails?.ratelimit)?.length > 0,
        count: wfVersionDetails?.ratelimit?.count || '',
        sliding_window: wfVersionDetails?.ratelimit?.sliding_window || '',
      }}
      validationSchema={WorkflowSettingsValidationSchema}
      innerRef={formRef}
      enableReinitialize={true}
      onSubmit={async values => {
        try {
          setLoading(true);
          await updateWFDetails.mutateAsync({
            name: values.name,
            description: values.description,
          });
          await updateWFVersion.mutateAsync({
            category: values.category,
            tags: values.tags,
            ratelimit: {
              count: values.count || null,
              sliding_window: values.sliding_window,
            },
          });
          toast('Settings saved successfully', '', { autoClose: 1000 });
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }}
    >
      {({ values, setFieldValue, errors }) => {
        const disabled = !editMode;
        return (
          <Form className="overflow-scroll h-full" noValidate>
            {editMode && (
              <InnerTabBar
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
              />
            )}
            <div className="px-4 py-4">
              {editMode ? (
                <span className="flex items-center justify-end text-indigo-600">
                  <div
                    className="flex gap-2 cursor-pointer"
                    onClick={() => {
                      window.open(
                        'https://docs.suprsend.com/docs/design-workflow#workflow-settings',
                        '_blank'
                      );
                    }}
                  >
                    <p className="text-sm font-medium">Docs</p>
                    <ExternalLinkIcon className="h-5 w-5" />
                  </div>
                </span>
              ) : (
                <div className="flex items-center gap-2 mb-4">
                  <p className="text-gray-900 font-medium text-lg">
                    Workflow settings
                  </p>
                  <a
                    href="https://docs.suprsend.com/docs/design-workflow#workflow-settings"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ExternalLinkIcon className="h-5 w-5 text-[#1E293B]" />
                  </a>
                </div>
              )}
              <div className={classNames(disabled ? 'mb-24' : 'mb-[250px]')}>
                <TextField label="Name" id="name" disabled={disabled} />
                <div className="mt-4">
                  <TextField
                    label="Slug"
                    id="slug"
                    disabled={true}
                    mandatory={false}
                  />
                </div>
                {!isDynamic && (
                  <div className="mt-4">
                    <TextField
                      label="Description"
                      id="description"
                      disabled={disabled}
                      mandatory={false}
                      as="textarea"
                      rows={2}
                    />
                  </div>
                )}

                <div className="mt-4">
                  <ListBox
                    label="Notification Category"
                    id="category"
                    value={values.category}
                    handleOnchange={value => {
                      setFieldValue('category', value);
                    }}
                    disabled={disabled}
                    getLabel={e => {
                      return e.name;
                    }}
                    getValue={e => {
                      return e.slug;
                    }}
                    options={ncategories?.results}
                  />
                </div>
                {!isDynamic && (
                  <>
                    <div className="mt-4 border-b pb-6">
                      <label
                        className="block text-sm text-gray-700 my-2 font-medium"
                        htmlFor="wf-tags"
                      >
                        Tags
                      </label>
                      <CreatableSelect
                        placeholder={
                          <p className="text-sm text-gray-300">
                            Type and press Enter
                          </p>
                        }
                        isDisabled={disabled}
                        components={{ DropdownIndicator: null }}
                        isClearable
                        isMulti
                        styles={selectStyles(!editMode)}
                        inputValue={inputValue}
                        menuIsOpen={false}
                        onKeyDown={handleKeyDown}
                        inputId="workflow-select-input"
                        value={values.tags}
                        getOptionLabel={option => {
                          return option;
                        }}
                        getOptionValue={option => {
                          return option;
                        }}
                        onChange={newValue => setFieldValue('tags', newValue)}
                        onInputChange={newValue => setInputValue(newValue)}
                      />
                    </div>
                    <p className="text-[#1E293B] font-semibold mb-2 mt-4 text-base">
                      Advanced configuration
                    </p>
                    <div>
                      <div className="flex items-center gap-5 my-4 mb-2 mt-4">
                        <Label
                          className="text-[#1E293B] font-medium text-base"
                          htmlFor="throttle-switch"
                        >
                          Throttle
                        </Label>
                        <Switch
                          checked={values.throttle_enabled}
                          disabled={disabled}
                          onCheckedChange={checked => {
                            setFieldValue('throttle_enabled', checked);
                            if (!checked) {
                              setFieldValue('count', '');
                              setFieldValue('sliding_window', '');
                            }
                          }}
                          id="throttle-switch"
                        />
                      </div>
                      {values.throttle_enabled && (
                        <>
                          <div>
                            <TextField
                              label="Max Executions"
                              id="count"
                              placeholder={disabled ? null : 2}
                              disabled={disabled}
                              mandatory={false}
                              type="number"
                              min={1}
                            />
                          </div>
                          <div className="mt-5">
                            <label className="block text-sm text-gray-700 my-2 font-medium">
                              Window
                            </label>
                            <DurationInput
                              value={values.sliding_window}
                              setValue={value => {
                                setFieldValue('sliding_window', value);
                              }}
                              disabled={disabled}
                            />
                            <ErrorMessage
                              name="sliding_window"
                              render={msg => (
                                <p className="text-red-500 text-sm pt-2">
                                  {msg}
                                </p>
                              )}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {editMode && (
                <div className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t">
                  <Button
                    className="flex-grow justify-center"
                    as="button"
                    onClick={() => {
                      setSelectedForm('nodes_list');
                      setSelectedNode({});
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    as="submit"
                    className="flex-grow justify-center ml-2"
                    disabled={
                      updateWFDetails.isLoading || updateWFVersion.isLoading
                    }
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

import Handlebars from 'handlebars';

const ParaFromTpl = ({ variables = {}, children, ...props }) => {
  if (variables && Object.getPrototypeOf(variables) === Object.prototype) {
    try {
      const template = Handlebars.compile(children, { strict: true });
      let result = template(variables);
      return <p {...props}>{result}</p>;
    } catch (err) {
      return <p {...props}>{children}</p>;
    }
  }
  return <p {...props}>{children}</p>;
};

export default ParaFromTpl;

import { useContext, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useUpdateBranchConditions } from 'apis';
import { Button, toast } from 'components';
import { MISSING_FIELD_ERROR } from 'utils';
import * as Yup from 'yup';
import { FlowContext } from '../WorkflowDetails';
import { NodeFormHeader, ListBox, DurationInput, TextField } from './Common';
import { ReactComponent as WaitUntilNodeIcon } from 'assets/svgs/waitUntilNodeIcon.svg';
import SubConditions from '../SubConditions';
import { CTooltip } from 'new-components';
import { CircleAlert } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

const DELAY_TYPE = [
  {
    name: 'Fixed',
    value: 'fixed',
  },
  {
    name: 'Dynamic',
    value: 'dynamic',
  },
];

const WaitUntilNodeValidationschema = Yup.object({
  delay_type: Yup.string().required(MISSING_FIELD_ERROR),
  value: Yup.string()
    .required(MISSING_FIELD_ERROR)
    .test('validateDelayValue', MISSING_FIELD_ERROR, (value, fieldData) => {
      const parentObj = fieldData.parent;
      if (parentObj.delay_type === 'dynamic') {
        return value;
      } else {
        if (!value) return false;
        const comps = value.match(/[a-z]+|[^a-z]+/gi);
        return (
          parseInt(comps[0]) ||
          parseInt(comps[2]) ||
          parseInt(comps[4]) ||
          parseInt(comps[6])
        );
      }
    }),
});

export default function WaitUntilNodeForm() {
  const [showFormErrors, setShowFormErrors] = useState();
  const [conditionFormActive, setConditionFormActive] = useState(false);

  const {
    editMode,
    setSelectedForm,
    setSelectedNode,
    selectedNode,
    nodesObj,
    slug,
    version,
    setLoading,
  } = useContext(FlowContext);

  const isBranch = selectedNode.node_type === 'condition_node';
  const nodeId = isBranch ? selectedNode?.parent_node_id : selectedNode.id;
  const nodeData = nodesObj[nodeId];
  const branchOne = nodeData.data.branches[0];
  const branchTwo = nodeData.data.branches[1];
  const branchTwoCondition = branchTwo?.conditions?.[0];
  const nonFieldErrors = nodeData?.data?.errors?.non_field_errors;
  const fieldErrors = nodeData?.data?.errors?.field_errors;
  const hasFieldErrors = fieldErrors
    ? Object.keys(fieldErrors)?.length > 0
    : false;

  const updateBranchTwoProperties = useUpdateBranchConditions(
    slug,
    version,
    branchTwo.id
  );

  const hasErrors = branchOne?.errors
    ? Object.keys(branchOne?.errors)?.length > 0
    : false;

  return (
    <Formik
      initialValues={{
        delay_type: branchTwoCondition?.delay_properties?.delay_type || 'fixed',
        value: branchTwoCondition?.delay_properties?.value || '0d0h0m0s',
      }}
      validationSchema={WaitUntilNodeValidationschema}
      enableReinitialize={true}
      onSubmit={async values => {
        try {
          setLoading(true);
          await updateBranchTwoProperties.mutateAsync({
            conditions: [
              {
                type: 'delay',
                delay_properties: {
                  delay_type: values.delay_type,
                  value: values.value,
                },
              },
            ],
          });
          setLoading(false);
          setShowFormErrors(true);
          toast('Conditions saved successfully', '', { autoClose: 1000 });
        } catch (e) {
          setLoading(false);
        }
      }}
    >
      {({ values, setFieldValue }) => {
        const selectedDelayType = DELAY_TYPE.find(
          item => item.value === values.delay_type
        );
        const disabled = !editMode;
        return (
          <Form className="overflow-scroll h-full">
            <div className="m-4 mt-6">
              <NodeFormHeader
                name="Wait Until"
                helptext="Wait until a condition is met or the maximum time is reached"
                Icon={WaitUntilNodeIcon}
                docLink="https://docs.suprsend.com/docs/wait-until"
              />
              {showFormErrors && (nonFieldErrors || hasFieldErrors) && (
                <div className="mb-6 text-xs text-red-500 bg-red-50 p-3 rounded mt-4">
                  <ul>
                    {Object.keys(fieldErrors)?.map((item, index) => {
                      return (
                        <li key={index}>
                          - {item}: {fieldErrors[item]}
                        </li>
                      );
                    })}
                    {nonFieldErrors?.map((item, index) => {
                      return <li key={index}>- {item}</li>;
                    })}
                  </ul>
                </div>
              )}
              <div className="mb-24">
                <div className="border-b pb-6 mt-6">
                  <div className="flex gap-2 items-center mb-4">
                    <p className="font-medium">{branchOne.name}</p>
                    {hasErrors && (
                      <CTooltip
                        trigger={
                          <CircleAlert className="h-4 w-4 text-destructive" />
                        }
                      >
                        <p>{JSON.stringify(branchOne.errors)}</p>
                      </CTooltip>
                    )}
                  </div>
                  <SubConditions
                    conditions={branchOne.conditions}
                    disabled={disabled}
                    id={branchOne.id}
                    setConditionFormActive={setConditionFormActive}
                  />
                </div>
                <div className="pt-6">
                  <p className="mb-4 font-medium">{branchTwo.name}</p>
                  <ListBox
                    label="Delay Type"
                    id="delay_type"
                    value={values.delay_type}
                    displayValue={selectedDelayType?.name}
                    disabled={disabled}
                    options={DELAY_TYPE}
                    getLabel={e => {
                      return e.name;
                    }}
                    getValue={e => {
                      return e.value;
                    }}
                    handleOnchange={value => {
                      if (values.delay_type !== value) {
                        setFieldValue('delay_type', value);
                        setFieldValue('value', '');
                      }
                    }}
                  />
                  {values.delay_type === 'fixed' ? (
                    <div className="mt-6">
                      <label
                        className="block text-sm text-gray-700 my-2 font-medium"
                        htmlFor="wf-delay-type"
                      >
                        Type<span className="text-red-500">*</span>
                      </label>
                      <DurationInput
                        value={values.value}
                        setValue={value => {
                          setFieldValue('value', value);
                        }}
                        disabled={disabled}
                      />
                      <ErrorMessage
                        name="value"
                        render={msg => (
                          <p className="text-red-500 text-sm pt-2">{msg}</p>
                        )}
                      />
                    </div>
                  ) : (
                    <div className="mt-4">
                      <TextField
                        label="Value"
                        placeholder=".timestamp_key"
                        id="value"
                        disabled={disabled}
                        helpText={
                          <p className="text-[#64748B] text-xs mt-1">
                            add property key from event call having delay
                            duration in{' '}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://jqlang.github.io/jq/manual/"
                              className="hover:underline text-indigo-600 cursor-pointer"
                            >
                              jq format.
                            </a>
                          </p>
                        }
                      />
                    </div>
                  )}
                  {editMode && (
                    <div
                      className="flex p-3 absolute bottom-0 left-0 right-0 bg-white drop-shadow border-t"
                      data-tooltip-id={'waituntil-save'}
                      data-tooltip-content={
                        'There are unsaved changes in branch condition'
                      }
                      data-tooltip-delay-show={200}
                      data-tooltip-hidden={!conditionFormActive}
                    >
                      <Button
                        className="flex-grow justify-center"
                        as="button"
                        onClick={() => {
                          setSelectedForm('nodes_list');
                          setSelectedNode({});
                        }}
                      >
                        Close
                      </Button>

                      <Button
                        type="primary"
                        as="submit"
                        className="rounded-md flex-grow justify-center ml-2"
                        disabled={
                          updateBranchTwoProperties.isLoading ||
                          conditionFormActive
                        }
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Tooltip
              id={'waituntil-save'}
              style={{ fontSize: 12, padding: 5 }}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

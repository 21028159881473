import { useState, Fragment, useCallback, useRef } from 'react';
import { usePopper } from 'react-popper';
import DatePicker from 'react-datepicker';
import { Dialog, Transition } from '@headlessui/react';
import { format, subDays, endOfDay, startOfDay } from 'date-fns';
import { useAuthState } from 'stores/AuthStore';
import useClickOutside from 'utils/useClickOutside';
import { useCSVDownloadReport } from 'apis';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { CalendarIcon } from '@heroicons/react/outline';
import { ReactComponent as ClockIcon } from 'assets/svgs/clock.svg';
import 'react-datepicker/dist/react-datepicker.css';

const timeDuration = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
  },
  {
    name: 'Last 7 days',
    value: '7days',
  },
  {
    name: 'Last 30 days',
    value: '30days',
  },
  {
    name: 'Custom',
    value: 'custom',
  },
];

function SuccessModalContent({ setSuccessPage, setCsvModalOpen, authState }) {
  return (
    <div className="py-10 pt-14 px-24 ">
      <div className="flex flex-col items-center">
        <ClockIcon className="h-14 w-14 text-gray-500" />
        <p className="text-center text-lg text-gray-600 mt-6">
          Your request is being processed.
        </p>
        <p className="text-center text-sm text-gray-600 mt-2">
          Data will be sent to
          <span className="text-black font-medium mx-1">
            {authState.user.get()?.email}
          </span>
          in a while.
        </p>
        <button
          onClick={() => {
            setCsvModalOpen(false);
            setTimeout(() => {
              setSuccessPage(false);
            }, 500);
          }}
          className="mt-8  w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:ml-3 sm:w-auto sm:text-sm"
        >
          OK
        </button>
      </div>
    </div>
  );
}

function handleCustomDateClick({
  e,
  setOpenCustomModal,
  setSelectedDate,
  setStartDate,
  setEndDate,
}) {
  setOpenCustomModal(false);
  setSelectedDate(e.target.value);

  const date = new Date();

  if (e.target.value === 'today') {
    setStartDate(startOfDay(date));
    setEndDate(new Date());
  } else if (e.target.value === 'yesterday') {
    setStartDate(startOfDay(subDays(date, 1)));
    setEndDate(endOfDay(subDays(date, 1)));
  } else if (e.target.value === '7days') {
    setStartDate(subDays(date, 7));
    setEndDate(new Date());
  } else if (e.target.value === '30days') {
    setStartDate(subDays(date, 30));
    setEndDate(new Date());
  } else if (e.target.value === 'custom') {
    setOpenCustomModal(true);
    setStartDate('');
    setEndDate('');
  }
}

function CustomDateModalContent({
  setPopperElement,
  styles,
  attributes,
  setOpenCustomModal,
  setStartDate,
  setEndDate,
  innerRef,
}) {
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [customEndDate, setCustomEndDate] = useState(new Date());
  return (
    <div
      ref={setPopperElement}
      style={{
        ...styles.popper,
        zIndex: 999,
        width: '550px',
      }}
      {...attributes.popper}
    >
      <>
        <div className="w-10 overflow-hidden ml-[233px] bg-white">
          <div className="h-4 w-4 border border-gray-300 rotate-45 transform origin-bottom-left bg-white"></div>
        </div>
        <div
          className="h-[480px] w-full rounded-md border bg-white shadow-lg py-4 px-6 -mt-[1px]"
          ref={innerRef}
        >
          <div className="flex w-full">
            <div className="pr-2 w-1/2 ">
              <DatePicker
                selected={customStartDate}
                className="rounded text-sm text-gray-900 border-gray-300 w-full"
                onChange={date => {
                  setCustomStartDate(date);
                }}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy hh:mm aa"
                placeholderText="from: dd/mm/yyyy hh:mm"
                timeInputLabel="Time:"
                showTimeInput
                open={openStartDatePicker}
                onInputClick={() => {
                  setOpenStartDatePicker(true);
                }}
                onChangeRaw={e => {
                  if (e.target.value) {
                    const splitValue = e.target.value.split('/');
                    const modifiedDate = `${splitValue?.[1]}/${splitValue?.[0]}/${splitValue?.[2]}`;
                    if (new Date(modifiedDate) > new Date()) {
                      setCustomStartDate(new Date());
                    }
                  }
                }}
                onClickOutside={() => {
                  setOpenStartDatePicker(false);
                }}
              >
                <button
                  className="text-sm bg-indigo-600 rounded text-white px-4 py-1 w-full ml-1 mt-2 mb-1"
                  onClick={() => {
                    setOpenStartDatePicker(false);
                  }}
                >
                  Set
                </button>
              </DatePicker>
            </div>
            <div className="pl-2 w-1/2">
              <DatePicker
                selected={customEndDate}
                className="rounded text-sm text-gray-900 border-gray-300 w-full"
                onChange={date => {
                  setCustomEndDate(date);
                }}
                dateFormat="dd/MM/yyyy hh:mm aa"
                minDate={new Date(customStartDate)}
                maxDate={new Date()}
                placeholderText="to: dd/mm/yyyy hh:mm"
                timeInputLabel="Time:"
                showTimeInput
                open={openEndDatePicker}
                onInputClick={() => {
                  setOpenEndDatePicker(true);
                }}
                onChangeRaw={e => {
                  if (e.target.value) {
                    const splitValue = e.target.value.split('/');
                    const modifiedDate = `${splitValue?.[1]}/${splitValue?.[0]}/${splitValue?.[2]}`;
                    if (new Date(modifiedDate) > new Date()) {
                      setCustomEndDate(new Date());
                    } else if (
                      new Date(modifiedDate) > new Date(customStartDate)
                    ) {
                      setCustomEndDate(new Date(customStartDate));
                    }
                  }
                }}
                onClickOutside={() => {
                  setOpenEndDatePicker(false);
                }}
              >
                <button
                  className="text-sm bg-indigo-600 rounded text-white px-4 py-1 w-full ml-1 mt-2 mb-1"
                  onClick={() => {
                    setOpenEndDatePicker(false);
                  }}
                >
                  Set
                </button>
              </DatePicker>
            </div>
          </div>
          <div className="py-4 fixed bottom-0 right-6 ml-auto">
            <button
              type="button"
              className="mt-3 bottom-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                setOpenCustomModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
              disabled={!customStartDate || !customEndDate}
              onClick={() => {
                setStartDate(customStartDate);
                setEndDate(customEndDate);
                setOpenCustomModal(false);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </>
    </div>
  );
}

function SelectDateRangeModalContent({
  setSuccessPage,
  setCsvModalOpen,
  workflowSlug,
}) {
  const ref = useRef(null);

  const [selectedDate, setSelectedDate] = useState('today');
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [openCustomModal, setOpenCustomModal] = useState(false);

  const close = useCallback(() => setOpenCustomModal(false), []);
  useClickOutside(ref, close);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const CSVDownloadReport = useCSVDownloadReport();

  return (
    <div>
      <div className="pt-5 pb-4 sm:p-6 sm:pb-8">
        <div className="">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="flex">
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Download report
              </Dialog.Title>
            </div>
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium mt-8">Select Date Range</p>

            <div className="flex mt-3 items-center">
              {timeDuration.map(item => (
                <div
                  key={item.value}
                  className="mr-6"
                  ref={setReferenceElement}
                >
                  <input
                    id={item.value}
                    name="template-type"
                    type="radio"
                    value={item.value}
                    defaultChecked={item.value === 'today'}
                    className="h-4 w-4 text-indigo-600 text-sm border-gray-300 focus:ring-0 focus:ring-offset-0"
                    onChange={e => {
                      handleCustomDateClick({
                        e,
                        setOpenCustomModal,
                        setSelectedDate,
                        setStartDate,
                        setEndDate,
                      });
                    }}
                    onClick={() => {
                      if (item.value === 'custom') {
                        setOpenCustomModal(true);
                      }
                    }}
                  />
                  <label
                    htmlFor={item.value}
                    className="ml-3 text-sm text-gray-500"
                  >
                    {item.name}
                  </label>
                </div>
              ))}
              {selectedDate === 'custom' && startDate && endDate && (
                <div className="text-indigo-600 flex items-center">
                  <button
                    className="text-sm text-indigo-600 flex items-center hover:underline"
                    onClick={() => {
                      setOpenCustomModal(true);
                    }}
                  >
                    <CalendarIcon className="h-4 w-4 mr-1" />
                    {`${format(startDate, 'dd/MM/yyyy hh:mm aa')} - ${format(
                      endDate,
                      'dd/MM/yyyy hh:mm aa'
                    )}`}
                  </button>
                </div>
              )}
            </div>
            {openCustomModal && (
              <CustomDateModalContent
                innerRef={ref}
                setPopperElement={setPopperElement}
                styles={styles}
                attributes={attributes}
                setOpenCustomModal={setOpenCustomModal}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            <div className="mt-8 flex items-center ">
              <ExclamationCircleIcon className="h-4 w-4 text-indigo-600" />
              <p className="text-xs font-normal ml-2 text-gray-500">
                Download limit is 500k rows. Select smaller date range for
                larger data sets.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse bg-gray-50 pb-4 rounded-b-lg">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
          onClick={() => {
            const unixStartDate = Math.floor(
              new Date(startDate).getTime() / 1000
            );
            const unixEndDate = Math.floor(new Date(endDate).getTime() / 1000);

            const payload = {
              start_ts: unixStartDate,
              end_ts: unixEndDate,
            };
            if (workflowSlug) {
              payload.workflow_slug = workflowSlug;
            }
            CSVDownloadReport.mutateAsync(payload);
            setSuccessPage(true);
          }}
          disabled={!startDate || !endDate}
        >
          Get Report
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => {
            setCsvModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function CSVDownloadModal({ csvModalOpen, setCsvModalOpen, workflowSlug }) {
  const authState = useAuthState();

  const [successPage, setSuccessPage] = useState(false);
  return (
    <div>
      <Transition.Root show={csvModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => {
            setCsvModalOpen(false);
            setTimeout(() => {
              setSuccessPage(false);
            }, 500);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-20 sm:align-top sm:w-full max-w-max">
                {successPage ? (
                  <SuccessModalContent
                    setSuccessPage={setSuccessPage}
                    setCsvModalOpen={setCsvModalOpen}
                    authState={authState}
                  />
                ) : (
                  <SelectDateRangeModalContent
                    setSuccessPage={setSuccessPage}
                    setCsvModalOpen={setCsvModalOpen}
                    workflowSlug={workflowSlug}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default CSVDownloadModal;

import { useHistory, useLocation } from 'react-router-dom';
import { EmptyState, Spinner, TimeAgo } from 'components';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Button,
  CTooltip,
  COverflowTooltip,
} from 'new-components';
import { HelpCircle } from 'lucide-react';
import urlUtils from 'utils/urlUtils';

const STATUS_COLORS = {
  completed: 'success-subtle',
  partial_failure: 'warning-subtle',
  failed: 'destructive-subtle',
  processing: 'primary-subtle',
};

export default function ListTable({
  executionsData,
  queryParam,
  setShowLoader,
  showDistinctID = true,
}) {
  const history = useHistory();
  const location = useLocation();

  const pageNumber = Number(queryParam.get('page')) || 0;
  const executionsList = executionsData?.results;
  const metaData = executionsData?.meta;

  if (!executionsData) {
    return <Spinner />;
  }
  if (executionsList?.length === 0) {
    return (
      <div>
        <EmptyState
          url="https://docs.suprsend.com/reference/overview"
          className="rounded mt-0 mx-0 border-solid border"
        />
      </div>
    );
  }
  return (
    <>
      <div className="rounded-t border border-t-0 overflow-hidden">
        <Table className="min-w-full table-fixed border-none">
          <TableHeader>
            <TableRow>
              {/* order of columns can be started_at, distinct_id, brand_id,
            execution id, idempotency_key, status (ended at can be removed) */}
              <TableHead className="w-[2.5%]" />
              <TableHead className="w-[16%]">Started At</TableHead>
              <TableHead className="w-[21%]">
                <div className="flex items-center">
                  <p>Workflow Slug</p>
                  <CTooltip
                    trigger={
                      <HelpCircle className="h-4 w-4 ml-1.5 text-muted-foreground" />
                    }
                  >
                    <p>
                      Unique identifier of the workflow. You'll get it from
                      workflow settings.
                    </p>
                  </CTooltip>
                </div>
              </TableHead>
              {showDistinctID && (
                <TableHead className="w-[20%]">
                  <div className="flex items-center">
                    <p>Distinct ID</p>
                    <CTooltip
                      trigger={
                        <HelpCircle className="h-4 w-4 ml-1.5 text-muted-foreground" />
                      }
                    >
                      <p>
                        Unique identifier of the user. Acts as the recipient id
                        if recipient isn't overriden in the workflow
                      </p>
                    </CTooltip>
                  </div>
                </TableHead>
              )}
              <TableHead className="w-[18.5%]">
                <div className="flex items-center">
                  <p>Tenant ID</p>
                  <CTooltip
                    trigger={
                      <HelpCircle className="h-4 w-4 ml-1.5 text-muted-foreground" />
                    }
                  >
                    <p>Unique identifier of the tenant</p>
                  </CTooltip>
                </div>
              </TableHead>
              <TableHead className="w-[22%]">
                <div className="flex items-center">
                  <p>Idempotency Key</p>
                  <CTooltip
                    trigger={
                      <HelpCircle className="h-4 w-4 ml-1.5 text-muted-foreground" />
                    }
                  >
                    <p>
                      Unique identifier of the request. Duplicate requests with
                      the same idempotency key are not processed within 24 hours
                    </p>
                  </CTooltip>
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {executionsList?.map(wfrun => {
              const barColor = `--${STATUS_COLORS[wfrun?.status]}`;
              return (
                <TableRow
                  key={wfrun.exec_id}
                  className="cursor-pointer"
                  onClick={() => {
                    history.push(
                      urlUtils.makeURL(`workflow-executions/${wfrun.exec_id}/`),
                      { from: location.pathname }
                    );
                  }}
                >
                  <TableCell className="w-[2.5%] p-3">
                    <div
                      className="h-6 w-1 rounded-lg ml-2.5"
                      style={{
                        backgroundColor: `hsl(var(${barColor}))`,
                      }}
                    />
                  </TableCell>
                  <TableCell className="overflow-hidden p-3">
                    <TimeAgo
                      dateInput={wfrun.started_time}
                      className="overflow-hidden text-ellipsis whitespace-nowrap"
                    />
                  </TableCell>
                  <TableCell className="overflow-hidden p-3">
                    <COverflowTooltip
                      trigger={
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {wfrun?.workflow_slug}
                        </p>
                      }
                    >
                      {wfrun?.workflow_slug || '-'}
                    </COverflowTooltip>
                  </TableCell>
                  {showDistinctID && (
                    <TableCell className="overflow-hidden p-3">
                      <COverflowTooltip
                        trigger={
                          <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                            {wfrun?.distinct_id}
                          </p>
                        }
                      >
                        {wfrun?.distinct_id || '-'}
                      </COverflowTooltip>
                    </TableCell>
                  )}
                  <TableCell className="overflow-hidden p-3">
                    <COverflowTooltip
                      trigger={
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {wfrun.tenant}
                        </p>
                      }
                    >
                      {wfrun.tenant || '-'}
                    </COverflowTooltip>
                  </TableCell>
                  <TableCell className="overflow-hidden p-3">
                    <COverflowTooltip
                      trigger={
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {wfrun.idempotency_key}
                        </p>
                      }
                    >
                      {wfrun.idempotency_key || '-'}
                    </COverflowTooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <nav
        className="min-w-full px-4 py-3 flex items-center justify-between border border-t-0 border-border rounded-b sm:px-6 "
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-accent-foreground">
            Showing <span className="font-medium">{pageNumber * 20 + 1}</span>{' '}
            to{' '}
            <span className="font-medium">
              {Math.min(pageNumber * 20 + 20, metaData?.count)}
            </span>{' '}
            of <span className="font-medium">{metaData?.count}</span> results
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <Button
            variant="outline"
            onClick={() => {
              setShowLoader(true);
              queryParam.set('page', pageNumber - 1);
              history.push({ search: queryParam.toString() });
            }}
            disabled={!metaData.previous}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              setShowLoader(true);
              queryParam.set('page', pageNumber + 1);
              history.push({ search: queryParam.toString() });
            }}
            disabled={!metaData.next}
            className="ml-3"
          >
            Next
          </Button>
        </div>
      </nav>
    </>
  );
}

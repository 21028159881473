import { Fragment, useState } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import urlUtils from 'utils/urlUtils';
import { useAuthState, loggedInAPI } from 'stores/AuthStore';
import { useSearchedTemplates, useSearchedWorkflows } from 'apis';
import {
  QuestionMarkCircleIcon,
  SearchIcon,
  ExclamationIcon,
  SupportIcon,
  TemplateIcon,
  ChatAltIcon,
} from '@heroicons/react/outline';
import { classNames } from 'utils';

function SearchWidget({ searchOpen, setSearchOpen, ...props }) {
  const authState = useAuthState();
  const history = useHistory();

  const [rawQuery, setRawQuery] = useState('');

  const query = rawQuery?.toLowerCase().replace(/^[#>?]/, '');

  const { data: templates, status: templateStatus } =
    useSearchedTemplates(query);
  const { data: workflows, status: workflowStatus } = useSearchedWorkflows({
    searchedText: query,
  });
  const { data: docsearchData, status: docsearchStatus } = useQuery(
    ['search/docs', query],
    async () => {
      if (!rawQuery.startsWith('?')) {
        return [];
      }
      const url = `/readme/docs/search/?query=${query}`;
      const { data } = await loggedInAPI.get(url);
      return data.results;
    },
    {
      enabled: query.length > 1,
    }
  );

  let templateData =
    rawQuery && !(rawQuery.startsWith('>') || rawQuery.startsWith('?'))
      ? templates?.results
      : [];
  let workflowData =
    rawQuery && !(rawQuery.startsWith('#') || rawQuery.startsWith('?'))
      ? workflows?.results
      : [];

  if (!authState.isLoggedIn.get()) {
    return null;
  }
  if (!searchOpen) {
    return null;
  }
  return (
    <Transition.Root
      show={searchOpen}
      as={Fragment}
      onClose={() => {
        setRawQuery('');
        setSearchOpen(false);
      }}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setSearchOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={item => {
                  setSearchOpen(false);
                  if (item.url.startsWith('https://')) {
                    window.open(item.url, '_blank').focus();
                  } else {
                    history.push(urlUtils.makeURL(item.url));
                  }
                  setRawQuery('');
                }}
              >
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    autoComplete="off"
                    onChange={event => setRawQuery(event.target.value)}
                  />
                </div>

                {(templateStatus === 'success' ||
                  workflowStatus === 'success' ||
                  docsearchStatus === 'success') &&
                  (templateData?.length > 0 ||
                    workflowData?.length > 0 ||
                    docsearchData?.length > 0) && (
                    <Combobox.Options
                      static
                      className="max-h-80 scroll-py-10 scroll-py-10 scroll-pb-2 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
                    >
                      {templateStatus === 'success' &&
                        templateData?.length > 0 && (
                          <li>
                            <h2 className="text-xs font-semibold text-gray-900">
                              Templates
                            </h2>
                            <ul className="-mx-4 mt-2 text-sm text-gray-700">
                              {templateData.map(template => (
                                <Combobox.Option
                                  key={template?.id}
                                  value={template}
                                  className={({ active }) =>
                                    classNames(
                                      'flex cursor-default select-none items-center px-4 py-2',
                                      active && 'bg-indigo-600 text-white'
                                    )
                                  }
                                >
                                  {({ active }) => (
                                    <>
                                      <TemplateIcon
                                        className={classNames(
                                          'h-6 w-6 flex-none',
                                          active
                                            ? 'text-white'
                                            : 'text-gray-400'
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span
                                        className="ml-3 flex-auto truncate"
                                        dangerouslySetInnerHTML={{
                                          __html: template?.highlight?.name,
                                        }}
                                      ></span>
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </ul>
                          </li>
                        )}
                      {workflowStatus === 'success' &&
                        workflowData?.length > 0 && (
                          <li>
                            <h2 className="text-xs font-semibold text-gray-900">
                              Workflows
                            </h2>
                            <ul className="-mx-4 mt-2 text-sm text-gray-700">
                              {workflowData.map(workflow => (
                                <Combobox.Option
                                  key={workflow?.id}
                                  value={workflow}
                                  className={({ active }) =>
                                    classNames(
                                      'flex cursor-default select-none items-center px-4 py-2',
                                      active && 'bg-indigo-600 text-white'
                                    )
                                  }
                                >
                                  {({ active }) => (
                                    <>
                                      <ChatAltIcon
                                        className={classNames(
                                          'h-6 w-6 flex-none',
                                          active
                                            ? 'text-white'
                                            : 'text-gray-400'
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span
                                        className="ml-3 flex-auto truncate"
                                        dangerouslySetInnerHTML={{
                                          __html: workflow?.highlight?.name,
                                        }}
                                      ></span>
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </ul>
                          </li>
                        )}
                      {docsearchStatus === 'success' &&
                        docsearchData.length > 0 && (
                          <li>
                            <h2 className="text-xs font-semibold text-gray-900">
                              Documentation
                            </h2>
                            <ul className="-mx-4 mt-2 text-sm text-gray-700">
                              {docsearchData.map(docItem => {
                                return (
                                  <Combobox.Option
                                    key={docItem.slug}
                                    value={docItem}
                                    className={({ active }) =>
                                      classNames(
                                        'flex cursor-default select-none items-center px-4 py-2',
                                        active && 'bg-indigo-600 text-white'
                                      )
                                    }
                                  >
                                    {({ active }) => (
                                      <>
                                        <QuestionMarkCircleIcon
                                          className={classNames(
                                            'h-6 w-6 flex-none',
                                            active
                                              ? 'text-white'
                                              : 'text-gray-400'
                                          )}
                                          aria-hidden="true"
                                        />
                                        <div className="w-full">
                                          <p
                                            className={classNames(
                                              active
                                                ? 'text-white'
                                                : 'text-gray-700',
                                              'ml-3 flex-auto truncate readmeresults text-sm'
                                            )}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                docItem._highlightResult?.title
                                                  .value,
                                            }}
                                          ></p>
                                          <p
                                            className={classNames(
                                              active
                                                ? 'text-white'
                                                : 'text-gray-500',
                                              'mx-3 flex-auto truncate readmeresults text-xs'
                                            )}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                docItem._highlightResult?.body
                                                  .value,
                                            }}
                                          ></p>
                                        </div>
                                      </>
                                    )}
                                  </Combobox.Option>
                                );
                              })}
                            </ul>
                          </li>
                        )}
                    </Combobox.Options>
                  )}

                {rawQuery === '' && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <SupportIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      Help with searching
                    </p>
                    <p className="mt-2 text-gray-500">
                      Use this tool to quickly search for templates, workflows
                      &amp; documentation across your current workspace. You can
                      also use the search modifiers found in the footer below to
                      limit the results to just templates, workflows or
                      documentation.
                    </p>
                  </div>
                )}

                {query !== '' &&
                  templateData?.length === 0 &&
                  workflowData?.length === 0 &&
                  docsearchStatus === 'success' &&
                  docsearchData?.length === 0 && (
                    <div className="py-14 px-6 text-center text-sm sm:px-14">
                      <ExclamationIcon
                        className="mx-auto h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <p className="mt-4 font-semibold text-gray-900">
                        No results found
                      </p>
                      <p className="mt-2 text-gray-500">
                        We couldn’t find anything with that term. Please try
                        again.
                      </p>
                    </div>
                  )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SearchWidget;

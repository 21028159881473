import { Field, ErrorMessage, useFormikContext } from 'formik';
import { forwardRef } from 'react';
import Handlebars from 'handlebars';

const TextField = (props, ref) => {
  const {
    label,
    name,
    mandatory = true,
    as = 'input',
    className = '',
    inputContainerClassName = '',
    inputClassName = '',
    rightComponent,
    leftComponent,
    channelType,
    warning,
    formChanged,
    ...others
  } = props;

  const { handleChange } = useFormikContext();

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm text-gray-700 my-2 font-medium"
        >
          {label}
          {mandatory && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className={inputContainerClassName}>
        {leftComponent && leftComponent()}
        <Field
          name={name}
          id={name}
          innerRef={input => {
            if (ref) ref.current = input;
          }}
          onChange={e => {
            handleChange(e);
            formChanged?.();
          }}
          validate={value => {
            if (channelType === 'whatsapp') {
              try {
                const compiledTemplate = Handlebars.compile(value, {
                  strict: true,
                  knownHelpersOnly: true,
                  knownHelpers: {
                    helperMissing: false,
                    blockHelperMissing: false,
                    each: false,
                    if: false,
                    unless: false,
                    with: false,
                    log: false,
                    lookup: false,
                  },
                });
                compiledTemplate({ name: 1 });
              } catch (err) {
                if (err.message.includes('You specified knownHelpersOnly')) {
                  return 'Handlebars helpers are not allowed';
                }
              }
            }
          }}
          as={as}
          autoComplete="off"
          className={
            'border-gray-300 rounded border w-full p-[12px] text-sm disabled:bg-gray-50 outline-offset-2 outline-indigo-600 placeholder-gray-300' +
            ' ' +
            inputClassName
          }
          {...others}
        />
        {rightComponent && rightComponent()}
      </div>
      <ErrorMessage name={name}>
        {msg => <p className="text-red-500 text-sm pt-2">{msg}</p>}
      </ErrorMessage>
      {warning && <p className="text-red-500 text-sm pt-2">{warning}</p>}
    </div>
  );
};

export default forwardRef(TextField);

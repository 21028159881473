import { useQuery, useQueryClient, useMutation } from 'react-query';
import { loggedInAPI, buildAPIURL, useEnvState } from '../stores/AuthStore';
import toast from 'react-hot-toast';

const getGlobalChannels = async () => {
  const url = '/channel/';
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useGlobalChannelAPI() {
  return useQuery('globalchannels', getGlobalChannels);
}

const getTemplateChannelList = async (templateslug, channelslug, liveOnly) => {
  const url = buildAPIURL(
    `/template/${templateslug}/channel/${channelslug}/version/?only_live=${liveOnly}`
  );
  try {
    const { data } = await loggedInAPI.get(url);
    return data;
  } catch (e) {
    return { results: [] };
  }
};

function useChannelListAPI(templateslug, channelslug, liveOnly) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/template/${templateslug}/${channelslug}`,
      liveOnly,
    ],
    () => getTemplateChannelList(templateslug, channelslug, liveOnly),
    {
      retry: 0,
      cacheTime: 0,
    }
  );
}

function useCreateChannelForTemplate(template) {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/template/${template}/channel/`);
  return useMutation(channel => loggedInAPI.post(url, channel), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/template/${template}`
      );
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/template`);
    },
    onError: error => {
      toast.error(`Error creating channel : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
}

export { useGlobalChannelAPI, useChannelListAPI, useCreateChannelForTemplate };

import { useContext, Fragment, useState, useEffect } from 'react';
import { FlowContext } from '../WorkflowDetails';
import { Transition, Menu, Listbox } from '@headlessui/react';
import { useDeleteWorkflowNode, useUpdateNodeProperties } from 'apis';
import {
  DotsVerticalIcon,
  TrashIcon,
  PencilIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
} from '@heroicons/react/solid';
import { Modal, Button } from 'components';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { classNames } from 'utils';

const daysOpts = [];
for (let i = 0; i < 29.5; i += 1) daysOpts.push(i);
const hoursOpts = [];
for (let i = 0; i < 23.5; i += 1) hoursOpts.push(i);
const minutesOpts = [];
for (let i = 0; i < 59.5; i += 1) minutesOpts.push(i);
const secondsOpts = [];
for (let i = 0; i < 59.5; i += 1) secondsOpts.push(i);

export function ListBox({
  id,
  label,
  value,
  displayValue,
  options,
  disabled,
  handleOnchange,
  mandatory = true,
  getLabel = e => e,
  getValue = e => e,
  labelClassName,
  menuClassName,
  menuButtonClassName,
  showError = true,
}) {
  return (
    <div>
      {label && (
        <label
          className={classNames(
            'block text-sm text-gray-700 font-medium mb-2',
            labelClassName
          )}
          htmlFor={id}
        >
          {label}
          {mandatory && <span className="text-red-500">*</span>}
        </label>
      )}
      <div>
        <Listbox
          value={value}
          disabled={disabled}
          onChange={value => {
            handleOnchange(value);
          }}
        >
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                'relative w-full px-[12px] select-text h-9 text-left bg-white rounded cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm border border-gray-300 disabled:bg-gray-50',
                menuButtonClassName
              )}
            >
              <span className="block truncate">{displayValue || value}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="w-5 h-5 ml-2 -mr-1 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  'text-center absolute w-full mt-1 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text z-50',
                  menuClassName
                )}
              >
                {options?.map((option, index) => {
                  const optionLabel = getLabel(option);
                  const optionValue = getValue(option);

                  return (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        `${
                          active
                            ? 'bg-indigo-100 text-amber-900'
                            : 'text-gray-900 bg-white'
                        }cursor-default select-none py-2`
                      }
                      value={optionValue}
                    >
                      {({ active }) => (
                        <>
                          <p
                            className={`${
                              active ? 'font-medium' : 'font-normal'
                            } block text-left px-4`}
                          >
                            {optionLabel}
                          </p>
                        </>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {showError && (
          <ErrorMessage
            name={id}
            render={msg => <p className="text-red-500 text-sm pt-2">{msg}</p>}
          />
        )}
      </div>
    </div>
  );
}

export function DurationInput({ value, setValue, disabled }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (value) {
      const comps = value.match(/[a-z]+|[^a-z]+/gi);
      setDays(parseInt(comps[0]));
      setHours(parseInt(comps[2]));
      setMinutes(parseInt(comps[4]));
      setSeconds(parseInt(comps[6]));
    } else {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }
  }, [value]);

  return (
    <div className="grid grid-flow-col grid-cols-4 space-x-2">
      <ListBox
        label="Days"
        id="days"
        value={days}
        options={daysOpts}
        mandatory={false}
        getLabel={e => {
          return `${e} days`;
        }}
        disabled={disabled}
        handleOnchange={value => {
          setDays(value);
          setValue(`${value}d${hours}h${minutes}m${seconds}s`);
        }}
        labelClassName="text-xs font-normal"
      />
      <ListBox
        label="Hours"
        id="hours"
        value={hours}
        mandatory={false}
        options={hoursOpts}
        getLabel={e => {
          return `${e} hrs`;
        }}
        disabled={disabled}
        handleOnchange={value => {
          setHours(value);
          setValue(`${days}d${value}h${minutes}m${seconds}s`);
        }}
        labelClassName="text-xs font-normal"
      />
      <ListBox
        label="Minutes"
        id="minutes"
        value={minutes}
        options={minutesOpts}
        mandatory={false}
        getLabel={e => {
          return `${e} min`;
        }}
        disabled={disabled}
        handleOnchange={value => {
          setMinutes(value);
          setValue(`${days}d${hours}h${value}m${seconds}s`);
        }}
        labelClassName="text-xs font-normal"
      />
      <ListBox
        label="Seconds"
        id="seconds"
        value={seconds}
        options={secondsOpts}
        mandatory={false}
        getLabel={e => {
          return `${e} sec`;
        }}
        disabled={disabled}
        handleOnchange={value => {
          setSeconds(value);
          setValue(`${days}d${hours}h${minutes}m${value}s`);
        }}
        labelClassName="text-xs font-normal"
      />
    </div>
  );
}

export function NodeFormHeader({ name, helptext, Icon, docLink }) {
  const { editMode, selectedNode } = useContext(FlowContext);

  const [showMetaDataModal, setShowMetaDataModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <div className="border-b pb-3">
        <div className="flex justify-between">
          <div className="flex items-center gap-2 mb-1">
            <div className="flex items-center gap-3">
              <Icon />
              <p className="text-[#1E293B] font-semibold text-xl">{name}</p>
            </div>
            <a href={docLink} target="_blank" rel="noopener noreferrer">
              <ExternalLinkIcon className="h-5 w-5 text-[#1E293B]" />
            </a>
          </div>
          {editMode && selectedNode.node_type !== 'trigger' && (
            <div>
              <Menu
                as="div"
                className="relative"
                onClick={e => {
                  e.preventDefault();
                }}
              >
                <Menu.Button className="pl-2">
                  <DotsVerticalIcon className="h-4 w-4 mt-1" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute bg-white rounded shadow-lg border z-50 w-[160px] right-2">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            setShowMetaDataModal(true);
                          }}
                          className={`flex items-center px-4 py-3 cursor-pointer ${
                            active && 'bg-[#F0F6FF]'
                          }`}
                        >
                          <PencilIcon className="h-4 w-4 mr-2" />
                          <p className={'text-gray-900 text-sm'}>
                            Edit metadata
                          </p>
                        </div>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={async () => {
                            setShowDeleteModal(true);
                          }}
                          className={`flex items-center px-4 py-3 cursor-pointer ${
                            active && 'bg-[#F0F6FF]'
                          }`}
                        >
                          <TrashIcon className="h-4 w-4 mr-2" />
                          <p className={'text-gray-900 text-sm'}>Delete</p>
                        </div>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          )}
        </div>
        <p className="text-[#475569] text-sm">{helptext}</p>
      </div>
      <NodeMetaDataModal
        showMetaDataModal={showMetaDataModal}
        setShowMetaDataModal={setShowMetaDataModal}
      />
      <NodeDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
}

function NodeDeleteModal({ showDeleteModal, setShowDeleteModal }) {
  const { slug, version, selectedNode, setSelectedForm, setLoading } =
    useContext(FlowContext);

  const deleteNode = useDeleteWorkflowNode(slug, version);

  return (
    <Modal open={showDeleteModal} setOpen={setShowDeleteModal}>
      <div className="max-w-md inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <div className="pt-5 pb-4 sm:p-6 sm:pb-4">
            <p className="text-md leading-6 mt-3 font-medium text-gray-900 mb-8">
              Are you sure you want to delete this node?
            </p>
            <div className="flex mt-6 mb-6 gap-5">
              <Button
                className="items-center justify-center text-center w-[50%]"
                as="button"
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                as="button"
                className="items-center justify-center w-[50%] bg-red-500 hover:bg-red-700"
                disabled={deleteNode.isLoading}
                onClick={async () => {
                  setLoading(true);
                  try {
                    await deleteNode.mutateAsync({
                      nodeId: selectedNode.id,
                    });
                    setSelectedForm('nodes_list');
                    setLoading(false);
                  } catch (e) {
                    setLoading(false);
                  }
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function NodeMetaDataModal({ showMetaDataModal, setShowMetaDataModal }) {
  const { slug, version, selectedNode, setSelectedNode } =
    useContext(FlowContext);

  const updateNodeData = useUpdateNodeProperties(
    slug,
    version,
    selectedNode.id
  );

  return (
    <Modal open={showMetaDataModal} setOpen={setShowMetaDataModal}>
      <div className="max-w-md inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <div className="pt-5 pb-4 sm:p-6 sm:pb-4">
            <p className="text-lg leading-6 font-medium text-gray-900 mb-3">
              Node metadata
            </p>
            <Formik
              initialValues={{
                name: selectedNode?.name || '',
                description: selectedNode?.description || '',
              }}
              enableReinitialize={true}
              onSubmit={async values => {
                await updateNodeData.mutateAsync(values);
                setSelectedNode(prev => {
                  return {
                    ...prev,
                    name: values.name,
                    description: values?.description,
                  };
                });
                setShowMetaDataModal(false);
              }}
            >
              {() => {
                return (
                  <Form>
                    <TextField id="name" label="Name" mandatory={false} />
                    <div className="mt-4">
                      <TextField
                        id="description"
                        label="Description"
                        as="textarea"
                        rows={3}
                        mandatory={false}
                      />
                    </div>
                    <div className="flex mt-10 mb-6 gap-5">
                      <Button
                        className="items-center justify-center text-center w-[50%]"
                        as="button"
                        onClick={() => {
                          setShowMetaDataModal(false);
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        type="primary"
                        as="submit"
                        className="items-center justify-center w-[50%]"
                        disabled={updateNodeData.isLoading}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function TextField({
  label,
  mandatory = true,
  id,
  disabled = false,
  helpText,
  showError = true,
  inputClassName = '',
  containerClassName = '',
  ...params
}) {
  return (
    <div className={classNames('flex flex-col', containerClassName)}>
      {label && (
        <label
          className="block text-sm text-gray-700 my-2 font-medium"
          htmlFor={id}
        >
          {label}
          {mandatory && <span className="text-red-500">*</span>}
        </label>
      )}
      <Field
        id={id}
        name={id}
        autoComplete="off"
        disabled={disabled}
        className={classNames(
          'border-gray-300 rounded border p-[5px] text-sm disabled:bg-gray-50 outline-offset-2 outline-[#2E70E8] placeholder-gray-300 focus:border-gray-300 focus:border-1 focus:ring-2 focus:ring-[#2E70E8]',
          inputClassName
        )}
        {...params}
      />
      {helpText && helpText}
      {showError && (
        <ErrorMessage
          name={id}
          render={msg => <p className="text-red-500 text-sm pt-2">{msg}</p>}
        />
      )}
    </div>
  );
}

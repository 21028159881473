import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import useClickOutside from '../utils/useClickOutside';

const PopoverColorPicker = ({ color, onChange, label }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className="relative">
      <div>
        <label
          htmlFor="phone-number"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1 relative rounded-md">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <label htmlFor="country" className="sr-only">
              Color Picker
            </label>
            <div
              className="w-12 h-full rounded-none rounded-l-md cursor-pointer border border-[#C8C8C8]"
              style={{
                backgroundColor: color,
              }}
              onClick={() => toggle(true)}
            />
          </div>
          <input
            type="text"
            name={label}
            id={label}
            className="block pl-20 text-gray-800 rounded-md w-[200px] focus:ring-0 border-1 text-base uppercase border-[#C8C8C8] placeholder-gray-300"
            placeholder="#ffffff"
            maxLength={7}
            value={color}
            onChange={event => onChange(event.target.value)}
            onFocus={() => toggle(true)}
            style={{ borderColor: '#C8C8C8', borderWidth: 1 }}
          />
        </div>
      </div>

      {isOpen && (
        <div
          className="absolute left-0 rounded-lg shadow-md z-10"
          ref={popover}
        >
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default PopoverColorPicker;

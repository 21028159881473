import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  loggedInAPI,
  buildAPIURL,
  useEnvState,
  buildSubscriptionAPIURL,
  subscriptionLoggedInAPI,
} from 'stores/AuthStore';
import toast from 'react-hot-toast';

const getBrandsList = async (page = 0) => {
  let offset = page * 20;
  let url = buildAPIURL(`/client_tenant/?limit=20&offset=${offset}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTenantList({ page = 0 }) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/client_tenant`, page],
    () => getBrandsList(page),
    { keepPreviousData: true }
  );
}

const getBrandDetails = async tenantID => {
  let url = buildAPIURL(`/client_tenant/${tenantID}/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTenantDetails(tenantID) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/client_tenant/${tenantID}`],
    () => getBrandDetails(tenantID),
    { enabled: !!tenantID }
  );
}

const getTenantPreferences = async tenantID => {
  let url = buildSubscriptionAPIURL(
    `/nc_client_tenant/${tenantID}/category/?limit=100`
  );
  const { data } = await subscriptionLoggedInAPI.get(url);
  return data;
};

function useTenantPreferences(tenantID) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/client_tenant_preferences/`],
    () => getTenantPreferences(tenantID)
  );
}

const useAddTenant = () => {
  const url = buildAPIURL(`/client_tenant/`);
  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast.error(`Error saving : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
};

const useEditTenant = tenantID => {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildAPIURL(`/client_tenant/${tenantID}/`);
  return useMutation(data => loggedInAPI.put(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/client_tenant/${tenantID}`
      );
    },
    onError: error => {
      toast.error(`Error saving : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
};

const useEditPreference = (brandID, categorySlug) => {
  const envState = useEnvState();
  const queryClient = useQueryClient();
  const url = buildSubscriptionAPIURL(
    `/nc_client_tenant/${brandID}/category/${categorySlug}`
  );

  return useMutation(data => subscriptionLoggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/client_tenant_preferences/`
      );
    },
    onError: error => {
      toast.error(`Error saving : ${error.response.data.message}`);
      throw new Error(error.response.data.message);
    },
  });
};

const getTenantsData = async search => {
  const url = buildAPIURL(`/client_tenant_short/?search=${search}`);
  const { data } = await loggedInAPI.get(url);
  return data?.results;
};

const useTenantsData = (search, enabled = true) => {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/client_tenant_short/`, search],
    () => getTenantsData(search),
    { enabled }
  );
};

export {
  useTenantList,
  useAddTenant,
  useTenantDetails,
  useEditTenant,
  useTenantPreferences,
  useEditPreference,
  useTenantsData,
};
